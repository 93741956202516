import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { ImageCapture } from './Components/ImageCapture';
import { StepsConstants } from '../../Constants/StepsConstants';
import { GestureFaceConstants } from '../../Constants/GestureFaceConstants';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { BiometricDataConstants } from '../../Constants/BiometricDataConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../Hooks/useDialog';
import { InformationModal } from '../../Components/Modal/InformationModal';
import smileFace from '../../Assets/smileface.png';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import configSegundaSelfie from '../BiometricData/configSegundaSelfie.json';

export const FourthStep = () => {
    const { confirmPhoto, images, error, attempt, activeStep, gestureType } =
        useContext(BiometricDataContext);
    const [errorMessage, setErrorMessage] = useState(null);
    const navigate = useNavigate();
    const dialog = useDialog();

    const handleOpenModal = () => {
        let dataGesture;
        Object.keys(configSegundaSelfie).forEach((key) => {
            if (configSegundaSelfie[key].id === gestureType) {
                dataGesture = key;
            }
        });
        dialog.setTitle(configSegundaSelfie[dataGesture].title);
        dialog.setContent(configSegundaSelfie[dataGesture].description);
        dialog.OpenDialog();
    };

    useEffect(() => {
        handleOpenModal();
    }, []);

    const handleSetDataAndExecute = async () => {
        setErrorMessage(null);
        let data = {
            entreConsultasImage: [
                {
                    image: images[StepsConstants.THIRD_STEP].photo,
                    typeGesturePhoto: GestureFaceConstants.NEUTRAL_FACE
                },
                {
                    image: images[StepsConstants.FOURTH_STEP].photo,
                    typeGesturePhoto: gestureType
                }
            ],
            step: StepsConstants.FOURTH_STEP,
            biometricDataType: gestureType,
            extension: 'jpg'
        };

        try {
            const res = await confirmPhoto(data, RoutesConstants.LOAN_SUMMARY_PAGE);

            if (res?.ErrorType.includes('serverError') && Number(attempt) < 2) {
                setErrorMessage({
                    title: 'No pudimos validar tu identidad',
                    description:
                        res?.message ||
                        'Hubo un error en el servidor, por favor intentalo nuevamente.'
                });
                return;
            }

            if (res?.ErrorType && Number(attempt) < 2) {
                setErrorMessage({
                    title: res.message.split('.')[0],
                    description: res.message.split('.')[1]
                });
                return;
            }
            if (res.response?.data?.errors && Number(attempt) < 2) {
                setErrorMessage({
                    title: 'No pudimos validar tu identidad',
                    description: 'Te pedimos que vuelvas a tomar la foto.'
                });
            }
        } catch {
            console.log('Error..');
        }
    };

    const biometricGesture = () => {
        switch (gestureType) {
            case BiometricDataConstants.SMILE_FACE:
                return 'sonriendo';
            case BiometricDataConstants.WINK_FACE:
                return 'guino-ojo';
            case BiometricDataConstants.OPEN_MOUTH_FACE:
                return 'boca-abierta';
            default:
                return 'sonriendo';
        }
    };

    const handleRepeatPhoto = () => {
        navigate(`${RoutesConstants.FACE_CAPTURE_PAGE}/${biometricGesture()}`);
    };

    return (
        <Grid>
            <ImageCapture
                title="Último paso a validar, ¡y listo!"
                image={images[StepsConstants.FOURTH_STEP].photo}
                description="Si lograste una foto clara, avancemos."
                action={() => handleSetDataAndExecute()}
                repeatCapture={() => handleRepeatPhoto()}
                error={error}
                attempt={attempt}
                faceValidation={true}
                disabled={errorMessage != null && Number(attempt) < 1}
                errorMessage={errorMessage}
                dialog={dialog}
                step={activeStep}
            />
        </Grid>
    );
};
