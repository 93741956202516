import React from 'react';
import { Outlet } from 'react-router-dom';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { Box } from '@mui/material';
import { Description, Title, offer_gradients } from './OfferPage.styled';

const TitleDesc = ({ title, description }) => {
    return (
        <Box py={[0, 3]} mt={[3, 5, '54px']}>
            <>
                {title && <Title>{title}</Title>}
                {description && <Description mt={[1, 2]}>{description}</Description>}
            </>
        </Box>
    );
};

const OfferTitle = () => {
    return (
        <TitleDesc
            title="Quiero mi préstamo"
            description="Con tu celular y DNI podremos buscar la mejor oferta para vos."
        />
    );
};

export const OfferMobilePages = () => {
    const layoutProps = {
        desktop: {
            showDescription: true
        },
        mobile: {
            gradients: offer_gradients,
            background: 'transparent'
        }
    };
    return (
        <BNPLLayout ContainerPanel={<OfferTitle />} Form={<Outlet />} layoutProps={layoutProps} />
    );
};
