import React from 'react';
import { Typography, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RoutesConstants } from '../Constants/RoutesConstants';

export const AcceptTermsAndPolicy = ({
    sx = { fontWeight: 'regular', paddingTop: '101px' },
    sxLink = {}
}) => {
    return (
        <Typography
            variant="h7"
            component="h5"
            color="#636569"
            textAlign="start"
            fontFamily="Archivo"
            sx={sx}>
            Al continuar declaro que soy mayor de edad y acepto los{' '}
            <b>
                <Link sx={sxLink} component={RouterLink} to={RoutesConstants.TERMS_PAGE}>
                    Términos y condiciones
                </Link>
            </b>{' '}
            y las{' '}
            <b>
                <Link sx={sxLink} component={RouterLink} to={RoutesConstants.PRIVACY_PAGE}>
                    Políticas de privacidad
                </Link>
            </b>{' '}
            de Directo.
        </Typography>
    );
};
