import { Typography, styled, Stack, Avatar } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const BottomContainer = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundColor: ColorConstants.WHITE,
        borderRadius: '32px',
        padding: '28px'
    },
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
}));

export const TopTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: '210px'
    },
    maxWidth: '279px',
    width: '100%',
    fontSize: '20px',
    fontFamily: 'Archivo'
}));

export const BottomTypography = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '24px'
    },
    fontSize: '16px',
    color: ColorConstants.blue[500],
    fontFamily: 'Archivo',
    marginLeft: '8px'
}));

export const AvatarIcon = styled(Avatar)`
    background-color: ${ColorConstants.green[100]};
    color: ${ColorConstants.blue[500]};
    width: 2rem;
    height: 2rem;
`;

export const Description = ({ title, description }) => (
    <>
        <Typography
            component="h4"
            sx={{
                fontSize: { xs: 24, sm: 32, md: 48 },
                fontWeight: 'bold',
                letterSpacing: { xs: '-0.02em', sm: 'normal' }
            }}
            color={ColorConstants.blue[900]}
            mt={[3, 3, '54px']}>
            {title}
        </Typography>
        <Typography
            variant="h6"
            component="h5"
            fontFamily={['Public Sans', 'Archivo']}
            color={ColorConstants.blue[900]}
            sx={{ fontSize: { xs: 24, sm: 24, md: 20 }, fontWeight: 'regular' }}>
            {description}
        </Typography>
    </>
);

export const gradients = [
    {
        type: 'circle',
        width: '316px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-120px',
        top: '300px'
    },
    {
        type: 'circle',
        width: '355px',
        height: '333px',
        bgcolor: '#7DEE80',
        blur: '100px',
        left: '290px',
        top: '180px'
    },
    {
        type: 'circle',
        width: '80px',
        height: '216px',
        bgcolor: ColorConstants.WHITE,
        blur: '100px',
        left: '270px',
        top: '165px'
    },
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '10px',
        top: '-310px'
    }
];
