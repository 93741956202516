import React from 'react';
import { InstallmentOption, PaymentMethodOption } from '../';

const renderOption = (variant, option, onClick, selectedValue, props) => {
    switch (variant) {
        case 'paymentMethod':
            return (
                <PaymentMethodOption
                    key={option.id}
                    value={option}
                    onClick={() => onClick(option)}
                    className={selectedValue.id == option.id ? 'selected' : ''}
                />
            );
        case 'installment':
            return (
                <InstallmentOption
                    key={`${option.quantityRate}x${option.value}`}
                    value={option}
                    onClick={() => onClick(option)}
                    className={selectedValue.quantityRate == option.quantityRate ? 'selected' : ''}
                    {...props}
                />
            );
        default:
            return null;
    }
};

export const SelectList = ({ options = [], selectedValue, onClick, variant, ...props}) => {
    return <>{options.map((option) => renderOption(variant, option, onClick, selectedValue, props))}</>;
};
