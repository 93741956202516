import { useContext } from 'react';
import { Box, Stack } from '@mui/material';
import { BNPLCrossDeviceContext } from '../../Contexts/BNPLCrossDevice/BNPLCrossDeviceContext';
import { DescriptionForm } from '../../Components/BNPL';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import QRCode from 'react-qr-code';
import STRINGS from '../../i18n/local/es.json';

export const QRCodeImage = (props) => {
    const { state } = useContext(BNPLCrossDeviceContext);
    const { qrUrl } = state;
    if (qrUrl) {
        return (
            <Box {...props} display="flex" justifyContent="center">
                <QRCode value={qrUrl} />
            </Box>
        );
    }
};

export const QRCodePage = () => {
    return (
        <Stack justifyContent="space-between">
            <ReturnButton />
            <DescriptionForm
                title={STRINGS.scan_qr_code.continuing_from_mobile.CONTENT_TITLE}
                description={STRINGS.scan_qr_code.continuing_from_mobile.CONTENT_SUBTITLE}
            />
            <Box my={6} textAlign="center">
                <QRCodeImage />
            </Box>
            <BackHomeDialogButton />
        </Stack>
    );
};
