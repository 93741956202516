import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../Global/AppContext';
import { getItemService } from '../../Services/LocalStorageService';
import { getCreditDetailById } from '../../Services/LoanActiveService';
import { getCreditListPerPerson } from '../../Services/LoanActiveService';
import LoanActiveContext from './LoanActiveContext';

import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const LoanActiveState = ({ children }) => {
    const location = useLocation();
    const { creditsUser, creditDetailAppState } = useContext(AppContext);
    const [creditList, setCreditList] = useState(null);
    const [creditDetail, setcreditDetail] = useState(null);

    useEffect(() => {
        if (location.pathname === RoutesConstants.LOAN_ACTIVE_PAGE) {
            getItemService('Token') ? getCreditDetail(getItemService('creditDetailId')) : null;
        }
    }, [location.pathname]);

    useEffect(() => {
        if (getItemService('Token')) {
            if (creditsUser !== null) {
                setCreditList(creditsUser);
            }
        }
    }, [creditsUser]);

    useEffect(() => {
        if (getItemService('Token')) {
            if (creditDetailAppState !== null) {
                setcreditDetail(creditDetailAppState);
            }
        }
    }, [creditDetailAppState]);

    /* useEffect(() => {
        getItemService('Token') ? getInitData() : null;
    }, []); */

    const getInitData = async () => {
        getCreditList();
        getItemService('creditDetailId') ? getCreditDetail(getItemService('creditDetailId')) : null;
    };

    const getCreditList = async () => {
        let creditList = await getCreditListPerPerson(
            JSON.parse(getItemService('Person')).personId
        );
        setCreditList(creditList);
    };

    const getCreditDetail = async (requestId) => {
        if (creditDetail !== null) return;
        let _creditDetail = await getCreditDetailById(requestId);
        setcreditDetail(_creditDetail);
    };

    return (
        <LoanActiveContext.Provider
            value={{
                creditList,
                getCreditDetail,
                creditDetail
            }}>
            {children}
        </LoanActiveContext.Provider>
    );
};
