import { createContext, useState, useEffect, useContext } from 'react';
import { useAllowedPaymentMethods } from '../../Pages/Financing/hooks/useAllowedPaymentMethods';
import { useOfferedInstallments } from '../../Pages/Financing/hooks/useOfferedInstallments';
import {
    creditDetailsService,
    getDetailsOfTyC,
    siisaValidationService
} from '../../Services/LoanRequestService';
import { selectCreditService } from '../../Services/OfferService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { confirmLoanService } from '../../Services/LoanRequestService';
import { BNPLConstants } from '../../Constants/BNPLConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { getDataOfRequestLoan } from '../../Services/LoanRequestService';
import { clearMask } from '../../Helpers/Masks';
import { BNPLOrderContext } from '../BNPLOrder/BNPLOrderContext';
import { BNPLSiisaContext } from '../BNPLSiisa/BNPLSiisaContext';
import { INSTALLMENT_TYPE } from '../../Constants/BNPLConstants';
import { APP_ID } from '../../Constants/BNPLConstants';
import { setItemService } from '../../Services/LocalStorageService';

const BNPLFinancingContext = createContext(null);

const BNPLFinancingProvider = ({ children }) => {
    const [hasConfirmationError, setHasConfirmationError] = useState(false);
    const [creditFullDetail, setCreditFullDetail] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dataTyC, setDataTyC] = useState({});
    const [personData, setPersonData] = useState(null);
    const navigate = useNavigate();
    const loanData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData));
    const { feedbackType, setFeedbackType } = useContext(BNPLOrderContext);
    const { getSiisaCode, saveHasValidatedIdentity } = useContext(BNPLSiisaContext);
    const paymentMethod = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.PaymentMethod));

    const { allowedPaymentMethods, error: errorAllowedPaymentMethods } = useAllowedPaymentMethods();

    useEffect(() => {
        const personDataAsJson = globalThis.localStorage.getItem(BNPLConstants.PERSON_DATA);
        const personData = JSON.parse(personDataAsJson);

        setPersonData(personData);
    }, []);

    const {
        installments,
        selectedInstallment,
        handleInstallmentSelection,
        currentTotal: total,
        currentDate: date,
        error: errorOfferedInstallments
    } = useOfferedInstallments();

    const state = {
        allowedPaymentMethods,
        errorAllowedPaymentMethods,
        installments,
        selectedInstallment,
        total,
        date,
        errorOfferedInstallments,
        feedbackType,
        hasConfirmationError,
        personData,
        paymentMethod,
        creditFullDetail,
        dataTyC
    };

    const getCreditDetails = async () => {
        try {
            const creditDetails = await creditDetailsService(loanData.pendingRequestId);
            handleInstallmentSelection(creditDetails.deadline);
        } catch (error) {
            console.log('error', error);
        }
    };

    const getFullCreditDetails = async () => {
        try {
            const creditFullDetails = await getDataOfRequestLoan(loanData.pendingRequestId);
            if ( creditFullDetails != null && creditFullDetails != undefined && 
                creditFullDetails.deadline != null && creditFullDetails.deadline != undefined && 
                creditFullDetails.deadline.type === INSTALLMENT_TYPE.ADELANTADO ) {
                const deadlineSchedule = [];
                const date = new Date(creditFullDetails.deadline.expirationDate);

                deadlineSchedule.push("Hoy!");
                for (let i = 1; i < creditFullDetails.deadline.quantityRate; i++) {
                        deadlineSchedule.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
                        date.setMonth(date.getMonth() + 1);
                }
                
                creditFullDetails.operationDetails.deadLines = deadlineSchedule;
            }
            setCreditFullDetail(creditFullDetails);
            const dataTyC = await getDetailsOfTyC();
            setDataTyC(dataTyC);
        } catch (error) {
            console.log('error', error);
        }
    };

    const setSelectedCredit = async (value) => {
        await selectCreditService(loanData.pendingRequestId, {
            ...selectedInstallment,
            amount: value
        });
    };

    const siisaValidation = async (requestId, creditNumber) => {
        await getSiisaCode()
            .then(async (res) => {
                await siisaValidationService({
                    id: requestId,
                    document: clearMask(personData.dni),
                    fingerPrint: res,
                    creditNumber: creditNumber
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const confirmLoan = async () => {
        try {
            if (loanData.pendingRequestId) {
                setIsLoading(true);
                setHasConfirmationError(false);
             
		const personDataAsJsonLocal = localStorage.getItem(BNPLConstants.PERSON_DATA);
        	const personDataLocal = JSON.parse(personDataAsJsonLocal);

		let ecommerceAccountId = localStorage.getItem('ecommerceAccountId');
		if (ecommerceAccountId == null || ecommerceAccountId == undefined) {
			if ( personDataLocal && personDataLocal.ecommerceAccountId !== null && personDataLocal.ecommerceAccountId !== undefined )
			{
				ecommerceAccountId = personDataLocal.ecommerceAccountId;
			} else {
				ecommerceAccountId = 0;
			}
		}
                const confirmationResponse = await confirmLoanService({
                    pendingRequestId: loanData.pendingRequestId,
                    debitPayment: paymentMethod.id != 1,
		    ecommerceAccountId
                });
                setItemService(LoanRequestConstantsLS.CreditDetailId, confirmationResponse.data.requestId);
                await siisaValidation(
                    confirmationResponse.data.requestId,
                    confirmationResponse.data.creditNumber
                );
                await saveHasValidatedIdentity(APP_ID.BNPL, true);
                
                if(creditFullDetail.deadline.type === INSTALLMENT_TYPE.ADELANTADO){
                    setFeedbackType('SUCCESSFUL_PAYMENT_DEBIT');
                } else {
                    setFeedbackType('SUCCESSFUL_PAYMENT');
                }
                navigate(RoutesConstants.BNPL_FEEDBACK);
            }
        } catch (error) {
            setHasConfirmationError(true);
            setIsLoading(false);
        }
    };

    const actions = {
        handleInstallmentSelection,
        getCreditDetails,
        setSelectedCredit,
        setFeedbackType,
        confirmLoan,
        getFullCreditDetails
    };

    return (
        <BNPLFinancingContext.Provider
            value={{
                state,
                actions,
                loanData
            }}>
            {children}
        </BNPLFinancingContext.Provider>
    );
};

export { BNPLFinancingContext, BNPLFinancingProvider };
