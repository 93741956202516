import React, { useContext, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AppContext from '../Contexts/Global/AppContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutesConstants } from './../Constants/RoutesConstants';

const PublicRoute = () => {
    const navigate = useNavigate();
    const { authentication } = useContext(AppContext);

    const location = useLocation();

    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
        window.history.pushState(null, document.title, window.location.href);
    });

    useEffect(() => {
        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
          window.history.pushState(null, document.title, window.location.href);
        });
    }, [location]);

    return !authentication ? <Outlet /> : <Navigate to={RoutesConstants.PRIVATE_HOME} replace={true} />;
    
};

export default PublicRoute;
