/* eslint-disable no-console */
import { Box, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ItemDetail } from '../CloseOffer/ItemDetail';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import GreenSlider from '../../../Components/Slider/GreenSlider';
import { useTimer } from '../../../Hooks/useTimer';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { firstName, maskDate } from '../../../Helpers/Masks';
import OfferSelectionContext from '../../../Contexts/OfferSelection/OfferSelectionContext';
import MaskMoney from './MaskMoney';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { Link } from 'react-router-dom';
import STRINGS from '../../../i18n/local/es.json';

const OfferSelectionPage = () => {
    const {
        offersAPI,
        amountRequest,
        personName,
        getOffers,
        selectCredit,
        goToNextPage,
        skipToOfferSelection,
        creditDetails,
        isRecommendation
    } = useContext(OfferSelectionContext);
    const { timerAction } = useTimer(2);
    const [offer, setOffer] = useState();
    const [amount, setAmount] = useState(amountRequest);
    const [quantityRate, setQuantityRate] = useState(offersAPI[offersAPI.length - 1]?.quantityRate);
    const [lockSendButton, setLockSendButton] = useState(false);
    const [firstCharge, setFirstCharge] = useState(true);
    const [disableSecondSlider, setDisableSecondSlider] = useState(false);
    const [marksQuantityRate, setMarksQuantityRate] = useState([]);

    useEffect(() => {
        const previouslySelectedOffers = async () => {
            /*  if (isRecommendation) {
                await getOffers();
            } else {
                //*Valida si existen datos precargados
                const response = await creditDetails();
                if (response.quantityRate != 0) {
                    setAmount(response.amount);
                    setQuantityRate(response.quantityRate);
                    setMarksQuantityRate([{ value: response.quantityRate }]);
                } else {
                    await getOffers();
                }
            } */
            await getOffers();
        };

        previouslySelectedOffers();
    }, []);

    useEffect(() => {
        if (offersAPI.length > 0) {
            const initialValues = offersAPI[offersAPI.length - 1];
            /* if (quantityRate !== undefined && quantityRate !== null && quantityRate) {
                handleChangeQuantityRate(quantityRate);
            } else {
                handleChangeQuantityRate(initialValues.quantityRate);
            } */
            handleChangeQuantityRate(initialValues.quantityRate);
            const marks = offersAPI.map((offer) => {
                return { value: offer.quantityRate };
            });
            setMarksQuantityRate(marks);
        }
    }, [offersAPI]);

    useEffect(() => {
        if (!firstCharge) {
            setDisableSecondSlider(true);
            setLockSendButton(true);
            timerAction(async () => {
                await getOffers(amount);
                setLockSendButton(false);
                setDisableSecondSlider(false);
            });
        } else {
            setFirstCharge(false);
        }
    }, [amount]);

    const handleOffers = (quantityRateSelected) => {
        const offerSelected = offersAPI.find((offer) => offer.quantityRate == quantityRateSelected);
        setOffer(offerSelected);
    };

    const handleChangeAmount = (value) => {
        setAmount(value);
    };

    const handleChangeQuantityRate = (value) => {
        setQuantityRate(value);
        handleOffers(value);
    };

    const onSubmit = async () => {
        await selectCredit({ ...offer, amount });
    };

    const getInitialExpirationDate = () => {
        const lastOffer = offersAPI[offersAPI.length - 1];
        return maskDate(lastOffer.expirationDate);
    };

    const title = `¡Genial ${firstName(personName)}!`;
    const description = 'Tenemos esta oferta para vos';

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            height={'100%'}
            minHeight={600}>
            <ReturnButton
                route={
                    skipToOfferSelection
                        ? RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE
                        : RoutesConstants.PRIVATE_HOME
                }
            />
            <DescriptionForm
                title={title}
                description={description}
                fontSizeDescription="24px"
                fontFamilyDescription="Public Sans"
            />
            <Grid sx={{ background: 'white', borderRadius: '32px' }}>
                <ItemDetail
                    amount={`$ ${amount?.toLocaleString('es-AR')}`}
                    description={'Monto total que te vamos a prestar'}
                    my={-1}
                    padding={'24px 24px 16px 24px'}
                />

                <GreenSlider
                    onChangeCommitted={handleChangeAmount}
                    onChange={setAmount}
                    max={amountRequest}
                    min={1000}
                    step={1000}
                    value={amount}
                />

                <ItemDetail
                    amount={`${quantityRate} cuotas`}
                    description={`Pagas la primera cuota el ${getInitialExpirationDate() || null}`}
                    my={-1}
                    padding={'24px 24px 16px 24px'}
                />

                <GreenSlider
                    onChangeCommitted={handleChangeQuantityRate}
                    max={offersAPI[offersAPI.length - 1]?.quantityRate}
                    min={offersAPI[0]?.quantityRate}
                    value={quantityRate}
                    marks={marksQuantityRate}
                    step={null}
                    disabled={disableSecondSlider}
                />

                <ItemDetail
                    amount={offer ? MaskMoney(offer?.amountRate.toLocaleString('es-AR')) : ''}
                    description={'Valor cuota por mes'}
                    inverted
                    margin={'24px 20px 24px 24px'}
                />
            </Grid>

            <Box width="100%">
                <Box mt={3} display="flex" width="100%">
                    <GrayButton
                        text={'Continuar'}
                        action={() => onSubmit()}
                        disabled={lockSendButton}
                    />
                </Box>

                <Box mt={['24px', '32px']} mb={['24px', '0px']} textAlign="center">
                    <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>
                </Box>
            </Box>
        </Grid>
    );
};

export default OfferSelectionPage;
