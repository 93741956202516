import { Box } from '@mui/material';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import FormPasswordChangeRequest from '../FormPasswordChangeRequest';

export const PasswordChangeRequest = () => {
    const titleForm = 'Olvidé mi contraseña';
    const descriptionForm =
        '¡No te preocupes! Recuperarla es muy simple, ingresá tu mail y enviaremos un link con los pasos a seguir';
    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton />
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormPasswordChangeRequest />
        </Box>
    );
};
