import { Grid } from '@mui/material';
import { useState, useEffect } from 'react';
import { SelectFormatter } from '../../Helpers/SelectFormatter';
import { GenericFormInput } from './GenericFormInput';

//Actions pasar componente botones
export const GenericForm = ({ formControls, options, handleValue, form }) => {
    const [formatedOptions, setFormatedOptions] = useState(null);

    useEffect(() => {
        if (options && formControls) {
            let formatedOptions = { ...options };
            for (var [key, value] of Object.entries(options)) {
                let control = formControls.filter((x) => x.optionsKey == key)[0];
                if (control.idListKey && control.nameListKey) {
                    formatedOptions[key] = SelectFormatter(
                        value,
                        control.idListKey,
                        control.nameListKey
                    );
                }
            }
            setFormatedOptions(formatedOptions);
        }
    }, [options]);

    return (
        <Grid container>
            {formControls &&
                formControls.map((x) => {
                    return (
                        <Grid item key={x.label} xs={x.size}>
                            {!x.hidden && (
                                <GenericFormInput
                                    key={x.label}
                                    label={x.label}
                                    field={x.field}
                                    type={x.type}
                                    valueData={form[x.field]}
                                    required={x.required}
                                    size={x.size}
                                    icon={x.icon}
                                    rows={x.rows}
                                    list={
                                        x.optionsKey && formatedOptions
                                            ? formatedOptions[x.optionsKey]
                                            : null
                                    }
                                    handle={
                                        x.action
                                            ? (value) => {
                                                  x.action(value, x.field);
                                              }
                                            : (value) => {
                                                  handleValue(value, x.field);
                                              }
                                    }
                                    validationFunction={(value) => {
                                        return x.validation(value, x);
                                    }}
                                    disabled={x.disabled}
                                />
                            )}
                        </Grid>
                    );
                })}
        </Grid>
    );
};
