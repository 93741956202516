import { FormHelperText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const InformationLabel = ({ description, sx }) => {
    return (
        <FormHelperText sx={{ display: 'flex', ...sx }} id="outlined-adornment-amount">
            <CheckIcon fontSize="small" sx={{ mr: '2%' }} /> {description}
        </FormHelperText>
    );
};

export default InformationLabel;
