import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { ItemDetail } from './ItemDetail';
import { AccountDetail } from './AccountDetail';
import { RateDetail } from './RateDetail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import OfferSelectionContext from '../../../Contexts/OfferSelection/OfferSelectionContext';
import { dateMask } from '../../../Helpers/Masks';
import MaskMoney from '../OfferSelection/MaskMoney';
import PenIcon from '../../../Assets/Icons/PenIcon';
import { useCardMethod } from '../../../Hooks/useCardMethod';
import { Link, useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { AccordionDetailsLoan } from './AccordionDetailsLoan';
import loanRequestConfig from '../../../Configs/loanRequestConfig.json';
import loanOperationDetailsConfig from '../../../Configs/loanOperationDetailsConfig.json';
import loanRequestTyCConfig from '../../../Configs/loanRequestTyCConfig.json';
import { FormGroup } from '@mui/material';
import { renderSeparator } from '../../../Helpers/GetRenderSeparator';
import { CheckboxTerms } from './CheckboxTerms';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import { getSectionOfLoanRequest } from '../../../Helpers/GetDetailOfLoanRequest';
import { Helmet } from 'react-helmet';
import STRINGS from '../../../i18n/local/es.json';
import { INSTALLMENT_TYPE } from '../../../Constants/BNPLConstants';

export const DetailsLoanBody = () => {
    const {
        returnToConfig,
        returnToCBU,
        creditDetails,
        getDataRequestLoan,
        dataOfLoanRequest,
        dataOfTyC,
        confirmLoan,
        paymentMethod,
        details
    } = useContext(OfferSelectionContext);
    const { getCards, cards } = useCardMethod();
    const navigate = useNavigate();

    const title = 'Estamos a un paso';
    const description = 'Solo queda que confirmes tu préstamo y lo disfrutes';

    const [isLoading, setIsLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(true);

    useEffect(() => {
        getCards();
        creditDetails();
        getDataRequestLoan();

        if ( dataOfLoanRequest != null && dataOfLoanRequest != undefined && 
             dataOfLoanRequest.deadline != null && dataOfLoanRequest.deadline != undefined && 
             dataOfLoanRequest.deadline.type === INSTALLMENT_TYPE.ADELANTADO ) {
            const deadlineSchedule = [];
            const date = new Date(dataOfLoanRequest.deadline.expirationDate);

            deadlineSchedule.push("Hoy!");
            for (let i = 1; i < dataOfLoanRequest.deadline.quantityRate; i++) {
                    deadlineSchedule.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
                    date.setMonth(date.getMonth() + 1);
            }
            
            dataOfLoanRequest.operationDetails.deadLines = deadlineSchedule;
        }
    }, []);

    const returnConfig = () => {
        returnToConfig('lastStep');
    };

    const confirmOperation = async () => {
        setIsLoading(true);
        await confirmLoan();
        setIsLoading(false);
        /* removeItemService('ClientValidation');
        removeItemService('TokenUrl');
        removeItemService('CardsQuantity');
        removeItemService('LoanData');
        removeItemService('PaymentMethod');
        removeItemService('AllowedMethods');
        removeItemService('creditDetailId');
        removeItemService('creditNumber'); */
    };

    return (
        <Grid container direction="column" justifyContent="flex-start">
            <Helmet>
                <script
                    className="siisa-validation"
                    async
                    src="https://motor.siisa.com.ar/fdsb/fdsb.js?v=1"></script>
            </Helmet>
            <DescriptionForm
                title={title}
                description={description}
                fontSizeDescription="24px"
                fontFamilyDescription="Public Sans"
            />

            <Grid style={{ background: 'white', borderRadius: '32px', pb: '30px' }}>
                <ItemDetail
                    amount={`$ ${details.amount.toLocaleString('es-AR')}`}
                    description={'Monto total que te vamos a prestar'}
                />

                {renderSeparator()}

                <ItemDetail
                    amount={`${details.quantityRate} cuotas`}
                    description={`Pagas la primera cuota el ${dateMask(
                        details.deadline.expirationDate
                    )}`}
                />

                {renderSeparator()}

                <ItemDetail
                    amount={MaskMoney(details.amountRate.toLocaleString('es-AR'))}
                    description={'Valor cuota por mes'}
                    inverted
                />

                {renderSeparator()}

                <Grid item sx={{ m: '0px 24px 24px 24px' }}>
                    <GrayButton
                        height={'32px'}
                        outlined
                        color="primary"
                        action={() => returnConfig()}
                        text={
                            <>
                                <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} /> Cambiar
                                configuración
                            </>
                        }
                    />
                </Grid>
            </Grid>

            <Grid item sx={{ pb: 0 }}>
                <Box width="100%">
                    <ButtonCustom
                        color={ColorConstants.WHITE}
                        text="Confirmar préstamo"
                        action={confirmOperation}
                        isLoading={isLoading}
                        disabled={disableButton}
                    />
                </Box>
            </Grid>

            <Grid
                maxWidth={'100%'}
                style={{
                    background: `${ColorConstants.MIDDLE_LIGHT_BLUE}`,
                    borderRadius: '32px',
                    marginTop: '20px',
                    paddingBottom: '30px'
                }}>
                <FormGroup>
                    <Grid container ml={4} xs={10} mt={2} mb={2}>
                        <Grid item xs={1} ml={-1.5}>
                            <CheckboxTerms disabled={setDisableButton} />
                        </Grid>
                        <Grid item xs={11}>
                            <Typography
                                variant="h7"
                                textAlign="start"
                                fontFamily="Archivo"
                                component="h5"
                                className="font-blue"
                                fontWeight={'Normal'}
                                mt={1.2}
                                ml={1.5}>
                                Al continuar estas aceptando los <b>Términos y condiciones</b> y la{' '}
                                <b>Solicitud de crédito</b>.
                            </Typography>
                        </Grid>
                    </Grid>
                </FormGroup>

                {renderSeparator(true)}

                <AccountDetail
                    value={`CBU ${details.cbu != null ? details.cbu : '...'}`}
                    label={'Te lo depositamos en'}
                    icon={<PenIcon />}
                    action={() => returnToCBU()}
                />

                {renderSeparator(true)}

                {paymentMethod.id == 1 ? (
                    <AccountDetail
                        value={`Primera cuota ${dateMask(details.deadline.expirationDate)}`}
                        label={'Pagarás con efectivo'}
                        icon={<PenIcon />}
                        action={() => navigate(RoutesConstants.LOAN_PAY_METHOD)}
                    />
                ) : (
                    cards.map((card) => {
                        return (
                            <AccountDetail
                                key={card.id}
                                value={`Tarjeta  **** ${card.numero.slice(12)}`}
                                label={'Pagarás con débito automático'}
                                icon={<PenIcon />}
                                action={() => navigate(RoutesConstants.LOAN_PAY_METHOD)}
                            />
                        );
                    })
                )}

                {renderSeparator(true)}

                <AccountDetail
                    body={
                        <RateDetail
                            data={{
                                tea:
                                    dataOfLoanRequest !== undefined &&
                                    dataOfLoanRequest.rates !== undefined &&
                                    dataOfLoanRequest.rates.tea !== undefined
                                        ? dataOfLoanRequest.rates.tea
                                        : '0',
                                tna:
                                    dataOfLoanRequest !== undefined &&
                                    dataOfLoanRequest.rates !== undefined &&
                                    dataOfLoanRequest.rates.tna !== undefined
                                        ? dataOfLoanRequest.rates.tna
                                        : '0',
                                cftea:
                                    dataOfLoanRequest !== undefined &&
                                    dataOfLoanRequest.rates !== undefined &&
                                    dataOfLoanRequest.rates.cft !== undefined
                                        ? dataOfLoanRequest.rates.cft
                                        : '0'
                            }}
                        />
                    }
                />

                {renderSeparator(true)}

                <Typography
                    variant="h7"
                    component="h5"
                    color={ColorConstants.BLUE}
                    textAlign="start"
                    fontFamily="Archivo"
                    sx={{ fontWeight: 'regular', ml: 4, mr: 3, mt: 2 }}>
                    {dataOfLoanRequest
                        ? parse(DOMPurify.sanitize(dataOfLoanRequest.description))
                        : ''}
                </Typography>
                <AccordionDetailsLoan
                    config={loanOperationDetailsConfig}
                    data={getSectionOfLoanRequest(
                        loanOperationDetailsConfig.nameOfSection,
                        dataOfLoanRequest
                    )}
                />
                {renderSeparator(true)}
                <AccordionDetailsLoan
                    config={loanRequestConfig}
                    data={getSectionOfLoanRequest(
                        loanRequestConfig.nameOfSection,
                        dataOfLoanRequest
                    )}
                />
                {renderSeparator(true)}
                <AccordionDetailsLoan
                    config={loanRequestTyCConfig}
                    data={getSectionOfLoanRequest(loanRequestTyCConfig.nameOfSection, dataOfTyC)}
                />
            </Grid>
            <Box mt={['24px', '32px']} mb={['24px', '0px']} textAlign="center">
                <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>
            </Box>
        </Grid>
    );
};
