import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

export const ReturnButtonRounded = () => {
    const navigate = useNavigate(); 

    const handleAction = () => {
        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        if (workflow === WORKFLOW_CONSTANTS.BNPL) {
            const redUrl = localStorage.getItem("BNPLURL");
            navigate(redUrl, { replace: true });
        } else {
            navigate(RoutesConstants.PUBLIC_HOME, { replace: true });
        }
    };

    return (
        <Button
            sx={{ borderRadius: '30px' }}
            variant="outlined"
            onClick={() => handleAction()}>
            <ArrowBackIcon />
            Volver al inicio
        </Button>
    );
};
