import { Stack, Typography } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { moneyMask } from '../../../Helpers/Masks';
import { Option, OptionContainer } from './InstallmentOption.styled';

export const InstallmentOption = ({ value, ...props }) => {
    const total = (value.quantityRate * value.amountRate).toFixed(2);
    return (
        <OptionContainer>
            <Option {...props}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography>
                        {value.quantityRate} x {moneyMask(value.amountRate.toString(), true, 2)}
                    </Typography>
                    {total > (props.total + 1)? (
                        <Typography color={ColorConstants.blue[500]}>
                            {moneyMask(total.toString(), true, 2)}
                        </Typography>
                    ) : (
                        <Typography>Sin interés</Typography>
                    )}
                </Stack>
            </Option>
        </OptionContainer>
    );
};
