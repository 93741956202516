export function MailIcon({ color = 'currentColor', width = '22', height = '18' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21 3C21 1.9 20.1 1 19 1H3C1.9 1 1 1.9 1 3M21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3M21 3L11 10L1 3"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}