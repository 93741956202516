import { Box, Typography, styled } from "@mui/material";
import { ColorConstants } from "../../../Constants/ColorConstants";

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        fontWeight: '600',
        lineHeight: '34px',
        letterSpacing: '-0.02em'
    },
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    letterSpacing: '-0.02em'
}));

export const Description = styled(Typography)`
    font-family: 'Archivo';
`;

export const Circle = styled(Box)`
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: ${ColorConstants.warning[100]};
    outline: 6px solid ${ColorConstants.warning[50]};
    border-radius: 28px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ColorConstants.warning[600]};
`;

export const Image = styled('img')`
    max-width: 327px;
    width: 100%;
`;