import {
    Accordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    styled
} from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const AccordionContainer = styled(Accordion)`
    background: transparent;
    box-shadow: none;
    color: ${ColorConstants.blue[900]};
    &:before {
        display: none;
    }
    &.Mui-expanded:last-of-type {
        margin: 16px 0;
    }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
    p {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
    color: inherit;
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
    background: ${ColorConstants.blue[50]};
    border-radius: 16px;
    margin-top: 5px;
    padding: 16px;
`;
