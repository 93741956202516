import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { clearMask } from '../../Helpers/Masks';
import { areaCodeValidate, phoneNumberValidate } from '../../Helpers/Validations';
import { useForm } from '../../Hooks/useForm';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import {
    creditDetailsService,
    getPersonByDNI,
    requestBNPLLoan,
    requestLoan,
    sendSMSCode,
    siisaValidationService
} from '../../Services/LoanRequestService';
import AppContext from '../Global/AppContext';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { associateRequestToOrder, updateOrderStatus, updateOrderStatusPending } from '../../Services/BNPLService';
import { BNPLConstants, ORDER_STATUS, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { BNPLOrderContext } from '../BNPLOrder/BNPLOrderContext';
import { BNPLSiisaContext } from '../BNPLSiisa/BNPLSiisaContext';
import { logEventRatingBNPLFunnel } from '../../Services/BNPLService';

const BNPLRegisterContext = createContext(null);

const BNPLRegisterProvider = ({ children }) => {
    const { authentication, user } = useContext(AppContext);
    const { orderData } = useContext(BNPLOrderContext);
    const { getSiisaCode } = useContext(BNPLSiisaContext)
    const formValidations = {
        areaCode: [[(value) => areaCodeValidate(value), 'Cód. Área inválido.']],
        phoneNumber: [[(value) => phoneNumberValidate(value), 'Nº celular inválido.']],
        province: [[(value) => !!value || authentication, 'Provincia inválida.']]
    };
    const navigate = useNavigate();

    const {
        values: personData,
        formValidation,
        handleOnChangeFieldEvent,
        handleOnChangeField,
        handleChangeMultipleValues
    } = useForm(
        {
            dni: '',
            gender: null,
            name: '',
            areaCode: '',
            phoneNumber: '',
            province: '',
            fullPhoneNumber: '',
            personId: '',
            provinceId: 0,
            ecommerceAccountId: '',
            cuit: ''
        },
        formValidations
    );
    const { values: checkoutData } = useForm({
        total: 9500,
        order: 123456,
        store: 'Tienda Online Lorem Ipsum'
    });
    const [peopleList, setPeopleList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loanData, setLoanData] = useState(
        JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData))
    );
    const [accountData, setAccountData] = useState(null);

    const getUserInfo = async () => {
        try {
            const response = await getDataPersonService(user.personId);
            const ecommerceAccountId = localStorage.getItem('ecommerceAccountId');
            const personData = {
                dni: String(response.dni),
                gender: response.sexTypeId,
                name: response.userName,
                areaCode: response.phoneCode,
                phoneNumber: response.phoneNumber,
                personId: response.idPerson,
                ecommerceAccountId: ecommerceAccountId
            };
            localStorage.setItem('personData', JSON.stringify(personData));
            handleChangeMultipleValues(personData);
        } catch (error) {
            console.log(error);
        }
    };

    const searchPersonByDniAndGender = (dni, gender) => {
        setLoading(true);
        setError(false);
        getPersonByDNI({ dni: clearMask(dni), sexTypeId: gender })
            .then((res) => {
                if (res.length) {
                    setPeopleList(res);
                    setLoading(false);
                    navigate(RoutesConstants.IDENTIFICATION_CONFIRM_PAGE);
                } else {
                    setLoading(false);
                    setError(true);
                }
            })
            .catch(() => {
                setLoading(false);
                setError(true);
            });
    };

    const siisaValidation = async (pendingRequestId) => {
        let siisaValue;

        try {
            await getSiisaCode()
                .then((res) => {
                    siisaValue = res;
                })
                .catch((err) => {
                    console.log(err);
                });
            if (pendingRequestId) {
                await siisaValidationService({
                    id: pendingRequestId,
                    document: clearMask(personData.dni),
                    fingerPrint: siisaValue
                });
            } else {
                await siisaValidationService({
                    document: clearMask(personData.dni),
                    fingerPrint: siisaValue
                });
            }
        } catch (error) {
            console.log(error);
        }
    };

    const validateUserAndSendMessage = async () => {
        const fullPhoneNumber = `${personData.areaCode}${personData.phoneNumber}`;
        handleOnChangeField('fullPhoneNumber', fullPhoneNumber);
        localStorage.setItem(
            'personData',
            JSON.stringify({ ...personData, fullPhoneNumber: fullPhoneNumber })
        );
        localStorage.setItem(LoanRequestConstantsLS.LoanData, JSON.stringify({}));

        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        const body = {
            name: personData.name,
            dni: clearMask(personData.dni),
            sexTypeId: personData.gender
        };
        (workflow === WORKFLOW_CONSTANTS.CS ? requestBNPLLoan(body) : requestLoan(body))
            .then((res) => {
                if (res.code != undefined && res.code != null && res.code == "ERR_BAD_REQUEST") {
                    siisaValidation(res.response.data.errors[0].pendingRequestId);
		    // TODO FUNNEL BNPL: First step
	            // INIT
	            const localStorageHash = localStorage.getItem("orderHash");
	            if ( localStorageHash )
	            {
	                const storageOrderID = localStorage.getItem("bnplOrderId")
	                logEventRatingBNPLFunnel("RATING_REJECTED", storageOrderID, localStorageHash, res.response.data.errors[0].pendingRequestId)
	            }
	            // FINISH
                    updateOrderStatusPending(orderData.id, ORDER_STATUS.CANCEL, res.response.data.errors[0].pendingRequestId)
                            .then(() => {
                                navigate(RoutesConstants.BNPL_NO_OFFERS);
                            })
                            .catch(() => {
                                console.log(error);
                                navigate(RoutesConstants.BNPL_NO_OFFERS);
                            });
                } else {
                    siisaValidation(res.data.pendingRequestId);
                    if (res.data.offer.length === 0) {
			// TODO FUNNEL BNPL: First step
                        // INIT
                        const localStorageHash = localStorage.getItem("orderHash");
                        if ( localStorageHash )
                        {
                            const storageOrderID = localStorage.getItem("bnplOrderId")
                            logEventRatingBNPLFunnel("RATING_REJECTED", storageOrderID, localStorageHash, res.data.pendingRequestId)
                        }
                        // FINISH
                        updateOrderStatusPending(orderData.id, ORDER_STATUS.CANCEL, res.data.pendingRequestId)
                            .then(() => {
                                navigate(RoutesConstants.BNPL_NO_OFFERS);
                            })
                            .catch(() => {
                                console.log(error);
                                navigate(RoutesConstants.BNPL_NO_OFFERS);
                            });
                    } else {
                        localStorage.setItem(LoanRequestConstantsLS.LoanData, JSON.stringify(res.data));
                        setLoanData(res.data);
			// TODO FUNNEL BNPL: First step
                        // INIT
                        const localStorageHash = localStorage.getItem("orderHash");
                        if ( localStorageHash )
                        {
                            const storageOrderID = localStorage.getItem("bnplOrderId")
                            logEventRatingBNPLFunnel("RATING_APPROVED", storageOrderID, localStorageHash, res.data.pendingRequestId)
                        }
                        // FINISH
                        if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                            if (personData.personId === 0) {
                                creditDetailsService(res.data.pendingRequestId)
                                    .then((res) => {
                                        handleOnChangeField('personId', res.personId);
                                        localStorage.setItem(
                                            'personData',
                                            JSON.stringify({
                                                ...personData,
                                                personId: res.personId,
                                                fullPhoneNumber: fullPhoneNumber
                                            })
                                        );
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                        navigate(RoutesConstants.IDENTIFICATION_CONFIRM_PAGE);
                                    });
                            }
                            associateRequestToOrder(orderData.id, res.data.pendingRequestId)
                                .then(() => {
                                    sendSMSCode({
                                        pendingRequestId: res.data.pendingRequestId,
                                        phoneNumber: fullPhoneNumber
                                    })
                                        .then(() => {
                                            navigate(RoutesConstants.BNPL_TOKEN_PAGE);
                                        })
                                        .catch((error) => {
                                            console.log(error);
                                        });
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        } else if (workflow === WORKFLOW_CONSTANTS.CS) {
                            sendSMSCode({
                                pendingRequestId: res.data.pendingRequestId,
                                phoneNumber: fullPhoneNumber
                            })
                                .then(() => {
                                    navigate(RoutesConstants.BNPL_TOKEN_PAGE);
                                })
                                .catch((error) => {
                                    console.log(error);
                                });
                        } else {
                            navigate(RoutesConstants.PUBLIC_HOME);
                        }
                    }
                }
            })
            .catch((error) => {
                siisaValidation()
		                .then(() => {
			                console.log(error);
                            navigate(RoutesConstants.BNPL_NO_OFFERS);
                        })
                        .catch(() => {
                            console.log(error);
                            navigate(RoutesConstants.BNPL_NO_OFFERS);
                        });
            });
    };

    return (
        <BNPLRegisterContext.Provider
            value={{
                personData,
                loanData,
                formValidation,
                handleOnChangeFieldEvent,
                handleOnChangeField,
                handleChangeMultipleValues,
                searchPersonByDniAndGender,
                loading,
                peopleList,
                error,
                setError,
                validateUserAndSendMessage,
                checkoutData,
                getUserInfo,
                accountData,
                setAccountData
            }}>
            {children}
        </BNPLRegisterContext.Provider>
    );
};

export { BNPLRegisterContext, BNPLRegisterProvider };
