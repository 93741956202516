import { FormControl, RadioGroup as MuiRadioGroup } from '@mui/material';
import { RadioButton } from './RadioButton';

export const RadioGroup = ({ options = [], selectedValue, onChange }) => {
    return (
        <FormControl fullWidth>
            <MuiRadioGroup value={selectedValue} onChange={onChange} sx={{ gap: 2 }}>
                {options.map((opt, i) => {
                    return (
                        <RadioButton value={opt} key={i} isSelected={selectedValue == opt.value} />
                    );
                })}
            </MuiRadioGroup>
        </FormControl>
    );
};
