import React from 'react';
import { ReturnButton } from '../../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';
import { Box } from '@mui/system';
import SelectionMethodForm from './SelectionMethodForm';
import { Grid } from '@mui/material';

const PaymentMethodPage = () => {
    const titleForm = '¿Como querés pagar tu préstamo?';

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton />
                </Grid>
                <DescriptionForm
                    title={titleForm}
                    pb={3}
                />
            </Box>
            <SelectionMethodForm />
        </Box>
    );
};

export default PaymentMethodPage;
