import { styled, Box } from '@mui/material';

export const BoxContent = styled(Box)(({ theme }) => ({
    [theme.breakpoints.between('xs', 'md')]: {
        background: 'white',
        padding: '24px',
        marginBottom: '4rem',
        borderRadius: '40px'
    }
}));
