import { useContext, useEffect, useState } from 'react';
import STRINGS from '../../i18n/local/es.json';
import { Grid, Stack, FormGroup, Typography } from '@mui/material';
import SellerCard from '../../Components/BNPL/SellerCard/SellerCard';
import InstallmentsCard from '../../Components/BNPL/InstallmentsCard/InstallmentsCard';
import { dateMask, moneyMask } from '../../Helpers/Masks';
import InstallmentDate from './components/InstallmentDate';
import PaymentMethodCard from './components/PaymentMethodCard';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { BNPLFinancingContext } from '../../Contexts/BNPLFinancing/BNPLFinancingContext';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ConfirmationPageSkeleton } from './components/ConfirmationPageSkeleton';
import { verifyBiometryRequirements } from '../../Services/OfferService';
import { ColorConstants } from '../../Constants/ColorConstants';
import { CheckboxTerms } from '../LoanRequest/Details/CheckboxTerms';
import { renderSeparator } from '../../Helpers/GetRenderSeparator';
import { AccountDetail } from '../LoanRequest/Details/AccountDetail';
import PenIcon from '../../Assets/Icons/PenIcon';
import { RateDetail } from '../LoanRequest/Details/RateDetail';
import { AccordionDetailsLoan } from '../LoanRequest/Details/AccordionDetailsLoan';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';
import loanOperationDetailsConfig from '../../Configs/loanOperationDetailsConfig.json';
import { getSectionOfLoanRequest } from '../../Helpers/GetDetailOfLoanRequest';
import loanRequestConfigBNPL from '../../Configs/loanRequestConfigBNPL.json';
import loanRequestTyCConfig from '../../Configs/loanRequestTyCConfig.json';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { AlertDialog } from '../../Components/BNPL/Dialog/AlertDialog';
import { useDialog } from '../../Hooks/useDialog';
import { INSTALLMENT_TYPE } from '../../Constants/BNPLConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

const ConfirmationPage = () => {
    const navigate = useNavigate();
    const dialog = useDialog();
    const { state, actions, loanData } = useContext(BNPLFinancingContext);
    const { orderData } = useContext(BNPLOrderContext);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const { handleInstallmentSelection, confirmLoan, getCreditDetails, getFullCreditDetails } =
        actions;

    const {
        selectedInstallment,
        total,
        hasConfirmationError,
        paymentMethod,
        creditFullDetail,
        dataTyC,
        date
    } = state;

    const OPERATION_DETAILS = {
        merchant_name: orderData.merchant_Name,
        src: '/tienda_online.svg',
        order: orderData.merchant_Order_Id,
        price: orderData.price
    };

    useEffect(() => {
        getCreditDetails();
        getFullCreditDetails();

        // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("CONFIRMACION", storageOrderID, localStorageHash)
            }
            // FINISH

        if ( creditFullDetail != null && creditFullDetail != undefined && 
             creditFullDetail.deadline != null && creditFullDetail.deadline != undefined && 
             creditFullDetail.deadline.type === INSTALLMENT_TYPE.ADELANTADO ) {
            const deadlineSchedule = [];
            const date = new Date(creditFullDetail.deadline.expirationDate);

            deadlineSchedule.push("Hoy!");
            for (let i = 1; i < creditFullDetail.deadline.quantityRate; i++) {
                    deadlineSchedule.push(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`);
                    date.setMonth(date.getMonth() + 1);
            }
            
            creditFullDetail.operationDetails.deadLines = deadlineSchedule;
        }
    }, []);

    const handleInstallmentChange = () => {
        navigate(RoutesConstants.FINANCING_PAGE);
    };

    const handlePaymentMethodEdit = () => {
        navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL);
    };

    useEffect(() => {
        if (hasConfirmationError) {
            dialog.OpenDialog();
        }
    }, [hasConfirmationError]);

    const handleOnContinue = () => {
        dialog.CloseDialog();
        confirmLoan();
    };

    return (
        <>
            <Stack width="100%" spacing={5} mb={2}>
                <Grid container xs={12} rowSpacing={3}>
                    <Grid item xs={12}>
                        {OPERATION_DETAILS.price && (
                            <SellerCard
                                avatar={{ src: OPERATION_DETAILS.src }}
                                value={moneyMask(OPERATION_DETAILS.price.toString(), true, 2)}
                                orderId={OPERATION_DETAILS.order}
                                shopName={OPERATION_DETAILS.merchant_name}
                            />
                        )}
                        <InstallmentsCard 
	                    orderValue={OPERATION_DETAILS.price ? OPERATION_DETAILS.price : 0} 
                            total={moneyMask(total, true, 2)}
	    		    totalMon={total}
                            numberOfInstallments={selectedInstallment?.quantityRate}
                            installmentAmount={moneyMask(
                                selectedInstallment?.amountRate.toString(),
                                true,
                                2
                            )}
                            onChangeInstallments={handleInstallmentSelection}
                            showCTAAsButton
                            handleEditButtonClick={handleInstallmentChange}
                        />
                        <InstallmentDate date={date} />
                        <PaymentMethodCard
                            onPaymentMethodEdit={handlePaymentMethodEdit}
                            paymentMethod={paymentMethod.id}
                        />
                        {/* <CardData
                            creditCardDescription={`Tarjeta ${paymentMethod.numero}`}
                            tnaValue="300,00%"
                            teaValue="1.360,75%"
                            cftnaValue="344,61%"
                        /> */}
                        
                        <ButtonCustom
                            disabled={buttonDisabled}
                            text={STRINGS.confirmation.BUTTON_TEXT}
                            action={confirmLoan}
                        />
                        <Grid
                            maxWidth={'100%'}
                            style={{
                                background: `${ColorConstants.MIDDLE_LIGHT_BLUE}`,
                                borderRadius: '32px',
                                marginTop: '20px',
                                paddingBottom: '30px'
                            }}>
                            <FormGroup>
                                <Grid container ml={4} xs={10} mt={2} mb={2}>
                                    <Grid item xs={1} ml={-1.5}>
                                        <CheckboxTerms disabled={setButtonDisabled} />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Typography
                                            variant="h7"
                                            textAlign="start"
                                            fontFamily="Archivo"
                                            component="h5"
                                            className="font-blue"
                                            fontWeight={'Normal'}
                                            mt={1.2}
                                            ml={1.5}>
                                            Al continuar estas aceptando los{' '}
                                            <b>Términos y condiciones</b> y la{' '}
                                            <b>Solicitud de crédito</b>.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            {renderSeparator(true)}

                            {paymentMethod.id == 1 ? (
                                <AccountDetail
                                    value={`Primera cuota ${
                                        creditFullDetail.deadline &&
                                        dateMask(creditFullDetail.deadline.expirationDate)
                                    }`}
                                    label={'Pagarás con efectivo'}
                                    icon={<PenIcon />}
                                    action={() => navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL)}
                                />
                            ) : (
                                <AccountDetail
                                    value={
                                        paymentMethod.numero
                                            ? `Tarjeta ${paymentMethod.numero}`
                                            : ''
                                    }
                                    label={'Pagarás con débito automático'}
                                    icon={<PenIcon />}
                                    action={() => navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL)}
                                />
                            )}

                            {renderSeparator(true)}

                            {creditFullDetail.rates && (
                                <AccountDetail
                                    body={
                                        <RateDetail
                                            data={{
                                                tea:
                                                    creditFullDetail.rates &&
                                                    creditFullDetail.rates.tea !== undefined
                                                        ? creditFullDetail.rates.tea
                                                        : '0',
                                                tna:
                                                    creditFullDetail.rates &&
                                                    creditFullDetail.rates.tna !== undefined
                                                        ? creditFullDetail.rates.tna
                                                        : '0',
                                                cftea:
                                                    creditFullDetail.rates &&
                                                    creditFullDetail.rates.cft !== undefined
                                                        ? creditFullDetail.rates.cft
                                                        : '0'
                                            }}
                                        />
                                    }
                                />
                            )}
                            {renderSeparator(true)}

                            <Typography
                                variant="h7"
                                component="h5"
                                color={ColorConstants.BLUE}
                                textAlign="start"
                                fontFamily="Archivo"
                                sx={{ fontWeight: 'regular', ml: 4, mr: 3, mt: 2 }}>
                                {creditFullDetail
                                    ? parse(DOMPurify.sanitize(creditFullDetail.description))
                                    : ''}
                            </Typography>
                            <AccordionDetailsLoan
                                config={loanOperationDetailsConfig}
                                data={getSectionOfLoanRequest(
                                    loanOperationDetailsConfig.nameOfSection,
                                    creditFullDetail
                                )}
                            />
                            {renderSeparator(true)}
                            <AccordionDetailsLoan
                                config={loanRequestConfigBNPL}
                                data={getSectionOfLoanRequest(
                                    loanRequestConfigBNPL.nameOfSection,
                                    creditFullDetail
                                )}
                            />
                            {renderSeparator(true)}
                            <AccordionDetailsLoan
                                config={loanRequestTyCConfig}
                                data={getSectionOfLoanRequest(
                                    loanRequestTyCConfig.nameOfSection,
                                    dataTyC
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <BackHomeDialogButton />
            </Stack>

            {paymentMethod.id == 1 ? (
                <AlertDialog
                    open={dialog.showDialog}
                    onClose={dialog.CloseDialog}
                    onContinue={handleOnContinue}
                    title="Algo salió mal"
                    description="Tuvimos un problema. Por favor, intentá nuevamente para confirmar tu compra."
                    buttonText="Volver a intentar"
                />
            ) : (
                <AlertDialog
                    open={dialog.showDialog}
                    onClose={dialog.CloseDialog}
                    onContinue={handleOnContinue}
                    title="Algo salió mal"
                    description="Tuvimos un problema. Por favor revisá que tu tarjeta tenga fondos para realizar el débito de la primera cuota."
                    buttonText="Volver a intentar"
                />
            )}
            
        </>
    );
};

export default ConfirmationPage;
