import React from 'react';
import { Box } from '@mui/system';
import Slider from '@mui/material/Slider';
import { ColorConstants } from '../../Constants/ColorConstants';

const GreenSlider = ({
    max,
    min,
    step,
    value,
    marks,
    onChangeCommitted,
    onChange,
    disabled = false
}) => {
    return (
        <Box px={3} mr={1}>
            <Slider
                value={value}
                valueLabelDisplay="auto"
                max={max}
                min={min}
                onChangeCommitted={(event, value) => onChangeCommitted(value)}
                onChange={onChange ? (e) => onChange(e.target.value) : null}
                step={step}
                marks={marks}
                sx={{
                    color: ColorConstants.GREEN,
                    height: 8,
                    '& .MuiSlider-thumb': {
                        backgroundColor: ColorConstants.LIGHT_BLUE,
                        width: '24px',
                        height: '24px'
                    }
                }}
                disabled={disabled}
            />
        </Box>
    );
};

export default GreenSlider;
