import { Box, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const RadioIcon = styled(Box)`
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid ${ColorConstants.gray[600]};
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
        color: ${ColorConstants.green[900]};
        background: ${ColorConstants.green[100]};
        border-color: ${ColorConstants.green[100]};
    }
`;

const StyledRadioButton = styled(FormControlLabel)`
    height: 56px;
    margin: 0;
    background: white;
    border: 1px solid ${ColorConstants.gray[200]};
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    padding: 16px;

    color: ${ColorConstants.blue[900]};

    .MuiTypography-root {
        font-weight: 700;
        font-size: 18px;
    }

    .Mui-checked {
        color: ${ColorConstants.green[500]};
    }

    &.selected {
        outline: 2px solid ${ColorConstants.green[500]};
    }
`;

export default StyledRadioButton;
