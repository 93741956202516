import { Stack } from '@mui/material';
import { Description, DescriptionForm } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import STRINGS from '../../i18n/local/es.json';
import { EmailTokenForm } from './EmailTokenForm';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { Link } from 'react-router-dom';
import { getItemService } from '../../Services/LocalStorageService';
import { RoutesConstants } from '../../Constants/RoutesConstants';

const FormDescription = () => {
    const email = getItemService('email');
    return (
        <Stack mb={['36px', '46px']}>
            <ReturnButton />
            <Stack gap="16px">
                <DescriptionForm
                    title={STRINGS.verify_email_code.FORM_TITLE}
                    description={STRINGS.verify_email_code.FORM_DESCRIPTION.replace('email', email)}
                />
                <Link to={RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE}>{STRINGS.verify_email_code.CHANGE_EMAIL}</Link>
            </Stack>
        </Stack>
    );
};

export const VerifyEmailCodePage = () => {
    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={STRINGS.identification.TITLE}
                    description={STRINGS.identification.SUBTITLE}
                    returnButton={false}
                />
            }
            Form={
                <Stack>
                    <FormDescription />
                    <EmailTokenForm />
                </Stack>
            }
            layoutProps={{
                desktop: {
                    showFullWidthDesciption: true
                }
            }}
        />
    );
};
