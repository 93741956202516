import { Route } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ConfirmPage } from '../../Pages/Identification/ConfirmPage';
import { ContactDetailsPage } from '../../Pages/Identification/ContactDetailsPage';
import { DNIPage } from '../../Pages/Identification/DNIPage';
import { GenderPage } from '../../Pages/Identification/GenderPage';
import { IdentificationPage } from '../../Pages/Identification/IdentificationPage';
import ValidateBNPLTokenPage from '../../Pages/LoanRequest/Token/BNPLToken/ValidateBNPLTokenPage';
import { CheckAvailableBalancePage } from '../../Pages/Identification/CheckAvailableBalancePage';
import { Successful } from '../../Pages/Register/CreateAccount/Successful';
import { ExistingAccount } from '../../Pages/Feedback/ExistingAccount/ExistingAccountPage';

export const IdentificationRoutes = () => {
    return (
        <Route element={<IdentificationPage />}>
            <Route exact path={RoutesConstants.IDENTIFICATION_DNI_PAGE} element={<DNIPage />} />
            <Route
                exact
                path={RoutesConstants.IDENTIFICATION_GENDER_PAGE}
                element={<GenderPage />}
            />
            <Route
                exact
                path={RoutesConstants.IDENTIFICATION_CONFIRM_PAGE}
                element={<ConfirmPage />}
            />
            <Route
                exact
                path={RoutesConstants.IDENTIFICATION_CONTACT_PAGE}
                element={<ContactDetailsPage />}
            />
            <Route path={RoutesConstants.BNPL_TOKEN_PAGE} element={<ValidateBNPLTokenPage />} />
            {<Route
                path={RoutesConstants.CHECK_AVAILABLE_BALANCE}
                element={<CheckAvailableBalancePage />}
            />}
            <Route
                exact
                path={RoutesConstants.VERIFY_PASSWORD_SUCCESSFUL_PAGE}
                element={<Successful />}
            />

            <Route exact path={RoutesConstants.EXISTING_ACCOUNT} element={<ExistingAccount />} />
        </Route>
    );
};
