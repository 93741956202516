import { useState } from 'react';
import { getItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import ApprovedLoanContext from './ApprovedLoanContext';

export const ApprovedLoanState = ({ children }) => {
    const [person] = useState(
        getItemService(LoanRequestConstantsLS.PersonToGetLoan)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))
            : null
    );

    return (
        <ApprovedLoanContext.Provider
            value={{
                personName: person?.personName
            }}>
            {children}
        </ApprovedLoanContext.Provider>
    );
};
