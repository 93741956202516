import {
    styled,
    Avatar as MuiAvatar,
    ListItem as MuiListItem,
    List,
    Grid,
    Typography,
    Button,
    Box
} from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const Card = styled(Box)`
    color: ${ColorConstants.blue[900]};
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: linear-gradient(117.06deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 99.28%);
    border-radius: 32px;
`;

export const ListItem = styled(MuiListItem)`
    padding: 0;

    .MuiListItemText-primary,
    .MuiListItemText-secondary {
        color: inherit;
        font-size: 14px;
    }

    .MuiListItemText-primary {
        font-weight: 700;
    }
`;

export const StyledList = styled(List)`
    color: ${ColorConstants.blue[500]};
    display: flex;
    flex-direction: column;
    gap: 24px;
    .MuiListItemText-primary {
        font-size: 16px;
        font-weight: 500;
    }
`;

export const StyledAvatar = styled(MuiAvatar)`
    color: inherit;
    background: ${ColorConstants.green[50]};

    &.green {
        background: ${ColorConstants.green[100]};
        border: 5px solid ${ColorConstants.green[50]};
    }
`;

export const GridMain = styled(Grid)`
    display: flex;
    justify-content: center;
    a {
        color: ${ColorConstants.blue[900]};
    }
`;

export const GridDescription = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        margin: ' 20px 20px 0 24px'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 30px 0px 0px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 5px 0px 0px',
        padding: '0px 95px 0px 0px'
    },
    borderRadius: '40px'
}));

export const GridForm = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        padding: '29px 24px',
        borderRadius: '40px 40px 0px 0px',
        backgroundColor: 'transparent'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 0px 0px 30px',
        padding: '64px',
        borderRadius: '40px',
        backgroundColor: 'white',
        maxWidth: '540px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 0px 0px 5px',
        padding: '64px',
        maxWidth: '540px'
    },
    display: 'flex',
    justifyContent: 'center'
}));

export const Logo = styled('img')(() => ({
    maxWidth: '152px'
}));

export const InstallmentText = styled(Typography)`
    color: ${ColorConstants.blue[500]};
    font-weight: 500;
    font-size: 18px;
`;

export const TotalText = styled(Typography)`
    font-size: 18px;
`;

export const LinkButton = styled(Button)`
    font-size: 16px;
    text-decoration: underline;
    padding: 0;
    &:hover {
        text-decoration: underline;
        background: none;
    }
`;

export const financing_gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '0',
        top: '-335px'
    },
    {
        type: 'circle',
        width: '316px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-60px',
        top: '389px'
    },
    {
        type: 'circle',
        width: '355px',
        height: '335px',
        bgcolor: '#7DEE80',
        blur: '100px',
        right: '-300px',
        top: '200px'
    }
];

export const feedback_gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '-10px',
        top: '-310px'
    },
    {
        type: 'circle',
        width: '96px',
        height: '65px',
        bgcolor: ColorConstants.green[500],
        blur: '80px',
        right: '-7px',
        top: '10px'
    },
    {
        type: 'circle',
        width: '80px',
        height: '53px',
        bgcolor: 'rgba(255, 255, 255, 0.6)',
        blur: '40px',
        right: '140px',
        top: '-15px'
    }
];

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
        lineHeight: '30px',
        letterSpacing: '-0.48px'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '48px',
        lineHeight: '52px',
        letterSpacing: '-0.96px'
    },
    color: ColorConstants.blue[900],
    fontWeight: 700,
    fontFamily: 'Public Sans'
}));

export const Description = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        fontSize: '22px',
        lineHeight: '28px',
        letterSpacing: '-0.48px'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '-0.96px'
    },
    color: ColorConstants.blue[900],
    fontWeight: 400,
    fontFamily: 'Public Sans'
}));

export const PaymentPlanContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        height: '410px'
    },
    height: '100%',
    overflow: 'auto',
    paddingRight: '8px',
    '&::-webkit-scrollbar': {
        width: '8px'
    },
    '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        backgroundColor: ColorConstants.gray[200]
    }
}));