import React from 'react';
import { ColorConstants } from '../../Constants/ColorConstants';

const EditPencilIcon = ({ currentColor = ColorConstants.blue[900], width = "20", height = "19" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17.9963H19M14.5 1.49632C14.8978 1.0985 15.4374 0.875 16 0.875C16.2786 0.875 16.5544 0.92987 16.8118 1.03648C17.0692 1.14308 17.303 1.29934 17.5 1.49632C17.697 1.6933 17.8532 1.92716 17.9598 2.18453C18.0665 2.4419 18.1213 2.71774 18.1213 2.99632C18.1213 3.2749 18.0665 3.55074 17.9598 3.80811C17.8532 4.06548 17.697 4.29934 17.5 4.49632L5 16.9963L1 17.9963L2 13.9963L14.5 1.49632Z" stroke={currentColor} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default EditPencilIcon;