import { RoutesConstants } from "../Constants/RoutesConstants";

export const getURLByParam = (param) => {
    switch (param) {
        case ALLOWED_REDIRECT_PARAMS.DATOS_CONTACTO:
            return RoutesConstants.IDENTIFICATION_CONTACT_PAGE;
        case ALLOWED_REDIRECT_PARAMS.FINANCING:
            return RoutesConstants.FINANCING_PAGE;
        case ALLOWED_REDIRECT_PARAMS.BALANCE:
            return RoutesConstants.AVAILABLE_BALANCE;
        case ALLOWED_REDIRECT_PARAMS.NEW_PASSWORD_SUCCESSFUL:
            return RoutesConstants.VERIFY_PASSWORD_SUCCESSFUL_PAGE
        default:
            return null;
    }
};

export const ALLOWED_REDIRECT_PARAMS = {
    DATOS_CONTACTO: 'datos-contacto',
    FINANCING: 'financiacion-bnpl',
    BALANCE: 'saldo-disponible',
    NEW_PASSWORD_SUCCESSFUL: 'cambio-password-exito'
};
