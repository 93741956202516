import React from 'react';
import { Box, styled } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const OutterContainer = styled(Box)(() => ({
    backgroundColor: ColorConstants.green[50],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    
}));

export const InnerContainer = styled(Box)(() => ({
    backgroundColor: ColorConstants.green[100],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    
}));

export const IconWrapper = (props) => {
    return (
        <>
            <OutterContainer {...props.outterContainer}>
                <InnerContainer {...props.innerContainer}>
                    {props.children}
                </InnerContainer>
            </OutterContainer>
        </>
    );
};
