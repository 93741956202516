import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DynamicGradientBackground, Footer, Navbar } from '../../Components/BNPL';
import { ColorConstants } from '../../Constants/ColorConstants';
import { Container } from '../PublicHomeBNPL/PublicHomeBNPL.styled';

const Header = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        height: '96px',
        background: 'linear-gradient(63.44deg, #0069D7 16.72%, #1357A0 83.39%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0'
    },
    padding: '20px 0'
}));

export const Title = styled(Typography)`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: ${ColorConstants.blue[900]};
    margin-bottom: 30px;
`;

export const PartnerCard = styled(Box)`
    background: white;
    box-shadow: 0px 12px 16px -4px rgba(0, 105, 215, 0.1), 0px 4px 6px -2px rgba(0, 105, 215, 0.05);
    border-radius: 32px;
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ColorConstants.gray[200]};
`;

export const PartnerImg = styled(Box)`
    object-fit: cover;
    overflow: hidden;
    border-radius: inherit;
`;

export const PartnerTitle = styled(Typography)`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: black;
    text-align: center;
`;

export const PartnerContainer = styled(Stack)`
    align-items: center;
    gap: 12px;
    cursor: pointer;
`;

export const PartnerErrorContainer = styled(Box)`
    padding: 24px 16px 24px 16px;
    border: solid 2px ${ColorConstants.error[400]};
    border-radius: 16px;
    display: flex;
    gap: 24px;
    background-color: white;
`

export const PartnerError = styled(Typography)`
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${ColorConstants.error[700]};
`

const gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '279px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '0px',
        top: '-300px'
    },
    {
        type: 'circle',
        width: '96px',
        height: '65px',
        bgcolor: ColorConstants.green[500],
        blur: '60px',
        right: '0px',
        top: '30px'
    },
    {
        type: 'circle',
        width: '333px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-150px',
        top: '400px'
    },
    {
        type: 'circle',
        width: '355px',
        height: '355px',
        bgcolor: "#7DEE80",
        blur: '100px',
        right: '-330px',
        top: '250px'
    }
];

export const PartnerStoresLayout = ({ children }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <DynamicGradientBackground
            sx={{
                backgroundColor: ColorConstants.blue[50],
                width: '100%'
            }}
            masked
            gradients={desktop ? [] : gradients}>
            <Header>
                <Container width="100%">
                    <Navbar inverted={desktop} />
                </Container>
            </Header>
            <Container marginTop={desktop ? "20px" : "0"} marginBottom="20px">{children}</Container>
            <Footer />
        </DynamicGradientBackground>
    );
};
