import React from 'react';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { Box, styled } from '@mui/material';

export const BnplCard = styled(Box)(({ className }) => ({
    flex: 1,
    color: ColorConstants.blue[900],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    background: 'linear-gradient(117.06deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 99.28%)',
    borderRadius: '32px',
    boxShadow:
        className === 'b-shadow'
            ? '0px 20px 24px -4px rgba(0, 105, 215, 0.1), 0px 8px 8px -4px rgba(0, 105, 215, 0.4)'
            : 'none'
}));
