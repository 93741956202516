import React, { useState, useEffect, useRef } from 'react';
import {
    getByPendingRequest,
    uploadBiometricsPhoto,
    uploadBiometricsPhotoFetch,
    validateBiometricsWithEntreConsultas
} from '../../Services/BiometricDataService';
import BiometricDataContext from './BiometricDataContext';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { StepsConstants } from '../../Constants/StepsConstants';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { trackPromise } from 'react-promise-tracker';
import { FINAL_ATTEMPTS } from '../../Constants/BiometricDataConstants';

export const BiometricDataState = ({ children }) => {
    const [images, setImages] = useState([
        {
            photo: getItemService(StepsConstants.FIRST_STEP)
                ? JSON.parse(getItemService(StepsConstants.FIRST_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.FIRST_STEP)
                ? JSON.parse(getItemService(StepsConstants.FIRST_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.SECOND_STEP)
                ? JSON.parse(getItemService(StepsConstants.SECOND_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.SECOND_STEP)
                ? JSON.parse(getItemService(StepsConstants.SECOND_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.THIRD_STEP)
                ? JSON.parse(getItemService(StepsConstants.THIRD_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.THIRD_STEP)
                ? JSON.parse(getItemService(StepsConstants.THIRD_STEP)).confirmImage
                : false
        },
        {
            photo: getItemService(StepsConstants.FOURTH_STEP)
                ? JSON.parse(getItemService(StepsConstants.FOURTH_STEP)).photo
                : null,
            confirmImage: getItemService(StepsConstants.FOURTH_STEP)
                ? JSON.parse(getItemService(StepsConstants.FOURTH_STEP)).confirmImage
                : false
        }
    ]);
    const [attempt, setAttempt] = useState(
        getItemService('attempt') != undefined ? getItemService('attempt') : 0
    );
    const [isOldImage, setIsOldImage] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [step, setStep] = useState(0);
    const [gestureType, setGestureType] = useState(
        getItemService('gestureType') ? Number(getItemService('gestureType')) : 0
    );
    const inputRef = useRef();
    const navigate = useNavigate();

    const dataBase = {
        frontDNI: getItemService(StepsConstants.FIRST_STEP)
            ? JSON.parse(getItemService(StepsConstants.FIRST_STEP)).photo
            : null,
        pendingRequestId: getItemService(LoanRequestConstantsLS.LoanData)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData)).pendingRequestId
            : ''
    };

    const error = useState(getItemService('error') ? getItemService('error') : false);

    useEffect(() => {
        const getGesture = async (pendingRequestId) => {
            try {
                if (gestureType === 0) {
                    const response = await getByPendingRequest(pendingRequestId);
                    if (response?.biometric?.gestureTypeId) {
                        setGestureType(response.biometric.gestureTypeId);
                        setItemService('gestureType', response.biometric.gestureTypeId);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        getGesture(dataBase.pendingRequestId);
    }, []);

    useEffect(() => {
        checkActiveStep();
    }, [images]);

    const checkActiveStep = () => {
        const firstStep = JSON.parse(getItemService(StepsConstants.FIRST_STEP));
        const secondStep = JSON.parse(getItemService(StepsConstants.SECOND_STEP));
        const thirdStep = JSON.parse(getItemService(StepsConstants.THIRD_STEP));

        if (thirdStep?.confirmImage) {
            setStep(StepsConstants.FOURTH_STEP);
            setActiveStep(StepsConstants.FOURTH_STEP);
            return;
        }

        if (secondStep?.confirmImage) {
            setStep(StepsConstants.THIRD_STEP);
            setActiveStep(StepsConstants.THIRD_STEP);
            return;
        }
        if (firstStep?.confirmImage) {
            setStep(StepsConstants.SECOND_STEP);
            setActiveStep(StepsConstants.SECOND_STEP);
            return;
        }
    };

    const setImage = (name, image) => {
        let data = { photo: image, confirmImage: false };
        setItemService(name, JSON.stringify(data));

        const copyImage = [...images];
        copyImage[name] = data;
        setImages(copyImage);
        navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
    };

    const saveImage = () => {
        let fileInput = document.getElementById('camera').files[0];
        getBase64(fileInput);
    };

    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    };

    const openCamera = (step) => {
        setIsOldImage(true);
        setStep(step);
        let fileInput = document.getElementById('camera').files[0];
        if (fileInput) {
            fileInput.value = '';
        }
        inputRef.current.children[0].click();
    };
    // --------------------------------------------------------------
    const handleConfirmImage = async ({ data, route, response = false }) => {
        const statusFromConfirm = response?.data?.response?.status;
        if (data.step === StepsConstants.SECOND_STEP) {
            let stepOne = JSON.parse(getItemService(StepsConstants.FIRST_STEP));
            const stepTwo = {
                ...data,
                status:
                    statusFromConfirm === 'false' || statusFromConfirm === undefined
                        ? 'false'
                        : 'true'
            };
            stepOne.status = stepTwo.status;
            setItemService(StepsConstants.FIRST_STEP, JSON.stringify(stepOne));
            confirmImage(stepTwo);
        }
        if (data.step !== StepsConstants.SECOND_STEP) {
            // Si falla mas de 2 veces va el status como true
            let newData = {
                ...data,
                status:
                    statusFromConfirm === 'false' || statusFromConfirm === undefined
                        ? 'false'
                        : 'true'
            };
            confirmImage(newData);
        }

        const lastStep = JSON.parse(getItemService(StepsConstants.FOURTH_STEP));
        if (lastStep?.confirmImage && location.pathname === RoutesConstants.BIOMETRIC_DATA_PAGE) {
            await sendPhotosToLoan();
        }
        route ? navigate(route) : '';
    };

    const confirmImage = async (data) => {
        let info = {
            photo: images[data.step].photo,
            confirmImage: true,
            extension: data.extension,
            biometricDataType: data.biometricDataType,
            status: data.status
            /* status: Number(data.step) > 2 ? 'true' : 'false' */
        };
        const image = {
            photo: images[data.step].photo,
            confirmImage: true
        };

        /* try {
            const dataForLoan = { ...info, ...dataBase };
            delete dataForLoan.confirmImage;
            delete dataForLoan.frontDNI;
            delete dataForLoan.backDNI;
            delete dataForLoan.step;
            uploadBiometricsPhotoFetch(dataForLoan).then((response) => {
                let retries = 1;
                if (retries > 0 && response?.status !== 200) {
                    retries -= 1;
                    uploadBiometricsPhotoFetch(dataForLoan).then((response) => {
                        return response;
                    });
                }
                return response;
            });
        } catch (error) {
            console.log('Error in uploadPhoto Loan', error);
            return;
        } */

        const copyImage = [...images];
        copyImage[data.step] = image;
        setImages(copyImage);
        setItemService(data.step, JSON.stringify(info));
        if (activeStep === StepsConstants.FIRST_STEP) {
            setActiveStep(data.step + 1);
        }
    };

    const handleChangeAttempt = (newValue) => {
        setItemService('attempt', newValue);
        setAttempt(newValue);
    };

    const finishedAttempts = (data, route) => {
        if (FINAL_ATTEMPTS == attempt + 1) {
            handleConfirmImage({ data, route });
            handleChangeAttempt(0);
        }
    };

    const confirmPhoto = async (body, route) => {
        let data = { ...dataBase, ...body };
        if (Number(attempt) >= 3) {
            handleConfirmImage({ data, route });
            handleChangeAttempt(0);
        } else {
            try {
                let response = await validateBiometricsWithEntreConsultas(data);

                if (response?.name?.includes('Error')) {
                    const errors = response?.response?.data?.errors;
                    const someBlocking = errors?.filter((err) => err.blocked);
                    const isBlocked = someBlocking?.length > 0;

                    if (isBlocked) {
                        handleChangeAttempt(3);
                        return {
                            ErrorType: 'customError',
                            message: someBlocking[0].message
                        };
                    } else {
                        if (
                            response?.response?.status >= 500 ||
                            response?.response?.status === 404
                        ) {
                            handleChangeAttempt(Number(attempt) == 2 ? 2 : attempt + 1);
                            finishedAttempts(data, route);
                            return {
                                ErrorType: 'customError',
                                message:
                                    'Hubo un error en el servidor. Te pedimos que vuelvas a tomar la foto.'
                            };
                        }
                        handleChangeAttempt(Number(attempt) + 1);
                        finishedAttempts(data, route);
                        return {
                            ErrorType: 'customError',
                            message:
                                'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                        };
                    }
                }

                if (response?.data?.response?.description=='false') {
                    finishedAttempts(data, route);
                    handleChangeAttempt(Number(attempt) + 1);
                    return {
                        ErrorType: 'customError',
                        message:
                            'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                    };
                }

                if (response?.data?.response?.status === 'false') {
                    finishedAttempts(data, route);
                    handleChangeAttempt(Number(attempt) + 1);
                    return {
                        ErrorType: 'customError',
                        message:
                            'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                    };
                }

                handleConfirmImage({ data, route, response });
                handleChangeAttempt(0);
                return response;
            } catch (error) {
                return {
                    ErrorType: 'customError',
                    message:
                        'No pudimos validar tu identidad. Te pedimos que vuelvas a tomar la foto.'
                };
            }
        }
    };

    const sendPhotosToLoan = async () => {
        const step1 = await JSON.parse(getItemService(StepsConstants.FIRST_STEP));
        const step2 = await JSON.parse(getItemService(StepsConstants.SECOND_STEP));
        const step3 = await JSON.parse(getItemService(StepsConstants.THIRD_STEP));
        const step4 = await JSON.parse(getItemService(StepsConstants.FOURTH_STEP));

        try {
            console.log(Date.now());
            let response = await uploadBiometricsPhoto({
                        ...dataBase,
                        ...step4
                    });
            delay(1000);
            console.log(Date.now());
            let response2 = await uploadBiometricsPhoto({
                        ...dataBase,
                        ...step3
                    });
            delay(1000);
            console.log(Date.now());
            let response3 = await uploadBiometricsPhoto({
                        ...dataBase,
                        ...step2
                    });
            delay(1000);
            console.log(Date.now());
            let response4 = await uploadBiometricsPhoto({
                        ...dataBase,
                        ...step1
                    });

        } catch (error) {
            console.log(error);
        }
    };

    function exportUserInfo(userInfo) {
        const fileData = JSON.stringify(userInfo);
        const blob = new Blob([fileData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = 'user-info.json';
        link.href = url;
        link.click();
    }

    // --------------------------------------------------------------

    const getBase64 = async (file) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
            const imageCompressed = await trackPromise(compressPhoto(reader.result, 800));
            setImage(step, imageCompressed);
            setIsOldImage(false);
            navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
            return;
        };
    };

    const compressPhoto = (imageB64, maxSize) => {
        const image = new Image();
        return new Promise((res) => {
            image.onload = () => {
                imageB64 = resizeImage(image, maxSize);
                res(imageB64);
            };
            image.src = imageB64;
        });
    };

    const resizeImage = (image, maxSize) => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        const resizedImage = canvas.toDataURL('image/png');
        return resizedImage;
    };

    return (
        <BiometricDataContext.Provider
            value={{
                images,
                confirmPhoto,
                confirmImage,
                setImage,
                saveImage,
                openCamera,
                activeStep,
                error,
                attempt,
                inputRef,
                setActiveStep,
                setAttempt,
                isOldImage,
                gestureType
            }}>
            {children}
        </BiometricDataContext.Provider>
    );
};
