import React, { useRef, useState, useContext, useEffect } from 'react';
import { Camera } from 'react-camera-pro';
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import { Grid, Typography, Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import FaceMarco from '../../../Assets/Images/RostroMacroVertical.png';
import { BiometricDataState } from '../../../Contexts/BiometricData/BiometricDataState';
import { useNavigate } from 'react-router-dom';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import CloseIcon from '@mui/icons-material/Close';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { setItemService } from '../../../Services/LocalStorageService';

import { BNPLBiometricContext } from '../../../Contexts/BNPLBiometric/BNPLBiometricContext';
import { BiometricDataConstants } from '../../../Constants/BiometricDataConstants';

const Wrapper = styled.div`
    position: absolute;
    top: ${window.screen.height * 0.008}em;
    width: ${window.screen.width * 0.05}em;
    height: ${window.screen.height * 0.03}em;
    left: ${window.screen.height * 0.0036}em;
    z-index: 1;
`;

const styles = {
    img: {
        position: 'relative',
        height: `${window.screen.height * 0.9}px`,
        top: 0,
        width: `${window.screen.width}px`,
        zIndex: 2
    },
    div: {
        position: 'relative',
        top: '30vh'
    }
};

const steps = {
    2: {
        title: 'Rostro tranquilo',
        description: 'Ubicá tu rostro en el oválo para sacar la foto'
    },
    3: {
        description: 'Ubicá tu rostro en el oválo para sacar la foto'
    }
};

export const NewCameraPage = () => {
    const { actions, state } = useContext(BNPLBiometricContext);
    const { saveImage2 } = actions;
    const { step, gestureType } = state;
    const camera = useRef(null);
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    const getGestureTitle = () => {
        switch (gestureType) {
            case BiometricDataConstants.SMILE_FACE:
                return 'Rostro sonriendo';
            case BiometricDataConstants.WINK_FACE:
                return 'Guiñe el ojo izquierdo';
            case BiometricDataConstants.OPEN_MOUTH_FACE:
                return 'Abra la boca';
            default:
                return 'Rostro sonriendo';
        }
    };

    const handleTakePhoto = () => {
        const photo = camera.current.takePhoto();
        saveImage2(photo, true);
        setImage(photo);
    };

    const handleSwitchCamera = () => {
        if (camera.current) {
            camera.current.switchCamera();
        }
    };

    useEffect(() => {
        if (image) navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
    }, [image]);

    return (
        <DeviceOrientation lockOrientation={'portrait'} sx={{ textAling: 'center' }}>
            <Orientation orientation="portrait" alwaysRender={false}>
                <div>
                    <BiometricDataState>
                        <Grid sx={{ zIndex: 2 }}>
                            <img src={FaceMarco} style={styles.img} />
                        </Grid>
                        <Grid
                            sx={{
                                zIndex: 3,
                                textAlign: 'center',
                                position: 'absolute',
                                width: '65%',
                                left: '9vh',
                                top: 15
                            }}>
                            <Typography sx={{ color: 'white', fontSize: '1.25rem' }}>
                                {step === 3 ? getGestureTitle() : steps[step].title}
                            </Typography>
                            <Box sx={{ textAlign: 'center', mr: 5 }}>
                                <CloseIcon
                                    sx={{
                                        fontSize: '1.8rem',
                                        color: 'white',
                                        zIndex: 2,
                                        position: 'absolute',
                                        top: 2,
                                        right: -50
                                    }}
                                    onClick={() => {
                                        navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
                                        setItemService('isSameSelfie', true);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Wrapper>
                            <Camera
                                ref={camera}
                                aspectRatio="cover"
                                errorMessages={{
                                    noCameraAccessible:
                                        'Ningún dispositivo de cámara accesible. Conecta tu cámara o prueba con otro navegador.',
                                    permissionDenied:
                                        'Permiso Denegado. Por favor actualice y dé permiso a la cámara.',
                                    switchCamera:
                                        'No es posible cambiar la cámara a una diferente porque solo hay un dispositivo de video accesible.',
                                    canvas: 'Canvas no soportado.'
                                }}
                                sx={{ width: '50%', height: '50%' }}
                            />
                        </Wrapper>
                        <>
                            <Box sx={{ textAlign: 'center', mr: 5 }}>
                                <Fab
                                    color="secondary"
                                    sx={{
                                        position: 'absolute',
                                        bottom: '17.5%',
                                        rigth: '50vh',
                                        backgroundColor: 'white'
                                    }}
                                    onClick={handleTakePhoto}></Fab>
                            </Box>
                        </>
                        <Fab
                            size="small"
                            color="secondary"
                            sx={{
                                background: '#524d4d30 !important',
                                position: 'absolute',
                                bottom: '18%',
                                right: 30
                            }}
                            onClick={handleSwitchCamera}>
                            <LoopIcon sx={{ color: 'white' }} />
                        </Fab>
                    </BiometricDataState>
                </div>
            </Orientation>
            <Orientation orientation="landscape" alwaysRender={false}>
                <div style={styles.div}>
                    <p>Por favor, desactiva la rotación automática del dispositivo</p>
                </div>
            </Orientation>
        </DeviceOrientation>
    );
};
