import React from 'react';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { feedback_gradients } from '../../Financing/FinancingPage.styled';
import { Box } from '@mui/material';
import { DescriptionForm } from '../../../Components/BNPL';
import { Img } from '../../../Components/ImageContainer';
import ThumbsDownImg from '../../../Assets/feedback-unsuccessful-payment.svg';
import STRINGS from '../../../i18n/local/es.json';

const PurchaseCanceledForm = () => {
    return (
        <Box textAlign="center">
            <DescriptionForm
                title={STRINGS.feedbackPurchaseCanceled.TITLE}
                description={STRINGS.feedbackPurchaseCanceled.DESCRIPTION}
            />
            <Box my={12} display="flex" justifyContent="center">
                <Img src={ThumbsDownImg} />
            </Box>
        </Box>
    );
};

export const PurchaseCanceledPage = () => {
    const layoutProps = {
        mobile: {
            background: 'transparent',
            showDescription: false,
            gradients: feedback_gradients
        }
    };
    return <BNPLLayout Form={<PurchaseCanceledForm />} layoutProps={layoutProps} />;
};
