import React from 'react';
import { Skeleton, Box, Stack } from '@mui/material';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import STRINGS from '../../../i18n/local/es.json';

export const OfferAvailablePageSkeleton = () => {
  return (
    <Stack width="100%" spacing={5}>
      <Skeleton variant="rectangular" width={118} height={20} />
      <Skeleton variant="rectangular" width={212} height={32} />
      <Skeleton variant="rectangular" width={212} height={30} />
      <Skeleton variant="rectangular" width={344} height={76} />
      <Skeleton variant="rectangular" width={344} height={101} />
      <Skeleton variant="rectangular" width={127} height={28} />
      <Skeleton variant="rectangular" width={312} height={39} />
      <Skeleton variant="rectangular" width={312} height={39} />
      <Skeleton variant="rectangular" width={212} height={63} />
      <Skeleton variant="rectangular" width={210} height={118} />
      <ButtonCustom text={STRINGS.financing.CTA_CONTINUE} disabled />
      <Box textAlign="center" mt="24px" width="100%">
        <span>{STRINGS.financing.CTA_BACK}</span>
      </Box>
    </Stack>
  )
}
