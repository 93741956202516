import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { updateOrderStatus } from '../Services/BNPLService';
import { BNPLConstants, ORDER_STATUS, WORKFLOW_CONSTANTS } from '../Constants/BNPLConstants';

export const useRedirectTimer = (seconds, url, externalUrl = false, orderData = null) => {
    const navigate = useNavigate();
    const [remainingSeconds, setRemainingSeconds] = useState(seconds);

    const resolveAfterSeconds = (url) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                window.location.replace(url);
                resolve(true);
            }, 4000);
        });
    };

    useEffect(() => {
        if (remainingSeconds === 0) {
            if (externalUrl) {
        		if ( orderData != null ) {
        			updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
                    .then(() => {
                        trackPromise(
                            resolveAfterSeconds(orderData.return_Url_Error)
                        );
                    })
                    .catch(() => {
                        trackPromise(
                            resolveAfterSeconds(orderData.return_Url_Error)
                        );
                    });
        		} else {
                    window.location.replace(url);
        		}
            } else {
                navigate(url);
            }
        }
    }, [remainingSeconds]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingSeconds((prevRemainingSeconds) => {
                if (prevRemainingSeconds === 0) {
                    clearInterval(intervalId);
                    return 0;
                } else {
                    return prevRemainingSeconds - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return { remainingSeconds };
};
