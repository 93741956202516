import { Box, IconButton, Stack } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { ButtonCustom } from '../../Buttons/ButtonCustom';
import { CloseIcon } from '../../Icons';
import {
    DialogActions,
    DialogContainer,
    DialogContent,
    DialogDescription,
    DialogIcon,
    DialogTitle,
    ImageContainer
} from './Dialog.styled';

export const Dialog = ({
    title,
    description,
    buttonText,
    icon,
    image,
    onClose,
    onContinue,
    open
}) => {

    const handleClose = () => {
        onClose && onClose();
    };

    return (
        <DialogContainer onClose={handleClose} open={open}>
            <Stack>
                {onClose && <Box textAlign="right">
                    <IconButton onClick={handleClose} sx={{ color: ColorConstants.blue[900] }}>
                        <CloseIcon />
                    </IconButton>
                </Box>}
                <DialogTitle>
                    {icon && (
                        <DialogIcon>
                            {icon}
                        </DialogIcon>
                    )}
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogDescription>{description}</DialogDescription>
                    <ImageContainer>{image && <img src={image} />}</ImageContainer>
                </DialogContent>
                <DialogActions>
                    <ButtonCustom
                        text={buttonText ? buttonText : 'Continuar'}
                        action={onContinue}
                    />
                </DialogActions>
            </Stack>
        </DialogContainer>
    );
};
