import React from 'react';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import { Description } from '../../../Components/DescriptionsLayout';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';

export const OfferDesktopPages = () => {
    const titleDescription = 'Quiero mi préstamo';
    const descriptionDesc = 'Con tu celular y DNI podremos buscar la mejor oferta para vos.';
    return (
        <Grid container>
            <BNPLLayout
                ContainerPanel={
                    <Description title={titleDescription} description={descriptionDesc} />
                }
                Form={<Outlet />}
            />
        </Grid>
    );
};
