import { ColorConstants } from "../Constants/ColorConstants";

export const renderSeparator = (inverted = false) => {
    let color = inverted ? ColorConstants.WHITE : ColorConstants.MIDDLE_LIGHT_BLUE;
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
                style={{
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: `${color}`,
                    width: '90%'
                }}></div>
        </div>
    );
};