import { Box, styled, Typography } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const TitleContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
`;

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        fontSize: '48px',
        fontWeight: '600',
        lineHeight: '52px',
        color: 'inherit',
        letterSpacing: '-0.02em'
    },
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    letterSpacing: '-0.02em',
    color: ColorConstants.error[700]
}));

export const error_gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '0',
        top: '-335px'
    },
    {
        type: 'circle',
        width: '316px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-60px',
        top: '389px'
    },
    {
        type: 'circle',
        width: '355px',
        height: '335px',
        bgcolor: '#7DEE80',
        blur: '100px',
        right: '-300px',
        top: '200px'
    }
];
