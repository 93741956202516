/* eslint-disable no-undef */
/* eslint-disable no-console */
import { getData } from './BaseService';
import { getItemService } from './LocalStorageService';
import { AppConstantsLS } from '../Contexts/Global/AppActions';

export const getFrequentQuestions = async (appId) => {
    const url = 'general/information/faqs';
    const query = `?AppId=${appId}`;
    const response = await getData(url, query);
    return response;
};

export const getFrequentQuestionsFetch = async () => {
    const token = getItemService(AppConstantsLS.Token);

    let config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers['Credentials'] = process.env.REACT_APP_CREDENTIAL;
    config.headers['ApiKey'] = process.env.REACT_APP_APIKEY_BACKEND;

    if (process.env.REACT_APP_SUBSCRIPTION_KEY) {
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
    }

    const url = 'general/information/faqs';

    const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}${url}`, config);
    const data = await response.json();
    return data;
};
