import { verifyEmailCode, resendEmailCode } from '../../Services/BNPLService';

export const useEmailValidateTokenHandler = () => {
    const verifyEmailToken = (code, commerceId, hash) => {
        return verifyEmailCode(code, commerceId, hash);
    };

    const resendEmailToken = (commerceId, hash) => {
        return resendEmailCode(Number(commerceId), hash);
    };

    return {
        verifyEmailToken,
        resendEmailToken
    };
};
