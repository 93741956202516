import { postData } from './BaseService';

export const getStoreFilters = async (body) => {
    const url = 'stores/filter';
    return await postData(url, body);
};


export const getAffiliatedStores = async (body) => {
    const url = 'stores/affiliated';
    return await postData(url, body);
};