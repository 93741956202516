import React, { useContext, useEffect } from 'react';
import { Box } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { BNPLRegisterContext } from '../../../../Contexts/BNPLRegister/BNPLRegisterContext';
import { RoutesConstants } from '../../../../Constants/RoutesConstants';
import { ReturnButton } from '../../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';
import BNPLTokenForm from './BNPLTokenForm';
import {
    ValidateBNPLTokenHandlerProvider,
    useValidateBNPLTokenHandler
} from './ValidateBNPLTokenHandler';
import { LoanRequestConstantsLS } from '../../../../Contexts/LoanRequest/LoanRequestActions';
import { logEventBNPLFunnel } from '../../../../Services/BNPLService';

const ValidateBNPLTokenPage = () => {
    const titleForm = 'Ingresa el código enviado';
    const { personData, handleChangeMultipleValues } = useContext(BNPLRegisterContext);
    const handler = useValidateBNPLTokenHandler();
    const loanData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData));
    const navigate = useNavigate();

    useEffect(() => {
        const personData = JSON.parse(localStorage.getItem('personData'));
            // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("VALIDAR_SMS", storageOrderID, localStorageHash)
            }
            // FINISH

        if (!loanData || !personData) {
            navigate(RoutesConstants.PUBLIC_HOME);
        } else {
            handleChangeMultipleValues(personData);
        }
    }, []);

    const TextDescription = () => {
        return (
            <>
                {`Te llegó por SMS al ${personData.fullPhoneNumber}`}
                <br />
                <Link to={RoutesConstants.IDENTIFICATION_CONTACT_PAGE}>
                    Quiero cambiar el celular
                </Link>
            </>
        );
    };

    return (
        <ValidateBNPLTokenHandlerProvider value={handler}>
            <Box style={{ textAlign: 'center' }}>
                <ReturnButton />
                <DescriptionForm title={titleForm} description={<TextDescription />} />
                <BNPLTokenForm />
            </Box>
        </ValidateBNPLTokenHandlerProvider>
    );
};

export default ValidateBNPLTokenPage;
