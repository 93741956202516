import * as React from 'react';
import { Grid } from '@mui/material';
import { TitleCustom } from '../../Components/TitleCustom';
import '../../Styles/GlobalStyle.css';
import { FrequentQuestionsState } from '../../Contexts/FrequentQuestions/FrequentQuestionsState';
import { FrequentQuestionsTabs } from './FrequentQuestionsTabs';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const FrequentQuestionsPage = () => {
    return (
        <FrequentQuestionsState>
            <Grid container spacing={3} sx={{ mb: 10 }}>
                <Grid item sm={2}></Grid>
                <Grid item xs={11} sm={10} sx={{ mt: 1, mb: -3 }}>
                    <ReturnButton
                        text={'Volver'}
                        medium
                        route={() => history.back()}
                        /* route={RoutesConstants.PUBLIC_HOME} */
                    />
                </Grid>
                <Grid item sm={2}></Grid>
                <Grid item xs={11} sm={6} md={4}>
                    <TitleCustom
                        title="Preguntas Frecuentes"
                        subtitle="Estamos para ayudarte y para responder todo lo que necesites saber."
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ mt: 1 }}>
                    <FrequentQuestionsTabs />
                </Grid>
            </Grid>
        </FrequentQuestionsState>
    );
};
