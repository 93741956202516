export const ColorConstants = {
    BLUE: '#636569',
    LIGHT_BLUE: '#0069D7',
    MIDDLE_LIGHT_BLUE: '#CEE6FF',
    WHITE: 'white',
    RED: '#B42318',
    GREEN: '#47D14B',

    blue: {
        50: '#F5FAFF',
        100: '#CEE6FF',
        200: '#4892E1',
        500: '#0069D7',
        700: '#0039a6',
        900: '#636569'
    },
    gray: {
        100: '#EEEEEF',
        200: '#E8E8E8',
        600: '#A7A7A8',
        700: '#656566'
    },
    green: {
        50: '#EEFFEE',
        100: '#C8F8C8',
        500: '#47D14B',
        900: '#1F951F'
    },
    error: {
        100: '#FEE4E2',
        200: '#FECDCA',
        400: '#F97066',
        500: '#F04438',
        600: '#D92D20',
        700: '#B42318'
    },
    warning: {
        50: '#FFFAEB',
        100: '#FEF0C7',
        600: '#DC6803'
    }
};
