import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const OptionButton = styled(Button)(() => ({
    display: 'flex',
    justifyContent: 'start',
    backgroundColor: 'white',
    borderRadius: '16px',
    height: '64px',
    width: '100%',
    marginTop: '16px',
    ":hover": {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 8px -2px rgba(0, 105, 215, 0.1), 0px 2px 4px -2px rgba(0, 105, 215, 0.06)',
    },
    boxShadow: '0px 4px 8px -2px rgba(0, 105, 215, 0.1), 0px 2px 4px -2px rgba(0, 105, 215, 0.06)',
    border: '1px solid',
    borderColor: '#E8E8E8'
}));


const ButtonTypeOccupation = ({ name, selected, value, onClick }) => {
    const [active, setActive] = useState();
    const styleActive = {
        backgroundColor: '#FFFFFF',
        border: '2px solid',
        borderColor: '#47D14B'
    }

    useEffect(() => {
        setActive(selected==value);
    },[selected])
    return (
        <OptionButton className='typeOccupationButton' variant="contained" sx={active ? styleActive : null} onClick={() => onClick(value)}>
            <Typography
                width={'100%'}
                fontFamily={'Archivo'}
                fontWeight={700}
                color={ColorConstants.BLUE}
                display="flex"
                justifyContent={'space-between'}>
                {name}
                <ArrowForwardIcon sx={{ color: ColorConstants.LIGHT_BLUE }} />
            </Typography>
        </OptionButton>
    );
};

export default ButtonTypeOccupation;
