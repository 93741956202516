import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';
import { getData } from '../../Services/BaseService';
import LoanRequestContext from './LoanRequestContext';
import {
    getCBUService,
    getOccupationsService,
    requestTokenChangeDeviceService,
    savePhoneNumberService,
    saveProvinceService,
    sendPaymentInfomationService,
    sendSMSCode,
    siisaValidationService,
    validateCBUService,
    verifySMSCodeService
} from '../../Services/LoanRequestService';
import { requestLoan } from '../../Services/LoanRequestService';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { LoanRequestConstantsLS } from './LoanRequestActions';
import { getAllowedMethodsService } from '../../Services/PaymentMethodService';
import { DetectMobile } from '../../Utils/DetectMobile';
import { useLocation } from 'react-router-dom';
import { BNPLSiisaContext } from '../BNPLSiisa/BNPLSiisaContext';

export const LoanRequestState = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [options, setOptions] = useState({ provinces: [] });
    const [loanData, setLoanData] = useState(
        getItemService(LoanRequestConstantsLS.LoanData)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData))
            : null
    );
    const [verifySMSCodeSuccess, setVerifySMSCodeSuccess] = useState(false);
    const [errorToken, setErrorToken] = useState(false);
    const [errorResponseCBU, setErrorResponseCBU] = useState(false);
    const [failedAttemptCBU, setFailedAttemptCBU] = useState(0);
    const [allFailedAttempts, setAllFailedAttempts] = useState(false);
    const [occupationsList, setOccupationsList] = useState([]);
    const [questionTitle, setQuestionTitle] = useState(
        getItemService('QuestionTitle') ? JSON.stringify(getItemService('QuestionTitle')) : []
    );
    const [maxAttempt, setMaxAttempt] = useState(null);
    const [data, setData] = useState(
        getItemService(LoanRequestConstantsLS.PersonToGetLoan)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))
            : null
    );
    const [paymentInformation, setPaymentInformation] = useState(
        getItemService(LoanRequestConstantsLS.PaymentInformation)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PaymentInformation))
            : null
    );
    const { getSiisaCode } = useContext(BNPLSiisaContext);

    const getInitData = async () => {
        let url = 'province';
        let provinces = await getData(url);
        setOptions({ provinces: provinces });
    };

    const savePerson = async (person) => {
        setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(person));
        setData(person);
        navigate(RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE);
    };

    const siisaValidation = async (pendingRequestId) => {
        let siisaValue;
        let response;
        const localLoanData = getItemService(LoanRequestConstantsLS.LoanData);

        await getSiisaCode()
            .then((res) => {
                siisaValue = res;
            })
            .catch((err) => {
                console.log(err);
            });
        if (pendingRequestId) {
            response = await siisaValidationService({
                id:
                    pendingRequestId ||
                    loanData?.pendingRequestId ||
                    localLoanData?.pendingRequestId,
                document: data.dni,
                fingerPrint: siisaValue
            });
        } else {
            response = await siisaValidationService({
                document: data.dni,
                fingerPrint: siisaValue
            });
        }

        if (!response.data.approved) {
            const allowedMethods = await getAllowedMethodsService({
                dni: data.dni,
                sexTypeId: data.sexTypeId
            });

            if (allowedMethods.paymentId == 1) {
                setItemService(LoanRequestConstantsLS.ClientValidation, true);
            } else {
                setItemService(LoanRequestConstantsLS.ClientValidation, false);
            }
        } else {
            setItemService(LoanRequestConstantsLS.ClientValidation, true);
        }
    };

    const saveLocalData = (form) => {
        const newInformation = {
            ...data,
            phoneNumberComplete: form.area + form.phoneNumber,
            phoneCode: form.area,
            phoneNumber: form.phoneNumber,
            email: form.email,
            provinceId: form.province
        };
        setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(newInformation));
        setData(newInformation);
    };

    const saveLoanData = (requestLoanData) => {
        setLoanData(requestLoanData.data);
        setItemService(LoanRequestConstantsLS.LoanData, JSON.stringify(requestLoanData.data));
    };

    const saveTotalData = async (form) => {
        saveLocalData(form);

        const personToGetLoan = getItemService(LoanRequestConstantsLS.PersonToGetLoan)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))
            : null;

        try {
            let requestLoanData;
            if (!loanData?.recommendation) {
                requestLoanData = await requestLoan({
                    name: data.personName,
                    dni: data.dni,
                    sexTypeId: data.sexTypeId,
                    personId: personToGetLoan?.personId || 0
                });
                await siisaValidation(requestLoanData.data.pendingRequestId);

                saveLoanData(requestLoanData);

                const isInvalidOffer =
                    !requestLoanData.data.offer[0]?.maximumAmount ||
                    requestLoanData.data.offer[0]?.maximumAmount < 1000;

                if (isInvalidOffer) {
                    navigate(RoutesConstants.LOAN_NO_OFFERS, { replace: true });
                    return;
                }
            } else {
                requestLoanData = { data: loanData };
                await siisaValidation(loanData.pendingRequestId);
            }
            if (requestLoanData?.data?.pendingRequestId) {
                const resp = await sendSMSCode({
                    pendingRequestId: requestLoanData?.data?.pendingRequestId,
                    phoneNumber: form.area + form.phoneNumber
                });
                if (resp?.response?.status >= 400) {
                    return resp?.response?.data?.errors[0]?.message || resp;
                }
            }
            navigate(RoutesConstants.LOAN_TOKEN_PAGE);
        } catch (error) {
            console.log('error loan request', error);
            await siisaValidation();
            navigate(RoutesConstants.LOAN_NO_OFFERS);
        }
    };

    const resendSMSCode = async () => {
        setErrorToken(false);
        await sendSMSCode({
            pendingRequestId: loanData.pendingRequestId,
            phoneNumber: data.phoneNumberComplete
        });

        navigate(RoutesConstants.LOAN_TOKEN_PAGE);
    };

    const verifySMSCode = async (code) => {
        if (loanData) {
            try {
                const verifyCode = await verifySMSCodeService({
                    pendingRequestId: loanData.pendingRequestId,
                    code: code
                });
                if (verifyCode?.response?.status >= 400) {
                    setErrorToken(true);
                    setVerifySMSCodeSuccess(false);
                    return;
                }
                await savePhoneNumberService({
                    personId: data.personId,
                    numCelular: data.phoneNumberComplete
                });

                await saveProvinceId();
                setErrorToken(false);
                setVerifySMSCodeSuccess(true);
            } catch (error) {
                setErrorToken(true);
                setVerifySMSCodeSuccess(false);
            }
        }
    };

    const saveProvinceId = async () => {
        const { dni, personId, provinceId } = data;

        if (personId != 0) {
            await saveProvinceService(personId, provinceId, dni);
        }
    };

    const getOccupations = async () => {
        const response = await getOccupationsService();
        setOccupationsList(response);
    };

    const saveOccupation = (occupationId, question) => {
        setItemService(LoanRequestConstantsLS.PaymentInformation, JSON.stringify({ occupationId }));
        setItemService(LoanRequestConstantsLS.QuestionTitle, question);
        setQuestionTitle(question);
        setPaymentInformation({ occupationId });
    };

    const savePayDay = async (day) => {
        const paymentData = { ...paymentInformation, payDay: day, personId: data.personId };
        await sendPaymentInfomationService(paymentData);
        removeItemService(LoanRequestConstantsLS.QuestionTitle);
        removeItemService(LoanRequestConstantsLS.PaymentInformation);
        navigate(RoutesConstants.LOAN_CBU);
    };

    const getCBU = async () => {
        const response = await getCBUService(data.personId);

        setErrorResponseCBU(false);
        setMaxAttempt(response.attempt);
        return response;
    };

    const validateCBU = async (cbu) => {
        try {
            const cbuValidate = await validateCBUService({
                pendingRequestId: loanData.pendingRequestId,
                cbu
            });
            // eslint-disable-next-line no-undef
            if (!process.env.REACT_APP_CBU_VALIDATE) {
                if (cbuValidate?.response?.status >= 400) {
                    setErrorResponseCBU({ notValid: true });
                    return cbuValidate;
                }

                setErrorResponseCBU(false);
                goToNextCbuPage();

                return;
            } else {
                setErrorResponseCBU(false);
                goToNextCbuPage();
            }
        } catch (error) {
            //* No coincide con el DNI: -> setErrorCBU({ notValid: false });
            //* Error general: no se pudo validar el CBU
            setErrorResponseCBU({ notValid: true });

            /* if (failedAttemptCBU === maxAttempt - 1) {
                setAllFailedAttempts(true);
                setFailedAttemptCBU(failedAttemptCBU + 1);
            } else {
                setFailedAttemptCBU(failedAttemptCBU + 1);
            }
            if (failedAttemptCBU >= maxAttempt) {
                goToNextCbuPage();
            } */
            return error;
        }
    };

    const goToNextCbuPage = () => {
        const goToLastPage = getItemService(LoanRequestConstantsLS.ReturnCbuPage);
        if (goToLastPage) {
            removeItemService(LoanRequestConstantsLS.ReturnCbuPage);
            navigate(RoutesConstants.LOAN_DETAILS_PAGE);
            return;
        }
        navigate(RoutesConstants.LOAN_PAY_METHOD);
    };

    const getTokenChangeDevice = async () => {
        const response = await requestTokenChangeDeviceService({
            personId: data?.personId || 0,
            pendingRequestId: loanData?.pendingRequestId
        });

        return response;
    };

    return (
        <LoanRequestContext.Provider
            value={{
                options,
                data,
                verifySMSCodeSuccess,
                errorToken,
                errorResponseCBU,
                occupationsList,
                questionTitle,
                allFailedAttempts,
                getCBU,
                getInitData,
                savePerson,
                saveTotalData,
                verifySMSCode,
                resendSMSCode,
                saveProvinceId,
                saveOccupation,
                getOccupations,
                savePayDay,
                validateCBU,
                setErrorToken,
                setErrorResponseCBU,
                setAllFailedAttempts,
                setFailedAttemptCBU,
                getTokenChangeDevice
            }}>
            {children}
        </LoanRequestContext.Provider>
    );
};

LoanRequestState.propTypes = {
    children: PropTypes.element
};
