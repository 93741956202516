import { getItemService } from "../../Services/LocalStorageService";
import { RegisterConstantsLS } from "../Register/RegisterActions";
import Actions from "./Actions";

export const initialState = { userEmail: getItemService(RegisterConstantsLS.UserEmail) };

export const ChangePasswordReducer = (state, action) => {
    switch (action.type) {

        case Actions.SET_USER_EMAIL:
            return { ...state, userEmail: action.payload };

        default:
            return state;
    }
};