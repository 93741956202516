import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
const TermsConditionsDescription = ({ title, description }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));
    return (
    <Box>
        <Typography
            variant="h5"
            // component="h7"
            fontSize={matches && "40px"}
            color={'#636569'}
            sx={{ fontWeight: 'bold', mt: '0px', mb: 2 }}>
            {title}
        </Typography>
        <Typography variant="h6" component="h5" color={'#636569'} 
        sx={{ fontSize: '16px', fontWeight: 'regular', mr: 2}}>
            {description}
        </Typography>
    </Box>
)};

export default TermsConditionsDescription;
