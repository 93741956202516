import { useState, useEffect, useCallback } from 'react';

let timeout = false;

export const useExpiredTime = ({ time }) => {
    const [isExpired, setIsExpired] = useState(false);

    useEffect(() => {
        window.addEventListener('click', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        updateExpireTime();

        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
        };
    }, []);

    const updateExpireTime = () => {
        setIsExpired(false);
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setIsExpired(true);
        }, time);
    };

    return isExpired;
};
