import { Drawer as MuiDrawer, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Drawer = styled(MuiDrawer)`
    .MuiBackdrop-root {
        background-color: transparent;
    }
    .MuiDrawer-paper {
        padding: 21px 24px;
        border-radius: 40px 40px 0px 0px;
        color: ${ColorConstants.blue[900]};
    }

    .MuiDrawer-paperAnchorBottom {
        height: 94%;
    }
`;

export const Title = styled(Typography)`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
`;
