const emailRegex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
const passwordRegex = new RegExp(/^(?=\w*\d)(?=\w*[a-z])\S{8,}$/);

export const DEFAULT_VALID_VALUE = undefined;

export const emailValidate = (email) => {
    return emailRegex.test(email);
};

export const passwordValidate = (pass, repeatPass) => {
    return pass.length >= 8 && pass === repeatPass;
};

export const passwordRepeatValidate = (pass, repeatPass) => {
    return pass === repeatPass;
};

export const passwordLengthValidate = (pass) => {
    return pass.length >= 8;
};

export const threePasswordsValidate = ({ currentPassword, password, passwordRepeat }) => {
    return currentPassword.length >= 8 && password.length >= 8 && password === passwordRepeat;
};

export const alphanumericTest = (pass) => {
    return passwordRegex.test(pass);
};

export const phoneNumberValidate = (number) => {
    if (String(number).slice(0, 1) == '0') return false;
    const regex = /^\d{6,8}$/;

    return regex.test(number);
};

export const areaCodeValidate = (number) => {
    if (String(number).slice(0, 1) == '0') return false;
    const regex = /^\d{2,4}$/;

    return regex.test(number);
};

export const dniValidate = (dni) => {
    const regex = /^\d*$/;
    return regex.test(dni) && Number(dni) > 0;
};

export const phoneNumberCompleteValidate = (phoneNumber) => {
    if (String(phoneNumber).slice(0, 1) == '0') return false;
    const regex = /^\d{6,8}$/;

    return regex.test(phoneNumber);
};

export const phoneCodeCompleteValidate = (phoneNumber) => {
    if (String(phoneNumber).slice(0, 1) == '0') return false;
    const regex = /^\d{2,4}$/;

    return regex.test(phoneNumber);
};

export const emailValidator = (value) => {
    return !value
        ? 'El campo Correo Electrónico es requerido.'
        : emailRegex.test(value)
        ? DEFAULT_VALID_VALUE
        : 'El campo Correo Electrónico no posee un formato válido.';
};

export const passwordValidator = (value) => {
    return !value
        ? 'El campo Contraseña es requerido.'
        : passwordRegex.test(value)
        ? DEFAULT_VALID_VALUE
        : 'El campo Contraseña debe contener 8 caracteres alfanuméricos.';
};

export const validationCBU = (value) => {
    const regex = /^[0]*[1-9][0-9.,]*$/;
    return regex.test(value);
};
