import { useNavigate, useParams } from 'react-router-dom';
import { setItemService } from '../../Services/LocalStorageService';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import { getPersonByDNI, requestLoan } from '../../Services/LoanRequestService';
import { verifyBiometryRequirements } from '../../Services/OfferService';

const ChangeDeviceToken = () => {
    const navigate = useNavigate();

    const { token } = useParams();

    useEffect(() => {
        const getInitData = async () => {

        const parametersQuery = window.location.href.split('?')[1].split('&')
        const paramsQuery = [parametersQuery[0].split('=')[0], parametersQuery[0].split('=')[1]]
        let ecommerceAccountId = null;
        if (paramsQuery[0] == 'ecommerceAccountId') {
                ecommerceAccountId = paramsQuery[1];
                localStorage.setItem(
                                'ecommerceAccountId',
                                ecommerceAccountId
                );
        }

        // const { personId, pendingRequestId } = jwtDecode(token);
	    const dataDecoded = jwtDecode(token);
	    const personId = dataDecoded.General[0];
	    const pendingRequestId = dataDecoded.General[1];
	    console.log(personId);
	    console.log(pendingRequestId);
            const personData = await getDataPersonService(personId);
            const peopleList = await getPersonByDNI({
                dni: personData.dni,
                sexTypeId: personData.sexTypeId || 2
            });

            const person = peopleList.filter((people) => people.personName == personData.userName);
            const newPersonData = {
                ...personData,
                ...person[0],
                name: person[0].personName,
                dni: personData.dni,
                sexTypeId: person.sexTypeId || 2
            };

            //Guardado PersonData
            setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(newPersonData));

            const requestLoanData = await requestLoan({
                name: newPersonData.personName,
                dni: newPersonData.dni,
                sexTypeId: newPersonData.sexTypeId,
                personId: personId || 0
            });

            //Guardado RequestData
            setItemService(LoanRequestConstantsLS.LoanData, JSON.stringify(requestLoanData.data));

            //Validación Biometría
            const biometryStatus = await verifyBiometryRequirements(pendingRequestId);
            if (biometryStatus.hasBiometricData) {
                navigate(RoutesConstants.LOAN_SUMMARY_PAGE);
            } else {
                navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
            }
        };

        getInitData();
    }, []);
};

export default ChangeDeviceToken;
