import { Typography, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        fontSize: '48px',
        fontWeight: '600',
        lineHeight: '52px',
        color: 'inherit',
        letterSpacing: '-0.02em'
    },
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    letterSpacing: '-0.02em',
    color: ColorConstants.error[700]
}));

export const SessionDescription = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: '445px'
    },
    [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        fontWeight: '400',
        color: 'inherit',
        marginTop: '16px',
        lineHeight: '30px'
    },
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    color: ColorConstants.error[700],
    fontFamily: 'Archivo',
    marginTop: '8px'
}));
