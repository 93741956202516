import { useContext, useEffect } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import STRINGS from '../../../i18n/local/es.json';
import { Title } from '../PartnerStoresLayout';
import { Card } from './Card';
import { PartnerStoresContext } from '../../../Contexts/PartnerStores/PartnerStoresContext'
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
const { REACT_APP_ENABLE_PARTNER_STORES } = process.env;

export const PartnersContent = () => {
    const { partners, unfilteredPartners } = useContext(PartnerStoresContext);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        if (REACT_APP_ENABLE_PARTNER_STORES !== 'true') {
            navigate(RoutesConstants.PUBLIC_HOME_BNPL);
        }
	}, []);

    return (
        <>
            {desktop && <Title>{STRINGS.partners.TITLE}</Title>}
            <Grid container spacing="24px" justifyContent="start">
                {(
                    partners && partners.length > 0 && partners.map((partner, i) => (
                        <Card key={i} {...partner} />
                    ))
                )
                ||(
                    unfilteredPartners && unfilteredPartners.length > 0 && unfilteredPartners.map((partner, i) => (
                        <Card key={i} {...partner} />
                    ))
                )}
            </Grid>
        </>
    );
};
