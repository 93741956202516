import { Outlet, useLocation } from 'react-router-dom';
import { Description } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { BNPLBiometricProvider } from '../../Contexts/BNPLBiometric/BNPLBiometricContext';
import STRINGS from '../../i18n/local/es.json';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const BiometricBNPLContainer = () => {
    const location = useLocation();

    return (
        <BNPLBiometricProvider>
            {location.pathname === RoutesConstants.CAMERA_PAGE ? (
                <Outlet />
            ) : (
                <BNPLLayout
                    ContainerPanel={
                        <Description
                            title={STRINGS.biometric.TITLE}
                            description={STRINGS.biometric.SUBTITLE}
                            returnButton={false}
                        />
                    }
                    Form={<Outlet />}
                />
            )}
        </BNPLBiometricProvider>
    );
};
