export function FlashIcon({ color = 'currentColor', width = '20', height = '20' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.3418 5.62467L10.8335 1.66634L8.8085 4.09967M15.4752 10.758L17.5002 8.33301H13.0502M6.66683 6.66634L2.50016 11.6663H10.0002L9.16683 18.333L13.3335 13.333M0.833496 0.833008L19.1668 19.1663"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
