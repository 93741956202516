import { useEffect, useContext } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { Img } from '../../../../Components/ImageContainer';
import { Description, DescriptionForm } from '../../../../Components/BNPL';
import { BNPLLayout } from '../../../../Components/Layout/BNPLLayout';
import { ButtonCustom } from '../../../../Components/Buttons/ButtonCustom';
import AllSet from '../../../../Assets/Images/all-set.svg';
import STRINGS from '../../../../i18n/local/es.json';
import { RoutesConstants } from '../../../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { BNPLSiisaContext } from '../../../../Contexts/BNPLSiisa/BNPLSiisaContext';
import { APP_ID } from '../../../../Constants/BNPLConstants';

const DISCOVERY_STRING = (
    <Typography fontFamily="Archivo">
        {`${STRINGS.register_feedback.CONTENT_SUBTITLE} También `}
        <Link to={RoutesConstants.PARTNERS_STORE_PAGE}>
            Conocé los comercios adheridos
        </Link>
        {' y comenzá a disfrutar tu saldo.'}
    </Typography>
);

const FeedbackForm = ({ isDiscovery }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const { saveHasValidatedIdentity } = useContext(BNPLSiisaContext);

    useEffect(() => {
        saveHasValidatedIdentity(APP_ID.CS, false);
    }, []);

    return (
        <Stack justifyContent="space-between">
            <Box>
                <DescriptionForm
                    title={STRINGS.register_feedback.CONTENT_TITLE}
                    description={
                        isDiscovery ? DISCOVERY_STRING : STRINGS.register_feedback.CONTENT_SUBTITLE
                    }
                />
                <Box mt={3} textAlign="center">
                    <Img src={AllSet} />
                </Box>
            </Box>

            <Box textAlign="center">
                <ButtonCustom
                    text={`${STRINGS.buttons.LOGIN}`}
                    margin="0 0 24px"
                    action={() => { navigate(RoutesConstants.LOGIN_PAGE) }}
                />

                {!desktop && <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>}
            </Box>
        </Stack>
    );
};

export const DiscoveryAndRegisterFeedbackPage = ({ isDiscovery }) => {
    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={STRINGS.scan_qr_code.TITLE}
                    description={STRINGS.scan_qr_code.SUBTITLE}
                    returnButton={false}
                />
            }
            Form={<FeedbackForm isDiscovery={isDiscovery} />}
        />
    );
};
