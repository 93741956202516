import { moneyMask } from "./Masks";
import { LoanRequestDetailConstants } from "../Constants/LoanRequestDetailConstants";
import { getItemService } from "../Services/LocalStorageService";

export const getSectionOfLoanRequest = (nameOfSection, data) => {
    switch(nameOfSection){
        case LoanRequestDetailConstants.operationDetails:
            return data.operationDetails;
        case LoanRequestDetailConstants.creditRequest:
            return data.creditRequest;
        case LoanRequestDetailConstants.requestTyC: 
            return data;
        default:
            return data;
    }
}

export const getSimpleData = (dataReturn, data, nameOfSection) =>{
    if(nameOfSection=== LoanRequestDetailConstants.creditRequest){
        switch(dataReturn){
            case LoanRequestDetailConstants.creditRequest_applicantDetails_name: 
                return data.applicantDetails.name;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_date: 
                return data.applicantDetails.date;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_document: 
                return data.applicantDetails.document;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_sexType: 
                return data.applicantDetails.sexType;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_birthday: 
                return data.applicantDetails.birthday;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_taxId: 
                return data.applicantDetails.taxId;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_iva: 
                return data.applicantDetails.iva;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_province: 
                return data.applicantDetails.province != null ? 
                data.applicantDetails.province : getItemService('province');
            case LoanRequestDetailConstants.creditRequest_applicantDetails_email: 
                return data.applicantDetails.email != null ? 
                data.applicantDetails.email : JSON.parse(getItemService('PersonToGetLoan')).email;
            case LoanRequestDetailConstants.creditRequest_applicantDetails_phone: 
                return data.applicantDetails.phone;
            case LoanRequestDetailConstants.creditRequest_creditConditions_amountCredit: 
                return moneyMask(data.creditConditions.amount);
            case LoanRequestDetailConstants.creditRequest_creditConditions_quantityRateCredit:  
                return data.creditConditions.quantityRate;
            case LoanRequestDetailConstants.creditRequest_creditConditions_firstAmountRate: 
                return moneyMask(data.creditConditions.firstAmountRate);
            case LoanRequestDetailConstants.creditRequest_creditConditions_currency: 
                return data.creditConditions.currency;
            case LoanRequestDetailConstants.creditRequest_creditConditions_amortizationSystem: 
                return data.creditConditions.amortizationSystem;
            case LoanRequestDetailConstants.creditRequest_creditConditions_amountInWords: 
                return data.creditConditions.amountInWords;
            case LoanRequestDetailConstants.creditRequest_creditConditions_netAmount: 
                return moneyMask(data.creditConditions.netAmount);
            case LoanRequestDetailConstants.creditRequest_creditConditions_tna: 
                return `${moneyMask(data.creditConditions.tna.amount, false)}%(1)`;
            case LoanRequestDetailConstants.creditRequest_creditConditions_tea: 
                return `${moneyMask(data.creditConditions.tea.amount, false)}%(1)`;
            case LoanRequestDetailConstants.creditRequest_creditConditions_cftna: 
                return `${moneyMask(data.creditConditions.cftna.amount, false)}%(2)`;
            case LoanRequestDetailConstants.creditRequest_creditConditions_tnaName: 
                return data.creditConditions.tna.name;
            case LoanRequestDetailConstants.creditRequest_creditConditions_teaName: 
                return data.creditConditions.tea.name.padStart(6, ' ');
            case LoanRequestDetailConstants.creditRequest_creditConditions_cftnaName: 
                return data.creditConditions.cftna.name;
            case LoanRequestDetailConstants.creditRequest_paymentMethod_description: 
                return data.paymentMethod.description;
            case LoanRequestDetailConstants.creditRequest_accreditationForm_cbu: 
                return data.accreditationForm.cbu;
            case LoanRequestDetailConstants.creditRequest_accreditationForm_bank: 
                return data.accreditationForm.bank;
            default: return '';
    
        }
    }
    else if(nameOfSection===LoanRequestDetailConstants.requestTyC){
        return data.description
    }
    else{
        switch(dataReturn){
            case LoanRequestDetailConstants.operationDetails_date: return data.deadLines;
            case LoanRequestDetailConstants.operationDetails_amountRate: return data.amountRate;
            case LoanRequestDetailConstants.operationDetails_amount: return moneyMask(data.amount);
            case LoanRequestDetailConstants.operationDetails_quantityRate: return data.quantityRate;
            default: return '';
        }
    }
    
}