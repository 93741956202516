import { useState, useEffect } from 'react';
import STRINGS from '../../../i18n/local/es.json';
import { BnplCard } from '../BnplCard/BnplCard';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { Button, styled, Collapse } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';
import EditPencilIcon from '../../Icons/EditPencilIcon';
import { SelectList } from '../../../Components/BNPL';

export const InstallmentText = styled(Typography)`
    color: ${ColorConstants.blue[500]};
    font-weight: 500;
    font-size: 18px;
`;

export const TotalText = styled(Typography)`
    font-size: 18px;
`;

export const LinkButton = styled(Button)`
    font-size: 16px;
    text-decoration: underline;
    padding: 0;
    &:hover {
        text-decoration: underline;
        background: none;
    }
`;
const InstallmentsCard = ({
    orderValue,
    totalMon,
    total,
    numberOfInstallments,
    installmentAmount,
    onChangeInstallments,
    installments,
    selectedInstallment,
    showCTAAsButton = false,
    hideButton = false,
    handleEditButtonClick
}) => {
    const [showInstallments, setShowInstallments] = useState(false);

    const handleChange = () => {
        setShowInstallments(!showInstallments);
    };

    const handleOnClickInstallment = (newSelectedInstallment) => {
        onChangeInstallments(newSelectedInstallment);
        setShowInstallments(!showInstallments);
    };

    var totalCal = 0;

    useEffect(() => {
        if (selectedInstallment !== null && selectedInstallment !== undefined && 
            selectedInstallment.quantityRate !== null && selectedInstallment.quantityRate !== undefined)
        {
            totalCal = (selectedInstallment.quantityRate * selectedInstallment.amountRate).toFixed(2);
        }
    }, []);

    return (
        <BnplCard mt={2}>
            <Stack spacing={1} p={3}>
                <Stack direction="row" justifyContent="space-between">
                    <TotalText>{STRINGS.financing.TOTAL}</TotalText>
                    <TotalText>{total}</TotalText>
                </Stack>
                <Divider />
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Box>
                        {totalMon > (orderValue + 2) ? (
                            <InstallmentText>
                                {numberOfInstallments} {STRINGS.financing.INSTALLMENT}
                            </InstallmentText>
                        ) : (
                            <InstallmentText>
                                {numberOfInstallments} {STRINGS.financing.INSTALLMENT} sin interés
                            </InstallmentText>
                        )}
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography fontSize={20} fontWeight={600}>
                                {installmentAmount}
                            </Typography>
                            <Typography fontSize={14}>{STRINGS.financing.TOTAL_FREQ}</Typography>
                        </Stack>
                    </Box>
                    {!hideButton && (
                        <Box>
                            {showCTAAsButton ? (
                                <Button onClick={() => handleEditButtonClick()}>
                                    <EditPencilIcon />
                                </Button>
                            ) : (
                                <LinkButton onClick={handleChange}>
                                    {STRINGS.financing.CTA_CHANGE}
                                </LinkButton>
                            )}
                        </Box>
                    )}
                </Stack>
            </Stack>
            <Collapse in={showInstallments}>
                <SelectList
                    options={installments}
                    selectedValue={selectedInstallment}
                    onClick={handleOnClickInstallment}
                    variant="installment"
                    total={orderValue}
                />
            </Collapse>
        </BnplCard>
    );
};

export default InstallmentsCard;
