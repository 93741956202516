import { RoutesConstants } from '../Constants/RoutesConstants';

const BNPLRoutes = [
    RoutesConstants.REDIRECT_TO_DIRECTO_PAGE,
    RoutesConstants.IDENTIFICATION_DNI_PAGE,
    RoutesConstants.IDENTIFICATION_GENDER_PAGE,
    RoutesConstants.IDENTIFICATION_CONFIRM_PAGE,
    RoutesConstants.IDENTIFICATION_CONTACT_PAGE,
    RoutesConstants.BNPL_TOKEN_PAGE,
    RoutesConstants.SELECT_PAYMENT_METHOD,
    RoutesConstants.FINANCING_PAGE,
    RoutesConstants.FINANCING_OFFER_PAGE,
    RoutesConstants.BIOMETRIC_BNPL_PAGE,
    RoutesConstants.CONFIRMATION_PAGE,
    RoutesConstants.SCAN_QR_CODE,
    RoutesConstants.VERIFY_PASSWORD_SUCCESSFUL_PAGE,
    RoutesConstants.CANCEL_PURCHASE
];

export const isBNPLRoute = (pathname) => {
    return BNPLRoutes.includes(pathname);
};
