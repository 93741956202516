import { useMediaQuery, useTheme } from '@mui/material';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { Container } from '../PublicHomeBNPL.styled';
import { ContactBox, ContactButtons, Description, Title } from './Contact.styled';
import { useHomeHandler } from '../PublicHomeHandler';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import STRINGS from '../../../i18n/local/es.json';

export const Contact = () => {
    const { contactProps } = useHomeHandler();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <Container>
            <ContactBox>
                <Title>{STRINGS.home.contact.CONTACT_TITLE}</Title>
                <Description>{STRINGS.home.contact.CONTACT_DESCRIPTION}</Description>
                <ContactButtons>
                    {contactProps.serviceProps.callToAction.map(
                        (btn, i) =>
                            ((desktop && !btn.props.showDesktop) ||
                                (!desktop && !btn.props.showMobile)) && (
                                <ButtonCustom
                                    key={i}
                                    text={btn.label}
                                    {...btn.props}
                                    bg="transparent"
                                    color="inherit"
                                    border
                                    icon={<ArrowForwardIcon />}
                                />
                            )
                    )}
                </ContactButtons>
            </ContactBox>
        </Container>
    );
};
