import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { ColorConstants } from '../../../Constants/ColorConstants';

const RowDetail = styled(Grid)(({ inverted, decreasePadding, padding = '24px 24px'}) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    padding: decreasePadding ? '24px 8px' : padding,
    borderRadius: '32px',
    background: inverted ? ColorConstants.MIDDLE_LIGHT_BLUE : 'white',
}));

const Amount = styled(Grid)(({ inverted, smallDevice, mediumDevice }) => ({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Public Sans',
    fontWeight: 600,
    // fontSize: smallDevice ? '23px' : '30px',
    fontSize: mediumDevice ? smallDevice ? '19px' : '24px' : '30px',
    lineHeight: '34px',
    letterSpacing: '-0.02em',
    color: inverted ? ColorConstants.BLUE : ColorConstants.LIGHT_BLUE
}));

const Details = styled(Grid)(({inverted, smallDevice, mediumDevice}) => ({
    display:'flex',
    alignItems: 'center',
    textAlign: 'end',
    fontFamily: 'Archivo',
    fontWeight: 400,
    height: '30px',
    fontSize: mediumDevice ? smallDevice ? '10px' : '12px' : '14px',
    lineHeight: '16px',
    marginLeft: inverted ? '15px !important' : '',
    color: ColorConstants.BLUE
}));

export const ItemDetail = ({ amount, description, inverted, my='8px', margin, summary, padding }) => {
    const [ decreasePadding, setDecreasePadding] = useState(false);
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down(340));
    const mediumDevice = useMediaQuery(theme.breakpoints.down(380));
    
    useEffect(() => {
        if(amount ? amount.props!=undefined : false){
            if(amount.props.children[1].length>5){
                setDecreasePadding(true);
            }else{
                setDecreasePadding(false);
            }
        }
    },[amount])

    return (
        <RowDetail my={my} inverted={inverted} margin={margin} padding={padding} decreasePadding={decreasePadding} sx={{width:'inherit'}}>
            <Amount item xs={inverted&&!summary? 8 : 7} sx={{justifyContent: inverted&&!summary ? 'center' : ''}} inverted={inverted} smallDevice={smallDevice}  mediumDevice={mediumDevice}>
                {amount}
            </Amount>
            <Details inverted={inverted} smallDevice={smallDevice}  mediumDevice={mediumDevice} item xs={inverted&&!summary? 4 : 5}>
                {description}
            </Details>
        </RowDetail>
    );
};
