import { Typography, Stack, Box, styled, Tab } from '@mui/material';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';

export const StyledTab = styled(Tab)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'flex-start'
    }
}));

export const gradients = {
    desktop: [
        {
            type: 'element',
            blur: '0px',
            right: 0,
            top: '1480px'
        }
    ],
    mobile: [{}]
};

// ******** HEADER

export const CardOffers = ({
    askForALoan,
    title,
    description,
    cta,
    className,
    isDesktop,
    ...props
}) => {
    return (
        <Stack padding={askForALoan && isDesktop ? 4.5 : 3}>
            <Stack>
                <CardTitle className={className}>{title}</CardTitle>
                {askForALoan ? (
                    <AskForLoanDescription>{description}</AskForLoanDescription>
                ) : (
                    <CardDefaultDescription className={className}>
                        {description}
                    </CardDefaultDescription>
                )}
                <Stack maxWidth="270px">
                    <ButtonCustom
                        text={cta}
                        fontSize="14px"
                        
                        fontFamily="Archivo"
                        margin="10px 0 0"
                        {...props}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

const CardTitle = styled(Typography)(({ theme, className }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: className === 'invert' ? '30px' : '20px'
    },
    fontSize: className === 'invert' ? '24px' : '16px',
    fontWeight: className === 'invert' ? 700 : 400,
    fontFamily: className === 'invert' ? 'Public Sans' : 'Archivo'
}));

const CardDefaultDescription = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '40px'
    },
    fontSize: '30px',
    fontWeight: 700
}));

const AskForLoanDescription = styled(Typography)({
    fontSize: '14px'
});

// ******** CONTENT

export const Container = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '0 16%'
    },
    padding: '0 5%'
}));
