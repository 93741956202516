import { TextField, InputAdornment, ClickAwayListener } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import StyledAutocomplete, { StyledBox, StyledOption, StyledPaper } from './Autocomplete.styled';

export const Autocomplete = ({ iconStart, value, onChange, noOptionsText, ...props }) => {
    const [inputValue, setInputValue] = useState((value && value.label) || '');
    const [error, setError] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [focus, setFocus] = useState(false);
    const shrink = useMemo(() => {
        return (value && !!value.value) || focus;
    }, [value, focus]);

    const handleShrink = () => {
        setFocus(value.length !== 0);
        setIsOpen(false);
        if (value.label) {
            setInputValue(value.label);
        }
    };

    const onAutocompleteChange = (e, value) => {
        if (value) {
            setInputValue(value.label);
            onChange({ target: { value } });
            setIsOpen(false);
        } else {
            onChange({ target: { value: '' } });
            setIsOpen(false);
        }
    };

    const onTextFieldChange = (e) => {
        const inputValue = e.target.value;
        setInputValue(inputValue);
        if (inputValue.length) {
            setFocus(true);
            const isValid = props.options.some((option) =>
                option.label.toLowerCase().includes(inputValue.toLowerCase())
            );
            setError(!isValid);
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (!value) {
            setError(false);
        }
        if (value) {
            setInputValue(value.label);
        }
    }, [value]);

    const filterOptions = (options, { inputValue }) => {
        if (inputValue.trim() === '') {
            return [];
        }
        const filteredOptions = options.filter((option) =>
            option.label.toLowerCase().startsWith(inputValue.toLowerCase())
        );
        return filteredOptions.length > 0 ? filteredOptions : [];
    };

    return (
        <ClickAwayListener onClickAway={handleShrink}>
            <StyledBox className={error ? 'error' : ''}>
                <StyledAutocomplete
                    {...props}
                    noOptionsText={inputValue.length & error ? noOptionsText : []}
                    open={isOpen}
                    filterOptions={filterOptions}
                    disablePortal
                    onChange={onAutocompleteChange}
                    PaperComponent={StyledPaper}
                    inputValue={inputValue}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={props.placeholder}
                            label={props.label}
                            error={error}
                            autoComplete="off"
                            onChange={onTextFieldChange}
                            value={value.label || ''}
                            InputLabelProps={{
                                ...props.InputLabelProps,
                                shrink
                            }}
                            onFocus={() => {
                                setFocus(true);
                            }}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">{iconStart}</InputAdornment>
                                )
                            }}
                        />
                    )}
                    renderOption={(props, option) => (
                        <StyledOption {...props}>{option.label}</StyledOption>
                    )}
                />
            </StyledBox>
        </ClickAwayListener>
    );
};
