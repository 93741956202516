import { useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { cleanSteps } from "../../../Services/BiometricDataService";
import { getDataPersonService } from "../../../Services/ChangePasswordService";
import { creditDetailsService } from "../../../Services/LoanRequestService";
import { BNPLConstants, WORKFLOW_CONSTANTS } from "../../../Constants/BNPLConstants";
import { LoanRequestConstantsLS } from "../../../Contexts/LoanRequest/LoanRequestActions";
import { RoutesConstants } from "../../../Constants/RoutesConstants";


export const CrossDeviceCS = () => {
  const navigate = useNavigate();
  const [isCrossDevice, setIsCrossDevice] = useState(
      localStorage.getItem(BNPLConstants.IS_CROSS_DEVICE) === 'true'
  );

  useEffect(() => {
    const parameters = window.location.href.split('?')[1].split('&')
    const params = [parameters[0].split('='), parameters[1].split('='), parameters[2].split('=')]
    let personId = null;
    let pendingRequestId = null;
    let ecommerceAccountId = null;
    for (let param of params) {
      if (param[0] == 'personId') personId = param[1]
      else if (param[0] == 'pendingRequestId') pendingRequestId = param[1]
      else if (param[0] == 'ecommerceAccountId') ecommerceAccountId = param[1]
    }

    // clean biometric steps
    cleanSteps();

    //workflow
    localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.CS);

    //personData
    getDataPersonService(personId)
      .then((response) => {
        const personData = {
          name: response.userName,
          personId: response.idPerson,
          dni: String(response.dni),
          gender: response.sexTypeId,
          areaCode: response.phoneCode,
          phoneNumber: response.phoneNumber,
          ecommerceAccountId: ecommerceAccountId
        };
        localStorage.setItem(
          BNPLConstants.PERSON_DATA,
          JSON.stringify(personData)
        );
        localStorage.setItem(
          LoanRequestConstantsLS.PersonToGetLoan,
          JSON.stringify({
            ...personData,
            phoneCode: response.phoneCode
          })
        );
      })
      .catch((error) => {
        console.log(error);
        navigate(RoutesConstants.PUBLIC_HOME);
      });

    //loanData
    const loanData = {
      pendingRequestId: pendingRequestId
    };
    localStorage.setItem(LoanRequestConstantsLS.LoanData, JSON.stringify(loanData));

    setIsCrossDevice(true);
    localStorage.setItem(BNPLConstants.IS_CROSS_DEVICE, true);

    navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
  });

  return (
    <div></div>
  )
}
