import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import {
    FeaturedService,
    AccordionStack,
} from '../../../Components/BNPL';
import { Container } from '../PrivateHomeBNPLPage.styled';
import { usePrivateHomeHandler } from '../PrivateHomeBNPLHandler';
import { Divider } from '../../PublicHomeBNPL/PublicHomeBNPL.styled';

export const PrivateHomeBenefitsSection = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const { buyInInstallments, applyForALoan } =
        usePrivateHomeHandler();
    return (
        <Container >
            {
                buyInInstallments.map((service, i) => (
                    <FeaturedService key={`featured_service_${i + 1}`} {...service}>
                        {desktop && <AccordionStack {...service.faqProps} />}
                    </FeaturedService>
                ))
            }

            {desktop && <Divider />}
            {
                applyForALoan.map((service, i) => (
                    <FeaturedService key={`featured_service_${i + 1}`} {...service}>
                        {desktop && <AccordionStack {...service.faqProps} />}
                    </FeaturedService>
                ))
            }
        </Container >
    )
}