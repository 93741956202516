import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DescriptionForm } from '../../../Components/BNPL';
import { Img } from '../../../Components/ImageContainer';
import FeedbackImg from '../../../Assets/feedback.svg';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { Box, Typography } from '@mui/material';
import STRINGS from '../../../i18n/local/es.json';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { useRedirectTimer } from '../../../Hooks/useRedirectTimer';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';
import { cleanBNPLOrderData } from '../../../Helpers/BNPLUtils';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const NoLoanOfferAvailableForm = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const { orderData } = useContext(BNPLOrderContext);
    const timer = useRedirectTimer(
        process.env.REACT_APP_CONTINUE_PURCHASE_TIMEOUT,
        orderData.return_Url_Error,
        true,
	orderData
    );
    const minutes = Math.floor(timer.remainingSeconds / 60);
    const seconds = timer.remainingSeconds % 60;

    useEffect(() => {
        const data = localStorage.getItem('personData');
        cleanBNPLOrderData(); // Clean order hash and credentials.
        if (data) {
            const { name } = JSON.parse(data);
            setName(name.split(',')[1]);
        } else {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    }, []);

    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between">
            <Box textAlign="center">
                <DescriptionForm
                    title={`${STRINGS.feedbackNoFunds.FORM_TITLE}${name}`}
                    description={STRINGS.feedbackNoFunds.FORM_DESCRIPTION}
                    lineHeightDescription="28px"
                    fontSizeDescription="22px"
                    fontFamilyDescription="Public Sans"
                />
                <Img src={FeedbackImg} />
            </Box>
            <Box textAlign="center" mt={15}>
                <Typography color={ColorConstants.blue[900]} textAlign="center">
                    En {`${minutes}:${seconds.toString().padStart(2, '0')}`} serás enviado a la
                    tienda.
                </Typography>
            </Box>
        </Box>
    );
};

export const NoLoanOfferAvailablePage = () => {
    return <BNPLLayout Form={<NoLoanOfferAvailableForm />} />;
};
