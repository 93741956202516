import { Alert, Typography } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

const ErrorMessage = ({ title, description, redirect, redirectText, fonstSize = 16, sx, icon }) => {
    return (
        <Alert
            icon={icon}
            variant="outlined"
            severity="error"
            sx={{
                borderRadius: '15px',
                backgroundColor: '#FEF3F2',
                ...sx
            }}>
            <Typography
                textAlign={'start'}
                color={ColorConstants.RED}
                fontFamily={'Archivo'}
                fontSize={fonstSize}>
                <b>{title}</b>
                <br />
                {description}
            </Typography>
            {redirect && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <a
                        href={redirect}
                        style={{
                            paddingTop: '10px',
                            color: 'red',
                            fontSize: '17px'
                        }}>
                        {redirectText ? redirectText : ''}
                    </a>
                </div>
            )}
        </Alert>
    );
};

export default ErrorMessage;
