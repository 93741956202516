import { styled } from '@mui/material/styles'
import {Box, Stack} from '@mui/material'
import { ColorConstants } from '../../../Constants/ColorConstants'

const StyledFooter = styled(Box)`
&&{
    width: 100%;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
    margin: 0 auto;
    flex-direction: column;

    .links-container{
        align-items: center;
        justify-content: space-evenly;

        ul{
            gap: 16px;
            padding: 0;
        }
        a{
            text-decoration: none;
            color: ${ColorConstants.blue[500]};
            margin-right: 30px;
        }
    } 
}
`

export const AddressContainer = styled(Stack)`
    height: 80px;
    justify-content: right;
    border-top: 1px solid ${ColorConstants.blue[100]};
    margin-top: 40px;
    padding-top: 24px;
    div {
        display: flex;
        justify-content: space-between;
        gap: 5px;
        p{
            margin:0;
            font-weight: 400;
            font-size: 12px;
            text-align: right;
            color: ${ColorConstants.gray[600]};
            line-height: 1em;
        }
    }
    img {
        width: 39px;
    }
`

export default StyledFooter