import React from 'react';
import { Route } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { FrequentQuestionsPage } from '../../Pages/FrequentQuestionsBNPL/FrequentQuestionsPage';
import { ContinueFromPhonePage } from '../../Pages/LoanRequest/Token/ContinueFromPhonePage';
import { PartnerStoresPage } from '../../Pages/PartnerStores/PartnerStoresPage';
import { PublicHomeBNPLPage } from '../../Pages/PublicHomeBNPL/PublicHomeBNPLPage';
import { RedirectToDirectoPage } from '../../Pages/RedirectToDirecto/RedirectToDirectoPage';
import { InsufficientFundsPage } from '../../Pages/Feedback/InsufficientFundsPage/InsufficientFundsPage';
import { AvailableBalancePage } from '../../Pages/AvailableBalance/AvailableBalancePage';
import { StepsRemainingPage } from '../../Pages/StepsRemaining/StepsRemainingPage';
import { ScanQrCode } from '../../Pages/ScanQrCode/ScanQrCodePage';
import { DiscoveryAndRegisterFeedbackPage } from '../../Pages/Feedback/Discovery/Register/Discovery&RegisterFeedbackPage';
import { NoLoanOfferAvailablePage } from '../../Pages/Feedback/NoLoanOfferAvailable/NoLoanOfferAvailable';
import { PurchaseCanceledPage } from '../../Pages/Feedback/PurchaseCanceled/PurchaseCanceled';
import { ValidationErrorPage } from '../../Pages/Feedback/ValidationError/ValidationError';
import { CrossDevice } from '../../Pages/ScanQrCode/CrossDevice';
import { SessionExpiredPage } from '../../Pages/Feedback/SessionExpired/SessionExpiredPage';
import { VerifyEmailCodePage } from '../../Pages/VerifyEmailCode/VerifyEmailCodePage';
import { FeedbackPage } from '../../Pages/Feedback/FeedbackPage';
import { VerifyPasswordCodePage } from '../../Pages/VerifyPasswordCode/VerifyPasswordCodePage';
import { CancelPurchasePage } from '../../Pages/Feedback/CancelPurchase/CancelPurchase';
import { ScanQrCodeCS } from '../../Pages/ScanQrCode/AvailableBalance/ScanQrCodeCS';
import { CrossDeviceCS } from '../../Pages/ScanQrCode/AvailableBalance/CrossDeviceCS';

export const BNPLRoutes = () => {
    return (
        <>
            {/* <Route exact path={RoutesConstants.PUBLIC_HOME} element={<PublicHomeBNPLPage />} /> TODO: AVAILABLE IN MVP 2 */}
            {
                <Route
                    exact
                    path={RoutesConstants.PUBLIC_HOME_BNPL}
                    element={<PublicHomeBNPLPage />}
                />
            }
            {
                <Route
                    exact
                    path={RoutesConstants.PARTNERS_STORE_PAGE}
                    element={<PartnerStoresPage />}
                />
            }

            {
                <Route
                    exact
                    path={RoutesConstants.FRECUENT_QUESTIONS_PAGE_BNPL}
                    index
                    element={<FrequentQuestionsPage />}
                />
            }
            <Route
                exact
                path={RoutesConstants.REDIRECT_TO_DIRECTO_PAGE}
                element={<RedirectToDirectoPage />}
            />

            <Route
                exact
                path={RoutesConstants.INSUFFICIENT_FUNDS_PAGE}
                element={<InsufficientFundsPage variant="withTimer" />}
            />

            <Route
                exact
                path={RoutesConstants.MINIMUM_PURCHASE_PRICE_PAGE}
                element={<InsufficientFundsPage variant="withTimer" minimumPrice />}
            />

            <Route
                exact
                path={RoutesConstants.BNPL_NO_OFFERS}
                element={<NoLoanOfferAvailablePage />}
            />

            <Route exact path={RoutesConstants.STEPS_REMAINING} element={<StepsRemainingPage />} />

            {
                <Route
                    exact
                    path={RoutesConstants.AVAILABLE_BALANCE}
                    element={<AvailableBalancePage />}
                />
            }

            <Route
                exact
                path={RoutesConstants.SCAN_QR_CODE_CS}
                element={<ScanQrCodeCS/>}
            />

            <Route
                exact
                path={RoutesConstants.CROSS_DEVICE_CS}
                element={<CrossDeviceCS/>}
            />

            <Route exact path={RoutesConstants.SCAN_QR_CODE} element={<ScanQrCode />} />

            <Route
                exact
                path={RoutesConstants.REGISTER_FEEDBACK}
                element={<DiscoveryAndRegisterFeedbackPage />}
            />

            {
                <Route
                    exact
                    path={RoutesConstants.DISCOVERY_FEEDBACK}
                    element={<DiscoveryAndRegisterFeedbackPage isDiscovery />}
                />
            }

            <Route
                exact
                path={RoutesConstants.PURCHASE_CANCELED}
                element={<PurchaseCanceledPage />}
            />

            <Route exact path={RoutesConstants.CANCEL_PURCHASE} element={<CancelPurchasePage />} />

            <Route
                exact
                path={RoutesConstants.VALIDATION_ERROR}
                element={<ValidationErrorPage />}
            />

            <Route exact path={RoutesConstants.CROSS_DEVICE} element={<CrossDevice />} />

            <Route exact path={RoutesConstants.HASH_EXPIRED} element={<SessionExpiredPage />} />

            <Route
                exact
                path={RoutesConstants.VERIFY_EMAIL_CODE}
                element={<VerifyEmailCodePage />}
            />

            <Route
                exact
                path={RoutesConstants.VERIFY_PASSWORD_CODE}
                element={<VerifyPasswordCodePage />}
            />

            <Route exact path={RoutesConstants.BNPL_FEEDBACK} element={<FeedbackPage />} />
        </>
    );
};
