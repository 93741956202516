import { WORKFLOW_CONSTANTS } from "../Constants/BNPLConstants";

export const GetDateOfRequest = (date) => {
    let month = date?.split('/')[1];
    switch (month) {
        case '01':
            return 'Enero';
        case '02':
            return 'Febrero';
        case '03':
            return 'Marzo';
        case '04':
            return 'Abril';
        case '05':
            return 'Mayo';
        case '06':
            return 'Junio';
        case '07':
            return 'Julio';
        case '08':
            return 'Agosto';
        case '09':
            return 'Septiembre';
        case '10':
            return 'Octubre';
        case '11':
            return 'Noviembre';
        case '12':
            return 'Diciembre';
    }
};

export const GetQuoteExpression = (status) => {
    switch (status.toLowerCase()) {
        case 'cancelado':
            return 'Sin cuotas pendientes';
        case 'rechazada':
            return 'Cuotas totales';
        default:
            return 'Próxima cuota';
    }
};

export const GetQuotesCancelled = (quotes) => {
    let quotesCancelled = 0;
    if (quotes != null && quotes.length > 0) {
        quotes.forEach((q) => {
            if (q.status == 'cancelada') quotesCancelled += 1;
        });
    }
    return quotesCancelled != 0 ? quotesCancelled + 1 : 1;
};

export const GetQuotesPaid = (quotes) => {
    let quotesCancelled = 0;
    if (quotes != null && quotes.length > 0) {
        quotes.forEach((q) => {
            if (q.status == 'cancelada') quotesCancelled += 1;
        });
    }
    return quotesCancelled;
};

export const GetExpiration = (status, quotes) => {
    let lastQuoteCancelled = 0;
    if (quotes != null && quotes.length > 0) {
        quotes.forEach((q, index) => {
            if (q.status == 'cancelada') lastQuoteCancelled = index;
        });
    }

    let dateArrayMora = quotes[lastQuoteCancelled].date.split('/');
    let dateArray = quotes[lastQuoteCancelled].date.split('/');

    switch (status.toLowerCase()) {
        case 'en mora':
            return `Vencida ${dateArrayMora[0]} ${GetDateOfRequest(
                quotes[lastQuoteCancelled + 1].date
            )}`;
        case 'al dia':
            return `Vence ${dateArray[0]} ${GetDateOfRequest(quotes[lastQuoteCancelled].date)}`;
        case 'acreditado':
            return `Vence ${dateArray[0]} ${GetDateOfRequest(quotes[lastQuoteCancelled].date)}`;
        case 'cancelado':
            return 'Pagado';
        default:
            return `Vence ${dateArray[0]} ${GetDateOfRequest(quotes[lastQuoteCancelled].date)}`;
    }
};

export const GetStatusPhrase = (status, variant = '', percentage = 0) => {
    switch (status.toLowerCase()) {
        case 'en mora':
            return '¡Ponete al día!';
        case 'al dia':
            if(percentage < 0.5){
                return variant === WORKFLOW_CONSTANTS.BNPL ? '¡Disfruta de tu compra!' : '¡Disfruta de tu préstamo!';
            } 
            else if (percentage < 0.75){
                return '¡Ya falta poco!';
            }
            return '¡Que poco queda!';
        case 'rechazada':
            return 'No pudimos avanzar';
        case 'acreditado':
            return '¡Disfrutá tu préstamo!';
        case 'en revisión':
            return 'Pronto habrá novedades';
        case 'finalizado':
            return variant === WORKFLOW_CONSTANTS.BNPL ? '¡Disfruta de tu compra!' : '¡Terminaste de pagar tu préstamo!';
        default:
            return '';
    }
};

export const GetChipColor = (property) => {
    switch (property.toLowerCase()) {
        case 'en mora':
            return { color: '#B42318', backgroundColor: '#FEE4E2', label: '#B42318' };
        case 'rechazada':
            return { color: '#B42318', backgroundColor: '#FEE4E2', label: '#B42318' };
        case 'al dia':
            return { color: '#0069D7', backgroundColor: '#F5FAFF', label: '#636569' };
        case 'en revisión':
            return { color: '#DC6803', backgroundColor: '#FEF0C7', label: '#DC6803' };
        default:
            return { color: '#1F951F', backgroundColor: '#EEFFEE', label: '#1F951F' };
    }
};

export const GetStatusAndColorOfQuote = (property) => {
    switch (property.toLowerCase()) {
        case 'cancelada':
            return { color: '#1F951F', textLabel: 'Pagada' };
        case 'pendiente':
            return { color: '#636569', textLabel: 'A pagar' };
        default:
            return { color: '#D92D20', textLabel: 'Vencida' };
    }
};

export const GetExpirationNew = (date) => {
    let dateArray = date.split('/');
    const localDate = new Date();
    const day = localDate.getDate();
    return dateArray[0] < day
        ? `Venció ${dateArray[0]} ${GetDateOfRequest(date)}`
        : `Vence ${dateArray[0]} ${GetDateOfRequest(date)}`;
};

export const GetExpirationNewNext = (nextRateData) => {
    let date = nextRateData.date;
    let dateArray = date.split('/');
    const localDate = new Date();
    const day = localDate.getDate();

    if ( nextRateData.status == 'Pendiente' ) {
	return `Vence ${dateArray[0]} ${GetDateOfRequest(date)}`;
    } else {
	return `Venció ${dateArray[0]} ${GetDateOfRequest(date)}`;
    }
};
