/* eslint-disable no-console */
// /* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PaymentMethodContext from './PaymentMethodContext';
import { useNavigate } from 'react-router-dom';
import {
    getAllowedMethodsService,
    getPaymentMethodsService,
    getUrlTokenService,
    savePaymentMethod
} from '../../Services/PaymentMethodService';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLConstants, INSTALLMENT_TYPE, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { creditDetailsService } from '../../Services/LoanRequestService';

export const PaymentMethodState = ({ children }) => {
    const personData = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan));
    const pendingRequestId = getItemService(LoanRequestConstantsLS.LoanData)
        ? JSON.parse(getItemService(LoanRequestConstantsLS.LoanData)).pendingRequestId
        : '';
    const navigate = useNavigate();
    const [paymentMethods, setPaymentMethods] = useState();
    const [allowedMethod, setAllowedMethod] = useState();
    const [urlToken, setUrlToken] = useState(
        getItemService(LoanRequestConstantsLS.TokenUrl)
            ? JSON.parse(getItemService(LoanRequestConstantsLS.TokenUrl))
            : null
    );
    const [methodSelected, setMethodSelected] = useState();
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);

    const getPaymentMethods = async () => {
        try {
            const methods = await getPaymentMethodsService();
            setPaymentMethods(methods);

            let creditDetails = null
            if (workflow == WORKFLOW_CONSTANTS.BNPL) {
                creditDetails = await creditDetailsService(pendingRequestId)
            }
            
            if (workflow === WORKFLOW_CONSTANTS.CS || (creditDetails && creditDetails.deadline && creditDetails.deadline.type === INSTALLMENT_TYPE.ADELANTADO)) {
                setItemService(LoanRequestConstantsLS.AllowedMethods, 2);
                setAllowedMethod(2);
            }
            else {
                const allowedMethods = await getAllowedMethodsService({
                    dni: personData.dni,
                    sexTypeId: personData.sexTypeId
                });

                setItemService(LoanRequestConstantsLS.AllowedMethods, allowedMethods.paymentId);
                setAllowedMethod(allowedMethods.paymentId);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const setPayMethod = async (method) => {
        setItemService(LoanRequestConstantsLS.PaymentMethod, JSON.stringify(method));
        console.log(method);

        if (method?.id !== 1 && method?.id !== 2) {
            await setPaymentMethod(true, method.id);
            navigate(
                workflow === WORKFLOW_CONSTANTS.BNPL
                    ? RoutesConstants.CONFIRMATION_PAGE
                    : workflow === WORKFLOW_CONSTANTS.CS
                        ? RoutesConstants.DISCOVERY_FEEDBACK
                        : RoutesConstants.LOAN_DETAILS_PAGE
            );
            return;
        }

        if (method?.id === 1) {
            await setPaymentMethod(false);
            navigate(
                workflow === WORKFLOW_CONSTANTS.BNPL
                    ? RoutesConstants.CONFIRMATION_PAGE
                    : workflow === WORKFLOW_CONSTANTS.CS
                        ? RoutesConstants.DISCOVERY_FEEDBACK
                        : RoutesConstants.LOAN_DETAILS_PAGE
            );
            return;
        }
        if (method?.id === 2) {
            try {
                const response = await getUrlTokenService({
                    personId: personData.personId,
                    phoneCode: personData.phoneCode,
                    phoneNumber: personData.phoneNumber,
                    cuit: personData.cuit
                });

                setUrlToken(response.data.request[0]);
                setItemService(
                    LoanRequestConstantsLS.TokenUrl,
                    JSON.stringify(response.data.request[0])
                );

                await setPaymentMethod(true);
                navigate(
                    workflow === WORKFLOW_CONSTANTS.BNPL || workflow === WORKFLOW_CONSTANTS.CS
                        ? RoutesConstants.LOAN_CARD_LOAD_BNPL
                        : RoutesConstants.LOAN_CARD_LOAD
                    );
                return;
            } catch {
                setMethodSelected();
                return;
            }
        }

        navigate(RoutesConstants.LOAN_DETAILS_PAGE);
        return;
    };

    const setPaymentMethod = async (debitMethod, cardId) => {
        await savePaymentMethod({
            personId: personData.personId,
            pendingRequestId,
            debitPayment: debitMethod,
            cardId: cardId
        });
    };

    return (
        <PaymentMethodContext.Provider
            value={{
                paymentMethods,
                urlToken,
                allowedMethod,
                methodSelected,
                setMethodSelected,
                getPaymentMethods,
                setPayMethod,
                setPaymentMethod
            }}>
            {children}
        </PaymentMethodContext.Provider>
    );
};

PaymentMethodContext.propTypes = {
    children: PropTypes.element
};
