import React from 'react'
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material'
import { TitleCustom } from '../../Components/TitleCustom'
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import '../../Styles/GlobalStyle.css';
import { ContactForm } from '../Contact/ContactForm';
import { ColorConstants } from '../../Constants/ColorConstants';
import { MobileCheck } from '../../Helpers/MobileCheck';

const ContactSection = () => {

  const theme = useTheme();
  const desktop  = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Grid container spacing={4} sx={{mb:10}} className='bg-aqua'>
      <Grid item xs={1} sm={1}></Grid>
      <Grid item xs={12} md={4} sx={{mr:3, ml:3}}>
        <TitleCustom size={MobileCheck() ? '24px' : '32px'} title="¿Necesitás hablar con nosotros?" subtitle="Seleccioná la opción que te sea más cómoda. Dejanos tu comentario y te respondemos a la brevedad."/>
        <Grid container>
          <Grid item xs={11} md={12} sx={({display: {xs: 'none', md: 'block'}})}>
            <ButtonCustom border bg="transparent" color={ColorConstants.BLUE} 
            icon = {<CallMadeIcon/>}  text="0800-345-6790"
            action={() => {window.location.href='tel:08003456790'}}
            />
            <ButtonCustom border bg="transparent" color={ColorConstants.BLUE} 
            icon = {<CallMadeIcon/>}  text="ayuda@directo.com.ar" 
            action={() => {window.location.href='mailto:ayuda@directo.com.ar'}}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={1} sx={({display: {xs: 'block', sm: 'none'}})}></Grid>
      <Grid item xs={12} md={4} lg={4} sx={{ ml: 0, mr: 0, mt: desktop? 0 : -5}}>
        <ContactForm/>
      </Grid>
      <Grid item xs={2} md={1}></Grid>
      <Grid item xs={11} md={4} sx={({ml: 3, mr:3, display: {xs: 'block', md: 'none'}})}>
        <Box width='100%'>
          <ButtonCustom border bg="transparent" 
          color={ColorConstants.BLUE} 
          padding='12px 0px 12px 32px'
          icon = {<CallMadeIcon/>}  text="0800-345-6790" 
          action={() => {window.location.href='tel:08003456790'}}
          />
          <ButtonCustom border bg="transparent" 
          color={ColorConstants.BLUE} 
          padding='12px 0px 12px 32px'
          icon = {<CallMadeIcon/>}  text="ayuda@directo.com.ar" 
          action={() => {window.location.href='mailto:ayuda@directo.com.ar'}}
          />
        </Box>
      </Grid>
      <Grid item xs={1} sm={7}>
      </Grid>
  </Grid>
  )
}

export default ContactSection