import { useMediaQuery, useTheme, Stack, Box } from '@mui/material';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { financing_gradients } from '../../Financing/FinancingPage.styled';
import { Description } from '../../../Components/BNPL';
import ThumbDown from '../../../Assets/feedback-unsuccessful-payment.svg';
import { Img } from '../../../Components/ImageContainer';
import { ErrorIcon } from '../../../Components/Icons';
import { Circle, TitleContainer } from '../ValidationError/ValidationError.styled';
import { Title, SessionDescription } from './SessionExpired.styled';
import STRINGS from '../../../i18n/local/es.json';
import { logEventBNPLFunnel } from '../../../Services/BNPLService';

const SessionTitle = ({ desktop }) => (
    <TitleContainer>
        {!desktop && (
            <Circle>
                <ErrorIcon />
            </Circle>
        )}
        <Title>{STRINGS.session_expired.TITLE}</Title>
    </TitleContainer>
);

const SessionExpiredDescription = ({ desktop }) => (
    <Description
        title={<SessionTitle desktop={desktop} />}
        description={<SessionDescription>{STRINGS.session_expired.DESCRIPTION}</SessionDescription>}
        returnButton={false}
    />
);

const SessionExpiredForm = ({ desktop }) => (
    <Stack mb={!desktop && 24} justifyContent={desktop && 'center'}>
        <Img src={ThumbDown} alt="" />
    </Stack>
);

export const SessionExpiredPage = () => {
    // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("HASH_EXPIRADO", storageOrderID, localStorageHash)
            }
            // FINISH

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <BNPLLayout
            ContainerPanel={<SessionExpiredDescription desktop={desktop} />}
            Form={<SessionExpiredForm desktop={desktop} />}
            layoutProps={{
                mobile: {
                    showDescription: true,
                    background: 'transparent',
                    gradients: financing_gradients,
                    showFullWidthDesciption: true
                },
                desktop: {
                    showFullWidthDesciption: true
                }
            }}
        />
    );
};
