import {
    Box,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React, { useContext, useEffect } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { alphanumericTest, passwordValidate } from '../../Helpers/Validations';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';
import ChangePasswordContext from '../../Contexts/ChangePassword/ChangePasswordContext';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import ErrorLabel from '../../Components/Form/ErrorLabel';
import { ColorConstants } from '../../Constants/ColorConstants';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { RegisterConstantsLS } from '../../Contexts/Register/RegisterActions';
import { getItemService, removeItemService } from '../../Services/LocalStorageService';
import AppContext from '../../Contexts/Global/AppContext';

const FormChangePassword = () => {
    const { changePassword, tokenValidate } = useContext(ChangePasswordContext);
    const { OnLogin } = useContext(AppContext);
    const navigate = useNavigate();

    const [errorPassword, setErrorPassword] = useState(false);
    const [alphanumericError, setAlphanumericError] = useState(false);

    const [values, setValues] = useState({
        password: '',
        passwordRepeat: '',
        showPassword: false,
        showPasswordRepeat: false
    });

    useEffect(() => {
        if (localStorage.getItem(BNPLConstants.WORKFLOW) !== WORKFLOW_CONSTANTS.BNPL) {
            const isValid = tokenValidate();
            !isValid && navigate(RoutesConstants.NEW_PASSWORD_EXPIRED_LINK_PAGE);
        }
    }, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value.replace(/\s/g, '') });
    };

    const showPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };

    const showPasswordRepeat = () => {
        setValues({
            ...values,
            showPasswordRepeat: !values.showPasswordRepeat
        });
    };

    const isWrongPassword = () => {
        setErrorPassword(
            values.password != '' &&
            values.passwordRepeat != '' &&
            !passwordValidate(values.password, values.passwordRepeat)
        );
        setAlphanumericError(values.password != '' && !alphanumericTest(values.password));
    };

    const onSubmit = async () => {
        const successful = await changePassword(values.password);
        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        if (successful) {
            if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                // LOGIN
                const email = getItemService(RegisterConstantsLS.UserEmail);
                removeItemService(RegisterConstantsLS.UserEmail);
                OnLogin(email, values.password)
            } else {
                navigate(RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PAGE);
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '430px'
            }}>
            <div>
                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="password">Ingresá tu nueva contraseña</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={() => isWrongPassword()}
                        onClick={() => setErrorPassword(false)}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorPassword || alphanumericError}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={showPassword}
                                    edge="end">
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Ingresá tu nueva contraseña"
                    />
                    {alphanumericError && (
                        <ErrorLabel description={'Debe introducir una contraseña alfanumérica'} />
                    )}
                </FormControl>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="passwordRepeat">Repetí tu contraseña</InputLabel>
                    <OutlinedInput
                        id="passwordRepeat"
                        type={values.showPasswordRepeat ? 'text' : 'password'}
                        value={values.passwordRepeat}
                        onChange={handleChange('passwordRepeat')}
                        onBlur={() => isWrongPassword()}
                        onClick={() => setErrorPassword(false)}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorPassword}
                        required={true}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={showPasswordRepeat}
                                    edge="end">
                                    {values.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Repetí tu contraseña"
                    />
                </FormControl>
                {errorPassword && (
                    <FormHelperText sx={{ display: 'flex' }} id="outlined-adornment-amount">
                        <ErrorOutlineIcon color="error" fontSize="small" sx={{ mr: '2%' }} /> Las
                        contraseñas no coinciden
                    </FormHelperText>
                )}
            </div>

            <Box width={'100%'}>
                <GrayButton
                    text={'Confirmar'}
                    action={() => onSubmit()}
                    disabled={!passwordValidate(values.password, values.passwordRepeat) || !alphanumericTest(values.password)}
                />

                <BackHomeButton />
            </Box>
        </Box>
    );
};

export default FormChangePassword;
