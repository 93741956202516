import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegisterContext from '../../../Contexts/Register/RegisterContext';
import {
    alphanumericTest,
    emailValidate,
    passwordLengthValidate,
    passwordRepeatValidate,
    passwordValidate
} from '../../../Helpers/Validations';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { ValidateEmailAndExecuteRegister } from '../../../Helpers/ValidateEmailAndExecute';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import ErrorLabel from '../../../Components/Form/ErrorLabel';
import { AcceptTermsAndPolicy } from '../../../Components/AcceptTermsAndPolicy';
import InformationLabel from '../../../Components/Form/InformationLabel';
import { ColorConstants } from '../../../Constants/ColorConstants';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import { getItemService } from '../../../Services/LocalStorageService';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';
import { ALLOWED_REDIRECT_PARAMS } from '../../../Helpers/GetURLByParam';
import AppContext from '../../../Contexts/Global/AppContext';

const FormCreateAccount = () => {
    const { setEmailData } = useContext(AppContext);
    const { createUserMail, createUserMailFast } = useContext(RegisterContext);
    const navigate = useNavigate();

    const [values, setValues] = useState({
        email: '',
        password: '',
        passwordRepeat: '',
        showPassword: false,
        showPasswordRepeat: false
    });

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorRepeatEmail, setErrorRepeatEmail] = useState(false);
    const [errorLengthPassword, setErrorLengthPassword] = useState(false);
    const [errorRepeatPassword, setErrorRepeatPassword] = useState(false);
    const [viewMessagePassword, setViewMessagePassword] = useState(false);
    const [alphanumericError, setAlphanumericError] = useState(false);
    const workflow = getItemService(BNPLConstants.WORKFLOW);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value.replace(/\s/g, '') });
    };

    const showPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword
        });
    };

    const showPasswordRepeat = () => {
        setValues({
            ...values,
            showPasswordRepeat: !values.showPasswordRepeat
        });
    };

    const validateFields = () => {
        const mailValid = emailValidate(values.email);
        const passwordLengthValid = passwordLengthValidate(values.password, values.passwordRepeat);
        const passwordRepeatValid = passwordRepeatValidate(values.password, values.passwordRepeat);
        const testResult = alphanumericTest(values.password);
        values.email != '' && setErrorEmail(!mailValid);
        values.password != '' && setAlphanumericError(!testResult);
        values.password != '' && setErrorLengthPassword(!passwordLengthValid);
        values.password != '' &&
            values.passwordRepeat != '' &&
            setErrorRepeatPassword(!passwordRepeatValid);
    };

    const disableButton = () => {
        const mailValid = emailValidate(values.email);
        const passwordValid = passwordValidate(values.password, values.passwordRepeat);
        const testResult = alphanumericTest(values.password);
        return !(testResult && mailValid && passwordValid);
    };

    const clearErrors = () => {
        setErrorEmail(false);
        setErrorRepeatPassword(false);
        setErrorLengthPassword(false);
        setAlphanumericError(false);
    };

    const handleOpenMessage = () => {
        setViewMessagePassword(true);
        clearErrors();
    };

    const handleCloseMessage = () => {
        setViewMessagePassword(false);
        validateFields();
    };

    const submitActionWithCode = async () => {
        const appIdentifier = 1;
        const personData = getItemService(BNPLConstants.PERSON_DATA)
            ? JSON.parse(getItemService(BNPLConstants.PERSON_DATA))
            : null;

        const autoRegisterPerson = {
            dni: personData.dni,
            cuit: personData.cuit,
            name: personData.name,
            phone: personData.fullPhoneNumber,
            personId: personData.personId,
            provinceId: personData.province.value,
            sexTypeId: personData.gender
        };

        const emailData = {
            app: appIdentifier,
            identifier: values.email,
            password: values.password
        };
        try {
            if (personData) {
                const res = await createUserMailFast(emailData, autoRegisterPerson);
                if (res === 'EXITO') {
                    setEmailData(emailData)
                    if (workflow === WORKFLOW_CONSTANTS.BNPL)
                        navigate(`${RoutesConstants.VERIFY_EMAIL_CODE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.FINANCING}`);
                    else
                        navigate(`${RoutesConstants.VERIFY_EMAIL_CODE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.BALANCE}`);
                }
            }
        } catch (error) {
            setErrorRepeatEmail(true);
        }
    };

    const submitAction = async () => {
        try {
            const autoRegisterPerson = getItemService('PersonToRegisterAfterLoan')
                ? JSON.parse(getItemService('PersonToRegisterAfterLoan'))
                : null;
            if (autoRegisterPerson) {
                const emailData = {
                    identifier: values.email,
                    password: values.password
                };
                const res = await createUserMailFast(emailData, autoRegisterPerson);
                if (res === 'EXITO') navigate(RoutesConstants.REGISTER_SEND_EMAIL_PAGE);
            } else {
                const res = await createUserMail(values);
                if (res === 'EXITO') navigate(RoutesConstants.REGISTER_SEND_EMAIL_PAGE);
            }
        } catch (error) {
            setErrorRepeatEmail(true);
        }
    };

    const onSubmit = async () => {
        clearErrors();
        try {
            if (workflow === WORKFLOW_CONSTANTS.BNPL || workflow === WORKFLOW_CONSTANTS.CS) {
                await ValidateEmailAndExecuteRegister(values.email, submitActionWithCode);
            } else {
                await ValidateEmailAndExecuteRegister(values.email, submitAction);
            }
        } catch (error) {
            setErrorEmail(true);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
                <FormControl fullWidth sx={{ my: 1, borderRadius: '40px' }}>
                    <InputLabel htmlFor="email">Ingresá tu email</InputLabel>
                    <OutlinedInput
                        id="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={() => validateFields()}
                        onClick={() => clearErrors()}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorEmail}
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        }
                        label="Ingresá tu email"
                    />
                    {errorEmail ? (
                        <ErrorLabel description={'El mail ingresado no es válido'} />
                    ) : null}
                </FormControl>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="password">Creá tu contraseña</InputLabel>
                    <OutlinedInput
                        id="password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        onBlur={() => handleCloseMessage()}
                        onClick={() => handleOpenMessage()}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorLengthPassword || alphanumericError}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={showPassword}
                                    edge="end">
                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Creá tu contraseña"
                    />
                    {errorLengthPassword || alphanumericError ? (
                        <ErrorLabel description={'Debe contener 8 caracteres alfanumericos '} />
                    ) : null}
                    {viewMessagePassword ? (
                        <InformationLabel
                            description={'Debe contener 8 caracteres alfanumericos '}
                        />
                    ) : null}
                </FormControl>

                <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <InputLabel htmlFor="passwordRepeat">Repetí tu contraseña</InputLabel>
                    <OutlinedInput
                        id="passwordRepeat"
                        type={values.showPasswordRepeat ? 'text' : 'password'}
                        value={values.passwordRepeat}
                        onChange={handleChange('passwordRepeat')}
                        onBlur={() => validateFields()}
                        onClick={() => clearErrors()}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        error={errorRepeatPassword}
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={showPasswordRepeat}
                                    edge="end">
                                    {values.showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Repetí tu contraseña"
                    />
                    {errorRepeatPassword && (
                        <ErrorLabel description={'Las contraseñas no coinciden'} />
                    )}
                </FormControl>

                {/* FALTA AGREGAR EL CONDICIONAL PARA MOSTRARLO CUANDO EL REGISTER FALLA */}
                {errorRepeatEmail && (
                    <ErrorMessage
                        title={'Ya existe una cuenta'}
                        description={'El mail ingresado ya se encuentra registrado.'}
                        redirect={
                            workflow === WORKFLOW_CONSTANTS.BNPL
                                ? `${RoutesConstants.LOGIN_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.FINANCING}`
                                : RoutesConstants.LOGIN_PAGE
                        }
                        redirectText={'Podés iniciar sesión'}
                    />
                )}

                <AcceptTermsAndPolicy />

                <GrayButton text={'Enviar'} action={() => onSubmit()} disabled={disableButton()} />

                <BackHomeButton />
            </div>
        </Box>
    );
};

export default FormCreateAccount;
