import { RegisterConstantsLS } from '../Contexts/Register/RegisterActions';
import { postData } from './BaseService';
import { setItemService } from './LocalStorageService';

export const createUserService = async ({ email, password }) => {
    const url = 'register/email';

    setItemService(RegisterConstantsLS.UserEmail, email);

    let response = await postData(url, {
        identifier: email,
        password: password
    });

    return response;
};

export const createUserServiceFast = async ({ app, identifier, password, personData }) => {
    const url = 'register/fastRegisterEmail';

    let response = await postData(url, { app, identifier, password, personData });
    return response;
};

export const postPersonForm = async (body) => {
    const url = 'person';
    await postData(url, body);
};

export const savePersonService = (Ecommerce, person) => {
    const personWithEcommerceId = {
        ...person,
        ecommerceAccountId: Ecommerce
    };

    setItemService(RegisterConstantsLS.PersonToGetRegister, JSON.stringify(personWithEcommerceId));
};

export const resendEmailRegisterService = async (ecommerce) => {
    const url = 'register/resend_activaction_link';

    await postData(url, { ecommerceAccountId: ecommerce });
};
