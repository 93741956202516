import { Box, Stack } from '@mui/material';
import React, { useContext } from 'react';
import { /* Autocomplete, */ SelectMenu } from '../../../Components/BNPL';
/* import { SearchIcon } from '../../../Components/Icons'; */
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { useForm } from '../../../Hooks/useForm';

import STRINGS from '../../../i18n/local/es.json';

/* import { DUMMY_SEARCH } from '../partnerValues'; */
import { PartnerStoresContext } from '../../../Contexts/PartnerStores/PartnerStoresContext';
import { PartnerErrorContainer, PartnerError } from '../PartnerStoresLayout';

export const PartnersForm = () => {
    const { filters, actions, error, setError } = useContext(PartnerStoresContext);

    const categories = [
        {
            value: 0,
            label: 'Todos los rubros'
        }
    ].concat(
        filters.categories.map((category) => ({
            value: category,
            label: category
        }))
    );

    const offers = [
        {
            value: 0,
            label: 'Todas las ofertas'
        }
    ].concat(
        filters.offers.map((offer) => ({
            value: offer,
            label: offer
        }))
    );

    const provinces = [
        {
            value: 0,
            label: 'Todas las provincias'
        }
    ].concat(
        filters.provinces.map((province) => ({
            value: province,
            label: province
        }))
    );

    const formValidations = {};
    const { values: formData, handleOnChangeFieldEvent } = useForm(
        {
            category: '',
            offer: '',
            province: ''
            //search: ''
        },
        formValidations
    );

    const handleSubmitForm = () => {
        actions.applyFilters(formData);
    };

    const handleChange = (fieldName) => (event) => {
        setError(false);
        handleOnChangeFieldEvent(fieldName)(event);
    };

    return (
        <Box>
            <ReturnButton text="" />
            <Stack spacing={2} marginTop={'20px'}>
                <SelectMenu
                    label={STRINGS.partners.category.LABEL}
                    items={categories}
                    onChange={handleChange('category')}
                    disabled={categories.length <= 0}
                    error={error && Boolean(formData.category)}
                />

                <SelectMenu
                    label={STRINGS.partners.offer.LABEL}
                    items={offers}
                    onChange={handleChange('offer')}
                    disabled={offers.length <= 0}
                    error={error && Boolean(formData.offer)}
                />

                <SelectMenu
                    label={STRINGS.partners.province.LABEL}
                    items={provinces}
                    onChange={handleChange('province')}
                    disabled={provinces.length <= 0}
                    error={error && Boolean(formData.province)}
                />

                {/* <Autocomplete
                    value={formData.search}
                    label={STRINGS.partners.search.LABEL}
                    onChange={handleOnChangeFieldEvent('search')}
                    noOptionsText={STRINGS.partners.search.ERROR_TEXT}
                    iconStart={<SearchIcon />}
                    options={DUMMY_SEARCH}
                /> */}

                <GrayButton
                    type="submit"
                    text={STRINGS.home.contactForm.CONTACT_FORM_CTA}
                    action={() => {
                        handleSubmitForm();
                    }}
                    disabled={false}
                />

                {error ? (
                    <PartnerErrorContainer>
                        <PartnerError>{STRINGS.partners.search.ERROR_TEXT}</PartnerError>
                    </PartnerErrorContainer>
                ) : (
                    ''
                )}
            </Stack>
        </Box>
    );
};
