import { Box, Grid } from '@mui/material';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import TextDescriptionSendEmail from '../TextDescriptionSendEmail';
import { useContext } from 'react';
import BodySendEmail from './BodySendEmail';
import RegisterContext from '../../../Contexts/Register/RegisterContext';

export const NeedCheckAccount = ({ changePassword }) => {
    const { resendEmailRegister } = useContext(RegisterContext);
    const title = '¡Listo! Ahora revisá tu mail';

    return (
        <Box textAlign={'center'}>
            <Grid sx={({display: {
                xs: 'block',
                sm: 'none'
            }})}>
                {!changePassword && <ReturnButton />}
            </Grid>
            <DescriptionForm
                title={title}
                description={<TextDescriptionSendEmail resendEmail={!changePassword} />}
            />
            <BodySendEmail resendEmail={!changePassword} action={() => resendEmailRegister()} />
        </Box>
    );
};
