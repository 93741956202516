import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Description } from '../../Components/DescriptionsLayout';
import HomeLayout from '../../Components/HomeLayouts';
import { MyProfileState } from '../../Contexts/MyProfile/MyProfileState';

const titleDesc = 'Mi perfil';
const descriptionDesc =
    'Encontrá todos tus datos y mantenelos actualizados para tener una mejor experiencia';

const MyProfilePage = () => {
    return (
        <MyProfileState>
            <Grid container>
                <HomeLayout
                    ContainerPanel={<Description title={titleDesc} description={descriptionDesc} />}
                    Form={<Outlet />}
                />
            </Grid>
        </MyProfileState>
    );
};

export default MyProfilePage;
