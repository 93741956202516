import { SvgIcon } from '@mui/material';

const DownloadIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg">
                <path d="M14.75 10C14.75 9.58579 14.4142 9.25 14 9.25C13.5858 9.25 13.25 9.58579 13.25 10H14.75ZM2 12.6667H1.25H2ZM2.75 10C2.75 9.58579 2.41421 9.25 2 9.25C1.58579 9.25 1.25 9.58579 1.25 10H2.75ZM5.197 6.13634C4.9041 5.84344 4.42923 5.84344 4.13634 6.13634C3.84344 6.42923 3.84344 6.9041 4.13634 7.197L5.197 6.13634ZM8 10L7.46967 10.5303C7.76256 10.8232 8.23744 10.8232 8.53033 10.5303L8 10ZM11.8637 7.197C12.1566 6.9041 12.1566 6.42923 11.8637 6.13634C11.5708 5.84344 11.0959 5.84344 10.803 6.13634L11.8637 7.197ZM8.75 2C8.75 1.58579 8.41421 1.25 8 1.25C7.58579 1.25 7.25 1.58579 7.25 2H8.75ZM13.25 10V12.6667H14.75V10H13.25ZM13.25 12.6667C13.25 12.8214 13.1885 12.9697 13.0791 13.0791L14.1398 14.1398C14.5305 13.7491 14.75 13.2192 14.75 12.6667H13.25ZM13.0791 13.0791C12.9697 13.1885 12.8214 13.25 12.6667 13.25V14.75C13.2192 14.75 13.7491 14.5305 14.1398 14.1398L13.0791 13.0791ZM12.6667 13.25H3.33333V14.75H12.6667V13.25ZM3.33333 13.25C3.17862 13.25 3.03025 13.1885 2.92085 13.0791L1.86019 14.1398C2.25089 14.5305 2.7808 14.75 3.33333 14.75V13.25ZM2.92085 13.0791C2.81146 12.9697 2.75 12.8214 2.75 12.6667H1.25C1.25 13.2192 1.46949 13.7491 1.86019 14.1398L2.92085 13.0791ZM2.75 12.6667V10H1.25V12.6667H2.75ZM4.13634 7.197L7.46967 10.5303L8.53033 9.46967L5.197 6.13634L4.13634 7.197ZM8.53033 10.5303L11.8637 7.197L10.803 6.13634L7.46967 9.46967L8.53033 10.5303ZM8.75 10V2H7.25V10H8.75Z" />
            </svg>
        </SvgIcon>
    );
};

export default DownloadIcon;
