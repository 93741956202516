import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCredentials } from '../../Services/CredentialsService';
import { getOrderById, getSessionFromMobile, logEventBNPLFunnel } from '../../Services/BNPLService';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { cleanBNPLOrderData } from '../../Helpers/BNPLUtils';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { isBNPLRoute } from '../../Helpers/IsBNPLRoute';
import { getItemService } from '../../Services/LocalStorageService';
import { cleanSteps } from '../../Services/BiometricDataService';

const BNPLOrderContext = createContext(null);

const BNPLOrderProvider = ({ children }) => {
    const navigate = useNavigate();
    const [loadingOrderData, setLoadingOrderData] = useState(false);
    const [orderData, setOrderData] = useState({});
    const [feedbackType, setFeedbackType] = useState();
    const [credentialsHash, setCredentialsHash] = useState();
    const [isCrossDevice, setIsCrossDevice] = useState(
        localStorage.getItem(BNPLConstants.IS_CROSS_DEVICE) === 'true'
    );

    const initCrossDevice = () => {
        const parameters = window.location.href.split('?')[1].split('&')
        const params = [parameters[0].split('='), parameters[1].split('=')]
        let hashQR = null;
        let ecommerceAccountId = null;
        for (let param of params) {
            if (param[0] == 'hashQR') hashQR = param[1]
            else if (param[0] == 'ecommerceAccountId') ecommerceAccountId = param[1]
        }
        //const hashQR = window.location.href.split('hashQR=')[1];
        if (hashQR) {
            getSessionFromMobile(hashQR)
                .then((response) => {
                    cleanSteps();
                    //workflow
                    localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.BNPL);
                    //orderHash
                    localStorage.setItem(BNPLConstants.ORDER_HASH, hashQR);
                    setCredentialsHash(hashQR);
                    //credentials
                    localStorage.setItem(BNPLConstants.CREDENTIALS, response.data.credentials);
                    //orderId
                    setLoadingOrderData(true);
                    getOrderById(response.data.orderId)
                        .then((response) => {
                            // clean biometric steps
                            const localStorageHash = localStorage.getItem("orderHash");
                            if ( localStorageHash )
                            {
                                localStorage.setItem("bnplOrderId", response.id);
                                logEventBNPLFunnel("CAMBIO_DISPOSITIVO", response.id, localStorageHash)
                            }
                            // FINISH
                            setOrderData(response);
                            setLoadingOrderData(false);
                        })
                        .catch((error) => {
                            setLoadingOrderData(false);
                            console.log(error);
                            navigate(RoutesConstants.PUBLIC_HOME);
                        });
                    //personData
                    getDataPersonService(response.data.personId)
                        .then((response) => {
                            const personData = {
                                name: response.userName,
                                personId: response.idPerson,
                                dni: String(response.dni),
                                gender: response.sexTypeId,
                                areaCode: response.phoneCode,
                                phoneNumber: response.phoneNumber,
                                ecommerceAccountId: ecommerceAccountId
                            };
                            localStorage.setItem(
                                BNPLConstants.PERSON_DATA,
                                JSON.stringify(personData)
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                            navigate(RoutesConstants.PUBLIC_HOME);
                        });
                    //loanData
                    const loanData = {
                        pendingRequestId: response.data.pendingRequestId
                    };
                    localStorage.setItem(LoanRequestConstantsLS.LoanData, JSON.stringify(loanData));
                    setIsCrossDevice(true);
                    localStorage.setItem(BNPLConstants.IS_CROSS_DEVICE, true);
                    navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
                })
                .catch((error) => {
                    console.log(error);
                    navigate(RoutesConstants.PUBLIC_HOME);
                });
        }
    };

    const initOrderRequest = () => {
        const urlHash = window.location.href.split('compra-cuotas?hash=')[1];
        if (urlHash) {
            localStorage.setItem(BNPLConstants.ORDER_HASH, urlHash);
            setCredentialsHash(urlHash);
        } else {
            const localStorageHash = localStorage.getItem(BNPLConstants.ORDER_HASH);
            if (localStorageHash) {
                setCredentialsHash(localStorageHash);
            } else {
                navigate(RoutesConstants.PUBLIC_HOME);
            }
        }
    };

    useEffect(() => {
        if (credentialsHash) {
            getOrderData();
        }
    }, [credentialsHash]);

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname === RoutesConstants.REDIRECT_TO_DIRECTO_PAGE) {
            cleanBNPLOrderData();
            initOrderRequest();
        } else if (pathname === RoutesConstants.CROSS_DEVICE) {
            initCrossDevice();
        } else if (
            isBNPLRoute(pathname) &&
            getItemService(BNPLConstants.WORKFLOW) === WORKFLOW_CONSTANTS.BNPL
        ) {
            initOrderRequest();
        }
    }, []);

    const getOrderData = async () => {
        setLoadingOrderData(true);
        getCredentials({ hash: credentialsHash })
            .then((response) => {
                localStorage.setItem(BNPLConstants.CREDENTIALS, response.data.credentials);
                getOrderById(response.data.orderId)
                    .then((response) => {
			// TODO FUNNEL BNPL: First step
	                // INIT
                        const localStorageHash = localStorage.getItem("orderHash");
                        if ( localStorageHash )
                        {
                            localStorage.setItem("bnplOrderId", response.id);
                            logEventBNPLFunnel("INICIO_FLUJO", response.id, localStorageHash)
                        }
                        // FINISH
                        setOrderData(response);
                        if ( response.integration_Type == 'DIRECTO' ) {
                            localStorage.setItem("drmerchant", "S");
                        } else {
                            localStorage.setItem("drmerchant", "N");
                        }
                        setLoadingOrderData(false);
                    })
                    .catch((error) => {
                        //La orden no existe.
                        setLoadingOrderData(false);
                        navigate(RoutesConstants.PUBLIC_HOME);
                        console.log(error);
                    });
            })
            .catch((error) => {
                setLoadingOrderData(false); //La credencíal es erronea.
                navigate(RoutesConstants.HASH_EXPIRED);
                console.log(error);
            });
    };

    const updateOrderData = () => {
        if (orderData.id) {
            setLoadingOrderData(true);
            getOrderById(orderData.id)
                .then((response) => {
                    setOrderData(response);
                    if ( response.integration_Type == 'DIRECTO' ) {
                        localStorage.setItem("drmerchant", "S");
                    } else {
                        localStorage.setItem("drmerchant", "N");
                    }
                    setLoadingOrderData(false);
                })
                .catch((error) => {
                    setLoadingOrderData(false);
                    navigate(RoutesConstants.PUBLIC_HOME);
                    console.log(error);
                });
        }
    };

    return (
        <BNPLOrderContext.Provider
            value={{
                orderData,
                loadingOrderData,
                updateOrderData,
                isCrossDevice,
                feedbackType,
                setFeedbackType,
                initOrderRequest
            }}>
            {children}
        </BNPLOrderContext.Provider>
    );
};

export { BNPLOrderContext, BNPLOrderProvider };
