import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { Grid, Typography, Box } from '@mui/material';
import BiometricDataContext from '../../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../../Constants/StepsConstants';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

export const DNIModal = ({ buttonPosition, buttonText, dialogHook, icon, action, onClose }) => {
    const navigate = useNavigate();
    const { openCamera } = useContext(BiometricDataContext);

    const handleClose = () => {
        dialogHook.CloseDialog();
        if (onClose) {
            onClose();
        }
    };

    return (
        <div>
            <Dialog
                open={dialogHook?.showDialog ? true : false}
                keepMounted
                onClose={() => handleClose()}
                aria-describedby="alert"
                PaperProps={{
                    style: { borderRadius: 32 }
                }}>
                <DialogContent>
                    {icon && (
                        <Grid
                            sx={{ textAlign: 'right', color: '#0069D7' }}
                            onClick={() => handleClose()}>
                            <CloseIcon />
                        </Grid>
                    )}
                    <Grid container xs={12} sx={{ mt: 5 }} className="mb-30">
                        <Grid item xs={2} sx={{ ml: 0, mr: 0 }}>
                            <Grid sx={{ pt: 0.1 }}>
                                <div className="icon-style">{icon}</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'center', color: '#0069D7', mt: 0.5 }}>
                            <Typography className="font-blue font-weight font-size-20">
                                {dialogHook?.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left', color: '#0069D7', mb: 2 }}>
                        <Typography>{dialogHook?.content}</Typography>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ justifyContent: buttonPosition, mb: 1, ml: 2, mr: 2 }}>
                    <Box width="100%">
                        <ButtonCustom
                            color={ColorConstants.WHITE}
                            text={buttonText}
                            action={() => {
                                /* navigate(`${RoutesConstants.DNI_FRONT_PAGE}/frente`); */
                                action();
                                handleClose();
                            }}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};
