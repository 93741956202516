import Actions from './HomeActions';

export const initialState = { example: false }

export const HomeReducer = ( state, action ) => {

    switch ( action.type ) {
        case Actions.SET_EXAMPLE:

            return { ...state, example: action.payload };

        default:
            return state;
    }
}