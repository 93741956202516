import { Box } from '@mui/material';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import FormCBU from './FormCBU';

const ValidateCBUPage = () => {

    const titleForm = `Ingresá el CBU en el que querés recibir el dinero`;
    const descriptionForm = 'La cuenta debe estar registrada a nombre del DNI ingresado.';

    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton />
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormCBU />
        </Box>
    );
};

export default ValidateCBUPage
