import React from 'react';
import { Typography, Box } from '@mui/material';
import { CaldendarIcon } from '../../../Components/Icons';
import STRINGS from '../../../i18n/local/es.json';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { IconWrapper } from '../../../Components/Icons/IconWrapper';

const InstallmentDate = (props) => {
    return (
        <Box margin="20px 27px" display="flex" alignItems="center" color={ColorConstants.BLUE}>
            <IconWrapper outterContainer={
                {
                    marginRight: '10px'
                }
            }>
                <CaldendarIcon />
            </IconWrapper>
            <Box>
                <Typography>
                    {
                        `${STRINGS.financing.INSTALLMENT_DATE} ${props.date}`
                    }
                </Typography>
            </Box>
        </Box>
    );
};

export default InstallmentDate;