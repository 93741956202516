import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import { QRCodeImage } from './QRCodeImage';
import { useEffect } from 'react';
import { useState } from 'react';
import { getItemService } from '../../Services/LocalStorageService';
import { firstName } from '../../Helpers/Masks';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';
import { BNPLSiisaContext } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';
import { APP_ID } from '../../Constants/BNPLConstants';

export const RedirectPage = () => {
    const [name, setName] = useState('');
    const { saveHasValidatedIdentity } = useContext(BNPLSiisaContext);

    useEffect(() => {
        let completedName = getItemService('Person')
            ? JSON.parse(getItemService('Person')).personName
            : null;
        if (completedName) {
            completedName = firstName(completedName);
            setName(completedName);
        }
        saveHasValidatedIdentity(APP_ID.LN, false);
    }, []);

    const title = 'Ingresar desde el celular';
    const description = `${
        name ? name + ', detectamos' : 'Detectamos'
    } que estás ingresando desde tu computadora. Vamos a pedirte que lo hagas nuevamente pero desde tu celular
    para tener una mejor experiencia y acompañarte en ella. Nos volvemos a ver por ahí. ¡Gracias!`;

    return (
        <Box textAlign={'center'}>
            <DescriptionForm title={title} description={description} />
            <QRCodeImage />
            <BackHomeButton />
        </Box>
    );
};
