import React, { useContext } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DescriptionForm } from '../../../Components/BNPL';
import { Img } from '../../../Components/ImageContainer';
import FeedbackSuccessful from '../../../Assets/feedback-successful-payment.svg';
import { ColorConstants } from '../../../Constants/ColorConstants';
import STRINGS from '../../../i18n/local/es.json';
import { getItemService } from '../../../Services/LocalStorageService';
import { BNPLConstants } from '../../../Constants/BNPLConstants';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';
import { useRedirectTimer } from '../../../Hooks/useRedirectTimer';

const SuccessTimer = ({ orderData }) => {
    const drmerchant = localStorage.getItem("drmerchant");

    var minutes = 0;
    var seconds = 0;

    if (drmerchant != 'S') {
        const timer = useRedirectTimer(
            process.env.REACT_APP_CONTINUE_PURCHASE_TIMEOUT,
            orderData.return_Url_Success,
            true,
    	    null
        );

        minutes = Math.floor(timer.remainingSeconds / 60);
        seconds = timer.remainingSeconds % 60;
    }

    return (
        <>
            {drmerchant == 'S' ? (
                <div>
                </div>
            ) : (
                <Typography color={ColorConstants.blue[900]} textAlign="center">
                    En {`${minutes}:${seconds.toString().padStart(2, '0')}`} serás enviado
                {drmerchant == 'S' ? ' al inicio.' : ' al carrito de compras.'}
                </Typography>
            )}
        </>
    );
};

export const SuccessfulFeedbackPaymentPage = ({debit}) => {
    const { orderData, isCrossDevice } = useContext(BNPLOrderContext);
    const personData = JSON.parse(getItemService(BNPLConstants.PERSON_DATA));
    const buyerName = personData.name.split(',')[1];
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Stack gap={"40px"}>
            <DescriptionForm
                title={
                    desktop
                        ? STRINGS.feedbackPaymentSuccessfull.FORM_TITLE_DESKTOP
                        : STRINGS.feedbackPaymentSuccessfull.FORM_CONGRATULATION +
                          buyerName +
                          STRINGS.feedbackPaymentSuccessfull.FORM_TITLE
                }
                description={(debit ? STRINGS.feedbackPaymentSuccessfull.FORM_DESCRIPTION_DEBIT : STRINGS.feedbackPaymentSuccessfull.FORM_DESCRIPTION)
                                + "\n\n" + (isCrossDevice ? STRINGS.feedbackPaymentSuccessfull.FORM_DESCRIPTION_MOBILE : "")
                            }
                fontFamilyDescription="Public Sans"
                fontSizeDescription={isCrossDevice ? "16px" : "18px"}
                whiteSpaceDescription={isCrossDevice ? "pre-line" : "normal"}
            />
            {!isCrossDevice && <SuccessTimer orderData={orderData} />}
            <Box my={12} display="flex" justifyContent="center">
                <Img src={FeedbackSuccessful} />
            </Box>
        </Stack>
    );
};
