import React from 'react';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import SentimentNeutralOutlinedIcon from '@mui/icons-material/SentimentNeutralOutlined';
import { Box, Typography, Grid } from '@mui/material';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { InformationModal } from '../../../Components/Modal/InformationModal';
import { useDialog } from '../../../Hooks/useDialog';
import neutralFace from '../../../Assets/neutral-face.svg';

export const TransitionStep = () => {
    const title = '¡Ya falta poco!';
    const description =
        'Para finalizar con la validación de tu identidad, te vamos a pedir dos selfies.';
    const dialog = useDialog();

    const handleOpenModal = () => {
        dialog.setTitle('Primera selfie');
        dialog.setContent('Mantené una expresión neutra y sin gestos. Usá este ejemplo de guía:');
        dialog.OpenDialog();
    };

    return (
        <>
            <DescriptionForm title={title} description={description} />
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <LightModeOutlinedIcon sx={{ fontSize: '22px', pr: 0.2 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Buscá un fondo claro y bien iluminado.{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <RemoveRedEyeOutlinedIcon sx={{ fontSize: '22px', pr: 0.2 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Poné el celular a la altura de tus ojos.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <SentimentNeutralOutlinedIcon sx={{ fontSize: '22px', pr: 0.2 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Evitá tener objetos que te tapen la cara (como lentes o tapabocas).{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Box width="100%" sx={{ ml: 2 }}>
                    <ButtonCustom
                        text="Comenzar"
                        action={() => {
                            handleOpenModal();
                        }}
                    />
                </Box>
                <BackHomeButton />
            </Grid>
            <InformationModal
                dialogHook={dialog}
                buttonPosition="center"
                image={neutralFace}
                icon={<SentimentNeutralOutlinedIcon />}
                buttonText="Entendido"
                type="neutra"></InformationModal>
        </>
    );
};
