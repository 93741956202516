import { Stepper, Step, StepLabel as MuiStepLabel, styled, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const StepperComponent = ({ steps = [], activeStep = 1, error }) => {
    return (
        <Stepper orientation="vertical" activeStep={activeStep} connector={<StepsConnector />}>
            {steps.map((step, index) => (
                <Step key={step}>
                    <StepLabel StepIconComponent={IconComponent} className={error && index > activeStep ? 'error' : ''}>
                        <Typography
                            color={
                                index > activeStep
                                    ? ColorConstants.gray[700]
                                    : ColorConstants.blue[500]
                            }>
                            {step}
                        </Typography>
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

const StepLabel = styled(MuiStepLabel)`
    &.error{
        .MuiStepLabel-iconContainer div {
            background: #D4071C;
            color: white;
        }
    }
`

const StepsConnector = styled(StepConnector)({
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: ColorConstants.green[100]
        }
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: ColorConstants.green[100]
        }
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderLeftWidth: 2,
        borderColor: ColorConstants.gray[100]
    }
});

const StepIconRoot = styled('div')(({ state }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ColorConstants.gray[100],
    color: ColorConstants.gray[600],
    width: 24,
    height: 24,
    borderRadius: '50%',
    ...(state.active && {
        backgroundColor: ColorConstants.green[100],
        color: ColorConstants.blue[500]
    }),
    ...(state.completed && {
        backgroundColor: ColorConstants.green[100],
        color: ColorConstants.blue[500]
    })
}));

function IconComponent(props) {
    const { active, completed } = props;
    const icon = <CheckIcon sx={{ width: '15px', height: '15px' }} />;
    return <StepIconRoot state={{ completed, active }}>{icon}</StepIconRoot>;
}
