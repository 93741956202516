import { Box, Link } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { useDialog } from '../../../Hooks/useDialog';
import { BackHomeDialog } from '../Dialog/AlertDialog';
import { updateOrderStatus } from '../../../Services/BNPLService';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { BNPLConstants, ORDER_STATUS, WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';
import STRINGS from '../../../i18n/local/es.json';
import { useContext } from 'react';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';

export const BackHomeDialogButton = ({ text }) => {
    const drmerchant = localStorage.getItem("drmerchant");
    const { orderData } = useContext(BNPLOrderContext)
    const location = useLocation();
    const dialog = useDialog();
    const navigate = useNavigate();

    const handleOnClick = () => {
        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        if (workflow === WORKFLOW_CONSTANTS.BNPL || location.pathname === RoutesConstants.REDIRECT_TO_DIRECTO_PAGE) {
            dialog.OpenDialog();
        } else {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    };

    const resolveAfterSeconds = (url) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                window.location.replace(url);
                resolve(true);
            }, 4000);
        });
    };

    const handleOnContinue = () => {
        if (orderData.id) {
            updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
                .then(() => {
                    const isCrossDevice = localStorage.getItem(BNPLConstants.IS_CROSS_DEVICE);
                    if (isCrossDevice === 'true') {
                        navigate(RoutesConstants.PURCHASE_CANCELED);
                    } else {
    			        trackPromise(
                            resolveAfterSeconds(orderData.return_Url_Error)
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    navigate(RoutesConstants.PURCHASE_CANCELED);
                });
        }
    };

    return (
        <Box textAlign="center" mt="24px" width="100%">
            <Link id="back_to_store" component="button" variant='button' onClick={handleOnClick}>
                {text ? text : (drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE)}
            </Link>
            <BackHomeDialog
                open={dialog.showDialog}
                onClose={dialog.CloseDialog}
                onContinue={handleOnContinue}
            />
        </Box>
    );
};
