import React, { useEffect, useState } from 'react';
import { BnplCard } from '../../Components/BNPL';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import STRINGS from '../../i18n/local/es.json';
import { moneyMask } from '../../Helpers/Masks';
import { DollarIcon } from '../../Components/Icons';
import { ColorConstants } from '../../Constants/ColorConstants';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import {
    BottomTypography,
    TopTypography,
    BottomContainer,
    AvatarIcon,
    gradients,
    Description
} from './AvailableBalance.styled';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { BNPLConstants } from '../../Constants/BNPLConstants';
import { requestLoan } from '../../Services/LoanRequestService';
import { firstName } from '../../Helpers/Masks';

const FormContainer = (personData) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const [availableBalance, setAvailableBalance] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const loanData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData));
        setAvailableBalance(loanData.offer.length > 0 ? loanData.offer[0].maximumAmount : 0);
    }, []);

    const handleContinue = () => {
        setItemService(
            LoanRequestConstantsLS.PersonToGetLoan,
            JSON.stringify({
                personId: personData.personId,
                dni: personData.dni,
                sexTypeId: personData.gender,
                phoneCode: personData.areaCode,
                phoneNumber: personData.phoneNumber,
                cuit: personData.cuit
            })
        );
        navigate(RoutesConstants.STEPS_REMAINING);
    };

    return (
        <Stack width={'100%'}>
            <Stack sx={{ mt: { md: 16 } }}>
                <BnplCard>
                    <Stack
                        padding="28px"
                        direction={['column', 'row']}
                        justifyContent={desktop && 'space-between'}
                        alignItems={desktop && 'center'}>
                        <TopTypography>{STRINGS.available_amount.CARD_CONTENT_TOP}</TopTypography>
                        <Typography fontWeight={700} fontSize={['30px', '24px']}>
                            {moneyMask(`${availableBalance ? availableBalance : 0}`, true, 2)}
                        </Typography>
                    </Stack>
                </BnplCard>
                <BottomContainer>
                    <AvatarIcon>
                        <DollarIcon color={ColorConstants.blue[500]} />
                    </AvatarIcon>
                    <BottomTypography>
                        {STRINGS.available_amount.CARD_CONTENT_BOTTOM}
                    </BottomTypography>
                </BottomContainer>
            </Stack>
            <ButtonCustom
                margin={desktop ? '24px 0 0' : '200px 0 0'}
                width={desktop ? '80%' : '100%'}
                action={handleContinue}
                text={STRINGS.buttons.CONTINUE}
            />
        </Stack>
    );
};

export const AvailableBalancePage = () => {
    const personData = JSON.parse(getItemService(BNPLConstants.PERSON_DATA));
    const personName = firstName(personData.name);

    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={`${STRINGS.available_amount.TITLE} ${personName}!`}
                    description={STRINGS.available_amount.DESCRIPTION}
                />
            }
            Form={<FormContainer {...personData} />}
            layoutProps={{
                desktop: {
                    background: 'transparent'
                },
                mobile: { background: 'transparent', showDescription: true, gradients: gradients }
            }}
        />
    );
};
