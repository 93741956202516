import { DescriptionForm } from '../../../Components/BNPL';
import { Box, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material';
import { Avatar, ButtonsContainer, List, ListItem } from './InstructionsLayout.styled';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import ImageIcon from '@mui/icons-material/Image';

import STRINGS from '../../../i18n/local/es.json';
import { BackHomeDialogButton } from '../../../Components/BNPL/Buttons/BackHomeDialogButton';
import SidLogo from '../../../Assets/sid-logo.svg'

const defaultInstructions = [
    {
        icon: <ImageIcon />,
        description: 'description'
    }
];

export const InstructionsLayout = ({
    title = '',
    description = '',
    instructions = defaultInstructions,
    onContinue,
    variant = 'dni'
}) => {
    return (
        <>
            <Stack>
                <DescriptionForm title={title} description={description} />
                <List>
                    {instructions.map((instruction, i) => (
                        <ListItem key={i}>
                            <ListItemAvatar>
                                <Avatar>{instruction.icon}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={instruction.description} />
                        </ListItem>
                    ))}
                </List>
            </Stack>
            <ButtonsContainer className={variant}>
                <Stack px={3}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={'10px'}>
                        <Typography color={'primary'}>Vamos a utilizar el</Typography>
                        <img src={SidLogo} style={{ padding: '30px 0px', maxWidth: '80px'}}/>
                    </Box>
                    <ButtonCustom text={STRINGS.biometric.CTA_CONTINUE} action={onContinue} />
                    <BackHomeDialogButton />
                </Stack>
            </ButtonsContainer>
        </>
    );
};
