import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { useTimer } from '../../../Hooks/useTimer';
import ButtonTypeOccupation from './ButtonTypeOccupation';

const TypeOccupation = () => {
    const { saveOccupation, getOccupations, occupationsList } = useContext(LoanRequestContext);
    const navigate = useNavigate();
    const [optionSelected, setOptionSelected] = useState();
    const { timerAction } = useTimer(2);

    const handleSubmit = (type) => {
        const occupation = occupationsList.filter((occupation) => occupation.occupationId == type);
        setOptionSelected(type);
        saveOccupation(type, occupation[0].question);
        timerAction(() => {
            navigate(RoutesConstants.LOAN_CALENDAR);
        });
    };

    useEffect(() => {
        getOccupations();
    }, []);


    return (
        <Grid container mb={6}>
            <Grid item xs={12}>
                <ReturnButton />
                <DescriptionForm title="Nos gustaría saber qué tipo de ocupación tenés" />
                {occupationsList.length > 0
                    ? occupationsList.map((occupation) => (
                          <ButtonTypeOccupation
                              key={occupation.occupationId}
                              name={occupation.name}
                              selected={optionSelected}
                              value={occupation.occupationId}
                              onClick={handleSubmit}
                          />
                      ))
                    : null}
            </Grid>
            <BackHomeButton />
        </Grid>
    );
};

export default TypeOccupation;
