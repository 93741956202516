import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../../Components/HomeLayouts';
import { Description } from '../../../Components/DescriptionsLayout';
import { Outlet } from 'react-router-dom';
import { ChangePasswordState } from '../../../Contexts/ChangePassword/ChangePasswordState';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';
import STRINGS from '../../../i18n/local/es.json';

const NewPasswordPage = () => {
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const titleDesc = STRINGS.identification.TITLE_AUTH;
    const descriptionDesc =
        workflow === WORKFLOW_CONSTANTS.BNPL
            ? STRINGS.identification.SUBTITLE_AUTH
            : 'El detalle de tu préstamo en un mismo sitio, para que tengas una experiencia unificadora.';
    return (
        <ChangePasswordState>
            <Grid container>
                <HomeLayout
                    ContainerPanel={<Description title={titleDesc} description={descriptionDesc} />}
                    Form={<Outlet />}
                />
            </Grid>
        </ChangePasswordState>
    );
};

export default NewPasswordPage;
