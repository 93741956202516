import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { DynamicGradientBackground } from '../DynamicGradientBackground/DynamicGradientBackground';
import { Navbar } from '../Navbar/Navbar';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { Description, Heading } from './HeroDiscovery.styled';
import { gradients } from './gradients';

export const HeroDiscovery = ({
    heroProps = { inverted: false, showMobile: true },
    navBarProps = { inverted: true, showButtons: true },
    heading,
    description,
    children,
    variant = 'public'
}) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid
            container
            className={`bg-image ${desktop ? 'bg-desktop' : 'bg-mobile'}`}
            sx={{
                flexDirection: 'column',
                height: 'auto',
                paddingX: [3, 8, '16%'],
                paddingY: [2, 3]
            }}>
            {/* <DynamicGradientBackground
            borderRadius={desktop ? '0 0 80px 80px' : '0 0 40px 40px'}
            sx={{
                backgroundColor: heroProps.inverted ? 'white' : ColorConstants.blue[900],
                color: 'white',
                paddingX: [3, 8, '16%'],
                paddingY: [2, 3]
            }}
            masked
            gradients={gradients[variant][desktop ? 'desktop': 'mobile']}
            boxShadow={`0px 12px 16px -4px rgba(0, 105, 215, 0.1), 0px 4px 6px -2px rgba(0, 105, 215, 0.05)`}> */}
            <Navbar {...{ ...navBarProps, whiteLogo: true }} />
            <Box>
                {((!desktop && heroProps.showMobile) || desktop) && (
                    <>
                        <Heading className={heroProps.inverted ? 'inverted' : ''}>
                            {heading}
                        </Heading>
                        <Description className={heroProps.inverted ? 'inverted' : ''}>
                            {description}
                        </Description>
                    </>
                )}
                <Stack mt={4}>{children}</Stack>
            </Box>
        </Grid>
    );
};
