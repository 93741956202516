import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import './PublicHomeStyle.css';
import { useNavigate } from 'react-router-dom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { useTheme } from '@emotion/react';
import { MobileCheck } from '../../Helpers/MobileCheck';

export const LoanSection = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const desktop  = useMediaQuery(theme.breakpoints.up("sm"))
    
    return (
        <Grid container spacing={2} className="text-aling-center bg-aqua" sx={{pb:2, pt:2, mt: 1}} >
            <Grid item xs={12} sm={12} display='flex' justifyContent='center'>
                <Grid item xs={desktop ? 5 : 11}>
                    <Typography
                        variant='h2'
                        color="primary"
                        textAlign={desktop ? 'center' : 'start'}
                        fontSize={desktop ? "40px" : '24px'}
                        fontWeight={700}
                        lineHeight={1.2}
                        letterSpacing="-0.02em"
                        mb={'16px'}>
                        ¿Ya tenés tu préstamo?
                    </Typography>
                    <Typography
                        color="primary"
                        textAlign={desktop ? 'center' : 'start'}>
                        Creá tu cuenta e ingresá a tu perfil. Consultá el estado de tu préstamo en
                        un solo lugar, de forma simple y clara.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={3}></Grid>
            <Grid item xs={8} sm={8} md={4} sx={{ ml: desktop && MobileCheck()? 15 : 10, mr: 15 }} display='flex' justifyContent='center'>
                <ButtonCustom
                    border
                    width='171px'
                    height='48px'
                    bg="transparent"
                    color={ColorConstants.BLUE}
                    icon={<ArrowForwardOutlinedIcon />}
                    padding={desktop && MobileCheck() ? '12px 10px 12px 22px' :'12px 32px 12px 32px'}
                    text="Registrate"
                    action={() => navigate('/registro')}
                />
            </Grid>
        </Grid>
    );
};
