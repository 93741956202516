import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import DoubtsSection from './DoubtsSection';
import ContactSection from './ContactSection';
import { HomeState } from '../../Contexts/Home/HomeState';
import { ActiveLoansSection } from './ActiveLoansSection';
import { LoanActiveState } from '../../Contexts/LoanActive/LoanActiveState';
import { CompletedLoansSection } from './CompletedLoansSection';
import { cleanBNPLOrderData } from '../../Helpers/BNPLUtils';

const PrivateHome = () => {
    window.scrollTo(0, 0);

    useEffect(() => {
        cleanBNPLOrderData();
    }, []);

    return (
        <Grid container>
            <HomeState>
                <Grid container>
                    <Grid sm={2}></Grid>
                    <Grid item sm={8}>
                        <LoanActiveState>
                            <ActiveLoansSection />
                        </LoanActiveState>
                    </Grid>
                    <Grid sm={2}></Grid>
                </Grid>
                <Grid container>
                    <Grid sm={2}></Grid>
                    <Grid item sm={8}>
                        <LoanActiveState>
                            <CompletedLoansSection />
                        </LoanActiveState>
                    </Grid>
                    <Grid sm={2}></Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={2} sx={{ display: { sm: 'block', md: 'none' } }}></Grid>
                    <Grid item sm={8} md={12}>
                        <DoubtsSection />
                    </Grid>
                    <Grid item sm={2} sx={{ display: { sm: 'block', md: 'none' } }}></Grid>
                </Grid>
                <Grid container>
                    <Grid item sm={2} md={1}></Grid>
                    <Grid item sm={8} md={11}>
                        <ContactSection />
                    </Grid>
                    <Grid item sm={2} md={11}></Grid>
                </Grid>
            </HomeState>
        </Grid>
    );
};

export default PrivateHome;
