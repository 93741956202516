import { Dialog } from './Dialog';
import { Box, Typography } from '@mui/material';
import UnsuccessfulPayment from '../../../Assets/feedback_2.svg';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { AlertIcon } from '../../Icons';
import { Circle, Description, Image, Title } from './AlertDialog.styled';
import { useActionTimer } from '../../../Hooks/useActionTimer';

const AlertTitle = ({ value }) => {
    return (
        <>
            <Circle>
                <AlertIcon />
            </Circle>
            <Title>{value}</Title>
        </>
    );
};
const AlertDescription = ({ value }) => {
    return (
        <>
            <Description>{value}</Description>
            <Box textAlign="center" mt={2}>
                <Image src={UnsuccessfulPayment} />
            </Box>
        </>
    );
};

export const AlertDialog = ({
    open,
    onClose,
    onContinue,
    title,
    description,
    buttonText,
    children
}) => {
    return (
        <Dialog
            title={<AlertTitle value={title} />}
            description={
                <>
                    <AlertDescription value={description} />
                    {children}
                </>
            }
            buttonText={buttonText}
            open={open}
            onClose={onClose}
            onContinue={onContinue}
        />
    );
};

export const BackHomeDialog = ({ open, onClose, onContinue }) => {
    return (
        <AlertDialog
            open={open}
            onClose={onClose}
            onContinue={onContinue}
            title={'¿Querés volver al inicio?'}
            description={'En caso de hacerlo, vas a perder los datos de la operación.'}
            buttonText={'Ir al inicio'}
        />
    );
};

// this conditions prevent to render the modal and start the counter before it is needed
// {dialog.showDialog && <TimeOutDialog open={dialog.showDialog} /> }
export const TimeOutDialog = ({ open, onClose, action }) => {
    const timer = useActionTimer(process.env.REACT_APP_INACTIVE_REDIRECT_TIMER, action);
    const minutes = Math.floor(timer.remainingSeconds / 60);
    const seconds = timer.remainingSeconds % 60;
    return (
        <AlertDialog
            open={open}
            onClose={onClose}
            onContinue={onClose}
            title={'¿Querés continuar?'}
            description={'En caso de abandonar la sesión vas a perder los datos de la operación.'}
            buttonText={'Continuar con tu compra'}>
            <Typography color={ColorConstants.blue[900]} textAlign="center">
                En {`${minutes}:${seconds.toString().padStart(2, '0')}`} serás enviado a la tienda.
            </Typography>
        </AlertDialog>
    );
};
