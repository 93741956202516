import React from 'react'
import { StepsConstants } from '../Constants/StepsConstants'
import { FirstStep } from '../Pages/BiometricData/FirstStep'
import { FourthStep } from '../Pages/BiometricData/FourthStep'
import { SecondStep } from '../Pages/BiometricData/SecondStep'
import { ThirdStep } from '../Pages/BiometricData/ThirdStep'

export const GetElementToRender = (activeStep) => {
    switch (activeStep){
        case StepsConstants.FIRST_STEP: 
          return <FirstStep/>
        case StepsConstants.SECOND_STEP:
          return <SecondStep/>
        case StepsConstants.THIRD_STEP: 
          return  <ThirdStep/>
        case StepsConstants.FOURTH_STEP: 
          return <FourthStep/>
        default:
          return 
    }
}