import React from 'react'
import { Grid, Typography, useTheme, useMediaQuery } from '@mui/material'
import '../Styles/GlobalStyle.css'

export const TitleCustom = ({title, subtitle, secondSubtitle, size = '32px', titleLineHeightDesktop = '48px', titleLineHeightMobile = '30px'}) => {

  const theme = useTheme();
  const desktop  = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <Grid container>
        <Grid item xs={12} sm={12}>
            <Typography variant="h2" className='title font-blue font-weight' sx={{fontSize:`${size}`, lineHeight: desktop ? titleLineHeightDesktop : titleLineHeightMobile}}>{title}</Typography>  
            {subtitle? <Typography sx={{fontSize:`16px`, lineHeight: '24px', pt: 0.5}} className='font-blue'>{subtitle}<br></br>{secondSubtitle? secondSubtitle : null}</Typography> : null }
        </Grid>
    </Grid>
  )
}