import { useContext, useEffect } from 'react';
import { Box, styled } from '@mui/material';
import { Description } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { StepperComponent } from '../StepsRemaining/components/Stepper';
import { WaitingConfirm } from './WaitingConfirm';
import { QRCodePage } from './QRCode';
import { NoSuccess } from './NoSuccess';
import {
    BNPLCrossDeviceContext,
    BNPLCrossDeviceProvider
} from '../../Contexts/BNPLCrossDevice/BNPLCrossDeviceContext';
import STRINGS from '../../i18n/local/es.json';
import { BNPLSiisaContext } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';
import { APP_ID } from '../../Constants/BNPLConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

const status = {
    continuing_from_mobile: {
        title: STRINGS.scan_qr_code.continuing_from_mobile.TITLE,
        subtitle: STRINGS.scan_qr_code.continuing_from_mobile.SUBTITLE,
        component: <QRCodePage />,
        error: false
    },
    waiting_for_confirmation: {
        title: STRINGS.scan_qr_code.waiting_for_confirmation.TITLE,
        subtitle: STRINGS.scan_qr_code.waiting_for_confirmation.SUBTITLE,
        component: <WaitingConfirm />,
        error: false
    },
    cancelled: {
        title: STRINGS.scan_qr_code.cancelled.TITLE,
        subtitle: STRINGS.scan_qr_code.cancelled.SUBTITLE,
        component: <NoSuccess />,
        error: true
    },
    identity_not_verified: {
        title: STRINGS.scan_qr_code.identity_not_verified.TITLE,
        subtitle: STRINGS.scan_qr_code.identity_not_verified.SUBTITLE,
        component: <NoSuccess />,
        error: true
    }
};

const StepperContainer = styled(Box)`
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 40px;
    padding: 36px;
    display: inline-flex;
    .MuiStepLabel-root {
        padding: 0;
    }
`;

const LeftPanel = () => {
    const { state } = useContext(BNPLCrossDeviceContext);
    const { purchaseStatus, activeStep, steps } = state;
    return (
        <>
            <Description
                title={status[purchaseStatus].title}
                description={status[purchaseStatus].subtitle}
                returnButton={false}
            />
            <StepperContainer>
                <StepperComponent
                    steps={steps}
                    activeStep={activeStep}
                    error={status[purchaseStatus].error}
                />
            </StepperContainer>
        </>
    );
};

const RightPanel = () => {
    const { state } = useContext(BNPLCrossDeviceContext);
    const { purchaseStatus } = state;
    return status[purchaseStatus].component;
};

export const ScanQrCode = () => {
    const { saveHasValidatedIdentity } = useContext(BNPLSiisaContext);

    useEffect(() => {
        // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("SCAN_QR", storageOrderID, localStorageHash)
            }
            // FINISH
        saveHasValidatedIdentity(APP_ID.BNPL, false);
    }, []);

    return (
        <BNPLCrossDeviceProvider>
            <BNPLLayout ContainerPanel={<LeftPanel />} Form={<RightPanel />} />
        </BNPLCrossDeviceProvider>
    );
};
