import { TimeOutDialog } from '../../Components/BNPL/Dialog/AlertDialog';
import { BNPLOrderContext, BNPLOrderProvider } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { Outlet } from 'react-router-dom';
import { trackPromise} from 'react-promise-tracker';
import { useDialog } from '../../Hooks/useDialog';
import { useExpiredTime } from '../../Hooks/useExpiredTime';
import { useContext, useEffect } from 'react';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { updateOrderStatus } from '../../Services/BNPLService';
import { BNPLConstants, ORDER_STATUS, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { BNPLSiisaProvider } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';

const { REACT_APP_INACTIVE_TIMEOUT } = process.env;

const TimeOut = () => {
    const { orderData } = useContext(BNPLOrderContext);
    const dialog = useDialog();
    const isInactive = useExpiredTime({ time: REACT_APP_INACTIVE_TIMEOUT * 1000 });

    useEffect(() => {
        if (
            isInactive &&
            window.location.pathname !== RoutesConstants.SCAN_QR_CODE &&
            window.location.pathname !== RoutesConstants.BNPL_FEEDBACK
        ) {
            dialog.OpenDialog();
        }
    }, [isInactive]);

    const resolveAfterSeconds = (url) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                window.location.replace(url);
                resolve(true);
            }, 4000);
        });
    };

    const handleOnRedirect = () => {
        updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
            .then(() => {
                trackPromise(
                    resolveAfterSeconds(orderData.return_Url_Error)
                );
            })
            .catch(() => {
                trackPromise(
                    resolveAfterSeconds(orderData.return_Url_Error)
                );
            });
    };

    if (dialog.showDialog) {
        return (
            <TimeOutDialog
                open={dialog.showDialog}
                onClose={dialog.CloseDialog}
                action={handleOnRedirect}
            />
        );
    }
};

export const OrderRequestPage = () => {
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    return (
        <BNPLOrderProvider>
            <BNPLSiisaProvider>
                <Outlet />
            </BNPLSiisaProvider>
            {(workflow === WORKFLOW_CONSTANTS.BNPL ||
                location.pathname === RoutesConstants.REDIRECT_TO_DIRECTO_PAGE) && <TimeOut />}
        </BNPLOrderProvider>
    );
};
