import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DescriptionForm } from '../../Components/BNPL';
import { Img } from '../../Components/ImageContainer';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import UnsuccessfulPayment from '../../Assets/feedback-unsuccessful-payment.svg';
import { ColorConstants } from '../../Constants/ColorConstants';
import STRINGS from '../../i18n/local/es.json';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const ErrorFeedbackForm = ({ connectionLost = false }) => {
    const navigate = useNavigate();
    return (
        <Stack>
            <DescriptionForm
                title={
                    connectionLost
                        ? STRINGS.feedbackConnectionLost.FORM_TITLE
                        : STRINGS.feedbackPaymentUnsuccessfull.FORM_TITLE
                }
                description={
                    connectionLost
                        ? STRINGS.feedbackConnectionLost.FORM_DESCRIPTION
                        : STRINGS.feedbackPaymentUnsuccessfull.FORM_DESCRIPTION
                }
                fontSizeDescription="16px"
            />
            <Box mt={6} mb={22} display="flex" justifyContent="center">
                <Img src={UnsuccessfulPayment} />
            </Box>
            <ButtonCustom
                text={
                    connectionLost
                        ? STRINGS.feedbackConnectionLost.BUTTON_GO_BACK_RESUME
                        : STRINGS.feedbackPaymentUnsuccessfull.UPDATE_CARD
                }
                bg={ColorConstants.WHITE}
                color={ColorConstants.blue[900]}
                fontFamily="Archivo"
                action={() => {
                    connectionLost ? navigate('/') : navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL);
                }}
            />
        </Stack>
    );
};
