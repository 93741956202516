import React, { useContext, useEffect, useRef } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
import jwt_decode from 'jwt-decode';
import { Box } from '@mui/material';

// eslint-disable-next-line no-undef
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const LoginGoogle2 = () => {
    const { onLoginGoogle, AssociateGoogleAccount } = useContext(AppContext);
    const btnRef = useRef();

    useEffect(() => {
        /* global google */
        console.log(window.google);
        try {
            if (window.google) {
                google.accounts.id.initialize({
                    client_id: REACT_APP_GOOGLE_CLIENT_ID,
                    callback: googleCallback
                });
                google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
                    type: 'standard',
                    theme: 'outline',
                    size: 'large',
                    text: 'signin_with',
                    shape: 'pill',
                    width: `${window.screen.width - window.screen.width * 0.1}px`
                });

                // google.accounts.id.prompt()
            }
        } catch (error) {
            console.log('Error al cargar el botón de Google', error);
        }
    }, []);

    const googleCallback = (response) => {
        console.log('se ejecuta el callback');
        const credentials = jwt_decode(response.credential);
        const data = {
            googleId: credentials.sub,
            profileObj: {
                imageUrl: credentials.picture,
                email: credentials.email,
                name: credentials.name,
                givenName: credentials.given_name,
                familyName: credentials.family_name
            }
        };
        /* if (isMyProfile) {
            AssociateGoogleAccount(data);
            return;
        } */
        onLoginGoogle(data);
    };

    const btnClicked = () => {
        const googleButton = document.getElementById('signUpDiv');
        googleButton?.firstChild?.firstChild?.firstChild?.click();
        if (
            googleButton?.firstChild?.firstChild?.firstChild?.click === null ||
            googleButton?.firstChild?.firstChild?.firstChild?.click === undefined
        ) {
            google.accounts.id.prompt();
        }
        console.log('button clicked', googleButton);
        console.log(btnRef.current);
    };

    useEffect(() => {
        if (btnRef.current) {
            console.log('ref', btnRef.current);
        }
    }, [btnRef]);

    return (
        <Box onClick={btnClicked}>
            <div id="signUpDiv" ref={btnRef}></div>
        </Box>
    );
};

export default LoginGoogle2;
