import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

export const ReturnButton = ({
    route = -1,
    text = 'Volver al paso anterior',
    medium,
    fontSizeIcon = 'small',
    additionalAction = false
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleAction = () => {
        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        if (location.pathname === '/solicitud-cambio-password') {
            if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                const redUrl = localStorage.getItem("BNPLURL");
                window.location = redUrl;
                return;
            } 
        }

        if (location.pathname === '/ingresa-dni') {
            if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                const redUrl = localStorage.getItem("BNPLURL");
                window.location = redUrl;
                return;
            } 
        }

        if (location.pathname === '/datos-contacto') {
            if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                const redUrl = localStorage.getItem("BNPLURL");
                window.location = redUrl;
                return;
            } 
        }

        if (location.pathname === '/iniciar-sesion') {
            if (workflow === WORKFLOW_CONSTANTS.BNPL) {
                const redUrl = localStorage.getItem("BNPLURL");
                window.location = redUrl;
                return;
            } 
        }

        if (location.pathname === '/metodo-pago-bnpl') {
            navigate('/financiacion-bnpl', { replace: true });
            return; 
        }
        
        if (location.pathname === '/datos-biometricos') {
	        if (additionalAction) {
            	additionalAction();
            }
            navigate('/seleccion-oferta', { replace: true });
            return;
        }

	    if (location.pathname === '/seleccion-oferta') {
            navigate('/informacion-contacto-prestamo', { replace: true });
            return;
        }

        if (location.pathname === RoutesConstants.FINANCING_OFFER_PAGE) {
            navigate(RoutesConstants.FINANCING_PAGE, { replace: true });
            return;
        }
        if (typeof route === 'function') {
            route();
            return;
        }
        navigate(route, { replace: true });
        if (additionalAction) {
            additionalAction();
        }
    };

    return (
        <Box
            alignItems="center"
            pb={3}
            color={ColorConstants.LIGHT_BLUE}
            display={'flex'}
            width="100%">
            <Button
                variant="text"
                sx={{
                    background: `${ColorConstants.LIGHT_BLUE}`,
                    justifyContent: 'left',
                    px: 0,
                    backgroundColor: 'transparent',
                    '&.MuiButtonBase-root:hover': {
                        bgcolor: 'transparent'
                    }
                }}
                onClick={() => handleAction(route)}>
                <ArrowBackIcon fontSize={fontSizeIcon} sx={{ color: ColorConstants.LIGHT_BLUE }} />
                <Typography
                    variant={!medium ? 'h7' : ''}
                    component={!medium ? 'h5' : ''}
                    color={ColorConstants.LIGHT_BLUE}
                    textAlign="start"
                    fontWeight={!medium ? 'bold' : ''}
                    sx={{ display: { xs: 'none', md: 'block' } }}>
                    {text}
                </Typography>
            </Button>
        </Box>
    );
};
