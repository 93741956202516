import { Box, styled } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const Header = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        height: '96px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0'
    },
    padding: '20px 0'
}));

export const Container = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '0 16%'
    },
    padding: '0 5%'
}));

export const ActiveLoanBackground = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        background: ColorConstants.blue[50],
        marginTop: '212px'
    }
}));

export const ActiveLoanContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        position: 'relative',
        transform: 'translateY(-212px)',
        marginBottom: '-212px',
    }
}));

export const loan_gradients = {
    mobile: [
        {
            type: 'circle',
            width: '279px',
            height: '293px',
            bgcolor: ColorConstants.blue[200],
            blur: '100px',
            left: '0',
            top: '-335px'
        },
        {
            type: 'circle',
            width: '316px',
            height: '333px',
            bgcolor: ColorConstants.blue[100],
            blur: '100px',
            left: '-60px',
            top: '389px'
        },
        {
            type: 'circle',
            width: '96px',
            height: '73px',
            bgcolor: ColorConstants.green[500],
            blur: '60px',
            right: '0px',
            top: '40px'
        }
    ],
    desktop: [
        {
            type: 'circle',
            width: '591px',
            height: '577px',
            bgcolor: 'rgba(71, 209, 75, 0.4)',
            blur: '100px',
            left: '-200px',
            top: '335px'
        },
        {
            type: 'circle',
            width: '513px',
            height: '479px',
            bgcolor: 'rgba(206,230,255, .4)',
            blur: '100px',
            right: '-60px',
            top: '-380px'
        },
        {
            type: 'circle',
            width: '562px',
            height: '562px',
            bgcolor: 'rgba(71, 209, 75, 0.6)',
            blur: '100px',
            right: '-250px',
            opacity: '.5',
            top: '72px'
        }
    ]
};
