import { useNavigate } from 'react-router-dom';
import { DollarIcon, ShoppingCartIcon } from '../../Components/Icons';
import { ColorConstants } from '../../Constants/ColorConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import STRINGS from '../../i18n/local/es.json';
import { DetectMobile } from '../../Utils/DetectMobile';
const { REACT_APP_ENABLE_BNPL_FAQ, REACT_APP_ENABLE_PARTNER_STORES } = process.env;

export const useHomeHandler = () => {
    const navigate = useNavigate();

    const heroDiscoveryProps = {
        navBarProps: {
            inverted: true,
            isLogged: false
        },
        heading: STRINGS.home.discovery.HERO_HEADING,
        description: STRINGS.home.discovery.HERO_DESCRIPTION,
        callToAction: [
            {
                title: STRINGS.home.discovery.CTA_CHECK_BALANCE,
                props: {
                    iconPosition: 'inline-start',
                    icon: <ShoppingCartIcon />,
                    action: () => {
                        navigate(RoutesConstants.CHECK_AVAILABLE_BALANCE);
                    }
                }
            },
            {
                title: STRINGS.home.discovery.CTA_WANT_LOANS,
                props: {
                    iconPosition: 'inline-start',
                    bg: 'white',
                    color: ColorConstants.blue[500],
                    icon: <DollarIcon />,
                    action: async () =>
                        (await !DetectMobile())
                            ? navigate(RoutesConstants.LOAN_REDIRECT_PAGE)
                            : navigate(RoutesConstants.LOAN_REQUEST_PAGE)
                }
            }
        ]
    };

    const featuredServices = [
        {
            serviceProps: {
                icon: <ShoppingCartIcon />,
                featureTitle: STRINGS.home.services.FEATURE_INSTALLMENTS_TITLE,
                description: STRINGS.home.services.FEATURE_INSTALLMENTS_DESCRIPTION,
                callToAction: [
                    {
                        label: STRINGS.home.services.FEATURE_INSTALLMENTS_CTA,
                        props: {
                            action: () => {
                                navigate(RoutesConstants.PARTNERS_STORE_PAGE);
                            },
                            showMobile: true
                        }
                    }
                ]
            },
            faqProps: {
                showButtons: REACT_APP_ENABLE_PARTNER_STORES === 'true',
                items: [
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_DESCRIPTION
                    },
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_PAYMENT_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_PAYMENT_DESCRIPTION
                    }
                ]
            }
        },
        {
            serviceProps: {
                icon: <DollarIcon />,
                featureTitle: STRINGS.home.services.FEATURE_LOAN_TITLE,
                description: STRINGS.home.services.FEATURE_LOAN_DESCRIPTION,
                callToAction: [
                    {
                        label: STRINGS.home.services.FEATURE_LOAN_CTA,
                        props: {
                            action: async () =>
                                (await !DetectMobile())
                                    ? navigate(RoutesConstants.LOAN_REDIRECT_PAGE)
                                    : navigate(RoutesConstants.LOAN_REQUEST_PAGE),
                            showMobile: true
                        }
                    }
                ]
            },
            faqProps: {
                showButtons: true,
                items: [
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_DESCRIPTION
                    },
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_PAYMENT_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_PAYMENT_DESCRIPTION
                    }
                ]
            }
        }
    ];

    const doubtsProps = {
        serviceProps: {
            featureTitle: STRINGS.home.doubts.DOUBTS_SECTION_TITLE,
            description: STRINGS.home.doubts.DOUBTS_SECTION_DESCRIPTION,
            callToAction: [
                {
                    label: STRINGS.home.doubts.DOUBTS_SECTION_CTA_BUTTON_LABEL,
                    props: {
                        action: () =>
                            navigate(
                                REACT_APP_ENABLE_BNPL_FAQ === 'true'
                                    ? RoutesConstants.FRECUENT_QUESTIONS_PAGE_BNPL
                                    : RoutesConstants.FRECUENT_QUESTIONS_PAGE
                            ),
                        showMobile: true
                    }
                }
            ]
        },
        faqProps: {
            showButtons: true
        },
        tabs: [
            {
                label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1,
                panel: {
                    items: [
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_QUESTION_1,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_ANSWER_1
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_QUESTION_2,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_ANSWER_2
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_QUESTION_3,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_ANSWER_3
                        }
                    ]
                }
            },
            {
                label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2,
                panel: {
                    items: [
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_QUESTION_1,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_ANSWER_1
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_QUESTION_2,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_ANSWER_2
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_QUESTION_3,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_ANSWER_3
                        }
                    ]
                }
            }
        ]
    };

    const contactProps = {
        serviceProps: {
            mx: 2,
            my: '24px !important',
            featureTitle: STRINGS.home.contactForm.CONTACT_FORM_TITLE,
            description: STRINGS.home.contactForm.CONTACT_FORM_DESCRIPTION,
            callToAction: [
                {
                    label: STRINGS.home.contact.CONTACT_CTA_REGISTER,
                    props: {
                        action: () => {
                            navigate(RoutesConstants.REGISTER_PAGE);
                        },
                        showMobile: false,
                        showDesktop: false,
                        variant: 'button',
                        width: 'auto'
                    }
                },
                {
                    label: STRINGS.home.contact.CONTACT_CTA_PHONENUMBER,
                    props: {
                        action: () => {
                            window.location.href = 'tel:08003456790';
                        },
                        showMobile: false,
                        showDesktop: true,
                        variant: 'button'
                    }
                },
                {
                    label: STRINGS.home.contact.CONTACT_CTA_MAIL,
                    props: {
                        action: () => {
                            window.location.href = 'mailto:ayuda@directo.com.ar';
                        },
                        showMobile: false,
                        showDesktop: true,
                        variant: 'button'
                    }
                }
            ]
        },
        faqProps: {
            showButtons: false
        }
    };

    return {
        heroDiscoveryProps,
        featuredServices,
        doubtsProps,
        contactProps
    };
};
