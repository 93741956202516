import {
    styled,
    List as MuiList,
    ListItem as MuiListItem,
    Avatar as MuiAvatar,
    Stack,
    Box
} from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const List = styled(MuiList)`
    color: ${ColorConstants.blue[500]};
`;

export const ListItem = styled(MuiListItem)`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
`;

export const Avatar = styled(MuiAvatar)`
    color: inherit;
    background: ${ColorConstants.green[100]};
    border: 5px solid ${ColorConstants.green[50]};
`;

export const ButtonsContainer = styled(Stack)`
    height: 100%;
    margin: 0 -24px;
    &.dni {
        background: url('biometric_dni_bg.svg') no-repeat bottom right;
        min-height: 186px;
    }
    &.selfie {
        background: url('biometric_selfie_bg.svg') no-repeat bottom right;
        min-height: 247px;
    }
    display: flex;
    justify-content: flex-end;
`;
