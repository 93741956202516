import { Box, Typography, styled } from '@mui/material';
import { useContext } from 'react';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { useRedirectTimer } from '../../Hooks/useRedirectTimer';
import { ColorConstants } from '../../Constants/ColorConstants';
import UnsuccessfulPayment from '../../Assets/feedback-unsuccessful-payment.svg';
import { Img } from '../../Components/ImageContainer';

const Container = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const NoSuccess = () => {
    const { orderData } = useContext(BNPLOrderContext);
    const drmerchant = localStorage.getItem("drmerchant");
    const timer = useRedirectTimer(
        process.env.REACT_APP_CONTINUE_PURCHASE_TIMEOUT,
        orderData.return_Url_Error,
        true,
	    orderData
    );

    const minutes = Math.floor(timer.remainingSeconds / 60);
    const seconds = timer.remainingSeconds % 60;

    return (
        <Container>
            <Img src={UnsuccessfulPayment} />
            <Typography color={ColorConstants.blue[900]}>
                En {`${minutes}:${seconds.toString().padStart(2, '0')}`} serás enviado 
		        {drmerchant == 'S' ? 'al inicio.' : 'al carrito de compras.'}
            </Typography>
        </Container>
    );
};
