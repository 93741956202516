import React, { useContext, useEffect, useRef } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
import jwt_decode from 'jwt-decode';
import { getURLByParam } from '../../Helpers/GetURLByParam';

// eslint-disable-next-line no-undef
const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

const LoginGoogle = ({ isMyProfile = false, personData = undefined, clicked = false }) => {
    const { onLoginGoogle, AssociateGoogleAccount, BNPLRegisterGoogle } = useContext(AppContext);
    const btnRef = useRef();
    const params = new URLSearchParams(location.search);
    const redirectToURL = getURLByParam(params.get('redirectTo'));

    useEffect(() => {
        const width = window.screen.width - window.screen.width * 0.1;
        /* global google */
        try {
            if (window.google) {
                google.accounts.id.initialize({
                    client_id: REACT_APP_GOOGLE_CLIENT_ID,
                    callback: googleCallback
                });
                if (width > 400) {
                    google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
                        type: 'standard',
                        theme: 'outline',
                        size: 'large',
                        text: 'continue_with',
                        shape: 'pill',
                        width: `400px`
                    });
                } else {
                    google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
                        type: 'standard',
                        theme: 'outline',
                        size: 'large',
                        text: 'continue_with',
                        shape: 'pill',
                        width: `350px`
                    });
                }

                // google.accounts.id.prompt();
            }
            if (isMyProfile) {
                if (personData?.googleAccountId === undefined) return;
                google.accounts.id.prompt();
            }
        } catch (error) {
            console.log('Error al cargar el botón de Google', error);
        }
    }, []);

    const googleCallback = (response) => {
        const credentials = jwt_decode(response.credential);
        const data = {
            googleId: credentials.sub,
            profileObj: {
                imageUrl: credentials.picture,
                email: credentials.email,
                name: credentials.name,
                givenName: credentials.given_name,
                familyName: credentials.family_name
            }
        };
        if (isMyProfile) {
            AssociateGoogleAccount(data);
            return;
        }
        if (redirectToURL) {
            BNPLRegisterGoogle(redirectToURL, redirectToURL)(data);
            return;
        } else {
            onLoginGoogle(data);
            return;
        }
    };

    const btnClicked = () => {
        const googleButton = document.getElementById('signUpDiv');
        googleButton?.firstChild?.firstChild?.firstChild?.click();
        if (
            googleButton?.firstChild?.firstChild?.firstChild?.click === null ||
            googleButton?.firstChild?.firstChild?.firstChild?.click === undefined
        ) {
            google.accounts.id.prompt();
        }
        console.log('button clicked', googleButton);
        console.log(btnRef.current);
    };

    useEffect(() => {
        if (btnRef.current) {
            console.log('ref', btnRef.current);
        }
    }, [btnRef]);

    return (
        /*  <Box onClick={btnClicked}>
            <div id="signUpDiv"></div>
        </Box> */
        <div id="signUpDiv"></div>
    );
};

export default LoginGoogle;
