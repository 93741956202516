import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    Stack,
    styled,
    Avatar,
    Typography
} from '@mui/material';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { ColorConstants } from '../../Constants/ColorConstants';
import { CreditCardIcon, FaceSmileIcon, PhoneIcon } from '../../Components/Icons';
import { DescriptionForm } from '../../Components/BNPL';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ALLOWED_REDIRECT_PARAMS } from '../../Helpers/GetURLByParam';
import AppContext from '../../Contexts/Global/AppContext';
import STRINGS from '../../i18n/local/es.json';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

const STEPS = [
    {
        title: STRINGS.check_available_balance.steps.VALIDATE_PHONE,
        icon: <PhoneIcon />
    },
    {
        title: STRINGS.check_available_balance.steps.VERIFY_YOUR_ID,
        icon: <FaceSmileIcon width="22" height="22" />
    },
    {
        title: STRINGS.check_available_balance.steps.SAVE_YOUR_CARD,
        icon: <CreditCardIcon />
    }
];

export const CheckAvailableBalancePage = () => {
    const navigate = useNavigate();
    const { authentication } = useContext(AppContext);

    const handleDontHaveAccountButton = () => {
        localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.CS);
        navigate(RoutesConstants.IDENTIFICATION_DNI_PAGE)
    }

    const handleHaveAccountButton = () => {
        localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.CS);
        if (authentication) {
            navigate(RoutesConstants.IDENTIFICATION_CONTACT_PAGE);
        } else {
            navigate(
                `${RoutesConstants.LOGIN_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.DATOS_CONTACTO}`
            );
        }
    };

    return (
        <Stack justifyContent="space-between">
            <Box>
                <ReturnButton text="" />
                <DescriptionForm
                    title={STRINGS.check_available_balance.CONTENT_TITLE}
                    description={STRINGS.check_available_balance.CONTENT_SUBTITLE}
                    fontSizeTitle="30px"
                />

                <List sx={{ display: 'grid', gap: '24px', paddingTop: '24px' }}>
                    {STEPS.map((step) => (
                        <ListItem key={step.title} sx={{ padding: '0' }}>
                            <ListItemAvatar>
                                <StyledAvatar>{step.icon}</StyledAvatar>
                            </ListItemAvatar>
                            <StyledItemText>{step.title}</StyledItemText>
                        </ListItem>
                    ))}
                </List>
            </Box>

            <Box mt={[15, 8]}>
                <ButtonCustom
                    padding="16px"
                    text={STRINGS.redirectToDirecto.CONTENT_HAVE_ACCOUNT_BTN}
                    action={handleHaveAccountButton}
                />
                <ButtonCustom
                    border
                    bg="white"
                    color={ColorConstants.blue[900]}
                    padding="16px"
                    text={STRINGS.redirectToDirecto.CONTENT_DONT_HAVE_ACCOUNT_BTN}
                    action={handleDontHaveAccountButton}
                />
            </Box>
        </Stack>
    );
};

const StyledItemText = styled(Typography)`
    color: ${ColorConstants.blue[500]};
    font-weight: 500;
    font-family: 'Archivo';
`;

export const StyledAvatar = styled(Avatar)`
    background: ${ColorConstants.green[100]};
    color: ${ColorConstants.blue[500]};
`;
