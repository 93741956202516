import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    Link,
    OutlinedInput
} from '@mui/material';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { DescriptionForm } from '../../Components/BNPL/Description/Description';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { dniValidate } from '../../Helpers/Validations';
import { clearMask, maskDNI } from '../../Helpers/Masks';
import { useEffect } from 'react';
import { ColorConstants } from '../../Constants/ColorConstants';

import InformationLabel from '../../Components/BNPL/InformationLabel/InformationLabel';
import ErrorLabel from '../../Components/Form/ErrorLabel';
import { BNPLRegisterContext } from '../../Contexts/BNPLRegister/BNPLRegisterContext';
import STRINGS from '../../i18n/local/es.json';
import { DNIIcon } from '../../Components/Icons';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';

export const DNIPage = () => {
    const navigate = useNavigate();
    const { personData, handleOnChangeFieldEvent, setError } = useContext(BNPLRegisterContext);
    const [flowProps, setFlowProps] = useState({});
    const [viewHelperText, setViewHelperText] = useState(false);
    const [isValidDNI, setIsValidDNI] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const drmerchant = localStorage.getItem("drmerchant");

    const maxLengthDNI = 8;
    const minLengthDNI = 7;

    const handleChange = (event) => {
        const dni = event.target.value;
        const dniDigits = clearMask(dni);
        if (dniDigits.length > maxLengthDNI) return;
        if (dniValidate(dniDigits) && dniDigits.length >= minLengthDNI && dniDigits.length <= maxLengthDNI) {
            handleOnChangeFieldEvent('dni')(event);
            setIsDisabled(false);
        } else {
            handleOnChangeFieldEvent('dni')(event);
            setIsDisabled(true);
        }
    };

    const handleWorkflow = (workflow) => {
        switch (workflow) {
            case WORKFLOW_CONSTANTS.CS:
                setFlowProps({
                    title: STRINGS.identification.dniPage.TITLE_CS,
                    description: STRINGS.identification.dniPage.SUBTITLE_CS,
                    returnButton: RoutesConstants.CHECK_AVAILABLE_BALANCE
                });
                break;
            case WORKFLOW_CONSTANTS.BNPL:
                setFlowProps({
                    title: STRINGS.identification.dniPage.TITLE,
                    description: STRINGS.identification.dniPage.SUBTITLE,
                    returnButton: RoutesConstants.REDIRECT_TO_DIRECTO_PAGE,
                    backHomeButton: drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE
                });
                break;
            default:
                navigate(RoutesConstants.PUBLIC_HOME);
                break;
        }
    };

    const handleHelperText = (value) => {
        setViewHelperText(value);
    };

    useEffect(() => {
        if (personData.dni.trim()?.length > 0 && !dniValidate(clearMask(personData.dni))) {
            setIsValidDNI(true);
            return;
        }
        setIsValidDNI(false);
    }, [personData.dni]);

    useEffect(() => {
        const storedPersonData = JSON.parse(localStorage.getItem('personData'));
        if (storedPersonData && dniValidate(clearMask(storedPersonData.dni))) {
            setIsValidDNI(false);
            setIsDisabled(false);
            handleOnChangeFieldEvent('dni')({ target: { value: storedPersonData.dni } });
        }

        const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
        handleWorkflow(workflow);
        localStorage.removeItem(BNPLConstants.LOAN_DATA);
        localStorage.removeItem(BNPLConstants.PERSON_DATA);
        localStorage.removeItem('Person');
        localStorage.removeItem('ecommerceAccountId');
        localStorage.removeItem(LoanRequestConstantsLS.PersonToGetLoan);
        localStorage.removeItem(LoanRequestConstantsLS.AllowedMethods);
        localStorage.removeItem(LoanRequestConstantsLS.CardsQuantity);
        localStorage.removeItem(LoanRequestConstantsLS.PaymentMethod);
        localStorage.removeItem('Token');
        localStorage.setItem(BNPLConstants.WORKFLOW, workflow);
        localStorage.setItem('personData', JSON.stringify(personData));
    }, []);

    const handleContinue = () => {
        localStorage.setItem('personData', JSON.stringify(personData));
        setError(false)
        navigate(RoutesConstants.IDENTIFICATION_GENDER_PAGE);
    };

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton route={flowProps.returnButton} />
                </Grid>
                <DescriptionForm
                    title={flowProps.title}
                    description={flowProps.description}
                    pb={3}
                />
            </Box>

            <Grid container item xs={12} direction="column" mt={3}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="dni">
                        {STRINGS.identification.dniPage.dni.LABEL}
                    </InputLabel>
                    <OutlinedInput
                        autoFocus
                        id="dni"
                        value={maskDNI(personData.dni) || personData.dni}
                        onChange={handleChange}
                        onClick={() => handleHelperText(true)}
                        onBlur={() => handleHelperText(false)}
                        sx={{
                            borderRadius: '16px',
                            color: `${ColorConstants.BLUE} !important`,
                            fontWeight: 400
                        }}
                        color="info"
                        error={isValidDNI}
                        startAdornment={
                            <InputAdornment position="start">
                                <DNIIcon />
                            </InputAdornment>
                        }
                        inputProps={{
                            inputMode: 'numeric'
                        }}
                        label={STRINGS.identification.dniPage.dni.LABEL}
                    />
                    {viewHelperText && (
                        <InformationLabel
                            formHelperSx={{ display: 'flex' }}
                            checkSx={{ marginRight: 1 }}
                            description={STRINGS.identification.dniPage.dni.HELPER_TEXT}
                        />
                    )}
                    {isValidDNI && (
                        <ErrorLabel
                            sx={{ mt: 3 }}
                            description={STRINGS.identification.dniPage.dni.ERROR_TEXT}
                        />
                    )}
                </FormControl>
            </Grid>

            <Box>
                <GrayButton
                    text={STRINGS.buttons.CONTINUE}
                    action={handleContinue}
                    disabled={isDisabled}
                />
                <BackHomeDialogButton text={flowProps.backHomeButton} />
            </Box>
        </Box>
    );
};
