import { useMediaQuery, useTheme } from '@mui/material';
import {
    AccordionStack,
    DynamicGradientBackground,
    FeaturedService,
    Footer,
    HeroDiscovery
} from '../../Components/BNPL';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { HomeState } from '../../Contexts/Home/HomeState';
import { Contact } from './components/Contact';
import { ContactForm } from './components/ContactForm';
import { Container, Divider, gradients, HeroButtons } from './PublicHomeBNPL.styled';
import { useHomeHandler } from './PublicHomeHandler';
import { DoubtsSection } from './components/DoubtsSection';
import { useEffect } from 'react';
import { cleanBNPLOrderData } from '../../Helpers/BNPLUtils';

export const PublicHomeBNPLPage = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { featuredServices, heroDiscoveryProps } = useHomeHandler();

    useEffect(() => {
        cleanBNPLOrderData();
    }, []);
    return (
        <HomeState>
            <DynamicGradientBackground
                sx={{
                    backgroundColor: '#F5FAFF',
                    width: '100%'
                }}
                masked
                gradients={desktop ? gradients.desktop : gradients.mobile}>
                <HeroDiscovery {...heroDiscoveryProps}>
                    <HeroButtons>
                        {heroDiscoveryProps.callToAction.map((btn, i) => (
                            <ButtonCustom
                                key={`hero_cta_btn_${i + 1}`}
                                text={btn.title}
                                padding="0px"
                                {...btn.props}
                            />
                        ))}
                    </HeroButtons>
                </HeroDiscovery>
                <Container>
                    {featuredServices.map((service, i) => (
                        <FeaturedService key={`featured_service_${i + 1}`} {...service}>
                            <AccordionStack {...service.faqProps} />
                        </FeaturedService>
                    ))}
                    <Divider />
                    <DoubtsSection />
                    <Divider />
                </Container>
                <ContactForm />
                <Contact />
                <Footer />
            </DynamicGradientBackground>
        </HomeState>
    );
};
