import { Typography, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Heading = styled(Typography)(({ theme, className }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '48px',
        lineHeight: '52px',
        maxWidth: className === 'inverted' ? '700px' : '820px',
        marginBottom: '16px'
    },
    fontFamily: 'Cocogoose',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    marginBottom: '8px',
    marginTop: className === 'inverted' ? '75px' : '112px',
    fontSize: '30px',
    color: className === 'inverted' ? ColorConstants.blue[900] : 'white'
}));

export const Description = styled(Typography)(({ theme, className }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '24px',
        lineHeight: '30px'
    },
    fontSize: '16px',
    lineHeight: '24px',
    color: className === 'inverted' ? ColorConstants.blue[900] : 'white'
}));
