import { memo, useState } from 'react';
import PropTypes from 'prop-types';
import style from './inactiveDialog.module.css';
import logo from '../../Assets/logo.svg';
import {
    IconButton,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {<img src={logo} className={style.logo} />}
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
};

const InactiveDialog = memo(({ open, setOpen }) => {
    const navigate = useNavigate();
    const SiisaCode = getItemService('SiisaCode');

    const handleClose = () => {
        localStorage.clear();
        sessionStorage.clear();
        navigate(RoutesConstants.PUBLIC_HOME, { replace: true });
        window.location.reload();
        setItemService('SiisaCode', SiisaCode);
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                className={style.inactiveDialog}
                PaperProps={{
                    className: style.inactiveDialogPaper
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <BootstrapDialogTitle
                    onClose={handleClose}
                    className={style.inactiveDialogTitle}
                    id="alert-dialog-title">
                    {'La solicitud de préstamo ha expirado.'}
                </BootstrapDialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Por favor volvé a iniciar el proceso de solicitud.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

InactiveDialog.displayName = 'InactiveDialog';

export { InactiveDialog };
