import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import {
    getItemService,
    setItemService,
    removeItemService
} from '../../../Services/LocalStorageService';

const RegistrationAndLoginButtons = () => {
    const navigate = useNavigate();

    const registerUser = () => {
        const person = getItemService('PersonToGetLoan')
            ? JSON.parse(getItemService('PersonToGetLoan'))
            : null;
        if (person) {
            const personForm = {
                personId: person.personId,
                cuit: person.cuit,
                phoneCode: person.phoneCode,
                phoneNumber: person.phoneNumber,
                provinceId: person.provinceId,
                email: person.email,
                name: person.name,
                dni: person.dni,
                sexTypeId: person.sexTypeId,
                ecommerceAccountId: 0,
                phone: person.phoneNumberComplete
            };
            setItemService('PersonToRegisterAfterLoan', JSON.stringify(personForm));
        }
        removeItemService('ClientValidation');
        removeItemService('TokenUrl');
        removeItemService('CardsQuantity');
        removeItemService('LoanData');
        removeItemService('PaymentMethod');
        removeItemService('AllowedMethods');
        removeItemService('creditDetailId');
        removeItemService('creditNumber');
        navigate(RoutesConstants.REGISTER_PAGE);
    };

    const goToLogin = () => {
        removeItemService('ClientValidation');
        removeItemService('TokenUrl');
        removeItemService('CardsQuantity');
        removeItemService('LoanData');
        removeItemService('PaymentMethod');
        removeItemService('AllowedMethods');
        removeItemService('creditDetailId');
        removeItemService('creditNumber');
        navigate(RoutesConstants.LOGIN_PAGE);
    };

    return (
        <>
            <DescriptionForm
                description={
                    'Desde la cuenta, podés hacer el seguimiento del préstamo y ver los detalles.'
                }
            />
            <Box width={'100%'}>
                <GrayButton
                    pt={0}
                    color="primary"
                    borderColor="#E3E5E6"
                    outlined
                    text={'No tengo cuenta, quiero registrarme'}
                    action={registerUser}
                />
            </Box>
            <Box width={'100%'}>
                <GrayButton
                    pt={1}
                    color="primary"
                    borderColor="#E3E5E6"
                    outlined
                    text={'Ya tengo cuenta, quiero ingresar'}
                    action={goToLogin}
                />
            </Box>
        </>
    );
};

export default RegistrationAndLoginButtons;
