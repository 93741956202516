export const RegisterConstantsLS = {
    EcommerceAccountID: "EcommerceAccountID",
    TokenRegister: "TokenRegister",
    UserEmail: "UserEmail",
    UserPass: "UserPass",
    PersonToGetRegister: "PersonToGetRegister "
}

const RegisterActions = {

    SET_ECOMMERCE_ACCOUNT_ID: "SET_ECOMMERCE_ACCOUNT_ID",
    
}


export default RegisterActions;