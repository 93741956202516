import { Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { CloseIcon } from '../../Icons';
import { Drawer, Title } from './Modal.styled';
import { Dialog } from '../Dialog/Dialog';

export const Modal = ({ title = '', children, ...props }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    if (desktop)
        return (
            <Dialog
                title={title}
                buttonText={'Entendido'}
                open={props.open}
                onClose={props.onClose}
                onContinue={props.onClose}
                description={children}
            />
        );
    return (
        <Drawer anchor="bottom" {...props}>
            <Stack spacing={2} height="100%">
                <Box textAlign="right">
                    <IconButton color="inherit" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Title>{title}</Title>
                {children}
            </Stack>
        </Drawer>
    );
};
