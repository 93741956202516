import { PartnerCard, PartnerContainer, PartnerTitle, PartnerImg } from '../PartnerStoresLayout';
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Grid } from '@mui/material';

export const Card = (partner) => {
    const src = partner.image;
    const title = partner.name;
    const action = () => { 
        window.open(partner.url);
    };

    return (
        <Grid item>
        <PartnerContainer onClick={action}>
            <PartnerCard>
                {src ? <PartnerImg component="img" src={src} alt={title} /> : <ImageNotSupportedIcon />}
            </PartnerCard>
            <PartnerTitle>{title}</PartnerTitle>
        </PartnerContainer>
        </Grid>
    );
};
