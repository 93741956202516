import React, { useEffect, useState } from 'react';
import { getFrequentQuestions } from '../../Services/FrequentQuestionsService';
import FrequentQuestionsContext from './FrequentQuestionsContext';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom'

export const FrequentQuestionsState = ({ children }) => {
    const location = useLocation();
    const [frequentQuestionsB2C, setFrequentQuestionsB2C] = useState(null);
    const [frequentQuestionsBNPL, setFrequentQuestionsBNPL] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === RoutesConstants.FRECUENT_QUESTIONS_PAGE || location.pathname === RoutesConstants.FRECUENT_QUESTIONS_PAGE_BNPL) {
            getInitData();
        }
    }, [location.pathname]);

    const getInitData = async () => {
        getFrequentQuestions(1).then((response) => {
            if (response.length > 0) {
                setFrequentQuestionsB2C(response);
            }
            else {
                navigate(-1)
            }
        });
        getFrequentQuestions(2).then((response) => {
            if (response.length > 0) {
                setFrequentQuestionsBNPL(response);
            }
            else {
                navigate(-1)
            }
        });
    };

    return (
        <FrequentQuestionsContext.Provider
            value={{
                frequentQuestionsB2C: frequentQuestionsB2C,
                frequentQuestionsBNPL: frequentQuestionsBNPL
            }}>
            {children}
        </FrequentQuestionsContext.Provider>
    );
};
