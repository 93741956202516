import styled from 'styled-components';

const Span = styled('span')(() => ({
    // properties
}))

const Sup = styled('sup')(() => ({
    marginLeft: '2px',
    fontSize: '16px',
    verticalAlign:'super',
}));

const MaskMoney = (amount, space=true) => {
    const splitAmount = amount.split(',');
    return (
        <Span>${space? ' ' : ''}{splitAmount[0]}<Sup>{splitAmount[1]}</Sup></Span>
    );
}


export default MaskMoney;