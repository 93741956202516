/* eslint-disable no-useless-escape */
export const DetectMobile = async () => {
    /* return true; */
    // eslint-disable-next-line no-undef
    /* if (process.env.NODE_ENV === 'development') return true;
    const result = await hasFrontBack();
    if (result.hasBack && result.hasFront) {
        return true;
    }
    return false; */

    /* if (!navigator.userAgentData.mobile) {
        return false;
    }
    if (checkSimulator()) {
        return false;
    } */

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/pixel/i.test(userAgent)) {
        return true;
    }

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return true;
    }

    if (/android/i.test(userAgent)) {
        return true;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    }
    return navigator?.userAgentData?.mobile || false;
};

const checkSimulator = () => {
    let tagScripts = document.getElementsByTagName('script');

    for (var j = tagScripts.length; j >= 0; j--) {
        if (
            tagScripts[j] &&
            tagScripts[j].getAttribute('id') != null &&
            (tagScripts[j].getAttribute('id').includes('emulator') ||
                tagScripts[j].getAttribute('id').includes('simulateur') ||
                tagScripts[j].getAttribute('id').includes('simulator'))
        ) {
            return true;
        }
    }
    return false;
};

async function hasFrontBack() {
    let result = { hasBack: false, hasFront: false, videoDevices: [] };
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        let devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => {
            if (device.kind === 'videoinput') {
                if (device.label && device.label.length > 0) {
                    if (device.label.toLowerCase().indexOf('back') >= 0) {
                        result.hasBack = true;
                    } else if (device.label.toLowerCase().indexOf('front') >= 0) {
                        result.hasFront = true;
                    } else {
                        /* some other device label ... desktop browser? */
                    }
                }
                return true;
            }
            return false;
        });
        result.videoDevices = videoDevices;
        /* drop stream */
        const tracks = stream.getTracks();
        if (tracks) {
            for (let t = 0; t < tracks.length; t++) tracks[t].stop();
        }
        return result;
    } catch (ex) {
        /* log and swallow exception, this is a probe only */
        console.error(ex);
        return result;
    }
}
