import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';

import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { DollarIcon, CreditCardIcon } from '../../Components/Icons';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { DescriptionForm, SelectList } from '../../Components/BNPL';
import STRINGS from '../../i18n/local/es.json';

const DUMMY_DETAILS = [
    {
        id: 1,
        icon: <DollarIcon />,
        title: STRINGS.select_payment_method.PAYMENT_METHOD_1_TITLE,
        description: STRINGS.select_payment_method.PAYMENT_METHOD_1_DESCRIPTION
    },
    {
        id: 2,
        icon: <CreditCardIcon />,
        title: STRINGS.select_payment_method.PAYMENT_METHOD_2_TITLE,
        description: STRINGS.select_payment_method.PAYMENT_METHOD_2_DESCRIPTION
    }
];

const PaymentMethodForm = () => {
    const [selected, setIsSelected] = useState('');

    function handlePaymentMethodClick(value) {
        setIsSelected(value);
    }

    return (
        <Stack justifyContent="space-between">
            <Stack>
                <ReturnButton />
                <DescriptionForm title={STRINGS.select_payment_method.FORM_TITLE} />

                <SelectList
                    options={DUMMY_DETAILS}
                    selectedValue={selected}
                    onClick={handlePaymentMethodClick}
                    variant="paymentMethod"
                />
            </Stack>
            <Box textAlign="center">
                <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>
            </Box>
        </Stack>
    );
};

export const SelectPaymentMethodPage = () => {
    return <BNPLLayout Form={<PaymentMethodForm />} />;
};
