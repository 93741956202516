import { useContext, useEffect } from 'react';
import { SuccessfulFeedbackPaymentPage } from './SuccessfulPayment/SuccessfulFeedbackPaymentPage';
import { ErrorFeedbackForm } from './ErrorFeedbackForm';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { Description, Title, feedback_gradients } from '../Financing/FinancingPage.styled';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { Box, useMediaQuery, useTheme } from '@mui/material';

import STRINGS from '../../i18n/local/es.json';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { BNPLConstants } from '../../Constants/BNPLConstants';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

const TitleDesc = ({ title, description }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box py={[0, 3]} mt={[3, 5, '54px']}>
            <>
                {!desktop && <ReturnButton />}
                {title && <Title>{title}</Title>}
                {description && <Description mt={[1, 2]}>{description}</Description>}
            </>
        </Box>
    );
};

const FeedbackTitle = ({ feedbackType }) => {
    const personData = JSON.parse(localStorage.getItem(BNPLConstants.PERSON_DATA));
    const USERNAME = personData?.name?.split(',')[1] || '';
    return (
        <TitleDesc
            title={
                (feedbackType === 'SUCCESSFUL_PAYMENT' || feedbackType === 'SUCCESSFUL_PAYMENT_DEBIT') 
                    ? `${STRINGS.feedbackPaymentSuccessfull.FORM_CONGRATULATION} ${USERNAME}!`
                    : `${STRINGS.feedbackInsufficientFunds.TITLE} ${USERNAME}`
            }
        />
    );
};

export const FeedbackPage = () => {
    const { feedbackType, setFeedbackType } = useContext(BNPLOrderContext);
    const navigate = useNavigate()
    let componentToRender;
    // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("FIN", storageOrderID, localStorageHash)
            }
            // FINISH

    switch (feedbackType) {
        case 'CONNECTION_LOST':
            componentToRender = <ErrorFeedbackForm connectionLost />;
            break;
        case 'SUCCESSFUL_PAYMENT':
            // TODO FUNNEL BNPL: First step
            // INIT
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("FIN_EXITO", storageOrderID, localStorageHash)
            }
            // FINISH
            componentToRender = <SuccessfulFeedbackPaymentPage />;
            break;
        case 'SUCCESSFUL_PAYMENT_DEBIT':
            // TODO FUNNEL BNPL: First step
            // INIT
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("FIN_EXITO", storageOrderID, localStorageHash)
            }
            // FINISH
            componentToRender = <SuccessfulFeedbackPaymentPage debit />;
            break;
        case 'UNSUCCESSFUL_PAYMENT':
            // TODO FUNNEL BNPL: First step
            // INIT
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("FIN_ERROR", storageOrderID, localStorageHash)
            }
            // FINISH
            componentToRender = <ErrorFeedbackForm />;
            break;
        case 'ERROR':
            componentToRender = <ErrorFeedbackForm />;
            break;
        default:
            setFeedbackType(null);
            break;
    }

    useEffect(() => {
        if (!feedbackType) {
            navigate(RoutesConstants.ERROR);
        }
    }, [feedbackType])

    const layoutProps = {
        desktop: {
            background: 'white',
            showDescription: true
        },
        mobile: {
            background: 'transparent',
            showDescription: false,
            gradients: feedback_gradients
        }
    };

    return (
        <BNPLLayout
            ContainerPanel={<FeedbackTitle feedbackType={feedbackType} />}
            Form={componentToRender}
            layoutProps={layoutProps}
        />
    );
};
