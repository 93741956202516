import { Typography, Stack, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionContainer, AccordionDetails, AccordionSummary } from './Accordion.styled';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Accordion = ({ item }) => {
    return (
        <>
            <AccordionContainer>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: ColorConstants.blue[900] }} />}>
                    <Typography>{item.label}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>{item.description}</Typography>
                </AccordionDetails>
            </AccordionContainer>
            <Divider sx={{ borderColor: ColorConstants.blue[100] }} />
        </>
    );
};

export const AccordionStack = ({ items, ...props }) => {
    return (
        <Stack {...props}>
            {items.map((item, i) => (
                <Accordion item={item} key={`accordion_item_${i + 1}`} />
            ))}
        </Stack>
    );
};
