export function HashIcon({ color = "currentColor", width = "20", height = "20" }) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.33398 7.5H16.6673M3.33398 12.5H16.6673M8.33398 2.5L6.66732 17.5M13.334 2.5L11.6673 17.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }