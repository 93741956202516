import React, { useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import logo from '../../Assets/complete-logo-color.svg';
import logoWhite from '../../Assets/complete-logo.svg';
import { ButtonCustom } from '../Buttons/ButtonCustom';
import '../../Pages/PublicHome/PublicHomeStyle.css';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { MobileCheck } from '../../Helpers/MobileCheck';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DetectMobile } from '../../Utils/DetectMobile';

export const Header = () => {
    const [header, setHeader] = useState('');
    const [scroll, setScroll] = useState(false);
    const routerLocation = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const desktopSM = useMediaQuery(theme.breakpoints.up('sm'));

    window.onscroll = function () {
        let showHeaderLigth = window.scrollY > header.getBoundingClientRect().height;
        showHeaderLigth ? setScroll(true) : setScroll(false);
    };

    return (
        <Grid container spacing={2}>
            <Grid
                container
                className="bg-light position-logo-color"
                sx={{
                    display: {
                        xs: `${
                            scroll || routerLocation.pathname != RoutesConstants.PUBLIC_HOME
                                ? 'inherit'
                                : 'none'
                        }`,
                        sm: `${
                            scroll || routerLocation.pathname != RoutesConstants.PUBLIC_HOME
                                ? 'inherit'
                                : 'none'
                        }`
                    },
                    height: `${!MobileCheck() && '100px'}`,
                    position: `${
                        routerLocation.pathname != RoutesConstants.PUBLIC_HOME ? 'inherit' : 'fixed'
                    }`
                }}>
                <Grid item sm={2} md={2} lg={2}></Grid>
                <Grid
                    item
                    xs={6}
                    sm={2}
                    md={2}
                    lg={2}
                    sx={{
                        pr: MobileCheck() ? 0 : 3,
                        ml: MobileCheck() && !desktopSM ? 5 : 0,
                        mr: MobileCheck() ? 2 : 2,
                        mt: MobileCheck() ? 1.5 : 0
                    }}>
                    {MobileCheck() && !desktopSM && <img src={logo} className="img-dimensions" />}
                    {(MobileCheck() && desktopSM && <img src={logo} className="img-sm" />) ||
                        (!MobileCheck() && <img src={logo} className="img-desktop" />)}
                </Grid>
                <Grid
                    item
                    sm={1}
                    md={1}
                    lg={1}
                    sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                <Grid item xs={4} sx={{ display: { xs: 'block', sm: 'none' } }}>
                    <ButtonCustom
                        border
                        padding={MobileCheck() && '8px 8px'}
                        width="111px"
                        height="32px"
                        color={ColorConstants.BLUE}
                        bg="transparent"
                        text="Iniciá sesión"
                        action={(() => navigate(RoutesConstants.LOGIN_PAGE), { replace: true })}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    md={2}
                    lg={2}
                    sx={{
                        mt: MobileCheck() ? 1 : 0,
                        ml: MobileCheck() ? 0.5 : 10,
                        mr: 4
                    }}>
                    <Box
                        width="100%"
                        sx={{
                            ml: 2,
                            display: {
                                xs: `${
                                    (routerLocation.pathname == RoutesConstants.CONTACT_PAGE ||
                                        routerLocation.pathname ==
                                            RoutesConstants.FRECUENT_QUESTIONS_PAGE ||
                                        routerLocation.pathname == RoutesConstants.TERMS_PAGE ||
                                        routerLocation.pathname == RoutesConstants.PRIVACY_PAGE) &&
                                    MobileCheck()
                                        ? 'none'
                                        : 'inherit'
                                }`
                            }
                        }}>
                        <ButtonCustom
                            border
                            width={
                                (!MobileCheck() && '215px') ||
                                (MobileCheck() && !desktopSM && '300px') ||
                                (MobileCheck() && desktopSM && '200px')
                            }
                            text="Quiero mi préstamo"
                            padding={
                                (MobileCheck() && !desktopSM && '12px 0px 12px 32px') ||
                                (MobileCheck() && !desktopSM && '12px 12px')
                            }
                            icon={MobileCheck() && !desktopSM && <ArrowForwardIcon />}
                            action={() =>
                                navigate(RoutesConstants.LOAN_REQUEST_PAGE, { replace: true })
                            }
                        />
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sm={2}
                    md={2}
                    lg={2}
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        mt: MobileCheck() && desktopSM && 1
                    }}>
                    <ButtonCustom
                        width={MobileCheck() && desktopSM ? '120px' : '160px'}
                        border
                        color={ColorConstants.BLUE}
                        bg="transparent"
                        text="Iniciá sesión"
                        padding={
                            (desktopSM && !MobileCheck() && '12px 20px') ||
                            (MobileCheck() && desktopSM && '12px 12px')
                        }
                        action={() => navigate(RoutesConstants.LOGIN_PAGE, { replace: true })}
                    />
                </Grid>
                <Grid
                    item
                    sm={3}
                    md={3}
                    lg={3}
                    sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
            </Grid>
            <Grid
                container
                ref={(h) => setHeader(h)}
                className={`bg-image ${desktop ? 'bg-desktop' : 'bg-mobile'}`}
                sx={{
                    display: {
                        xs: `${
                            routerLocation.pathname != RoutesConstants.PUBLIC_HOME
                                ? 'none'
                                : 'inherit'
                        }`,
                        sm: `${
                            routerLocation.pathname != RoutesConstants.PUBLIC_HOME
                                ? 'none'
                                : 'inherit'
                        }`
                    }
                }}>
                <Grid
                    container
                    className="padding-logo"
                    sx={{
                        display: {
                            xs: `${scroll ? 'none' : 'inherit'}`,
                            sm: `${scroll ? 'none' : 'inherit'}`
                        },
                        textAlign: `${MobileCheck() && 'center'}`
                    }}>
                    <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                    <Grid
                        item
                        xs={3}
                        sm={3}
                        sx={{
                            mt: 1.5,
                            ml:
                                (MobileCheck() && !desktopSM && 0.5) ||
                                (MobileCheck() && desktopSM && -2)
                        }}>
                        {MobileCheck() && !desktopSM && (
                            <img src={logoWhite} className="img-dimensions" />
                        )}
                        {(MobileCheck() && desktopSM && (
                            <img src={logoWhite} className="img-sm" />
                        )) ||
                            (!MobileCheck() && <img src={logoWhite} className="img-desktop" />)}
                    </Grid>
                    <Grid item md={3} sm={1} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                    {MobileCheck() && !desktopSM ? (
                        <Grid item xs={4} sm={1} sx={{ mt: 0 }} className="position-right">
                            <ButtonCustom
                                border
                                width="111px"
                                height="32px"
                                padding="8px 8px"
                                bg="transparent"
                                text="Iniciá sesión"
                                action={() =>
                                    navigate(RoutesConstants.LOGIN_PAGE, { replace: true })
                                }
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={4} md={2} sx={{ mt: 0, ml: desktopSM && 8 }}>
                            <ButtonCustom
                                width="160px"
                                border
                                bg="transparent"
                                text="Iniciá sesión"
                                padding={desktopSM && '12px 20px'}
                                action={() =>
                                    navigate(RoutesConstants.LOGIN_PAGE, { replace: true })
                                }
                            />
                        </Grid>
                    )}
                    <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={11} sm={8} sx={{ ml: 2, mr: 1 }}>
                        <Typography
                        variant='h1'
                            sx={{
                                fontSize: `${MobileCheck() ? '30px' : '48px'}`,
                                color: 'white',
                                lineHeight: `${MobileCheck() && '1.2'}`
                            }}>
                            Accedé a tu préstamo de una forma <b>simple, segura e inmediata.</b>
                        </Typography>
                        <Grid item xs={11} sm={9}>
                            <Typography
                                sx={{
                                    fontSize: '24px',
                                    color: 'white',
                                    lineHeight: `${MobileCheck() && '1.2'}`
                                }}>
                                Solo necesitás tu celular y DNI para saber en pocos minutos qué
                                tenemos para ofrecerte.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={1}></Grid>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={10} sm={3} sx={{ pb: MobileCheck() ? 3 : 5 }}>
                        <Box width="100%">
                            <ButtonCustom
                                bg="white"
                                height="48px"
                                width="327px"
                                color={ColorConstants.LIGHT_BLUE}
                                padding={desktopSM && '12px 20px'}
                                text="Quiero mi préstamo"
                                action={() =>
                                    navigate(RoutesConstants.LOAN_REQUEST_PAGE, {
                                        replace: true
                                    })
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
