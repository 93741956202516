import { Box } from '@mui/material';

const getGradientProps = ({ blur, ...gradient }) => {
    const commonProps = {
        zIndex: -1,
        sx: { filter: `blur(${blur})` }
    };
    const propMap = {
        circle: {
            borderRadius: '600px'
        },
        rect: {
            borderRadius: 'none'
        },
        element: {
            borderRadius: 'none'
        }
    };
    return {
        ...gradient,
        ...commonProps,
        ...propMap[gradient.type]
    };
};

export const DynamicGradientBackground = ({ children, masked, gradients = [], ...boxProps }) => {
    return (
        <Box zIndex={0} overflow={masked ? 'hidden' : 'initial'} position="relative" {...boxProps}>
            {/* {gradients.map((gradientElement, i) => (
        <Box
          position="absolute"
          key={`gradient_elem_${i + 1}`}
          {...getGradientProps(gradientElement)}
        />
      ))} */}
            {children}
        </Box>
    );
};
