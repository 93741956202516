import { Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const TitlePrivateHeader = ({children}) => {
    const theme = useTheme();
    const desktop  = useMediaQuery(theme.breakpoints.up("md"))
    return (
        <Typography
            variant='h1'
            sx={{ fontSize: desktop ? '48px' : '30px', fontWeight: 400 }}
            fontSize={desktop ? '48px' : '30px'}
            lineHeight={desktop ? '52px' : '34px'}
            letterSpacing="-0.02em"
            className="font-blue">
            {children}
        </Typography>
    );
};

export default TitlePrivateHeader;
