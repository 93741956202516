import React, { useContext, useEffect, useState } from 'react';
import HomeContext from '../../../Contexts/Home/HomeContext';
import { FeaturedService, Tabs } from '../../../Components/BNPL';
import { useHomeHandler } from '../PublicHomeHandler';
import parse from 'html-react-parser';

export const DoubtsSection = () => {
    const { frequentQuestions } = useContext(HomeContext);
    const { doubtsProps } = useHomeHandler();
    const [doubts, setDoubts] = useState(doubtsProps)

    useEffect(() => {
        if(frequentQuestions){
            doubtsProps.tabs[0].panel.items = frequentQuestions.map( faq => ({
                label: faq.question,
                description: parse(faq.answere)
            }))
            setDoubts(doubtsProps)
        }
    }, [frequentQuestions]);

    return (
        <FeaturedService {...doubts}>
            <Tabs items={doubts.tabs} />
        </FeaturedService>
    );
};
