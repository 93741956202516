import { useEffect, useMemo, useState } from 'react';

export const useForm = (initialValues = {}, formValidations = {}) => {
    const [values, setValues] = useState(initialValues);
    const [formValidation, setFormValidation] = useState({});

    const handleOnChangeFieldEvent = (fieldName) => (event) => {
        setValues({
            ...values,
            [fieldName]: event.target.value
        });
    };

    const handleOnChangeField = (fieldName) => (value) => {
        setValues({
            ...values,
            [fieldName]: value
        });
    };

    const handleChangeMultipleValues = (newValues) => {
        setValues({
            ...values,
            ...newValues
        });
    }

    const onResetForm = () => {
        setValues(initialValues);
    };

    const isFormValid = useMemo(() => {
        for (const formValue of Object.keys(formValidation)) {
            if (formValidation[formValue] !== null) return false;
        }
        return true;
    }, [formValidation]);

    const createValidators = () => {
        const formCheckedValues = {};
        for (const formField of Object.keys(formValidations)) {
            for (const tupla in formValidations[formField]) {
                const [fn, errorMessage] = formValidations[formField][tupla];
                formCheckedValues[`${formField}Valid`] = fn(values[formField])
                    ? null
                    : errorMessage;
                if (!fn(values[formField])) break;
            }
        }
        setFormValidation(formCheckedValues);
    };

    useEffect(() => {
        createValidators();
    }, [values]);

    return {
        values,
        formValidation,
        handleOnChangeFieldEvent,
        handleOnChangeField,
        handleChangeMultipleValues,
        onResetForm,
        isFormValid
    };
};
