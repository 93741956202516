import { getData, postData } from "./BaseService";

export const getOffersService = async (pendingRequestId, amount) => {
    const url = 'credit/pending/deadlines';
    const response = await postData(url, {
        pendingRequestId,
        amount
    });
    
    return response;
}

export const selectCreditService = async (pendingRequestId, values) => {
    const url = 'credit/pending/selectcredit';
    const response = await postData(url, {...values, pendingRequestId});

    return response;
}

export const verifyBiometryRequirements = async (pendingRequestId) => {
    const url = `biometrics/verification/${pendingRequestId}`;
    const response = await getData(url);

    return response;
}

export const getProvinceById = async (id) => {
    let url = `province/${id}`
    const response = await getData(url);
    return response.sDescription;
}