import RegisterActions from './RegisterActions';

export const initialState = { ecommerceAccountID: '' };

export const RegisterReducer = (state, action) => {
    switch (action.type) {
        case RegisterActions.SET_ECOMMERCE_ACCOUNT_ID:
            return { ...state, ecommerceAccountID: action.payload };

        default:
            return state;
    }
};
