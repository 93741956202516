/* eslint-disable no-undef */
/* eslint-disable no-console */
import { StepsConstants } from '../Constants/StepsConstants';
import { getData, postData } from './BaseService';
import { removeItemService, getItemService } from './LocalStorageService';
import { AppConstantsLS } from '../Contexts/Global/AppActions';

export const getByPendingRequest = async (pendingRequestId) => {
    const url = `biometrics/bypendingrequest/${pendingRequestId}`;
    const response = await getData(url);
    return response;
};

export const validateBiometricsWithEntreConsultas = async (body) => {
    const url = `biometrics/validatedata`;
    let response = await postData(url, body);
    return response;
};

export const uploadBiometricsPhoto = async (body) => {
    const url = `biometrics/uploadphoto`;
    let response = await postData(url, body);
    return response;
};

export const uploadBiometricsPhotoFetch = async (body) => {
    const token = getItemService(AppConstantsLS.Token);

    let config = {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify(body)
    };
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    config.headers['Credentials'] = process.env.REACT_APP_CREDENTIAL;
    config.headers['ApiKey'] = process.env.REACT_APP_APIKEY_BACKEND;

    if (process.env.REACT_APP_SUBSCRIPTION_KEY) {
        config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
    }

    const url = 'biometrics/uploadphoto';

    const response = await fetch(`${process.env.REACT_APP_URL_BACKEND}${url}`, config);
    /* const data = await response.json();
    return data; */
    return response;
};

export const saveValidatedIdentity = async (body) => {
    const url = `biometrics/saveValidIdentity`;
    let response = await postData(url, body);
    return response;
};

export const getValidatedIdentity = async (body) => {
    const url = `biometrics/getValidIdentity`;
    let response = await postData(url, body);
    return response;
};

export const cleanSteps = () => {
    removeItemService(StepsConstants.FIRST_STEP);
    removeItemService(StepsConstants.SECOND_STEP);
    removeItemService(StepsConstants.THIRD_STEP);
    removeItemService(StepsConstants.FOURTH_STEP);
    removeItemService('attempt');
    removeItemService('gestureType');
};