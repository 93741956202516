import React, { useContext, useEffect, useState, useRef } from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../../Components/HomeLayouts';
import { DescriptionForm, Description } from '../../../Components/DescriptionsLayout';
import { Box } from '@mui/system';
import {
    TextField,
    FormControl,
    OutlinedInput,
    InputLabel,
    InputAdornment,
    IconButton
} from '@mui/material';
import LoginGoogle from '../../../Components/LoginBtns/LoginGoogle';
import LoginFacebook from '../../../Components/LoginBtns/LoginFacebook';
import AppContext from '../../../Contexts/Global/AppContext';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
    emailValidator,
    passwordValidator,
    DEFAULT_VALID_VALUE
} from '../../../Helpers/Validations';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import { useTimer } from '../../../Hooks/useTimer';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ColorConstants } from '../../../Constants/ColorConstants';
import SocialAccountsButtons from '../../../Components/Buttons/socialAccountsButtons';
import LoginGoogle2 from '../../../Components/LoginBtns/LoginGoogle2';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';
import STRINGS from '../../../i18n/local/es.json';

const LoginPage = () => {
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const { OnLogin, errorLogin, setErrorLogin } = useContext(AppContext);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState(null);
    const [showPw, setShowPw] = useState(false);
    const googleButtonRef = useRef(null);
    const facebookButonRef = useRef(null);

    const navigate = useNavigate();
    const { timerAction } = useTimer(5);

    const onSubmit = async () => {
        let errors = {};
        let emailError = emailValidator(user);
        if (emailError !== DEFAULT_VALID_VALUE) {
            errors.user = emailError;
        }
        let passwordError = passwordValidator(password);
        if (passwordError !== DEFAULT_VALID_VALUE) {
            errors.password = passwordError;
        }
        const hasKeys = !!Object.keys(errors).length;
        setErrors(errors);
        if (hasKeys) {
            return false;
        }
        const tryLogin = await OnLogin(user, password);
        if (tryLogin?.response?.data?.errors) {
            setLoginError(tryLogin.response.data.errors[0].message);
            return;
        } else {
            setLoginError(null);
        }
        /* timerAction(() => setErrorLogin(false)); */
    };

    useEffect(() => {
        if (errorLogin) {
            setErrorLogin(false);
        }
    }, [user, password]);

    const getError = (input) => {
        if (errors[input]) {
            return (
                <p className="messageLabel">
                    <ErrorOutlineIcon style={{ marginRight: '5px' }} />
                    <>{errors[input]}</>
                </p>
            );
        }
        return null;
    };

    const disableSubmitButton = () => {
        return errors?.length > 0 || user == '' || password == '';
    };

    return (
        <Grid container>
            <HomeLayout
                ContainerPanel={
                    <Description
                        title={'Mi Cuenta'}
                        description={
                            workflow === WORKFLOW_CONSTANTS.BNPL ?
                            STRINGS.identification.SUBTITLE_AUTH
                            :
                            'El detalle de tu préstamo en un mismo sitio, para que tengas una experiencia unificadora.'
                        }
                    />
                }
                Form={
                    <Box
                        style={{
                            textAlign: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <ReturnButton route={RoutesConstants.PUBLIC_HOME} />
                        </Box>
                        <DescriptionForm
                            title={'Ingresar a mi cuenta'}
                            description={'Mantente al día con las actualizaciones de tus préstamos'}
                        />
                        <TextField
                            id="user"
                            label="Ingresá tu mail"
                            variant="outlined"
                            fullWidth
                            className="inputLogin"
                            onChange={(e) => {
                                setUser(e.target.value);
                            }}
                            error={!!getError('user')}
                            helperText={getError('user')}
                            required={true}
                        />
                        {/* <TextField
                            type="password"
                            id="password"
                            label="Ingresá tu contraseña"
                            variant="outlined"
                            fullWidth
                            className="inputLogin"
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            error={!!getError('password')}
                            helperText={getError('password')}
                            required={true}
                        /> */}
                        <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                            <InputLabel htmlFor="password">Ingresá tu contraseña *</InputLabel>
                            <OutlinedInput
                                id="password"
                                label="Ingresá tu contraseña *"
                                type={showPw ? 'text' : 'password'}
                                value={password}
                                helperText={getError('password')}
                                required={true}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                /* onClick={() => setErrorPassword(false)} */
                                sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                                inputProps={{ style: { borderRadius: '16px !important' } }}
                                color="info"
                                error={!!getError('password')}
                                /*  startAdornment={
                                    <InputAdornment position="start">
                                        <LockOutlinedIcon />
                                    </InputAdornment>
                                } */
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPw(!showPw)}
                                            edge="end">
                                            {showPw ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <p
                            className="forget-password"
                            onClick={() =>
                                navigate(RoutesConstants.NEW_PASSWORD_CHANGE_REQUEST_PAGE)
                            }>
                            Olvidé mi contraseña
                        </p>
                        <Box>
                            {loginError !== null ? (
                                <ErrorMessage
                                    title="No pudimos validar tu acceso"
                                    description={
                                        'El mail y/o contraseña que ingresaste no coinciden con nuestros registros. Volvé a verificar e intentá de nuevo.'
                                    }
                                    fonstSize="14px"
                                />
                            ) : (
                                <>
                                    <Box width="100%">
                                        <LoginGoogle />
                                        {/*  <SocialAccountsButtons
                                            title="Continuar con Google"
                                            google
                                            action={() =>
                                                googleButtonRef.current.firstChild.click()
                                            }
                                        /> */}
                                    </Box>
                                    <Box display="none">
                                        <Box mt={2} height={50} ref={googleButtonRef}>
                                            <LoginGoogle />
                                        </Box>
                                        <Box mt={3} height={50} ref={facebookButonRef}>
                                            <LoginFacebook />
                                        </Box>
                                    </Box>
                                    {/*  <Box mt={2} height={50}>
                                        <LoginGoogle />
                                    </Box>
                                    <Box mt={3} height={50}>
                                        <LoginFacebook />
                                    </Box> */}
                                </>
                            )}
                            <GrayButton
                                text={'Iniciar sesión'}
                                action={() => onSubmit()}
                                disabled={disableSubmitButton()}
                            />
                            {
                                workflow !== WORKFLOW_CONSTANTS.BNPL &&
                            <p
                                className="registerLink"
                                onClick={() => navigate(RoutesConstants.REGISTER_PAGE)}>
                                Registrarme
                            </p>
                            }
                        </Box>
                    </Box>
                }
            />
        </Grid>
    );
};

export default LoginPage;
