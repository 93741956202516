import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../Components/HomeLayouts';
import { Description } from '../../Components/DescriptionsLayout';
import { Outlet } from 'react-router-dom';
import { LoanRequestState } from '../../Contexts/LoanRequest/LoanRequestState';
import { BNPLSiisaProvider } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';

const titleDescription = 'Quiero mi préstamo';
const descriptionDesc = 'Con tu celular y DNI podremos buscar la mejor oferta para vos.';

export const LoanRequestGeneralPage = () => {
    return (
        <BNPLSiisaProvider>
            <LoanRequestState>
                <Grid container>
                    <HomeLayout
                        ContainerPanel={
                            <Description title={titleDescription} description={descriptionDesc} />
                        }
                        Form={<Outlet />}
                    />
                </Grid>
            </LoanRequestState>
        </BNPLSiisaProvider>
    );
};
