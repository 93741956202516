import { useState, useEffect } from 'react';
import { Box, Grid, useTheme, useMediaQuery, styled } from '@mui/material';
import { DynamicGradientBackground, Footer, Navbar } from '../BNPL';
import { ColorConstants } from '../../Constants/ColorConstants';

const GridMain = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        backgroundImage: `url("fondo.svg")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
    },
    display: 'flex',
    justifyContent: 'center'
}));

const GridContent = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '0 5%',
        marginBottom: '40px'
    },
    [theme.breakpoints.up('lg')]: {
        padding: '0 11.5%'
    },
    alignContent: 'space-between',
    justifyContent: 'center'
}));

const GridDescription = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        padding: '20px 20px 0 24px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '30px'
    },
    [theme.breakpoints.up('lg')]: {
        padding: '40px 100px 0px 0px'
    },
    borderRadius: '40px',
    ['h4']: {
        letterSpacing: '-0.02em'
    }
}));

const GridForm = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        minHeight: '90%',
        marginTop: '20px'
    },
    [theme.breakpoints.up('sm')]: {
        minHeight: '680px'
    },
    [theme.breakpoints.up('md')]: {
        marginTop: '30px'
    },
    [theme.breakpoints.up('lg')]: {
        marginTop: '40px'
    },
    display: 'flex',
    justifyContent: 'flex-end'
}));

const FormContainer = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('xs')]: {
        padding: '24px',
        borderRadius: '40px 40px 0px 0px',
        minHeight: '90%',
        width: '100%',
        '&.transparent': {
            padding: '0 24px'
        }
    },
    [theme.breakpoints.up('sm')]: {
        minHeight: '680px'
    },
    [theme.breakpoints.up('md')]: {
        padding: '64px',
        borderRadius: '40px',
        maxWidth: '540px',
        '&.transparent': {
            padding: '0'
        }
    },
    [theme.breakpoints.up('lg')]: {
        padding: '64px',
        maxWidth: '540px'
    },
    display: 'flex',
    justifyContent: 'center'
}));

const gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '0',
        top: '-335px'
    },
    {
        type: 'circle',
        width: '316px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-60px',
        top: '389px'
    },
    {
        type: 'circle',
        width: '96px',
        height: '73px',
        bgcolor: ColorConstants.green[500],
        blur: '60px',
        right: '0px',
        top: '40px'
    }
];

const defaultProps = {
    desktop: {
        background: 'white',
        showDescription: true,
        showFullWidthDesciption: false,
        gradients: []
    },
    mobile: {
        background: 'white',
        showDescription: false,
        showFullWidthDesciption: false,
        gradients: []
    }
};

const mergeProps = (defaultProps, newProps) => {
    const updatedProps = { ...defaultProps };
    for (const prop in newProps) {
        if (Object.prototype.hasOwnProperty.call(updatedProps, prop)) {
            updatedProps[prop] = { ...updatedProps[prop], ...newProps[prop] };
        }
    }
    return updatedProps;
};

export const BNPLLayout = ({ Form, ContainerPanel, layoutProps = {} }) => {
    const [activeLayoutProps, setActiveLayoutProps] = useState(defaultProps);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const resolveLayoutProps = () => {
        const initialProps = mergeProps(defaultProps, layoutProps);

        if (desktop) {
            setActiveLayoutProps(initialProps.desktop);
        } else {
            setActiveLayoutProps(initialProps.mobile);
        }
    };

    useEffect(() => {
        resolveLayoutProps();
    }, [desktop, layoutProps]);

    return (
        <DynamicGradientBackground masked gradients={activeLayoutProps.gradients} width="100%">
            <GridMain container>
                <GridContent container item>
                    <GridDescription item xs={12} md={6}>
                        <Navbar showButtons={false} />
                        {activeLayoutProps.showDescription && (
                            <Box
                                maxWidth={
                                    activeLayoutProps.showFullWidthDesciption ? '100%' : '445px'
                                }>
                                {ContainerPanel}
                            </Box>
                        )}
                    </GridDescription>
                    <GridForm item xs={12} md={6}>
                        <FormContainer
                            sx={{ backgroundColor: activeLayoutProps.background }}
                            className={
                                activeLayoutProps.background === 'transparent'
                                    ? activeLayoutProps.background
                                    : ''
                            }>
                            {Form}
                        </FormContainer>
                    </GridForm>
                </GridContent>
                <Footer />
            </GridMain>
        </DynamicGradientBackground>
    );
};
