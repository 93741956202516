import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

const GrayButton = ({
    id,
    text,
    action,
    disabled,
    outlined,
    pt = 3,
    color = 'secondary',
    borderColor = '',
    height = '48px',
    sx,
    ...props
}) => {
    return (
        <Box pt={pt} width="100%">
            <Button
                {...props}
                id={id}
                onClick={() => action()}
                disabled={disabled}
                color={color}
                sx={{
                    borderRadius: '30px',
                    width: '100%',
                    height: height,
                    borderColor: { borderColor },
                    border: outlined ? '1.5px solid' : '',
                    ...sx
                }}
                variant={outlined ? 'outlined' : 'contained'}>
                <Typography
                    fontSize={16}
                    fontWeight={500}
                    fontFamily={'Archivo'}
                    alignItems="center"
                    display="flex">
                    {text}
                </Typography>
            </Button>
        </Box>
    );
};

export default GrayButton;
