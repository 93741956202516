import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const IconContainer = styled(Box)`
    color: ${ColorConstants.green[900]};
    width: 48px;
    height: 48px;
    background: ${ColorConstants.green[50]};
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '48px'
    },
    fontFamily: 'Public Sans',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '30px'
}));

export const Description = styled(Typography)`
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

export const DescriptionContainer = styled(Box)`
    margin-top: 16px;
    margin-bottom: 24px;
`;

export const ServiceContainer = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        gap: '125px',
        justifyContent: 'center',
        marginTop: '80px'
    },
    marginTop: '40px',
    color: ColorConstants.blue[900]
}));

export const Service = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        minWidth: '255px',
        width: '255px'
    }
}));

export const Faq = styled(Box)(({ theme, className }) => ({
    [theme.breakpoints.up('md')]: {
        marginTop: className?.includes('icon') ? '56px' : '0px'
    },
    width: '100%'
}));

export const ButtonContainer = styled(Box)`
    margin-top: 40px;
    display: flex;
    button {
        width: auto;
        padding: 0 32px;
        div {
            flex-direction: row;
            flex-wrap: nowrap;
            width: 100%;
            gap: 8px;
            max-width: 100%;
            p {
                white-space: nowrap;
            }
        }
    }
`;

export const LinkButton = styled(Button)`
    color: ${ColorConstants.blue[500]};
    font-size: 16px;
    text-decoration: underline;
    padding: 0;
    margin-top: 20px;
    &:hover {
        text-decoration: underline;
        background: none;
    }
    svg {
        width: 16px;
    }
`;
