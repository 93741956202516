import { Stack } from '@mui/material';
import styled from 'styled-components';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const DniPhoto = styled.img`
    max-width: 92%;
    max-height: 275px;
    border-radius: 20px;
    background: ${ColorConstants.gray[200]};
`;

export const SelfiePhoto = styled.img`
    width: 260px;
    height: 260px;
    object-fit: cover;
    border-radius: 500px;
    background: ${ColorConstants.gray[200]};
    object-position: 50% 30%;
`;

export const ButtonsContainer = styled(Stack)`
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    > div {
        flex-basis: 50%;
        button {
            padding: 0;
            p {
                display: flex;
            }
        }
    }
`;
