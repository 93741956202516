import { Box, Stack, Typography, styled } from '@mui/material';
import Hourglass from '../../Assets/hourglass.svg';
import { QRCodeImage } from './QRCode';
import STRINGS from '../../i18n/local/es.json';

const Circle = styled(Box)`
    background: #c8f8c8;
    border-radius: 160px;
    width: 160px;
    height: 160px;
    outline: 16px solid #eeffee;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 2s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const QRText = styled(Typography)`
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #656566;
`;

const QRContainer = styled(Stack)`
    align-items: center;
    gap: 24px;

    img {
        filter: grayscale(100%);
    }
`;

const Container = styled(Stack)`
    align-items: center;
    justify-content: space-between;
`;

export const WaitingConfirm = () => {
    return (
        <Container>
            <Circle>
                <img src={Hourglass} />
            </Circle>
            <QRContainer>
                <QRText>{STRINGS.scan_qr_code.waiting_for_confirmation.CONTENT}</QRText>
                <QRCodeImage width="130px" />
            </QRContainer>
        </Container>
    );
};
