import { Route } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BiometricBNPLContainer } from '../../Pages/BiometricBNPL/BiometricBNPLContainer';
import { BiometricBNPLPage } from '../../Pages/BiometricBNPL/BiometricBNPLPage';
import { NewCameraPage } from '../../Pages/BiometricBNPL/Camera/NewCameraPage';

export const BiometricRoutes = () => {
    return (
        <Route element={<BiometricBNPLContainer />}>
            <Route
                exact
                path={RoutesConstants.BIOMETRIC_BNPL_PAGE}
                element={<BiometricBNPLPage />}
            />
            <Route
                exact
                path={RoutesConstants.CAMERA_PAGE}
                element={<NewCameraPage />}
            />
        </Route>
    );
};
