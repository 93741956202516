import React from 'react';
import { Outlet } from 'react-router-dom';
import { OfferSelectionState } from '../../../Contexts/OfferSelection/OfferSelectionState';
import { BNPLSiisaProvider } from '../../../Contexts/BNPLSiisa/BNPLSiisaContext';

export const OfferPages = () => {
    return (
        <BNPLSiisaProvider>
            <OfferSelectionState>
                <Outlet />
            </OfferSelectionState>
        </BNPLSiisaProvider>
    );
};
