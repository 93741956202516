import { Avatar, Box, styled, Typography } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Option = styled(Box)`
    margin: 10px 0;
    cursor: pointer;
    background: linear-gradient(117.06deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 99.28%);
    box-shadow: 0px 4px 8px -2px rgba(0, 105, 215, 0.1), 0px 2px 4px -2px rgba(0, 105, 215, 0.06);
    border-radius: 16px;
    padding: 12px 16px;
    outline: 1px solid ${ColorConstants.gray[200]};
    &:hover {
        outline: 1px solid ${ColorConstants.gray[600]};
        box-shadow: 0px 4px 9.6px -2.4px rgba(0, 105, 215, 0.1),
            0px 2px 4.8px -2.4px rgba(0, 105, 215, 0.06);
    }
    &.selected {
        outline: 2px solid ${ColorConstants.green[500]};
    }
`;

export const TitleOption = styled(Typography)`
    color: ${ColorConstants.blue[900]};
    font-weight: 700;
    font-size: 1.125rem;
`;

export const DescriptionOption = styled(Typography)`
    color: ${ColorConstants.blue[500]};
`;

export const AvatarIcon = styled(Avatar)`
    background-color: ${ColorConstants.blue[100]};
    color: ${ColorConstants.blue[500]};
    width: 2rem;
    height: 2rem;
`;
