/* eslint-disable no-unused-vars */
import React, { useContext, useRef } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import FlashOffOutlinedIcon from '@mui/icons-material/FlashOffOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import { Box, Typography, Grid } from '@mui/material';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import BiometricDataContext from '../../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../../Constants/StepsConstants';
import { FirstStep } from '../FirstStep';
import { DNIModal } from '../Components/DNIModal';
import PaymentIcon from '@mui/icons-material/Payment';
import SidLogo from '../../../Assets/sid-logo.svg';

export const DNISteps = ({ handleOpenModal, dialog }) => {
    const { setImage, saveImage, inputRef, openCamera, setStartBiometric, images } =
        useContext(BiometricDataContext);

    const title = '¡Genial! Ahora vamos a validar tu identidad';
    const description =
        'Buscá tu DNI. Vamos a necesitar fotos del frente y dorso. Podés seguir estos tips:';
    const navigate = useNavigate();

    return (
        <>
            <DNIModal
                action={() => openCamera(StepsConstants.FIRST_STEP)}
                dialogHook={dialog}
                buttonPosition="center"
                icon={<PaymentIcon />}
                buttonText="Continuar"
            />

            <DescriptionForm title={title} description={description} />
            <Grid container spacing={2} sx={{ mt: 3 }}>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <FlashOffOutlinedIcon sx={{ fontSize: '22px', pr: 0.1 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Evitá usar flash para que los datos sean legibles. La foto no debe tener
                            reflejos.{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <CameraAltOutlinedIcon sx={{ fontSize: '22px', pr: 0.2 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Encuadrá tu DNI para que coincida con el marco y salga completo.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={4} className="mt-15 mb-30">
                    <Grid item xs={1} sm={2} mr={1}>
                        <div className="icon-style">
                            <LoopOutlinedIcon sx={{ fontSize: '22px', pr: 0.1 }} />
                        </div>
                    </Grid>
                    <Grid item xs={9} sm={12} sx={{ textAlign: 'left', color: '#0069D7' }}>
                        <Typography className="ml-10">
                            Girá tu celular para lograr una foto más cercana y nítida.{' '}
                        </Typography>
                    </Grid>
                </Grid>
                <Box width="100%" sx={{ ml: 2 }}>
                    {/* <ButtonCustom
                        text="Comenzar"
                        action={() => {
                            navigate(`${RoutesConstants.DNI_FRONT_PAGE}/frente`);
                        }}
                    /> */}
                    {/* <ButtonCustom
                        text="Comenzar"
                        action={() => openCamera(StepsConstants.FIRST_STEP)}
                    /> */}
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        gap={'10px'}>
                        <Typography color={'primary'}>Vamos a utilizar el</Typography>
                        <img src={SidLogo} style={{ padding: '30px 0px', maxWidth: '80px' }} />
                    </Box>
                    <ButtonCustom text="Comenzar" action={() => handleOpenModal()} />
                </Box>
                <BackHomeButton />
            </Grid>
        </>
    );
};
