import { SvgIcon } from '@mui/material';

const MoneyIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="2" y="2" width="32" height="32" rx="16" fill="#CEE6FF" />
                <path
                    d="M18.6667 10.6665C18.6667 10.2983 18.3682 9.99984 18 9.99984C17.6318 9.99984 17.3333 10.2983 17.3333 10.6665H18.6667ZM17.3333 25.3332C17.3333 25.7014 17.6318 25.9998 18 25.9998C18.3682 25.9998 18.6667 25.7014 18.6667 25.3332H17.3333ZM21.3333 13.9998C21.7015 13.9998 22 13.7014 22 13.3332C22 12.965 21.7015 12.6665 21.3333 12.6665V13.9998ZM14 15.6665H13.3333H14ZM16.3333 17.9998V18.6665V17.9998ZM19.6667 17.9998L19.6667 17.3332H19.6667V17.9998ZM19.6667 22.6665L19.6667 23.3332H19.6667L19.6667 22.6665ZM14 21.9998C13.6318 21.9998 13.3333 22.2983 13.3333 22.6665C13.3333 23.0347 13.6318 23.3332 14 23.3332V21.9998ZM17.3333 10.6665V25.3332H18.6667V10.6665H17.3333ZM21.3333 12.6665H16.3333V13.9998H21.3333V12.6665ZM16.3333 12.6665C15.5377 12.6665 14.7746 12.9826 14.212 13.5452L15.1548 14.488C15.4674 14.1754 15.8913 13.9998 16.3333 13.9998V12.6665ZM14.212 13.5452C13.6494 14.1078 13.3333 14.8709 13.3333 15.6665H14.6667C14.6667 15.2245 14.8423 14.8006 15.1548 14.488L14.212 13.5452ZM13.3333 15.6665C13.3333 16.4622 13.6494 17.2252 14.212 17.7878L15.1548 16.845C14.8423 16.5325 14.6667 16.1085 14.6667 15.6665H13.3333ZM14.212 17.7878C14.7746 18.3504 15.5377 18.6665 16.3333 18.6665V17.3332C15.8913 17.3332 15.4674 17.1576 15.1548 16.845L14.212 17.7878ZM16.3333 18.6665H19.6667V17.3332H16.3333V18.6665ZM19.6667 18.6665C20.1087 18.6665 20.5326 18.8421 20.8452 19.1547L21.788 18.2118C21.2254 17.6492 20.4623 17.3332 19.6667 17.3332L19.6667 18.6665ZM20.8452 19.1547C21.1577 19.4672 21.3333 19.8911 21.3333 20.3332H22.6667C22.6667 19.5375 22.3506 18.7745 21.788 18.2118L20.8452 19.1547ZM21.3333 20.3332C21.3333 20.7752 21.1577 21.1991 20.8452 21.5117L21.788 22.4545C22.3506 21.8919 22.6667 21.1288 22.6667 20.3332H21.3333ZM20.8452 21.5117C20.5326 21.8242 20.1087 21.9998 19.6667 21.9998L19.6667 23.3332C20.4623 23.3332 21.2254 23.0171 21.788 22.4545L20.8452 21.5117ZM19.6667 21.9998H14V23.3332H19.6667V21.9998Z"
                    fill="#0069D7"
                />
            </svg>
        </SvgIcon>
    );
};

export default MoneyIcon;
