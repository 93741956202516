import { useContext, useState } from 'react';
import { BNPLBiometricContext } from '../../../Contexts/BNPLBiometric/BNPLBiometricContext';
import { ConfirmPhotoLayout } from '../Layout/ConfirmPhotoLayout';
import { StepsConstants } from '../../../Constants/StepsConstants';
import { GestureFaceConstants } from '../../../Constants/GestureFaceConstants';
import { BiometricDataConstants } from '../../../Constants/BiometricDataConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import STRINGS from '../../../i18n/local/es.json';
import { useEffect } from 'react';

export const FourthStep = () => {
    const { actions, state } = useContext(BNPLBiometricContext);
    const { openCamera, openCamera2, getImage, confirmPhoto } = actions;
    const { isLoading, gestureType, customError } = state;
    const [errorMessage, setErrorMessage] = useState(null);

    const onContinueToCamera = (step) => {
        openCamera2(step);
    };

    const onConfirmPhoto = async () => {
        let data = {
            frontDNI: getImage(StepsConstants.FIRST_STEP),
            backDNI: getImage(StepsConstants.SECOND_STEP),
            entreConsultasImage: [
                {
                    image: getImage(StepsConstants.FOURTH_STEP),
                    typeGesturePhoto: gestureType
                },
                {
                    image: getImage(StepsConstants.THIRD_STEP),
                    typeGesturePhoto: GestureFaceConstants.NEUTRAL_FACE
                }
            ],
            step: StepsConstants.FOURTH_STEP,
            biometricDataType: BiometricDataConstants.SMILE_FACE,
            extension: 'jpeg'
        };

        const res = await confirmPhoto(data, RoutesConstants.BIOMETRIC_BNPL_PAGE);
        if (res.ErrorType == 'customError') {
            const error = res.message.split('.');
            setErrorMessage({ title: error[0], description: error[1] });
        }
    };

    const onRepeatPhoto = () => {
        onContinueToCamera(StepsConstants.FOURTH_STEP);
        setErrorMessage(null);
    };

    useEffect(() => {
        if(customError){
            const error = customError.split('.');
            setErrorMessage({ title: error[0], description: error[1] });
        }
    }, [customError])
    
    return (
        <ConfirmPhotoLayout
            title={STRINGS.biometric.selfie.CONFIRM_SMILE.TITLE}
            variant="selfie"
            src={getImage(StepsConstants.FOURTH_STEP)}
            description={STRINGS.biometric.selfie.CONFIRM_SMILE.DESCRIPTION}
            onRepeatCapture={onRepeatPhoto}
            onConfirm={onConfirmPhoto}
            error={errorMessage}
            loading={isLoading}
        />
    );
};
