import React from 'react'
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import { TitleCustom } from '../../Components/TitleCustom';
import { CompletedLoanCard } from './CompletedLoanCard';
import DownloadableFiles from '../ActiveLoan/Downloadable/DownloadableFiles';
import downloadableOptionsHome from '../../Configs/downloadableOptionsHome.json'
import { WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

export const CompletedLoansSection = (props) => {
    const { creditList } = useContext(LoanActiveContext);
    const theme = useTheme();
    const desktop  = useMediaQuery(theme.breakpoints.up("sm"));

    return (
        <Grid container spacing={2} mb={2}>
            <Grid item xs={1} sx={{display:{xs:'block', sm:'none'}}}></Grid>
            <Grid item xs={10} sm={3} md={3}>
                {creditList && creditList.creditsCanceled.length > 0 &&
                    <TitleCustom
                    size={desktop ? '40px' : '24px'}
                    title={props.variant === WORKFLOW_CONSTANTS.BNPL ? 'Operaciones finalizadas' : 'Préstamos finalizados'}
                    subtitle=""
                    />
                }
            </Grid>
            <Grid item xs={1} sx={{display:{xs:'block', sm:'none'}}}></Grid>
            <Grid item xs={1} sm={2} sx={{display:{xs:'block'}}}></Grid>
            <Grid item xs={10} sm={7} md={7}>
                {creditList && creditList.creditsCanceled.map((c, index) => (
                        <CompletedLoanCard key={index}
                            size={!desktop && '15px'}
                            credit={c}
                            index={index}
                            totalCredits={creditList.credits.length}
                        />
                    ))
                }
            </Grid>
            <Grid item xs={1} sx={{display:{xs:'block', sm:'none'}}}></Grid>
                {creditList && creditList.creditsCanceled.length > 0 &&
                    <Grid item xs={10} sm={12} ml={desktop ? -6 :  1} mr={desktop && 8}>
                        <DownloadableFiles optionsList={downloadableOptionsHome.options}/>
                    </Grid>
                }
        </Grid>
    )
}
