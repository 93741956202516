import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import MyProfileContext from './MyProfileContext';
import { changePasswordService, getDataPersonService } from '../../Services/ChangePasswordService';
import { getItemService } from '../../Services/LocalStorageService';
import { dataUpdateService } from '../../Services/MyProfileService';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { AppConstantsLS } from '../Global/AppActions';
import AppContext from '../Global/AppContext';

export const MyProfileState = ({ children }) => {
    const navigate = useNavigate();
    const {accessMethod} = useContext(AppContext);

    const [successfulUpdate, setSuccessfulUpdate] = useState(false);
    const [errorUpdatePhoneNumber, setErrorUpdatePhoneNumber] = useState(false);
    const [personId] = useState(getItemService(AppConstantsLS.Person) ? JSON.parse(getItemService(AppConstantsLS.Person)).personId : {});
    const [notConnection, setNotConnection] = useState(false);

    const changePassword = async ({ currentPassword, password }) => {
        try{
            const ecommerceAccountId = getItemService(AppConstantsLS.EcommerceAccountId);
            const response = await changePasswordService(ecommerceAccountId, password, currentPassword);
            navigate(RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE);
            return response;
        }
        catch (error){
            if (error.code) return;
            setNotConnection(true);
        }
    };

    const getPersonData = async () => {
        const response = await getDataPersonService(personId);
        return response;
    };

    const dataUpdate = async (values) => {
        let response;
        try{
            response = await dataUpdateService(personId, values);
            const errorPhoneNumber =
                response.data.message ==
                'Todos los cambios excepto celular; se realizaron correctamente';
                if (!errorPhoneNumber) {
                    setSuccessfulUpdate(true);
                } else {
                    setErrorUpdatePhoneNumber(true);
                }
            setSuccessfulUpdate(true);
        }
        catch (error){
            if (error.code) return;
            setNotConnection(true);
        }


        return response;
    };

    return (
        <MyProfileContext.Provider
            value={{
                accessMethod: accessMethod,
                errorUpdatePhoneNumber: errorUpdatePhoneNumber,
                successfulUpdate: successfulUpdate,
                notConnection : notConnection,
                changePassword: changePassword,
                dataUpdate: dataUpdate,
                getPersonData: getPersonData,
                setSuccessfulUpdate: setSuccessfulUpdate,
                setErrorUpdatePhoneNumber: setErrorUpdatePhoneNumber,
                setNotConnection : setNotConnection
            }}>
            {children}
        </MyProfileContext.Provider>
    );
};

MyProfileContext.propTypes = {
    children: PropTypes.element
};
