import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useState } from 'react';
import { emailValidate } from '../../Helpers/Validations';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';
import InformationLabel from '../../Components/Form/InformationLabel';
import ChangePasswordContext from '../../Contexts/ChangePassword/ChangePasswordContext';
import { ValidateEmailAndExecuteRegister } from '../../Helpers/ValidateEmailAndExecute';
import { ColorConstants } from '../../Constants/ColorConstants';

const FormPasswordChangeRequest = () => {
    const { changePasswordRequest } = useContext(ChangePasswordContext);

    const [email, setEmail] = useState('');

    const [errorEmail, setErrorEmail] = useState(false);

    const handleSetEmail = (value) => {
        setEmail(value.replace(/\s/g, ''));
    };

    const isWrongEmail = () => {
        setErrorEmail(email != '' && !emailValidate(email));
    };

    const onSubmit = async () => {
        try {
            await ValidateEmailAndExecuteRegister(email, () =>
                changePasswordRequest(email)
            );
        } catch (error) {
            console.log(error);
        }
    };

    const descriptionFormLabel = 'Ejemplo de mail: nombre@gmail.com';

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'space-between',
                height: '430px'
            }}>
            <Box width={'100%'}>
                <FormControl fullWidth sx={{ my: 1, borderRadius: '40px' }}>
                    <InputLabel htmlFor="email">Ingresá tu mail</InputLabel>
                    <OutlinedInput
                        id="email"
                        value={email}
                        onChange={(e) => handleSetEmail(e.target.value)}
                        onBlur={() => isWrongEmail()}
                        onClick={() => setErrorEmail(false)}
                        sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                        color="info"
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        }
                        label="Ingresá tu email"
                    />
                </FormControl>
                {errorEmail && <InformationLabel description={descriptionFormLabel} />}
            </Box>
            <Box width={'100%'}>
                <GrayButton
                    text={'Enviar'}
                    action={() => onSubmit()}
                    disabled={!emailValidate(email)}
                />

                <BackHomeButton />
            </Box>
        </Box>
    );
};

export default FormPasswordChangeRequest;
