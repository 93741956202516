import React from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TermsConditionsLayout from '../../Components/TermsConditionsLayout';
import TermsConditionsDescription from './TermsConditionsDescription';
import { TermsConditionsState } from '../../Contexts/TermsConditions/TermsConditionsState';

const TermsConditionsPage = () => {
    const titleDesc = 'Términos y condiciones';
    const descriptionDesc =
        'Por favor, leé cuidadosamente el detalle para el correcto uso del presente sitio web.';
    return (
        <Grid container>
            <TermsConditionsState>
                <TermsConditionsLayout
                    ContainerPanel={
                        <TermsConditionsDescription
                            title={titleDesc}
                            description={descriptionDesc}
                        />
                    }
                    Form={<Outlet />}
                />
            </TermsConditionsState>
        </Grid>
    );
};

export default TermsConditionsPage;
