import React from 'react';
import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { TitleCustom } from '../../Components/TitleCustom';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import CallMadeIcon from '@mui/icons-material/CallMade';
import '../../Styles/GlobalStyle.css';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ContactForm } from './ContactForm';
import { HomeState } from '../../Contexts/Home/HomeState';
import { ColorConstants } from '../../Constants/ColorConstants';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { MobileCheck } from '../../Helpers/MobileCheck';

export const ContactPage = () => {
    const routerLocation = useLocation();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <HomeState>
            <Grid container spacing={2} sx={{ mb: 5, mt: desktop ? 2 : -1 }} className="bg-aqua">
                <Grid
                    item
                    sm={2}
                    sx={{
                        display: {
                            sm: `${
                                routerLocation.pathname == RoutesConstants.CONTACT_PAGE
                                    ? 'block'
                                    : 'none'
                            }`
                        },
                        ml: !desktop && 1.2
                    }}></Grid>
                <Grid
                    item
                    xs={11}
                    sm={10}
                    sx={{
                        display: {
                            xs: `${
                                routerLocation.pathname == RoutesConstants.CONTACT_PAGE
                                    ? 'inherit'
                                    : 'none'
                            }`
                        }
                    }}>
                    <ReturnButton
                        text={'Volver'}
                        medium
                        route={() => history.back()} /* route={RoutesConstants.PUBLIC_HOME} */
                    />
                </Grid>
                <Grid
                    item
                    sm={2}
                    sx={{
                        display: {
                            sm: `${
                                routerLocation.pathname == RoutesConstants.CONTACT_PAGE
                                    ? 'block'
                                    : 'none'
                            }`
                        }
                    }}></Grid>
                {/* <Grid item sx={{display:{sm:'none'}}}></Grid> */}
                <Grid
                    item
                    xs={11}
                    md={routerLocation.pathname == RoutesConstants.CONTACT_PAGE ? 3 : 4}
                    sx={{
                        mt:
                            (!desktop &&
                                routerLocation.pathname == RoutesConstants.CONTACT_PAGE &&
                                -4) ||
                            (desktop &&
                                routerLocation.pathname != RoutesConstants.CONTACT_PAGE &&
                                3) ||
                            (desktop &&
                                routerLocation.pathname == RoutesConstants.CONTACT_PAGE &&
                                3),
                        ml: !desktop && 2.5
                    }}>
                    <TitleCustom
                        size={MobileCheck() ? '24px' : '32px'}
                        title="¿Cómo podemos ayudarte?"
                        subtitle="Seleccioná la opción que te sea más cómoda. Dejanos tu comentario y te respondemos a la brevedad."
                    />
                    <Grid container>
                        <Grid item xs={11} md={12} sx={{ display: { xs: 'none', md: 'block' } }}>
                            <ButtonCustom
                                border
                                bg="transparent"
                                color={ColorConstants.BLUE}
                                icon={<CallMadeIcon />}
                                padding="12px 0px 12px 32px"
                                text="0800-345-6790"
                                action={() => {
                                    window.location.href = 'tel:08003456790';
                                }}
                            />
                            <ButtonCustom
                                border
                                bg="transparent"
                                color={ColorConstants.BLUE}
                                icon={<CallMadeIcon />}
                                padding="12px 0px 12px 32px"
                                text="ayuda@directo.com.ar"
                                action={() => {
                                    window.open('mailto:ayuda@directo.com.ar');
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} sx={{ display: { sm: 'none' } }}></Grid>
                <Grid item xs={1} sm={1} sx={{ display: { xs: 'block' } }}></Grid>
                <Grid
                    item
                    xs={12}
                    md={routerLocation.pathname == RoutesConstants.CONTACT_PAGE ? 4 : 7}
                    lg={routerLocation.pathname == RoutesConstants.CONTACT_PAGE ? 4 : 7}
                    sx={{ ml: 0, mr: 0, mt: desktop ? 4 : -2 }}>
                    <ContactForm />
                </Grid>
                <Grid item xs={2} md={1}></Grid>
                <Grid
                    item
                    xs={11}
                    md={4}
                    sx={{ ml: 3, mr: 3, display: { xs: 'block', md: 'none' } }}>
                    <ButtonCustom
                        border
                        bg="transparent"
                        color={ColorConstants.BLUE}
                        padding="12px 0px 12px 32px"
                        icon={<CallMadeIcon />}
                        text="0800-345-6790"
                        action={() => {
                            window.location.href = 'tel:08003456790';
                        }}
                    />
                    <ButtonCustom
                        border
                        bg="transparent"
                        padding="12px 0px 12px 32px"
                        color={ColorConstants.BLUE}
                        icon={<CallMadeIcon />}
                        text="ayuda@directo.com.ar"
                        action={() => {
                            window.location.href = 'mailto:ayuda@directo.com.ar';
                        }}
                    />
                </Grid>
                <Grid item xs={1} sm={7}></Grid>
            </Grid>
        </HomeState>
    );
};
