import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Footer } from './Footer';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const FooterWrapper = () => {
    const [footer, setFooter] = useState(null);
    const routerLocation = useLocation();

    useEffect(() => {
        if (
            routerLocation.pathname === RoutesConstants.CARD_CRED_IFRAME ||
            routerLocation.pathname === RoutesConstants.CARD_CRED_IFRAME_ERROR
        ) {
            setFooter(null);
            return;
        }
        setFooter(renderFooter(routerLocation.pathname));
    }, [routerLocation.pathname]);

    const renderFooter = (pathname) => {
        switch (pathname) {
            case `${RoutesConstants.DNI_FRONT_PAGE}/dorso`:
                break;
            case `${RoutesConstants.DNI_FRONT_PAGE}/frente`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/neutra`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/sonriendo`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/guino-ojo`:
                break;
            case `${RoutesConstants.FACE_CAPTURE_PAGE}/boca-abierta`:
                break;
            case `${RoutesConstants.LOAN_SATISFACTORY_CARD_LOAD}`:
                break;
            case `${RoutesConstants.REDIRECT_TO_DIRECTO_PAGE}`:
                break;
            case `${RoutesConstants.IDENTIFICATION_GENDER_PAGE}`:
                break;
            case `${RoutesConstants.IDENTIFICATION_CONFIRM_PAGE}`:
                break;
            case `${RoutesConstants.IDENTIFICATION_DNI_PAGE}`:
                break;
            case `${RoutesConstants.IDENTIFICATION_CONTACT_PAGE}`:
                break;
            case `${RoutesConstants.BNPL_TOKEN_PAGE}`:
                break;
            case `${RoutesConstants.BNPL_FEEDBACK}`:
                break;
            case `${RoutesConstants.FINANCING_PAGE}`:
                break;
            case `${RoutesConstants.SELECT_PAYMENT_METHOD}`:
                break;
            case `${RoutesConstants.FINANCING_OFFER_PAGE}`:
                break;
            case `${RoutesConstants.BIOMETRIC_BNPL_PAGE}`:
                break;
            case `${RoutesConstants.CONFIRMATION_PAGE}`:
                break;
            case `${RoutesConstants.PUBLIC_HOME_BNPL}`:
                break;
            case `${RoutesConstants.PRIVATE_HOME_BNPL}`:
                break;
            case `${RoutesConstants.PARTNERS_STORE_PAGE}`:
                break;
            case `${RoutesConstants.INSUFFICIENT_FUNDS_PAGE}`:
                break;
            case `${RoutesConstants.BNPL_NO_OFFERS}`:
                break;
            case `${RoutesConstants.AVAILABLE_BALANCE}`:
                break;
            case `${RoutesConstants.STEPS_REMAINING}`:
                break;
            case `${RoutesConstants.SCAN_QR_CODE}`:
                break;
            case `${RoutesConstants.REGISTER_FEEDBACK}`:
                break;
            case `${RoutesConstants.CHECK_AVAILABLE_BALANCE}`:
                break;
            case RoutesConstants.LOAN_ACTIVE_PAGE:
                break;
            case `${RoutesConstants.DISCOVERY_FEEDBACK}`:
                break;
            case `${RoutesConstants.EXISTING_ACCOUNT}`:
                break;
            case `${RoutesConstants.CAMERA_PAGE}`:
                break;
            case `${RoutesConstants.PURCHASE_CANCELED}`:
                break;
            case `${RoutesConstants.VALIDATION_ERROR}`:
                break;
            case `${RoutesConstants.CROSS_DEVICE}`:
                break;
            case `${RoutesConstants.HASH_EXPIRED}`:
                break;
            case `${RoutesConstants.VERIFY_EMAIL_CODE}`:
                break;
            case `${RoutesConstants.VERIFY_PASSWORD_CODE}`:
                break;
            case `${RoutesConstants.VERIFY_PASSWORD_SUCCESSFUL_PAGE}`:
                break;
            case `${RoutesConstants.MINIMUM_PURCHASE_PRICE_PAGE}`:
                break;
            case `${RoutesConstants.LOAN_PAY_METHOD}`:
                break;
            case `${RoutesConstants.LOAN_CARD_LOAD}`:
                break;
            case `${RoutesConstants.LOAN_PAY_METHOD_BNPL}`:
                break;
            case `${RoutesConstants.LOAN_CARD_LOAD_BNPL}`:
                break;
            case `${RoutesConstants.LOAN_OFFER_SELECTION}`:
                break;
            case `${RoutesConstants.LOAN_SUMMARY_PAGE}`:
                break;
            case `${RoutesConstants.LOAN_DETAILS_PAGE}`:
                break;
            case `${RoutesConstants.LOAN_LAST_DIFFERENTIAL_SCREEN}`:
                break;
            case `${RoutesConstants.LOAN_LAST_SATISFACTORY_SCREEN}`:
                break;
            default:
                return <Footer />;
        }
    };

    return <>{footer}</>;
};
