import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { ButtonCustom } from '../../Buttons/ButtonCustom';
import {
    ButtonContainer,
    Description,
    DescriptionContainer,
    Faq,
    IconContainer,
    LinkButton,
    Service,
    ServiceContainer,
    Title
} from './FeaturedService.styled';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const resolveCtaProps = ({ label, props }, isDesktop) => {
    if ((props?.showMobile && !isDesktop) || (props?.showDesktop && isDesktop)) {
        if (props?.variant === 'button') {
            return (
                <ButtonCustom
                    {...props}
                    text={label}
                    margin="40px 0 0 0"
                    bg="transparent"
                    color="inherit"
                    border
                    icon={<ArrowForwardIcon />}
                />
            );
        }
        return (
            <LinkButton onClick={props.action}>
                {label}
                <ArrowForwardIcon />
            </LinkButton>
        );
    }
};

export const FeaturedService = ({ serviceProps, faqProps, children }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const { icon = false, featureTitle, description, descriptionSecondLine, callToAction, ...props } = serviceProps;

    return (
        <ServiceContainer {...props}>
            <Service>
                <Stack spacing={2}>
                    {icon && <IconContainer>{icon}</IconContainer>}
                    <Title>{featureTitle}</Title>
                </Stack>
                <DescriptionContainer>
                    <Description>{description}</Description>
                    <Description>{descriptionSecondLine}</Description>
                    {callToAction?.map((cta) => resolveCtaProps(cta, desktop))}
                </DescriptionContainer>
            </Service>
            <Faq className={icon ? 'icon' : ''}>
                {children}
                {desktop && faqProps?.showButtons && callToAction && (
                    <ButtonContainer>
                        {callToAction?.map((cta, i) => (
                            <ButtonCustom
                                key={i}
                                {...cta.props}
                                text={cta.label}
                                bg="transparent"
                                color="inherit"
                                border
                                icon={<ArrowForwardIcon />}
                            />
                        ))}
                    </ButtonContainer>
                )}
            </Faq>
        </ServiceContainer>
    );
};
