import { useEffect, useState } from "react";
import { getAllowedMethodsService } from "../../../Services/PaymentMethodService";
import { BNPLConstants } from '../../../Constants/BNPLConstants';
import { useNavigate } from "react-router-dom";
import { RoutesConstants } from "../../../Constants/RoutesConstants";

export const useAllowedPaymentMethods = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [dni, setDni] = useState(null);
    const [sexTypeId, setSexTypeId] = useState(null);
    const [allowedPaymentMethods, setAllowedPaymentMethods] = useState(null);

    useEffect(() => {
        const dataAsJson = globalThis.localStorage.getItem(BNPLConstants.PERSON_DATA);
        const data = JSON.parse(dataAsJson);
        if (data && data.dni) {
            setDni(data.dni);
            setSexTypeId(data.gender);
        } else {
            navigate(RoutesConstants.PUBLIC_HOME)
        }
    }, [BNPLConstants.PERSON_DATA]);

    useEffect(() => {
        const serviceCall = async (paramDni, paramSexTypeId) => {
            try {
                const response = await getAllowedMethodsService({
                    dni: paramDni,
                    sexTypeId: paramSexTypeId
                });
                setAllowedPaymentMethods(response);
            } catch (error) {
                setError(true)
            }
        };

        dni && sexTypeId && serviceCall(dni, sexTypeId);
    }, [dni, sexTypeId]);

    return {
        allowedPaymentMethods,
        error
    };
}