import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { RoutesConstants } from '../Constants/RoutesConstants';

const GridMain = styled(Grid)(() => ({
    backgroundImage: `url("fondo.svg")`,
    // height: '100vh',
    display: 'flex',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    justifyContent: 'center'
}));

const GridDescription = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        margin: ' 20px 0px 20px 24px'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 30px 0px 0px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 5px 0px 0px',
        padding: '0px 95px 0px 0px'
    },
    borderRadius: '40px'
}));

const GridForm = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        padding: '29px 44px',
        borderRadius: '40px 40px 0px 0px'
    },
    [theme.breakpoints.up('md')]: {
        margin: '40px 0px 0px 30px',
        padding: '64px',
        borderRadius: '40px'
    },
    [theme.breakpoints.up('lg')]: {
        margin: '40px 0px 0px 5px',
        padding: '64px'
    },
    display: 'flex',
    justifyContent: 'center',
    height: `90%`,
    // rounded: 'true',
    backgroundColor: 'white'
}));

const Img = styled('img')(() => ({
    maxWidth: '152px'
}));

const HomeLayout = ({ Form, ContainerPanel }) => {
    const routerLocation = useLocation();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const showImage =
        routerLocation.pathname != RoutesConstants.UPDATE_USER_DATA &&
        routerLocation.pathname != RoutesConstants.PROFILE_CHANGE_PASSWORD &&
        routerLocation.pathname != RoutesConstants.NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE;

    return (
        <GridMain
            container
            component="main"
            justifyContent="start"
            sx={{ background: !showImage && !desktop && '#F5FAFF' }}>
            {desktop || showImage ? (
                <GridDescription item xs={12} md={5}>
                    <Img src="directo-logo.svg" />

                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>{ContainerPanel}</Box>
                </GridDescription>
            ) : null}
            <GridForm
                item
                maxHeight="max"
                maxWidth="auto"
                minWidth={'430px'}
                xs={12}
                md={5}
                mb={routerLocation.pathname == RoutesConstants.LOAN_TYPE_OCUPPATION && -3}
                height={!showImage && !desktop && '100% !important'}>
                {Form}
            </GridForm>
        </GridMain>
    );
};

export default HomeLayout;
