export function CameraIcon({ color = 'currentColor', width = '20', height = '20' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.1668 15.8333C19.1668 16.2754 18.9912 16.6993 18.6787 17.0118C18.3661 17.3244 17.9422 17.5 17.5002 17.5H2.50016C2.05814 17.5 1.63421 17.3244 1.32165 17.0118C1.00909 16.6993 0.833496 16.2754 0.833496 15.8333V6.66667C0.833496 6.22464 1.00909 5.80072 1.32165 5.48816C1.63421 5.17559 2.05814 5 2.50016 5H5.8335L7.50016 2.5H12.5002L14.1668 5H17.5002C17.9422 5 18.3661 5.17559 18.6787 5.48816C18.9912 5.80072 19.1668 6.22464 19.1668 6.66667V15.8333Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0002 14.1667C11.8411 14.1667 13.3335 12.6743 13.3335 10.8333C13.3335 8.99238 11.8411 7.5 10.0002 7.5C8.15921 7.5 6.66683 8.99238 6.66683 10.8333C6.66683 12.6743 8.15921 14.1667 10.0002 14.1667Z"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}