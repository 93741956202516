import { StepsConstants } from "../../Constants/StepsConstants";
import { FirstStep, FourthStep, SecondStep, ThirdStep } from "./Steps";

export const StepComponent = (activeStep) => {
    switch (activeStep) {
        case StepsConstants.FIRST_STEP:
            return <FirstStep />;
        case StepsConstants.SECOND_STEP:
            return <SecondStep />;
        case StepsConstants.THIRD_STEP:
            return <ThirdStep />;
        case StepsConstants.FOURTH_STEP:
            return <FourthStep />;
        default:
            return;
    }
};