import { Link } from 'react-router-dom';
import { useMediaQuery, useTheme, Stack, Box, Grid } from '@mui/material';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { error_gradients, TitleContainer, Title } from './ErrorPage.styled';
import { Description, DescriptionForm } from '../../../Components/BNPL';
import ErrorImg from '../../../Assets/feedback.svg';
import { Img } from '../../../Components/ImageContainer';
import STRINGS from '../../../i18n/local/es.json';

const ErrorTitle = () => (
    <TitleContainer>
        <Title>{STRINGS.error.ERROR_TITLE}</Title>
    </TitleContainer>
);

const ErrorDescription = () => <Description title={<ErrorTitle />} returnButton={false} />;

const ErrorForm = ({ desktop }) => (
    <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
        <Box>
            <Grid>
                <DescriptionForm
                    title={STRINGS.error.ERROR_DESCRIPTION}
                    pb={3}
                    paddingBottom={!desktop ? '24px' : '0px'}
                />
            </Grid>
        </Box>
        <Box>
            <Stack mb={!desktop && 24} justifyContent={desktop && 'center'} alignItems={'center'}>
                <Img src={ErrorImg} alt="" />
            </Stack>
        </Box>
        <Box textAlign="center">
            <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>
        </Box>
    </Box>
);

export const ErrorPage = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <BNPLLayout
            ContainerPanel={<ErrorDescription />}
            Form={<ErrorForm desktop={desktop} />}
            layoutProps={{
                mobile: {
                    showDescription: false,
                    background: 'none',
                    gradients: error_gradients,
                    showFullWidthDesciption: true
                },
                desktop: {
                    showFullWidthDesciption: true
                }
            }}
        />
    );
};
