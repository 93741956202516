import { useContext, useEffect, useState } from 'react';
import { ConfirmPhotoLayout } from '../Layout/ConfirmPhotoLayout';
import { InstructionsLayout } from '../Layout/InstructionsLayout';

import { BNPLBiometricContext } from '../../../Contexts/BNPLBiometric/BNPLBiometricContext';
import { StepsConstants } from '../../../Constants/StepsConstants';
import { BiometricDataConstants } from '../../../Constants/BiometricDataConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';

import STRINGS from '../../../i18n/local/es.json';
import { Dialog } from '../../../Components/BNPL';
import { useDialog } from '../../../Hooks/useDialog';
import { INSTRUCTIONS, MODALS } from './constants';
import { CreditCardIcon } from '../../../Components/Icons';

export const FirstStep = () => {
    const { actions, state } = useContext(BNPLBiometricContext);
    const { images } = state;
    const { openCamera, openCamera2, getImage, confirmPhoto } = actions;
    const { isLoading, isRejected } = state;

    const dialog = useDialog();
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (images[StepsConstants.FIRST_STEP].photo) {
            dialog.CloseDialog();
            if (images[StepsConstants.FIRST_STEP].confirmImage) {
                handleOpenModal(MODALS.SECONDSTEP.title, MODALS.SECONDSTEP.content);
            }
        }
    }, [images[StepsConstants.FIRST_STEP]]);

    const handleOpenModal = (title, content) => {
        dialog.setTitle(title);
        dialog.setContent(content);
        dialog.OpenDialog();
    };

    const onContinueToCamera = (step) => {
        openCamera(step);
    };

    const onConfirmPhoto = async () => {
        let data = {
            frontDNI: getImage(StepsConstants.FIRST_STEP),
            entreConsultasImage: [],
            step: StepsConstants.FIRST_STEP,
            biometricDataType: BiometricDataConstants.FRONT_DNI,
            extension: 'jpeg'
        };
        const res = await confirmPhoto(data, RoutesConstants.BIOMETRIC_BNPL_PAGE);
        if (res.ErrorType == 'customError') {
            const error = res.message.split('.');
            setErrorMessage({ title: error[0], description: error[1] });
        } else if (!isRejected) {
            handleOpenModal(MODALS.SECONDSTEP.title, MODALS.SECONDSTEP.content);
        }
    };

    const onRepeatPhoto = () => {
        onContinueToCamera(StepsConstants.FIRST_STEP);
        setErrorMessage(null);
    };

    return (
        <>
            {!getImage(StepsConstants.FIRST_STEP) ? (
                <InstructionsLayout
                    title={STRINGS.biometric.dni.TITLE}
                    description={STRINGS.biometric.dni.DESCRIPTION}
                    instructions={INSTRUCTIONS.dni}
                    onContinue={() => handleOpenModal(
                        STRINGS.biometric.dni.DIALOG_FRONT.TITLE, 
                        {
                            description: STRINGS.biometric.dni.DIALOG_FRONT.DESCRIPTION,
                            buttonText: STRINGS.biometric.dni.DIALOG_FRONT.CTA_CONTINUE,
                            step: StepsConstants.FIRST_STEP,
                            icon: <CreditCardIcon/>
                        }
                    )}
                />
            ) : (
                <ConfirmPhotoLayout
                    title={STRINGS.biometric.dni.CONFIRM_FRONT.TITLE}
                    src={getImage(StepsConstants.FIRST_STEP)}
                    description={STRINGS.biometric.dni.CONFIRM_FRONT.DESCRIPTION}
                    onRepeatCapture={onRepeatPhoto}
                    onConfirm={onConfirmPhoto}
                    error={errorMessage}
                    loading={isLoading}
                />
            )}
            <Dialog
                title={dialog.title}
                description={dialog.content.description}
                buttonText={dialog.content.buttonText}
                open={dialog.showDialog}
                onContinue={() => onContinueToCamera(dialog.content.step)}
                icon={dialog.content.icon}
            />
        </>
    );
};
