import { getData, postData, putData } from './BaseService';
import jwt from 'jwt-decode';
import { setItemService } from './LocalStorageService';
import { RegisterConstantsLS } from '../Contexts/Register/RegisterActions';


export const changePasswordService = async (ecommerceAccountId, newPassword, currentPassword) => {
    const url = 'register/change/password';

    const body = { ecommerceAccountId: ecommerceAccountId, newPassword: newPassword, oldPassword: currentPassword };

    const response = await putData(url, body);

    const result = response.data.message == "OPERACION REALIZADA CORRECTAMENTE";

    return result;
};

export const getDataPersonService = async (personId) => {
    const url = `person/information/${personId}`;

    const response = await getData(url);
    return response

}


export const resendEmailService = async (userEmail) => {
    const response = await changePasswordRequestEmail(userEmail);

    return response;
}


export const changePasswordRequestEmail = async (email) => {
    const url = 'login/forgot_password';
    const response = await postData(url, {email: email});
    
    setItemService(RegisterConstantsLS.UserEmail, email)

    return response;
};

export const tokenTimeValidate = (token) => {
    const dataJwt = jwt(token);
    const dateTime = +new Date();
    const currentUnixTime = Math.floor(dateTime / 1000);

    return currentUnixTime < dataJwt.exp;
};
