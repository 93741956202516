import { Stack, useMediaQuery, useTheme, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { ButtonCustom } from '../../Buttons/ButtonCustom';
import { Logo } from '../../Icons';
import { ArrowForward } from '@mui/icons-material';
import { firstName } from '../../../Helpers/Masks';
import STRINGS from '../../../i18n/local/es.json';
import { useEffect, useState, useContext } from 'react';
import AppContext from '../../../Contexts/Global/AppContext';
import logo from '../../../Assets/complete-logo.svg';

export const Navbar = ({
    inverted = false,
    isLogged = false,
    showButtons = true,
    showUsername = true,
    whiteLogo = false
}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const colorProps = inverted ? { color: 'white' } : { color: ColorConstants.blue[900] };
    const logoProps = mobile ? { width: '152', height: '31' } : { width: '235', height: '48' };
    const [greetUsername, setGreetUserName] = useState('');
    const { user } = useContext(AppContext);

    useEffect(() => {
        if (user && user?.personName) {
            setGreetUserName(`Hola ${firstName(user.personName)}`);
        }
    }, []);

    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                {whiteLogo ? (
                    <img src={logo} width="220px" />
                ) : (
                    <Logo {...colorProps} {...logoProps} />
                )}
                {showButtons && (
                    <Stack direction="row">
                        {!mobile && isLogged && showUsername && (
                            <Stack
                                color="black"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                pr={3}
                                borderRight={`2px solid ${ColorConstants.blue[900]}`}>
                                {greetUsername && (
                                    <Typography
                                        style={{ whiteSpace: 'nowrap' }}
                                        ml={[0, 3]}
                                        fontSize="24px"
                                        fontWeight={700}
                                        color={ColorConstants.blue[900]}
                                        mr={3}>
                                        {greetUsername}
                                    </Typography>
                                )}
                                <ButtonCustom
                                    text={STRINGS.buttons.MY_ACCOUNT}
                                    action={() =>
                                        navigate(RoutesConstants.UPDATE_USER_DATA, {
                                            replace: true
                                        })
                                    }
                                    hover={ColorConstants.blue[900]}
                                    fontSize="14px"
                                    padding="16px 32px"
                                    margin="0"
                                    bg={ColorConstants.blue[500]}
                                    icon={<ArrowForward />}
                                />
                            </Stack>
                        )}
                        {isLogged ? (
                            <Stack ml={3}>
                                <ButtonCustom
                                    text={STRINGS.buttons.LOGOUT}
                                    action={() => navigate(RoutesConstants.PUBLIC_HOME_BNPL)}
                                    bg="transparent"
                                    border
                                    fontSize={mobile ? '14px' : '16px'}
                                    height={mobile ? '32px' : 'auto'}
                                    padding={mobile ? '16px' : '16px 32px'}
                                    margin="0"
                                    {...colorProps}
                                />
                            </Stack>
                        ) : (
                            <ButtonCustom
                                text={STRINGS.buttons.LOGIN}
                                action={() => navigate(RoutesConstants.LOGIN_PAGE)}
                                bg="transparent"
                                border
                                fontSize={mobile ? '14px' : '16px'}
                                height={mobile ? '32px' : 'auto'}
                                lineHeight={'1.15'}
                                padding={mobile ? '16px' : '16px 32px'}
                                margin="0"
                                {...colorProps}
                            />
                        )}
                    </Stack>
                )}
            </Stack>
            {mobile && isLogged && showButtons && showUsername && (
                <Stack
                    direction="row"
                    color="black"
                    alignItems="center"
                    justifyContent="space-between"
                    borderTop="2px solid white"
                    marginTop={1.5}>
                    <Typography fontSize="30px" fontWeight={700} color={ColorConstants.blue[900]}>
                        {greetUsername}
                    </Typography>
                    <ButtonCustom
                        text={STRINGS.buttons.MY_ACCOUNT}
                        action={() => navigate(RoutesConstants.UPDATE_USER_DATA, { replace: true })}
                        bg={ColorConstants.blue[500]}
                        border
                        fontSize="14px"
                        height="32px"
                        padding="8px 16px"
                        margin="0"
                        hover={ColorConstants.blue[900]}
                        icon={<ArrowForward />}
                    />
                </Stack>
            )}
        </>
    );
};
