import { useContext, useState } from 'react';
import { Box, Paper, Tab } from '@mui/material';
import { FaqCategory } from './components/FaqCategory';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import FrequentQuestionsContext from '../../Contexts/FrequentQuestions/FrequentQuestionsContext';

import STRINGS from '../../i18n/local/es.json';

export const FrequentQuestionsTabs = () => {
    const { frequentQuestionsB2C, frequentQuestionsBNPL } = useContext(FrequentQuestionsContext);
    const [value, setValue] = useState(0);

    const FAQ = [
        {
            tabTitle: STRINGS.FAQ.FAQ_TAB_1,
            tabPanel: frequentQuestionsB2C
        },
        {
            tabTitle: STRINGS.FAQ.FAQ_TAB_2,
            tabPanel: frequentQuestionsBNPL
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper sx={{ borderRadius: '15px' }}>
            <Box>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'center' }}>
                        <TabList
                            centered
                            onChange={handleChange}
                            sx={{ textAlign: 'center', justifyContent: 'center' }}>
                            {frequentQuestionsB2C && frequentQuestionsBNPL &&
                                FAQ.map((tab, index) => (
                                    <Tab
                                        key={index}
                                        label={tab.tabTitle}
                                        value={index}
                                        sx={{ textAlign: 'center' }}
                                    />
                                ))}
                        </TabList>
                    </Box>
                    <TabPanel value={value}>
                        {frequentQuestionsB2C && frequentQuestionsBNPL &&
                            FAQ[value].tabPanel.map((tab, index) => {
                                return (
                                    <FaqCategory
                                        key={index}
                                        title={tab.faqCategory}
                                        faqs={tab.faQs}
                                    />
                                );
                            })}
                    </TabPanel>
                </TabContext>
            </Box>
        </Paper>
    );
};
