import { useNavigate, useLocation } from 'react-router-dom';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { BoxContent } from './RedirectToDirectoPage.styled';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { DollarIcon, HashIcon } from '../../Components/Icons';
import { Description, OperationDetails } from '../../Components/BNPL';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import STRINGS from '../../i18n/local/es.json';
import { ALLOWED_REDIRECT_PARAMS } from '../../Helpers/GetURLByParam';
import { useContext, useEffect } from 'react';
import AppContext from '../../Contexts/Global/AppContext';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { moneyMask } from '../../Helpers/Masks';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';

const RedirectForm = ({ title, subtitle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { authentication, removeSession } = useContext(AppContext);
    const { orderData } = useContext(BNPLOrderContext);
    const drmerchant = localStorage.getItem("drmerchant");

    useEffect(() => {
        removeSession();
        localStorage.setItem("BNPLURL", location.pathname + location.search);
    }, []);

    const OPERATION_DETAILS = [
        {
            title: 'Número de orden',
            description: orderData.merchant_Order_Id,
            icon: <HashIcon />
        },
        {
            title: 'Comercio:',
            description: orderData.merchant_Name,
            src: '/tienda_online.svg'
        },
        {
            title: 'Valor de la compra:',
            description: orderData.price && moneyMask(String(orderData.price)),
            icon: <DollarIcon />
        }
    ];

    const handleDontHaveAccountButton = () => {
        localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.BNPL);
        navigate(RoutesConstants.IDENTIFICATION_DNI_PAGE);
    };

    const handleHaveAccountButton = () => {
        localStorage.setItem(BNPLConstants.WORKFLOW, WORKFLOW_CONSTANTS.BNPL);
        if (authentication) {
            navigate(RoutesConstants.IDENTIFICATION_CONTACT_PAGE);
        } else {
            navigate(
                `${RoutesConstants.LOGIN_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.DATOS_CONTACTO}`
            );
        }
    };

    return (
        <Stack width="100%">
            <BoxContent>
                <OperationDetails title={title} subtitle={subtitle} details={OPERATION_DETAILS} />
            </BoxContent>

            <ButtonCustom
                padding="16px"
                text={STRINGS.redirectToDirecto.CONTENT_DONT_HAVE_ACCOUNT_BTN}
                action={handleDontHaveAccountButton}
                id={'checkout-new-account'}
            />

            <ButtonCustom
                border={desktop}
                bg={desktop ? 'transparent' : 'white'}
                color={desktop ? ColorConstants.blue[900] : ColorConstants.blue[500]}
                padding="16px"
                text={STRINGS.redirectToDirecto.CONTENT_HAVE_ACCOUNT_BTN}
                action={handleHaveAccountButton}
                id={'checkout-existing-account'}
            />
            <BackHomeDialogButton id={'back_to_store'} text={drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE} />
        </Stack>
    );
};

export const RedirectToDirectoPage = () => {
    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={STRINGS.redirectToDirecto.TITLE}
                    description={STRINGS.redirectToDirecto.SUBTITLE}
                    returnButton={false}
                />
            }
            Form={
                <RedirectForm
                    title={STRINGS.redirectToDirecto.CONTENT_TITLE}
                    subtitle={STRINGS.redirectToDirecto.CONTENT_SUBTITLE}
                />
            }
            layoutProps={{ mobile: { showDescription: true } }}
        />
    );
};
