import { Typography } from '@mui/material';
import React, { useContext } from 'react';
import FacebookLogin from 'react-facebook-login';
import AppContext from '../../Contexts/Global/AppContext';
import { getURLByParam } from '../../Helpers/GetURLByParam';
const { REACT_APP_FB_APP_ID } = process.env;

const LoginFacebook = ({ isMyProfile = false }) => {
    const { onLoginFacebook, BNPLRegisterFacebook, AssociateFacebookAccount } =
        useContext(AppContext);
        
    const params = new URLSearchParams(location.search);
    const redirectToURL = getURLByParam(params.get('redirectTo'));

    const facebookCallback = () => {
        if (isMyProfile) {
            return AssociateFacebookAccount;
        }
        if (redirectToURL) {
            return BNPLRegisterFacebook(
                redirectToURL,
                redirectToURL
            );
        } else {
            return onLoginFacebook;
        }
    };

    return (
	<div/>
    );
};

export default LoginFacebook;
