import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ColorConstants } from '../../Constants/ColorConstants';
import style from './loginBtn.module.css';

export const LoginEmailButton = (sx) => {
    const navigate = useNavigate();

    return (
        <Button
            className={style.loginBtnParent}
            variant="outlined"
            onClick={() => navigate(RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE)}
            fullWidth
            sx={{
                borderRadius: '30px',
                border: '1px solid #dadce0',
                height: 40,
                px: 0,
                mt: 1,
                mb: 1,
                ':disabled': { color: ColorConstants.BLUE },
                ...sx
            }}>
            <Grid item xs={1} ml={1} justifyContent={'center'} display={'flex'}>
                <MailOutlineIcon />
            </Grid>
            <Grid item xs={11} mr={5} textAlign={'center'}>
                <Typography className={style.loginBtn} fontWeight={500}>
                    Continuar con Mail
                </Typography>
            </Grid>
        </Button>
    );
};
