import React, { useRef } from 'react';
import { Camera } from 'react-camera-pro';
import styled from 'styled-components';
import Fab from '@mui/material/Fab';
import { Grid, Typography, Box } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import FaceMarco from '../../Assets/Images/RostroMacroVertical.png';
import { BiometricDataState } from '../../Contexts/BiometricData/BiometricDataState';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../Constants/StepsConstants';
import { useNavigate, useParams } from 'react-router-dom';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import CloseIcon from '@mui/icons-material/Close';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ScreenOrientationPortrait } from '../../Helpers/GetScreenOrientation';
import { getMobileOperatingSystem } from '../../Utils/DetectOpSystemPhone';
import { DisplaySettings } from '@mui/icons-material';
import style from './dniCapture.module.css';
import { useLayoutEffect } from 'react';
import { cleanSteps } from '../../Services/BiometricDataService';

const Wrapper = styled.div`
    position: fixed;
    /*  top: 13%;
    left: 15%;
    width: 75%;
    height: 70%;
    max-height: 470px;
    max-width: 400px; */
    /*  top: ${window.screen.height * 0.006}rem;
    width: ${window.screen.width * 0.045}rem;
    height: ${window.screen.height * 0.035}rem;
    left: ${window.screen.width * 0.01}rem; */
    /* border-radius: 30px; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    z-index: 10;
`;

const styles = {
    img: {
        position: 'relative',
        height: `${window.screen.height * 0.9}px`,
        top: 0,
        width: `${window.screen.width}px`,
        zIndex: 2
    },
    div: {
        position: 'relative',
        top: '30vh'
    }
};

export const DNICapture2 = () => {
    const camera = useRef(null);
    const { type } = useParams();
    const navigate = useNavigate();

    useLayoutEffect(() => {
        const { width, height } = window.screen;
        window.scrollTo(0, height);
        /* window.document.documentElement.requestFullscreen({ navigationUI: 'hide' }); */
    }, []);

    const setXBottomText = () => {
        if (getMobileOperatingSystem() === 'iOS') {
            return `${
                window.screen.width <= 320
                    ? `-${window.screen.width * 0.053}rem`
                    : `-${window.screen.width * 0.045}rem`
            }`;
        }
        if (getMobileOperatingSystem() === 'Android') {
            return `${
                window.screen.width <= 320
                    ? `-${window.screen.width * 0.053}rem`
                    : `-${window.screen.width * 0.77}px`
            }`;
        }
        if (getMobileOperatingSystem() !== 'iOS' && getMobileOperatingSystem() !== 'Android') {
            return `${
                window.screen.width <= 320
                    ? `-${window.screen.width * 0.035}rem`
                    : `-${window.screen.width * 0.0442}rem`
            }`;
        }
    };

    const setYBottomText = () => {
        if (getMobileOperatingSystem() == 'iOS') {
            return `${
                window.screen.width <= 320
                    ? `${window.screen.height * 0.013}rem`
                    : `${window.screen.height * 0.0152}rem`
            }`;
        } else {
            return `${ScreenOrientationPortrait() ? `${window.screen.height * 0.016}rem` : ''}`;
        }
    };

    const setYTopText = () => {
        if (getMobileOperatingSystem() === 'Android') {
            return `${ScreenOrientationPortrait() ? `-${window.screen.width * 0.42}em` : ''}`;
        } else {
            return `${ScreenOrientationPortrait() ? `-${window.screen.width * 0.36}em` : ''}`;
        }
    };

    const setYBtnTakePhoto = () => {
        if (window.screen.height <= 568) {
            return `5%`;
        }
        if (window.screen.height >= 568 && window.screen.height <= 852) {
            return `10%`;
        }
        if (window.screen.height >= 852 && window.screen.height <= 1024) {
            return `18%`;
        }
    };

    return (
        <DeviceOrientation lockOrientation={'portrait'} sx={{ textAling: 'center' }}>
            <Orientation orientation="portrait" alwaysRender={false}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: '90%',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'flex-start'
                    }}>
                    <BiometricDataState>
                        {/* <Grid
                            sx={{
                                position: 'fixed',
                                zIndex: 10,
                                background: '#000',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                left: 0,
                                opacity: 0.65
                            }}>
                            
                        </Grid> */}
                        <Box sx={{ textAlign: 'center', mr: 5 }}>
                            <Fab
                                size="small"
                                color="secondary"
                                sx={{
                                    background: '#524d4d30 !important',
                                    position: 'absolute',
                                    top: '4%',
                                    right: '7%'
                                }}
                                onClick={() => {
                                    cleanSteps();
                                    navigate(RoutesConstants.BIOMETRIC_DATA_PAGE);
                                }}>
                                <CloseIcon sx={{ color: 'white' }} />
                            </Fab>
                        </Box>
                        <Grid
                            sx={{
                                zIndex: 14,
                                position: 'fixed',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                height: '100%',
                                width: '98%'
                            }}>
                            <Typography className={style.topText}>
                                {type === 'dorso' ? 'Ahora' : 'Primero'} tomá la foto del {type} del
                                DNI
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{
                                zIndex: 14,
                                position: 'fixed',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: '100%',
                                width: '98%'
                            }}>
                            <Typography className={style.bottomText}>
                                Recordá encuadrar tu DNI para que coincida con el marco y salga
                                completo
                            </Typography>
                        </Grid>
                        <Wrapper>
                            <Camera
                                facingMode="environment"
                                ref={camera}
                                aspectRatio="cover"
                                errorMessages={{
                                    noCameraAccessible:
                                        'Ningún dispositivo de cámara accesible. Conecta tu cámara o prueba con otro navegador.',
                                    permissionDenied:
                                        'Permiso Denegado. Por favor actualice y dé permiso a la cámara.',
                                    switchCamera:
                                        'No es posible cambiar la cámara a una diferente porque solo hay un dispositivo de video accesible.',
                                    canvas: 'Canvas no soportado.'
                                }}
                                sx={{ width: '100%', height: '100%' }}
                            />
                        </Wrapper>
                        <BiometricDataContext.Consumer>
                            {(ctx) => {
                                return ctx.setImage ? (
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Fab
                                            size="medium"
                                            color="secondary"
                                            sx={{
                                                position: 'absolute',
                                                top: '85%',
                                                right: '45%',
                                                backgroundColor: 'white'
                                            }}
                                            onClick={() => {
                                                ctx.setImage(
                                                    ctx.images[StepsConstants.FIRST_STEP]
                                                        .confirmImage
                                                        ? StepsConstants.SECOND_STEP
                                                        : StepsConstants.FIRST_STEP,
                                                    camera.current.takePhoto()
                                                );
                                            }}></Fab>
                                    </Box>
                                ) : null;
                            }}
                        </BiometricDataContext.Consumer>
                        <Fab
                            size="small"
                            color="secondary"
                            sx={{
                                background: '#524d4d30 !important',
                                position: 'absolute',
                                top: '85%',
                                right: '7%'
                            }}
                            onClick={() => {
                                if (camera.current) {
                                    camera.current.switchCamera();
                                }
                            }}>
                            <LoopIcon sx={{ color: 'white' }} />
                        </Fab>
                    </BiometricDataState>
                </Box>
            </Orientation>
            <Orientation orientation="landscape" alwaysRender={false}>
                <div style={styles.div}>
                    <p>Por favor, desactiva la rotación automática del dispositivo</p>
                </div>
            </Orientation>
        </DeviceOrientation>
    );
};
