import { useEffect, useState } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { useContext } from 'react';
import QRCode from 'react-qr-code';
import LoanRequestContext from '../../Contexts/LoanRequest/LoanRequestContext';

const QRCodeGenerator = () => {
    const { getTokenChangeDevice } = useContext(LoanRequestContext);
    const [token, setToken] = useState();
    const [eaid, setEaid] = useState();

    useEffect(() => {
        const getToken = async () => {
            const tokenResponse = await getTokenChangeDevice();
            setToken(tokenResponse);
        };

        getToken();

        const ecommerceAccountId = localStorage.getItem('ecommerceAccountId');
        setEaid(ecommerceAccountId);
    }, []);

    const qrUrl = `https://${window.location.host}/cambio-movil/${token}?ecommerceAccountId=${eaid}`;
    return (
        <Box display="flex" justifyContent="center" minHeight={250}>
            {token ? <QRCode value={qrUrl} /> : null}
        </Box>
    );
};

export const QRCodeImage = () => {
    return (
        <Grid>
            <Typography fontWeight={'bold'} color={'#004489'} mb={4}>
                Escaneá el código QR
            </Typography>

            <QRCodeGenerator />
        </Grid>
    );
};
