import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

const StyledTextField = styled(TextField)`
    && {
        width: 100%;
        &.iconStart {
            .MuiInputLabel-outlined {
                padding-left: 30px;
            }

            .MuiInputLabel-shrink {
                padding-left: 0;
            }
        }
        .MuiInputBase-input,
        .Mui-focused {
            color: ${ColorConstants.blue[900]};
        }
        fieldset {
            border-radius: 16px;
            border: 1.5px solid ${ColorConstants.gray[200]};
        }

        &:hover fieldset,
        .Mui-focused fieldset {
            border-color: ${ColorConstants.blue[100]};
        }
        .Mui-error {
            color: ${ColorConstants.gray[700]};
            fieldset {
                border: 2px solid ${ColorConstants.error[400]};
            }
        }
        .passwordBtn {
            cursor: pointer;
        }
        input[type='number'] {
            -moz-appearance: textfield;
        }
        input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }
    }
`;

export default StyledTextField;