import { useContext, useEffect, useState } from 'react';
import { BiometricDataConstants } from '../../../Constants/BiometricDataConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { StepsConstants } from '../../../Constants/StepsConstants';
import { BNPLBiometricContext } from '../../../Contexts/BNPLBiometric/BNPLBiometricContext';
import { ConfirmPhotoLayout } from '../Layout/ConfirmPhotoLayout';
import { InstructionsLayout } from '../Layout/InstructionsLayout';

import { GestureFaceConstants } from '../../../Constants/GestureFaceConstants';

import { Dialog } from '../../../Components/BNPL';
import { useDialog } from '../../../Hooks/useDialog';

import STRINGS from '../../../i18n/local/es.json';
import { INSTRUCTIONS, MODALS } from './constants';

export const ThirdStep = () => {
    const { actions, state } = useContext(BNPLBiometricContext);
    const { images, isLoading, gestureType } = state;
    const { openCamera, openCamera2, getImage, confirmPhoto } = actions;
    const dialog = useDialog();
    const [errorMessage, setErrorMessage] = useState(null);

    const getModalDataToRender = () => {
        switch (gestureType) {
            case BiometricDataConstants.SMILE_FACE:
                return MODALS.FOURTHSTEP.SMILE;
            case BiometricDataConstants.WINK_FACE:
                return MODALS.FOURTHSTEP.WINK;
            case BiometricDataConstants.OPEN_MOUTH_FACE:
                return MODALS.FOURTHSTEP.OPEN_MOUTH;
            default:
                return MODALS.FOURTHSTEP.SMILE;
        }
    };

    useEffect(() => {
        if (images[StepsConstants.THIRD_STEP].photo) {
            dialog.CloseDialog();
            if (images[StepsConstants.THIRD_STEP].confirmImage) {
                const modalData = getModalDataToRender();
                handleOpenModal(modalData.title, modalData.content);
            }
        }
    }, [images[StepsConstants.THIRD_STEP]]);

    const handleOpenModal = (title, content) => {
        dialog.setTitle(title);
        dialog.setContent(content);
        dialog.OpenDialog();
    };

    const onContinueToCamera = (step) => {
        openCamera2(step);
    };

    const onConfirmPhoto = async () => {
        let data = {
            frontDNI: getImage(StepsConstants.FIRST_STEP),
            backDNI: getImage(StepsConstants.SECOND_STEP),
            entreConsultasImage: [
                {
                    image: getImage(StepsConstants.THIRD_STEP),
                    typeGesturePhoto: GestureFaceConstants.NEUTRAL_FACE
                }
            ],
            step: StepsConstants.THIRD_STEP,
            biometricDataType: BiometricDataConstants.NEUTRAL_FACE,
            extension: 'jpeg'
        };

        const res = await confirmPhoto(data, RoutesConstants.BIOMETRIC_BNPL_PAGE);
        if (res?.data?.response?.status === 'true') {
            const modalData = getModalDataToRender();
            handleOpenModal(modalData.title, modalData.content);
        }
        if (res.ErrorType == 'customError') {
            const error = res.message.split('.');
            setErrorMessage({ title: error[0], description: error[1] });
        }
    };

    const onRepeatPhoto = () => {
        onContinueToCamera(StepsConstants.THIRD_STEP);
        setErrorMessage(null);
    };

    return (
        <>
            {!getImage(StepsConstants.THIRD_STEP) ? (
                <InstructionsLayout
                    title={STRINGS.biometric.selfie.TITLE}
                    description={STRINGS.biometric.selfie.DESCRIPTION}
                    instructions={INSTRUCTIONS.selfie}
                    onContinue={() =>
                        handleOpenModal(MODALS.THIRDSTEP.title, MODALS.THIRDSTEP.content)
                    }
                    variant="selfie"
                />
            ) : (
                <ConfirmPhotoLayout
                    title={STRINGS.biometric.selfie.CONFIRM_NEUTRAL.TITLE}
                    variant="selfie"
                    src={getImage(StepsConstants.THIRD_STEP)}
                    description={STRINGS.biometric.selfie.CONFIRM_NEUTRAL.DESCRIPTION}
                    onRepeatCapture={onRepeatPhoto}
                    onConfirm={onConfirmPhoto}
                    error={errorMessage}
                    loading={isLoading}
                />
            )}
            <Dialog
                title={dialog.title}
                description={dialog.content.description}
                buttonText={dialog.content.buttonText}
                open={dialog.showDialog}
                onContinue={() => onContinueToCamera(dialog.content.step)}
                image={dialog.content.image}
                icon={dialog.content.icon}
            />
        </>
    );
};
