import { Box, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const OptionContainer = styled(Box)`
    border-top: 1px solid ${ColorConstants.gray[200]};
    padding: 6px 8px;
`;

export const Option = styled(Box)`
    cursor: pointer;
    background: linear-gradient(117.06deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 99.28%);
    border-radius: 32px;
    padding: 12px 16px;
    &:hover {
        outline: 1px solid ${ColorConstants.gray[200]};
    }
    &.selected {
        transition: 0.5s;
        outline: 1px solid ${ColorConstants.green[500]};
    }
`;
