import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography, Grid} from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { getSimpleData } from '../../../Helpers/GetDetailOfLoanRequest';
import { moneyMask } from '../../../Helpers/Masks';
import parse from 'html-react-parser';
import * as DOMPurify from 'dompurify';

export const AccordionDetailsLoan = ({config, data}) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
    
    return (
        <div>
            {data && 
                <Accordion elevation={0} expanded={expanded === 'panel'} 
                onChange={handleChange('panel')}>
                    <AccordionSummary
                    sx={{ 
                    background: `${ColorConstants.MIDDLE_LIGHT_BLUE}`
                    }}
                    expandIcon={<ExpandMoreIcon className="colorIcon"/>}
                    aria-controls="panelbh-content"
                    id="panelbh-header">
                        <Typography 
                            variant="h7" 
                            textAlign="start"
                            fontFamily="Archivo"
                            component="h5" 
                            ml={1.6}
                            className='font-blue font-weight'>
                                {config.title}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{background: `${ColorConstants.MIDDLE_LIGHT_BLUE}`, 
                    border: `1px solid ${ColorConstants.MIDDLE_LIGHT_BLUE}`,
                    mt: -1.5
                    }}>
                        {config.nameOfSection != 'requestTyC' ? 
                        config.sections && config.sections.map((s, index) => { 
                            return (
                            <Grid container key={index}>
                                <Grid item xs={12}>
                                    { s.title && 
                                        <Typography 
                                            variant="h7" 
                                            textAlign="start"
                                            fontFamily="Archivo"
                                            component="h6" 
                                            ml={1.6}
                                            className='font-blue'
                                            mt={2}
                                            >
                                                {s.title}
                                        </Typography>
                                    }
                                </Grid>
                                {s.properties && s.properties.map(p => {
                                    return ( p.label != 'Vencimiento/s:' ? 
                                    <Grid container key={p.label}>
                                        <Grid item xs={s.columns == 2 ? 6 : 12}>
                                            <Typography 
                                                variant="h7" 
                                                textAlign="start"
                                                fontFamily="Archivo"
                                                component="h6"
                                                fontWeight="Normal"
                                                ml={1.6}
                                                className='font-blue'>
                                                    {p.label} {s.columns == 2 ? '' : getSimpleData(p.field, data, config.nameOfSection)}
                                            </Typography>
                                        </Grid>
                                        {s.columns == 2 && 
                                            <Grid item xs={6}>
                                                <Typography 
                                                    variant="h7" 
                                                    textAlign={s.textAlign}
                                                    fontFamily="Archivo"
                                                    component="h6" 
                                                    fontWeight="Normal"                                                
                                                    mr={0.5}
                                                    className='font-blue'>
                                                        {getSimpleData(p.field, data, config.nameOfSection) ? getSimpleData(p.field, data, config.nameOfSection) : '' }
                                                </Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    : 
                                    <Grid container key={p.label}>
                                        <Grid item xs={12}>
                                            <Typography 
                                                variant="h7" 
                                                textAlign="start"
                                                fontFamily="Archivo"
                                                component="h6"
                                                fontWeight="Normal"
                                                ml={1.6}
                                                className='font-blue'>
                                                    {p.label}
                                            </Typography>
                                        </Grid>

                                        {getSimpleData(p.field, data, config.nameOfSection) &&  getSimpleData(p.field, data, config.nameOfSection).map( d =>{
                                            return (
                                            <Grid container key={d}>
                                                <Grid item xs={s.columns == 2 ? 7 : 12}>
                                                    <Typography 
                                                        variant="h7" 
                                                        textAlign="start"
                                                        fontFamily="Archivo"
                                                        component="h6"
                                                        fontWeight="Normal"
                                                        ml={1.6}
                                                        className='font-blue'>
                                                            {d}:
                                                    </Typography>
                                                </Grid>
                                                {s.columns == 2 && 
                                                    <Grid item xs={5}>
                                                        <Typography 
                                                            variant="h7" 
                                                            textAlign="end"
                                                            fontFamily="Archivo"
                                                            component="h6"
                                                            fontWeight="Normal" 
                                                            mr={0.5}
                                                            className='font-blue'>
                                                                {moneyMask(getSimpleData(p.secondField, data, config.nameOfSection))}
                                                        </Typography>
                                                    </Grid>
                                                }
                                            </Grid>
                                            )})    
                                        }
                                    </Grid>                                 
                                )})
                                }
                            </Grid>);
                        })
                        :
                        <Grid>
                            <Typography 
                            variant="h7" 
                            textAlign="start"
                            fontFamily="Archivo"
                            component="h6"
                            fontWeight="Normal" 
                            mr={0.5}
                            ml={1.5}
                            className='font-blue'>
                                {parse(DOMPurify.sanitize(data.maxRequestTyC))}
                            </Typography>
                        </Grid>
                    }
                    </AccordionDetails>
                </Accordion>
            }
        </div>
    )
}
