import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { CardActiveLoanDetail } from './CardActiveLoanDetail';
import { LoanActiveState } from '../../Contexts/LoanActive/LoanActiveState';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import { LoanEvolution } from './LoanEvolution';
import DownloadContainer from './DownloadContainer';
import { DynamicGradientBackground, Footer, Navbar } from '../../Components/BNPL';
import {
    ActiveLoanBackground,
    ActiveLoanContainer,
    Container,
    Header,
    loan_gradients
} from './ActiveLoanGeneralPage.styled';

export const ActiveLoanContent = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <LoanActiveState>
            <Grid container>
                <Grid item sm={2}></Grid>
                <Grid item xs={10} sm={8} ml={desktop ? 3 : 4}>
                    <LoanActiveContext.Consumer>
                        {(ctx) => {
                            return ctx.creditDetail ? (
                                <CardActiveLoanDetail
                                    list={false}
                                    size={desktop ? '40px' : '18px'}
                                    sizeQuoteExpression={!desktop && '12px'}
                                    sizeQuotes={!desktop && '14px'}
                                    credit={ctx?.creditDetail.credits}
                                />
                            ) : (
                                ''
                            );
                        }}
                    </LoanActiveContext.Consumer>
                </Grid>
                <Grid item xs={1} sm={2}></Grid>
                <Grid container spacing={desktop ? 10 : 2} ml={desktop ? -8 : 4}>
                    <Grid item sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                    <DownloadContainer desktop={desktop} />
                    <Grid xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}></Grid>

                    <Grid item xs={10} sm={5} md={4} mb={3}>
                        <LoanEvolution />
                    </Grid>
                    <Grid sm={2} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
                </Grid>
            </Grid>
        </LoanActiveState>
    );
};

export const ActiveLoanGeneralPage = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <DynamicGradientBackground
            masked
            gradients={desktop ? loan_gradients.desktop : loan_gradients.mobile}
            width="100%">
            <Header mb={[-4, 0]}>
                <Container width="100%">
                    <Navbar isLogged showUsername={desktop} />
                </Container>
            </Header>
            <ActiveLoanBackground>
                <ActiveLoanContainer>
                    <ActiveLoanContent />
                    <Footer />
                </ActiveLoanContainer>
            </ActiveLoanBackground>
        </DynamicGradientBackground>
    );
};