import { RoutesConstants } from '../Constants/RoutesConstants';

const disabledToastRoutes = [
    RoutesConstants.BIOMETRIC_BNPL_PAGE,
    RoutesConstants.FINANCING_PAGE,
    RoutesConstants.FINANCING_OFFER_PAGE,
    RoutesConstants.MINIMUM_PURCHASE_PRICE_PAGE,
];

export const validateToastRoute = (pathname) => {
    return disabledToastRoutes.includes(pathname);
};
