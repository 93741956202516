import React from 'react'
import { DialogContainer } from './Dialog.styled'
import { Typography } from '@mui/material'
import { ColorConstants } from '../../../Constants/ColorConstants'
import { useRedirectTimer } from '../../../Hooks/useRedirectTimer'

export const ErrorDialog = ({open,url,children}) => {
    const timer = useRedirectTimer(process.env.REACT_APP_CONTINUE_PURCHASE_TIMEOUT, url, true);
    const minutes = Math.floor(timer.remainingSeconds / 60);
    const seconds = timer.remainingSeconds % 60;
  return (
    <DialogContainer open={open} className='error'>
            {children}
            <Typography color={ColorConstants.blue[900]} textAlign="center">
                En {`${minutes}:${seconds.toString().padStart(2, '0')}`} serás enviado a la tienda.
            </Typography>
    </DialogContainer>
  )
}
