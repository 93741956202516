import { Box } from '@mui/material';
import { usePromiseTracker } from 'react-promise-tracker';
import { ClipLoader } from 'react-spinners';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const Spinner = () => {
    const { promiseInProgress } = usePromiseTracker();
    if(window.location.pathname !== RoutesConstants.SCAN_QR_CODE)
    return (
        <>
            {promiseInProgress == true ? (
                <Box
                    sx={{
                        display: 'flex',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        position: 'fixed',
                        width: '100%',
                        height: '100%',
                        opacity: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '9999999',
                        transition: 'all 1s ease-in-out'
                    }}>
                    <ClipLoader
                        size="120px"
                        speedMultiplier={1.4}
                        loading={promiseInProgress}
                        cssOverride={{
                            display: 'block',
                            margin: '0 auto',
                            borderColor: 'black',
                            zIndex: '9999',
                            opacity: '1',
                            transition: 'all 1s ease-in-out'
                        }}></ClipLoader>
                </Box>
            ) : null}
        </>
    );
};
