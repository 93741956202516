import { TabPanel as MuiTabPanel } from '@mui/lab';
import { Box, Tab as MuiTab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const TabPanel = styled(MuiTabPanel)`
    padding: 0;
`;

export const Tab = styled(MuiTab)`
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: ${ColorConstants.blue[900]};
    &.Mui-selected {
        font-weight: 600;
    }
`;

export const TabContainer = styled(Box)`
    border-bottom: 1px solid ${ColorConstants.blue[100]};
`

export const TabPanelContainer = styled(Box)`
    margin-top: 32px;
`