export function CheckIcon({ color = "currentColor", width = "12", height = "9" }) {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 12 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6663 1.5L4.24967 7.91667L1.33301 5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }