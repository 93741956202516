import { Box, Grid } from '@mui/material';
import React, { useContext } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { Img } from '../../../Components/ImageContainer';
import ThumbsUpImg from '../../../Assets/feedback-successful-payment.svg';
import RegistrationLoginButtons from './RegistrationAndLoginButtons';
import AppContext from '../../../Contexts/Global/AppContext';
import { MetaPixel } from '../../../Components/MetaPixel/MetaPixel';

export const DifferentialFeedback = () => {
    const { authentication } = useContext(AppContext);

    const title = '¡Ahora nos toca trabajar a nosotros!';
    const description =
        'Vamos a estar revisando los datos enviados y luego te contactaremos para que puedas disfrutar de tu préstamo.';
    return (
        <Box textAlign="center">
            <MetaPixel />
            <DescriptionForm
                title={title}
                description={description}
                fontFamilyDescription="Public Sans"
            />

            <Box my={!authentication ? 0 : '20%'} display="flex" justifyContent="center">
                <Img src={ThumbsUpImg} />
            </Box>

            {!authentication ? <RegistrationLoginButtons /> : null}
        </Box>
    );
};
