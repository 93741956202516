import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0039a6'
        },
        secondary: {
            main: '#0069D7'
        },
        info: {
            main: '#CEE6FF'
        }
    },
    typography: {
        fontFamily: 'Open Sans',
        h1: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            fontSize: 48,
            fontWeight: 600,
            lineHeight: '52px',
            color: '#0039a6'
            // letterSpacing: '-0.96px',
        },
        h2: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            fontSize: 24,
            fontWeight: 600,
            lineHeight: '30px',
            color: '#0039a6'
            // letterSpacing: '-0.48px',
        },
        h3: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            fontSize: 22,
            fontWeight: 600,
            lineHeight: '28px',
            color: '#0039a6'
            // letterSpacing: '-0.44px',
        },
        h4: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            color: '#0039a6'
        },
        h5: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            color: '#0039a6'
        },
        h6: {
            fontFamily: 'Cocogoose, Open Sans, Public Sans, sans-serif',
            color: '#0039a6'
        },
        subtitle1: {
            fontSize: 20,
            lineHeight: '30px',
        },
        subtitle2: {
            fontSize: 16,
            lineHeight: '24px',
        },
        button: {
            fontSize: 16,
            textTransform: "none",
            fontFamily: 'Archivo'
        }
    },
    
});
