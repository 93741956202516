import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, Button } from '@mui/material';
import BackHomeButton from '../../Components/Buttons/BackHomeButton';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
    emailValidate,
    phoneNumberCompleteValidate,
    phoneCodeCompleteValidate
} from '../../Helpers/Validations';
import { ValidateEmailAndExecuteRegister } from '../../Helpers/ValidateEmailAndExecute';
import MyProfileContext from '../../Contexts/MyProfile/MyProfileContext';
import ErrorLabel from '../../Components/Form/ErrorLabel';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import SocialAccountsButtons from '../../Components/Buttons/socialAccountsButtons';
import { ColorConstants } from '../../Constants/ColorConstants';
import LoginGoogle from '../../Components/LoginBtns/LoginGoogle';
import LoginFacebook from '../../Components/LoginBtns/LoginFacebook';
import ErrorModal from '../../Components/Modal/ErrorModal';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import style from './dataUpdateForm.module.css';
import { width } from '@mui/system';

export const DataUpdateForm = ({ personData }) => {
    const {
        dataUpdate,
        successfulUpdate,
        setSuccessfulUpdate,
        errorUpdatePhoneNumber,
        setErrorUpdatePhoneNumber,
        notConnection,
        setNotConnection,
        accessMethod
    } = useContext(MyProfileContext);
    const facebookButonRef = useRef();
    const googleButtonRef = useRef();
    const navigate = useNavigate();
    const [values, setValues] = useState({
        email: '',
        phoneNumber: '',
        phoneCode: ''
    });

    const [errorEmail, setErrorEmail] = useState(false);
    const [errorPhoneNumberComplete, setErrorPhoneNumberComplete] = useState(false);
    const [errorPhoneCodeComplete, setErrorPhoneCodeComplete] = useState(false);
    const [text, setText] = useState('Guardar cambios');
    const [isModified, setIsModified] = useState(false);

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));
    const sm = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        setValues({
            ...values,
            email: personData?.email,
            phoneNumber: personData?.phoneNumber,
            phoneCode: personData?.phoneCode
        });
    }, [personData]);

    const validateFields = () => {
        values.email != '' && setErrorEmail(!emailValidate(values.email));
        values.phoneNumber != '' &&
            setErrorPhoneNumberComplete(!phoneNumberCompleteValidate(values.phoneNumber));
        values.phoneCode != '' &&
            setErrorPhoneCodeComplete(!phoneCodeCompleteValidate(values.phoneCode));
    };

    const disableSubmit = (values) => {
        if (values.email != '' && values.phoneNumber != '') {
            return errorEmail || successfulUpdate || errorPhoneNumberComplete || !isModified;
        }
        return true;
    };

    const handleCloseModal = () => {
        setNotConnection(false);
    };

    const handleChange = (prop) => (event) => {
        !isModified && setIsModified(true);
        if (successfulUpdate) setSuccessfulUpdate(false);
        if (errorUpdatePhoneNumber) setErrorUpdatePhoneNumber(false);
        setValues({ ...values, [prop]: event.target.value.replace(/\s/g, '') });
    };

    const handleTextButton = () => {
        setTimeout(() => {
            setText('Listo');
        }, 1000);

        setTimeout(() => {
            setText('Guardar cambios');
        }, 3000);
    };

    const onSubmit = () => {
        if (emailValidate(values.email)) {
            ValidateEmailAndExecuteRegister(values.email, () => dataUpdate(values));
            handleTextButton();
        } else {
            setErrorEmail(true);
        }
    };

    const errorPhoneNumberMessage = 'El N° celular, no es válido';
    const errorPhoneCodeMessage = 'El Cód. Área, no es válido';
    const errorEmailMessage = 'El email no es válido';

    return (
        <Grid display={'flex'} flexWrap={'wrap'} alignContent={'space-between'} height={'480px'}>
            <Box width={'100%'}>
                <Grid container>
                    <Grid item xs={4} my={1}>
                        <FormControl fullWidth sx={{ borderRadius: '40px' }}>
                            <InputLabel htmlFor="phoneCode">Cód. Área</InputLabel>
                            <OutlinedInput
                                id="phoneCode"
                                value={values.phoneCode}
                                onChange={handleChange('phoneCode')}
                                onKeyUp={() => validateFields()}
                                sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                                color="info"
                                error={errorPhoneCodeComplete}
                                label="Cód. Área"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Typography mt={1}>
                                            <LocalPhoneOutlinedIcon />
                                        </Typography>
                                        <Typography pl={1}>0 -</Typography>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {errorPhoneCodeComplete && (
                            <ErrorLabel description={errorPhoneCodeMessage} />
                        )}
                    </Grid>
                    <Grid item xs={7} my={1} ml={sm && !desktop ? 2 : 1}>
                        <FormControl fullWidth sx={{ borderRadius: '40px', width: '111%' }}>
                            <InputLabel htmlFor="phoneNumber">N° celular</InputLabel>
                            <OutlinedInput
                                id="phoneNumber"
                                value={values.phoneNumber}
                                onChange={handleChange('phoneNumber')}
                                onKeyUp={() => validateFields()}
                                sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                                color="info"
                                error={errorPhoneNumberComplete || errorUpdatePhoneNumber}
                                label="N° celular"
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Typography pl={1}>15 -</Typography>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {(errorPhoneNumberComplete || errorUpdatePhoneNumber) && (
                            <ErrorLabel description={errorPhoneNumberMessage} />
                        )}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth sx={{ mt: 1, borderRadius: '40px' }}>
                        <InputLabel htmlFor="email">Ingresá tu email</InputLabel>
                        <OutlinedInput
                            id="email"
                            value={values.email}
                            onChange={handleChange('email')}
                            onBlur={() => validateFields()}
                            sx={{ borderRadius: '16px', color: ColorConstants.BLUE }}
                            color="info"
                            error={errorEmail}
                            readOnly={personData.emailAccountId != null}
                            disabled={personData.emailAccountId != null}
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailOutlinedIcon />
                                </InputAdornment>
                            }
                            label="Ingresá tu email"
                        />
                    </FormControl>
                    {errorEmail && <ErrorLabel description={errorEmailMessage} />}
                </Grid>
                <GrayButton
                    text={text}
                    action={() => onSubmit()}
                    disabled={disableSubmit(values)}
                />
                <GrayButton
                    text={'Cambiar contraseña'}
                    color={'primary'}
                    outlined
                    pt={1}
                    disabled={accessMethod != 'MAIL'}
                    action={() => navigate(RoutesConstants.PROFILE_CHANGE_PASSWORD)}
                />
            </Box>
            <Box width="100%">
                <Box
                    sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}>
                    <Button
                        disableRipple={personData.googleAccountId ? true : false}
                        sx={{
                            backgroundColor: `${
                                personData.googleAccountId ? '#cac7c73d' : 'transparent'
                            }`,
                            borderRadius: '50px',
                            position: 'absolute',
                            zIndex: '99',
                            width: '100%',
                            height: '100%',
                            cursor: `${personData.googleAccountId ? 'unset' : 'pointer'}`,
                            '&:hover': {
                                backgroundColor: `${
                                    personData.googleAccountId ? '#cac7c73d' : 'transparent'
                                }`
                            }
                        }}
                        disabled={!personData.googleAccountId}></Button>
                    <LoginGoogle isMyProfile personData={personData} />
                    <img
                        className={style.associated}
                        src={personData.googleAccountId ? 'associatedAndCheck.svg' : ''}></img>
                </Box>
                {/* <SocialAccountsButtons
                    google
                    associated={personData.googleAccountId}
                    action={() => googleButtonRef.current.firstChild.click()}
                /> */}
                <SocialAccountsButtons
                    associated={personData.facebookAccountId}
                    action={() => {
                        facebookButonRef.current.children[0].children[1].click();
                    }}
                />

                <BackHomeButton />
            </Box>
            <Box display="none">
                <Box mt={1} height={50} ref={googleButtonRef} isMyProfile>
                    <LoginGoogle isMyProfile personData={personData} />
                </Box>
                <Box mt={2} height={50} ref={facebookButonRef} isMyProfile>
                    <LoginFacebook isMyProfile />
                </Box>
            </Box>
            {notConnection && (
                <ErrorModal open={notConnection} handleOpen={() => handleCloseModal()}></ErrorModal>
            )}
        </Grid>
    );
};
