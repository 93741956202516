import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DollarIcon, ShoppingBagIcon } from '../../Components/Icons';
import { moneyMask } from '../../Helpers/Masks';
import { ColorConstants } from '../../Constants/ColorConstants';
import { ArrowForward } from '@mui/icons-material';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import STRINGS from '../../i18n/local/es.json';
import { DetectMobile } from '../../Utils/DetectMobile';
import { getDataPersonService } from '../../Services/ChangePasswordService';
import { getPersonByDNI } from '../../Services/LoanRequestService';
import { setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import AppContext from '../../Contexts/Global/AppContext';
import { obtainRecommendationsFetch } from '../../Services/HeaderService';

export const usePrivateHomeHandler = () => {
    const navigate = useNavigate();
    const { user, creditsUser } = useContext(AppContext);
    const [recommendation, setRecommendation] = useState({ qualified: false });

    const btnProps = {
        bg: ColorConstants.WHITE,
        color: ColorConstants.blue[900],
        border: true,
        icon: <ArrowForward />,
        height: 'auto',
        padding: 0
    };

    const heroDiscoveryProps = {
        heroProps: {
            inverted: true,
            showMobile: false
        },
        navBarProps: {
            inverted: false,
            isLogged: true
        },
        heading: STRINGS.privateHome.header.PAGE_TITLE,
        description: STRINGS.privateHome.header.PAGE_DESCRIPTION,
        bnplCards: {
            card1: {
                title: STRINGS.privateHome.header.cards.card1.TITLE,
                description: moneyMask('80000', true, 0),
                cta: STRINGS.privateHome.header.cards.card1.CTA,
                props: { ...btnProps, action: () => { navigate(RoutesConstants.PARTNERS_STORE_PAGE); }}
            },
            card2: {
                title: STRINGS.privateHome.header.cards.card2.TITLE,
                description: STRINGS.privateHome.header.cards.card2.DESCRIPTION,
                cta: STRINGS.privateHome.header.cards.card2.CTA,
                props: { ...btnProps, action: () => {} }
            },
            card3: {
                title: STRINGS.privateHome.header.cards.card3.TITLE,
                description: moneyMask('20000', true, 0),
                cta: STRINGS.privateHome.header.cards.card3.CTA,
                props: { ...btnProps, action: () => { RequestLoan(); }}
            }
        }
    };

    const RequestLoan = async () => {
        if (creditsUser === null) return;
        if (creditsUser.credits.length > 0 || creditsUser.creditsCanceled.length > 0) {
            setRecommendation(await obtainRecommendationsFetch(user.personId));
        }

        if (await DetectMobile()) {
            const userData = await getDataPersonService(user.personId);

            const peopleList = await getPersonByDNI({
                dni: userData.dni,
                sexTypeId: userData.sexTypeId
            });

            const filteredPerson = peopleList.filter(
                (people) => people.personName == userData.userName
            );

            const personData = {
                ...filteredPerson[0],
                name: filteredPerson[0].personName,
                dni: userData.dni,
                sexTypeId: userData.sexTypeId,
                skipToOfferSelection: true
            };

            setItemService(LoanRequestConstantsLS.PersonToGetLoan, JSON.stringify(personData));

            if (recommendation?.qualified) {
                const loanData = {
                    pendingRequestId: recommendation.pendingRequestId,
                    recommendation: true,
                    offer: [
                        {
                            maximumAmount: recommendation.amount
                        }
                    ]
                };
                setItemService(LoanRequestConstantsLS.LoanData, JSON.stringify(loanData));
            }
            navigate(RoutesConstants.LOAN_CONTACT_INFORMATION_PAGE);
        } else {
            navigate(RoutesConstants.LOAN_REDIRECT_PAGE);
        }
    }

    const buyInInstallments = [
        {
            serviceProps: {
                icon: <ShoppingBagIcon />,
                featureTitle: STRINGS.home.services.FEATURE_INSTALLMENTS_TITLE,
                description: STRINGS.home.services.FEATURE_INSTALLMENTS_DESCRIPTION_LINE1,
                descriptionSecondLine: STRINGS.home.services.FEATURE_INSTALLMENTS_DESCRIPTION_LINE2,
                callToAction: [
                    {
                        label: STRINGS.home.services.FEATURE_INSTALLMENTS_CTA,
                        props: { action: () => {}, showMobile: true }
                    }
                ]
            },
            faqProps: {
                showButtons: true,
                items: [
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_DESCRIPTION
                    },
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_PAYMENT_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_PAYMENT_DESCRIPTION
                    }
                ]
            }
        }
    ];
    const applyForALoan = [
        {
            serviceProps: {
                icon: <DollarIcon />,
                featureTitle: STRINGS.home.services.FEATURE_LOAN_TITLE,
                description: STRINGS.home.services.FEATURE_LOAN_DESCRIPTION_LINE1,
                descriptionSecondLine: STRINGS.home.services.FEATURE_LOAN_DESCRIPTION_LINE2,
                callToAction: [
                    {
                        label: STRINGS.home.services.FEATURE_LOAN_CTA,
                        props: {
                            action: async () =>
                                (await !DetectMobile())
                                    ? navigate(RoutesConstants.LOAN_REDIRECT_PAGE)
                                    : navigate(RoutesConstants.LOAN_REQUEST_PAGE),
                            showMobile: true
                        }
                    }
                ]
            },
            faqProps: {
                showButtons: true,
                items: [
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_REQUIREMENTS_DESCRIPTION
                    },
                    {
                        label: STRINGS.home.services.FEATURE_FAQ_PAYMENT_TITLE,
                        description: STRINGS.home.services.FEATURE_FAQ_PAYMENT_DESCRIPTION
                    }
                ]
            }
        }
    ];

    const doubtsProps = {
        serviceProps: {
            featureTitle: STRINGS.home.doubts.DOUBTS_SECTION_TITLE,
            description: STRINGS.home.doubts.DOUBTS_SECTION_DESCRIPTION,
            callToAction: [
                {
                    label: STRINGS.home.doubts.DOUBTS_SECTION_CTA_BUTTON_LABEL,
                    props: {
                        action: () => navigate(RoutesConstants.FRECUENT_QUESTIONS_PAGE),
                        showMobile: true
                    }
                }
            ]
        },
        faqProps: {
            showButtons: true
        },
        tabs: [
            {
                label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1,
                panel: {
                    items: [
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_QUESTION_1,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_ANSWER_1
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_QUESTION_2,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_1_ANSWER_2
                        }
                    ]
                }
            },
            {
                label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2,
                panel: {
                    items: [
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_QUESTION_1,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_ANSWER_1
                        },
                        {
                            label: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_QUESTION_2,
                            description: STRINGS.home.doubts.DOUBTS_SECTION_TAB_2_ANSWER_2
                        }
                    ]
                }
            }
        ]
    };

    const contactCTAS = [
        {
            label: STRINGS.home.contact.CONTACT_CTA_PHONENUMBER,
            props: {
                action: () => {
                    window.location.href = 'tel:08102225554';
                }
            }
        },
        {
            label: STRINGS.home.contact.CONTACT_CTA_MAIL,
            props: {
                action: () => {
                    window.location.href = 'mailto:ayuda@directo.com.ar';
                }
            }
        }
    ];

    return {
        heroDiscoveryProps,
        buyInInstallments,
        applyForALoan,
        doubtsProps,
        contactCTAS
    };
};
