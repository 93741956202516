import { getData, postData } from './BaseService';
import { setItemService } from './LocalStorageService';

export const getOrderById = async (id) => {
    const url = `merchant/orders/${id}`;
    const response = await getData(url);
    return response;
};

export const updateOrderStatus = async (id, status) => {
    const url = 'merchant/orders/status';
    const response = await postData(url, { orderId: id, status: status });
    return response;
};

export const updateOrderStatusPending = async (id, status, pendingId) => {
    const url = 'merchant/orders/status/pending';
    const response = await postData(url, { orderId: id, status: status, pendingRequestId: pendingId });
    return response;
};

export const associateRequestToOrder = async (orderId, requestId) => {
    const url = 'merchant/orders/request';
    const response = await postData(url, { orderId: orderId, pendingRequestId: requestId });
    return response;
};

export const getHashQR = async (orderId, personId) => {
    const url = 'merchant/createQrCode';
    const response = await postData(url, { orderId: orderId, personId: personId });
    return response;
};

export const getSessionFromMobile = async (qrCodeHash) => {
    const url = 'merchant/sessionFromMobile';
    const response = await postData(url, { qrCodeHash: qrCodeHash });
    return response;
};

export const changePasswordAccount = async (email) => {
    const url = 'login/forgot_password_account';
    const response = await postData(url, {email: email});
    return response;
};

export const logEventBNPLInfo = async (eventName, orderId, traceId) => {
    const url = 'credit/logevent';
    const response = await postData(url, {EventType: 'INFO', EventName: eventName, OrderId: orderId, EventDetail: traceId});
    return response;
};

export const logEventBNPLFunnel = async (eventName, orderId, traceId) => {
    const url = 'credit/logevent';
    const response = await postData(url, {EventType: 'FUNNEL', EventName: eventName, OrderId: orderId, EventDetail: traceId});
    return response;
};

export const logEventRatingBNPLFunnel = async (eventName, orderId, traceId, pendingRequestId) => {
    const url = 'credit/logevent';
    const response = await postData(url, {EventType: 'FUNNEL', EventName: eventName, OrderId: orderId, EventDetail: traceId, PendingRequestId: pendingRequestId});
    return response;
};

export const verifyEmailCode = async (code, ecommerceAccountId, hash) => {
    const url = 'register/verifyCodeMail';
    const codeNumber = Number(code);
    const commerceIdNumber = Number(ecommerceAccountId);
    const response = await postData(url, {
        code: codeNumber,
        ecommerceAccountId: commerceIdNumber,
        hash: hash
    });
    return response.data.status;
};

export const resendEmailCode = async (commerceId, hash) => {
    const url = 'register/resend_activaction_code';
    const response = await postData(url, {
        ecommerceAccountId: commerceId,
        hash: hash
    });
    setItemService('registerHash', response.data.hash);
    return response;
};
