import { createContext, useState, useEffect } from 'react';
import { getStoreFilters, getAffiliatedStores } from '../../Services/PartnerStoresService';

const PartnerStoresContext = createContext(null);

const PartnerStoresProvider = ({ children }) => {
    const [unfilteredPartners, setUnfilteredPartners] = useState(null);
    const [partners, setPartners] = useState(null);
    const [error, setError] = useState(false);
    const [filters, setFilters] = useState({ categories: [], offers: [], provinces: [] });

    useEffect(() => {
        getInitData();
    }, []);

    const getInitData = async () => {
        getStoreFilters({})
            .then((response) => {
                setFilters(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
        getAffiliatedStores({})
            .then((response) => {
                setPartners(response.data);
                if (response.data.length <= 0) setError(true);
                setUnfilteredPartners(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const applyFilters = (newFilters) => {
        const tempFilters = {};
        setError(false);
        for (const key in newFilters) {
            tempFilters[key] = newFilters[key] === 0 ? null : newFilters[key];
        }
        getAffiliatedStores(tempFilters).then((response) => {
            setPartners(response.data);
            if (response.data.length <= 0) setError(true);
        });
    };

    const actions = {
        applyFilters
    };

    return (
        <PartnerStoresContext.Provider
            value={{
                partners,
                unfilteredPartners,
                filters,
                actions,
                error,
                setError
            }}>
            {children}
        </PartnerStoresContext.Provider>
    );
};

export { PartnerStoresContext, PartnerStoresProvider };
