import { createContext } from 'react';
import {
    savePhoneNumberService,
    sendSMSCode,
    verifySMSCodeService
} from '../../../../Services/LoanRequestService';

export const ValidateBNPLTokenHandlerContext = createContext();

export const ValidateBNPLTokenHandlerProvider = ValidateBNPLTokenHandlerContext.Provider;

export const useValidateBNPLTokenHandler = () => {
    const verifyToken = (pendingRequestId, code) => {
        return verifySMSCodeService({
            pendingRequestId: pendingRequestId,
            code: code
        });
    };

    const saveContactData = (data) => {
        return savePhoneNumberService({
            personId: data.personId,
            numCelular: data.phoneNumberComplete
        });
    };

    const resendToken = (pendingRequestId, phone) => {
        return sendSMSCode({
            pendingRequestId: pendingRequestId,
            phoneNumber: phone
        });
    };

    return {
        verifyToken,
        resendToken,
        saveContactData
    };
};
