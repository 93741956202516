import React, { useContext, useEffect, useState } from 'react';
import { useGenericForm } from '../../../Hooks/useGenericForm';
import { Box } from '@mui/material';
import { GenericForm } from '../../../Components/Form/GenericForm';
import contactInfoConfig from '../../../Configs/contactInfoConfig.json';
import { AcceptTermsAndPolicy } from '../../../Components/AcceptTermsAndPolicy';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import ContactInfoContext from '../../../Contexts/Register/ContactInformation/ContactInfoContext';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { GetValidateFunction } from '../../../Helpers/GetValidateFunction';

export const ContactInformationForm = () => {
    const { form, config, validForm, handleValue, initForm, setConfig, validateForm} = useGenericForm(
        contactInfoConfig.formControls
    );
    const { options, savePersonForm, data } = useContext(ContactInfoContext);
    const [firstExecuted, setFirstExecuted] = useState(false);

    const initValues = {
        area: `${data.phoneCode!=null ? data.phoneCode : ''}`,
        phoneNumber: `${data.phoneNumber!=null ? data.phoneNumber : ''}`,
        province: `${data.provinceId!=0 ? data.provinceId : ''}`,
    };

    const blockFormControls = () => {
        if(config) {
            let newConfig = [...config];
            
            if(data.provinceId != 0 && !firstExecuted){
                setFirstExecuted(true)
                setConfig(
                    newConfig.map((e) => {
                        let x = {...e};
    
                        if(x.field == 'province') {
                                x.hidden = true;
                        }else{
                            x.hidden = false;
                        }
                        return x;
                    })
                )
            }
        }
    }

    useEffect(() => {
        initForm(initValues);
        validateForm(initValues);
    }, []);

    useEffect(() => {
        blockFormControls();
    }, [config]);

    return (
        <Box sx={{ mt: 2, mb: 2, pt: 2, pb: 2 }}>
            <GenericForm
                formControls={config}
                options={options}
                form={form}
                validation={GetValidateFunction}
                handleValue={handleValue}
            />
            <AcceptTermsAndPolicy />
            <Box width="100%">
                <GrayButton
                    text={'Continuar'}
                    action={() => {
                        savePersonForm(form);
                    }}
                    disabled={!validForm}
                />
            </Box>
            <BackHomeButton />
        </Box>
    );
};
