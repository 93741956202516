import React from 'react';

import { FormHelperText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const InformationLabel = ({ description, formHelperSx, checkSx, fontSize = 'small' }) => {
    return (
        <FormHelperText sx={formHelperSx} id="outlined-adornment-amount">
            <CheckIcon fontSize={fontSize} sx={checkSx} /> {description}
        </FormHelperText>
    );
};

export default InformationLabel;
