import curl_mobile from '../../../Assets/curl_1.svg';
import curl_desktop from '../../../Assets/curl_2.svg';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const gradients = {
    public: {
        desktop: [
            {
                type: 'circle',
                width: '455px',
                height: '455px',
                bgcolor: ColorConstants.green[500],
                blur: '120px',
                right: '-100px',
                bottom: '-250px'
            },
            {
                type: 'circle',
                width: '338px',
                height: '338px',
                bgcolor: ColorConstants.green[100],
                blur: '150px',
                right: '-280px',
                bottom: '100px'
            },
            {
                type: 'circle',
                width: '620px',
                height: '620px',
                bgcolor: ColorConstants.blue[200],
                blur: '150px',
                left: '-300px',
                top: '-540px'
            },
            {
                type: 'circle',
                width: '860px',
                height: '860px',
                bgcolor: ColorConstants.blue[500],
                blur: '150px',
                left: '-700px',
                top: '-240px'
            },
            {
                type: 'element',
                children: <img src={curl_desktop} />,
                blur: '0px',
                left: 0,
                top: 0
            }
        ],
        mobile: [
            {
                type: 'circle',
                width: '426px',
                height: '426px',
                bgcolor: ColorConstants.blue[500],
                blur: '50px',
                left: '-348px',
                top: '-60px'
            },
            {
                type: 'circle',
                width: '308px',
                height: '308px',
                bgcolor: ColorConstants.blue[200],
                blur: '50px',
                left: '-130px',
                top: '-280px'
            },
            {
                type: 'circle',
                width: '168px',
                height: '168px',
                bgcolor: ColorConstants.green[100],
                blur: '50px',
                right: '-140px',
                top: '1%'
            },
            {
                type: 'circle',
                width: '226px',
                height: '226px',
                bgcolor: ColorConstants.green[500],
                blur: '60px',
                right: '-120px',
                top: '18%'
            },
            {
                type: 'element',
                children: <img src={curl_mobile} />,
                blur: '0px',
                right: 0,
                top: '25px'
            }
        ]
    }, 
    private: {
        desktop: [
            {
                type: 'circle',
                width: '455px',
                height: '455px',
                bgcolor: '#7DEE80',
                blur: '120px',
                right: '-100px',
                bottom: '-250px'
            },
            {
                type: 'circle',
                width: '338px',
                height: '338px',
                bgcolor: '#C8F8C8',
                blur: '150px',
                right: '-280px',
                bottom: '100px'
            },
            {
                type: 'circle',
                width: '860px',
                height: '860px',
                bgcolor: ColorConstants.blue[50],
                blur: '150px',
                left: '-700px',
                top: '-240px'
            },
            {
                type: 'element',
                children: <img src={curl_desktop} />,
                blur: '0px',
                left: 0,
                top: 0
            }
        ],
        mobile: [
            {
                type: 'circle',
                width: '279px',
                height: '293px',
                bgcolor: '#4892E1',
                blur: '100px',
                left: '0',
                top: '400px'
            },
            {
                type: 'circle',
                width: '316px',
                height: '333px',
                bgcolor: '#CEE6FF',
                blur: '100px',
                left: '-160px',
                top: '-90px'
            },
            {
                type: 'circle',
                width: '300px',
                height: '300px',
                bgcolor: '#7DEE80',
                blur: '100px',
                right: '-240px',
            }
        ]
    }
};
