import { getData, postData } from './BaseService';

export const getPaymentMethodsService = async () => {
    const url = 'payment/methods';
    const response = await getData(url);

    return response;
};

export const getAllowedMethodsService = async ({ dni, sexTypeId }) => {
    const url = `payment/methodsByClient?Dni=${dni}&SexTypeId=${sexTypeId}`;
    const response = await getData(url);

    return response;
};

export const getCardsService = async ({ personId }) => {
    const url = 'cardcred/getCards';
    const response = await postData(url, { personId });

    return response;
};

export const getVerifyCardsService = async ({ personId }) => {
    const url = 'cardcred/verifyCards';
    const response = await postData(url, { personId });
    return response;
};

export const getUrlTokenService = async ({ personId, phoneCode, phoneNumber, cuit }) => {
    const url = 'cardcred/getUrlToken';
    const response = await postData(url, { personId, phoneCode, phoneNumber, cuit });

    return response;
};

export const savePaymentMethod = async ({ personId, pendingRequestId, debitPayment, cardId }) => {
    const url = 'credit/payment/debitPayment';
    const body = cardId
        ? { personId, pendingRequestId, debitPayment, cardId }
        : { personId, pendingRequestId, debitPayment };
    const response = await postData(url, body);

    return response;
};

export const saveErrorService = async (personId, errorCode) => {
    const url = 'cardcred/saveError';
    const response = await postData(url, { personId, errorCode });

    return response;
};
