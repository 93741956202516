import React, { useState, useEffect } from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
    Footer,
    BnplCard,
    HeroDiscovery,
    FeaturedService,
    Tabs,
    DynamicGradientBackground
} from '../../Components/BNPL';
import { CardOffers, Container, gradients } from './PrivateHomeBNPLPage.styled';
import { usePrivateHomeHandler } from './PrivateHomeBNPLHandler';
import { ContactForm } from '../PublicHomeBNPL/components/ContactForm';
import { HomeState } from '../../Contexts/Home/HomeState';
import { Divider } from '../PublicHomeBNPL/PublicHomeBNPL.styled';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../Constants/ColorConstants';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { LoanActiveState } from '../../Contexts/LoanActive/LoanActiveState';
import { PrivateHomeLoanSection } from './components/PrivateHomeLoanSection';
import { obtainRecommendations } from '../../Services/HeaderService';
import { moneyMask } from '../../Helpers/Masks';
import { cleanBNPLOrderData } from '../../Helpers/BNPLUtils';
import { requestBNPLLoan } from '../../Services/LoanRequestService';
import { getItemService } from '../../Services/LocalStorageService';

export const PrivateHomeBNPLPage = () => {
    const [askForALoan, setAskForALoan] = useState(false);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [availableLoanAmount, setAvailableLoanAmount] = useState(0);

    const { heroDiscoveryProps, buyInInstallments, applyForALoan, doubtsProps, contactCTAS } =
        usePrivateHomeHandler();

    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const tablet = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        cleanBNPLOrderData();

        const personObjectAsJson = globalThis.localStorage.getItem('Person'); // todo: replace for constant.
        const { personId } = JSON.parse(personObjectAsJson);
        const personToGetLoan = getItemService('ContactInfo')
            ? JSON.parse(getItemService('ContactInfo'))
            : null;

        if (personId) {
            //Rating -> Izquierda
            requestBNPLLoan({
                name: personToGetLoan.userName,
                dni: personToGetLoan.dni,
                sexTypeId: personToGetLoan.sexTypeId,
                personId: personId
            }).then((res) => {
                setAvailableBalance(
                    res.data.offer && res.data.offer.length > 0
                        ? res.data.offer[0].maximumAmount
                        : 0
                );
            });

            //Recommended -> Derecha
            obtainRecommendations(personId).then((res) => {
                setAskForALoan(false);
                setAvailableLoanAmount(res.amount);
            });
        }
    }, []);

    return (
        <HomeState>
            <DynamicGradientBackground
                sx={{
                    backgroundColor: '#F5FAFF',
                    width: '100%'
                }}
                masked
                gradients={desktop ? gradients.desktop : gradients.mobile}>
                <HeroDiscovery {...heroDiscoveryProps} variant="private">
                    <Stack
                        direction={desktop ? 'row' : 'column'}
                        spacing={3}
                        justifyContent="space-between"
                        alignItems={desktop ? 'center' : 'stretch'}>
                        {availableBalance !== 0 && (
                            <BnplCard
                                className="b-shadow"
                                style={{
                                    justifyContent: 'center',
                                    minHeight: desktop ? '224px' : 0
                                }}>
                                <CardOffers
                                    isDesktop={desktop}
                                    title={heroDiscoveryProps.bnplCards.card1.title}
                                    description={`${moneyMask(String(availableBalance), true, 0)}`}
                                    cta={heroDiscoveryProps.bnplCards.card1.cta}
                                    {...heroDiscoveryProps.bnplCards.card1.props}
                                />
                            </BnplCard>
                        )}
                        {askForALoan && (
                            <BnplCard
                                className="b-shadow"
                                style={{
                                    justifyContent: 'center',
                                    minHeight: desktop ? '224px' : 0
                                }}>
                                <CardOffers
                                    isDesktop={desktop}
                                    className="invert"
                                    askForALoan
                                    title={heroDiscoveryProps.bnplCards.card2.title}
                                    description={heroDiscoveryProps.bnplCards.card2.description}
                                    cta={heroDiscoveryProps.bnplCards.card2.cta}
                                    fontWeightDescription={400}
                                    {...heroDiscoveryProps.bnplCards.card2.props}
                                />
                            </BnplCard>
                        )}
                        {availableLoanAmount !== 0 && (
                            <BnplCard
                                className="b-shadow"
                                style={{
                                    justifyContent: 'center',
                                    minHeight: desktop ? '224px' : 0
                                }}>
                                <CardOffers
                                    isDesktop={desktop}
                                    title={heroDiscoveryProps.bnplCards.card3.title}
                                    description={`${moneyMask(
                                        String(availableLoanAmount),
                                        true,
                                        0
                                    )}`}
                                    cta={heroDiscoveryProps.bnplCards.card3.cta}
                                    {...heroDiscoveryProps.bnplCards.card3.props}
                                />
                            </BnplCard>
                        )}
                    </Stack>
                </HeroDiscovery>
                <Container>
                    <LoanActiveState>
                        <PrivateHomeLoanSection />
                    </LoanActiveState>
                </Container>
                <Container>
                    {!desktop && <Divider />}
                    <FeaturedService {...doubtsProps}>
                        {desktop && <Tabs items={doubtsProps.tabs} />}
                    </FeaturedService>
                    <Divider />
                </Container>
                {<ContactForm />}
                <Container>
                    {contactCTAS.map(
                        (btn, i) =>
                            !tablet && (
                                <ButtonCustom
                                    key={i}
                                    text={btn.label}
                                    bg="transparent"
                                    color={ColorConstants.blue[900]}
                                    border
                                    icon={<CallMadeIcon />}
                                    {...btn.props}
                                />
                            )
                    )}
                </Container>
                <Footer />
            </DynamicGradientBackground>
        </HomeState>
    );
};
