import { Outlet, useLocation } from 'react-router-dom';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { BNPLFinancingProvider } from '../../Contexts/BNPLFinancing/BNPLFinancingContext';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { Description, Title, financing_gradients } from './FinancingPage.styled';

import STRINGS from '../../i18n/local/es.json';

const TitleDesc = ({ title, description }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const location = useLocation();

    return (
        <Box py={[0, 3]} mt={[3, 5, '54px']}>
            <>
                {!desktop && location.pathname !== RoutesConstants.FINANCING_PAGE && (
                    <ReturnButton route={RoutesConstants.LOAN_PAY_METHOD_BNPL} />
                )}
                {title && <Title>{title}</Title>}
                {description && <Description mt={[1, 2]}>{description}</Description>}
            </>
        </Box>
    );
};

const FinancingTitle = () => {
    const personData = JSON.parse(localStorage.getItem('personData'));
    const USERNAME = personData?.name?.split(',')[1] || '';
    const location = useLocation();

    switch (location.pathname) {
        case RoutesConstants.FINANCING_PAGE:
            return (
                <TitleDesc
                    title={`${STRINGS.financing.FORM_TITLE} ${USERNAME}!`}
                    description={STRINGS.financing.FORM_DESCRIPTION}
                />
            );
        case RoutesConstants.FINANCING_OFFER_PAGE:
            return (
                <TitleDesc
                    title={`${STRINGS.offer.FORM_TITLE} ${USERNAME}!`}
                    description={STRINGS.offer.FORM_DESCRIPTION}
                />
            );
        case RoutesConstants.CONFIRMATION_PAGE:
            return (
                <TitleDesc
                    title={STRINGS.confirmation.PAGE_TITLE}
                    description={STRINGS.confirmation.PAGE_TAGLINE}
                />
            );
        default:
            return;
    }
};

export const FinancingPage = () => {
    const layoutProps = {
        desktop: {
            background: 'transparent',
            showDescription: true
        },
        mobile: {
            background: 'transparent',
            showDescription: true,
            gradients: financing_gradients
        }
    };
    return (
        <BNPLFinancingProvider>
            <BNPLLayout
                ContainerPanel={<FinancingTitle />}
                Form={<Outlet />}
                layoutProps={layoutProps}
            />
        </BNPLFinancingProvider>
    );
};
