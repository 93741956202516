import { useState, useEffect } from 'react';

export const useActionTimer = (seconds, action) => {
    const [remainingSeconds, setRemainingSeconds] = useState(seconds);

    useEffect(() => {
        if (remainingSeconds === 0 && action) {
            action();
        }
    }, [remainingSeconds]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setRemainingSeconds((prevRemainingSeconds) => {
                if (prevRemainingSeconds === 0) {
                    clearInterval(intervalId);
                    return 0;
                } else {
                    return prevRemainingSeconds - 1;
                }
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return { remainingSeconds };
};
