import React, { useContext } from 'react';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import ContainerForm from '../../../Components/Form/ContainerForm';
import { Img } from '../../../Components/ImageContainer';
import LoanRequestContext from '../../../Contexts/LoanRequest/LoanRequestContext';
import { firstName } from '../../../Helpers/Masks';

const NoOffers = () => {
    const { data } = useContext(LoanRequestContext);

    const textDescription =
        'En este momento no encontramos una oferta para vos. Podés intentarlo en otro momento y seguro te vamos ayudar.';

    return (
        <ContainerForm>
            <div>
                <DescriptionForm
                    title={`Lo sentimos ${firstName(data?.personName)}`}
                    description={textDescription}
                    fontSizeDescription={'22px'}
                />
                <Img src={'NoOffers.svg'} />
            </div>
            <BackHomeButton />
        </ContainerForm>
    );
};

export default NoOffers;
