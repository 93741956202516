import React from 'react';
import { Outlet } from 'react-router-dom';
import { ApprovedLoanState } from '../../../Contexts/ApprovedLoan/ApprovedLoanState';
import { feedback_gradients } from '../../Financing/FinancingPage.styled';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { Description } from '../../../Components/BNPL/Description/Description';
import { firstCapitalLetter, firstName } from '../../../Helpers/Masks'
import { LoanRequestConstantsLS } from '../../../Contexts/LoanRequest/LoanRequestActions';

export const ApprovedLoanPage = () => {
    const pathname = window.location.pathname;
    const title = '¡Lista tu parte, ';
    const personData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.PersonToGetLoan));
    const userName = personData?.personName?.split(',')[1] || '';
    const firstName = userName.split(' ')[0];
    const firstCapitalizedName = firstCapitalLetter(firstName);
    
    const layoutProps = {
        mobile: {
            background: 'transparent',
            showDescription: false,
            gradients: feedback_gradients
        }
    };
    return (
        <ApprovedLoanState>
            <BNPLLayout 
                ContainerPanel={pathname === RoutesConstants.LOAN_LAST_DIFFERENTIAL_SCREEN && 
                    <Description  title={`${title} ${firstCapitalizedName}!`} returnButton={false} />} 
                Form={<Outlet />} approvedLoan layoutProps={layoutProps} />
        </ApprovedLoanState>
    );
};
