import { Autocomplete, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

const StyledAutocomplete = styled(Autocomplete)`
    && {
        width: 100%;
        .MuiInputBase-root {
            padding-left: 14px;
            padding-right: 14px;
        }

        .MuiAutocomplete-endAdornment {
            display: none;
        }
        &.iconStart {
            .MuiInputLabel-outlined {
                padding-left: 30px;
            }

            .MuiInputLabel-shrink {
                padding-left: 0;
            }
        }
        .MuiInputBase-input,
        .Mui-focused {
            color: ${ColorConstants.blue[900]};
        }
        .MuiInputBase-input &::placeholder {
            display: none;
        }
        .MuiAutocomplete-input::placeholder {
            opacity: 1;
        }
        fieldset {
            background: white;
            border-radius: 16px;
            border: 1.5px solid ${ColorConstants.gray[200]};
        }
        input, .MuiInputAdornment-root{
            z-index: 1;
        }

        &:hover fieldset,
        .Mui-focused fieldset {
            border-color: ${ColorConstants.blue[100]};
        }

        .Mui-error {
            color: ${ColorConstants.gray[700]};
            fieldset {
                border: 2px solid ${ColorConstants.error[400]};
            }
        }

        .MuiInputLabel-root {
            position: absolute;
            transform: translate(40px, 15px) scale(1);
            transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
            max-width: calc(100% - 50px);
        }

        .MuiInputLabel-shrink {
            transform: translate(15px, -8px) scale(0.75);
        }
    }
`;

export const StyledPaper = styled(Paper)`
    && {
        box-shadow: 0px 12px 16px -4px rgba(0, 105, 215, 0.1),
            0px 4px 6px -2px rgba(0, 105, 215, 0.05);
        border-radius: 8px;
        box-sizing: border-box;
        ul {
            &::-webkit-scrollbar {
                width: 7px;
            }
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
                background: ${ColorConstants.gray[200]};
                border-radius: 13px;
            }
            li {
                height: 52px;
                &:hover {
                    background-color: ${ColorConstants.gray[200]};
                }
            }
        }
    }
`;

export const StyledBox = styled(Box)`
    && {
        width: 100%;
        &.error {
            .MuiPaper-root {
                border-color: ${ColorConstants.error[400]};
            }
        }
    }
`;

export const StyledOption = styled('li')`
    background-color: white;
    &:hover {
        background-color: ${ColorConstants.blue[50]} !important;
    }
`;

export default StyledAutocomplete;
