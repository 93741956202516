export function SunIcon({ color = 'currentColor', width = '20', height = '20' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99998 0.833008V2.49967M9.99998 17.4997V19.1663M3.51665 3.51634L4.69998 4.69967M15.3 15.2997L16.4833 16.483M0.833313 9.99967H2.49998M17.5 9.99967H19.1666M3.51665 16.483L4.69998 15.2997M15.3 4.69967L16.4833 3.51634M14.1666 9.99967C14.1666 12.3009 12.3012 14.1663 9.99998 14.1663C7.69879 14.1663 5.83331 12.3009 5.83331 9.99967C5.83331 7.69849 7.69879 5.83301 9.99998 5.83301C12.3012 5.83301 14.1666 7.69849 14.1666 9.99967Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
