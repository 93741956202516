import { Box } from '@mui/material';
import { DescriptionForm } from '../../Components/DescriptionsLayout';
import BodySendEmail from '../Register/CreateAccount/BodySendEmail';
import TextDescriptionSendEmail from '../Register/TextDescriptionSendEmail';
import ChangePasswordContext from '../../Contexts/ChangePassword/ChangePasswordContext';
import { useContext } from 'react';

export const SendSuccessEmail = () => {
    const { resendEmailRequest } = useContext(ChangePasswordContext);

    const title = '¡Listo! Ahora revisá tu mail';

    return (
        <Box textAlign={'center'}>
            {/* No coinciden las pantallas(movil/desktop) entre mostrar o no el botón return*/}
            <DescriptionForm
                title={title}
                description={<TextDescriptionSendEmail />}
            />
            <BodySendEmail action={() => resendEmailRequest()} />
        </Box>
    );
};
