export const GenderConstants = {
    FEMALE: 3,
    MALE: 2,
    FEMALE_LABEL: 'Femenino',
    MALE_LABEL: 'Masculino'
}

export const GENDER_OPTIONS = [
    { value: GenderConstants.FEMALE, label: GenderConstants.FEMALE_LABEL },
    { value: GenderConstants.MALE, label: GenderConstants.MALE_LABEL }
];