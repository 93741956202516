export function EyeIcon({ color = 'currentColor', width = '20', height = '20' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.833313 9.99967C0.833313 9.99967 4.16665 3.33301 9.99998 3.33301C15.8333 3.33301 19.1666 9.99967 19.1666 9.99967C19.1666 9.99967 15.8333 16.6663 9.99998 16.6663C4.16665 16.6663 0.833313 9.99967 0.833313 9.99967Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />{' '}
            <path
                d="M9.99998 12.4997C11.3807 12.4997 12.5 11.3804 12.5 9.99967C12.5 8.61896 11.3807 7.49967 9.99998 7.49967C8.61927 7.49967 7.49998 8.61896 7.49998 9.99967C7.49998 11.3804 8.61927 12.4997 9.99998 12.4997Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
