import { Box } from '@mui/system';
import { Grid } from '@mui/material';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import ButtonsBody from './ButtonsBody';
import { useEffect } from 'react';

export const Register = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const titleForm = 'Registrarme';
    const descriptionForm =
        'Podés crear tu cuenta con tu correo o redes sociales. En pocos pasos podrás ingresar para ver tu préstamo o sacar uno.';
    return (
        <Box style={{ textAlign: 'center', maxWidth: '400px' }}>
            <Grid sx={{ display: { xs: 'block', sm: 'none' } }}>
                <ReturnButton route={RoutesConstants.PUBLIC_HOME} />
            </Grid>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <ButtonsBody />
        </Box>
    );
};
