import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BnplCard } from '../../../Components/BNPL/BnplCard/BnplCard';
import STRINGS from '../../../i18n/local/es.json';
import { CreditCardIcon } from '../../../Components/Icons';
import EditPencilIcon from '../../../Components/Icons/EditPencilIcon';
import { IconWrapper } from '../../../Components/Icons/IconWrapper';
const PaymentMethodCard = (props) => {
    let textToRender;
    switch (props.paymentMethod) {
        case 1:
            textToRender = STRINGS.financing.INSTALLMENT_METHOD_1
            break;
        case 2:
            textToRender = STRINGS.financing.INSTALLMENT_METHOD_2
            break;
        case 3:
            textToRender = STRINGS.financing.INSTALLMENT_METHOD_3
            break;
        default:
            textToRender = STRINGS.financing.INSTALLMENT_METHOD_2
            break;
    }
    return (
        <BnplCard padding="16px 27px" marginBottom={4}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <IconWrapper
                        outterContainer={
                            {
                                marginRight: '10px'
                            }
                        }
                    >
                        <CreditCardIcon height="20px" />
                    </IconWrapper>
                    <Typography>
                        {textToRender}
                    </Typography>
                </Box>
                <Button variant='text' onClick={props.onPaymentMethodEdit}>
                    <EditPencilIcon width='18px' height='18px' />
                </Button>
            </Box>
        </BnplCard>

    )
}

export default PaymentMethodCard
