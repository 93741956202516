import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { BounceLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';
import GoogleAnalytics from '../Components/Layout/GoogleAnalytics(Universal)';
import ReactGA from 'react-ga';
import { saveErrorService } from '../Services/PaymentMethodService';
import { getItemService } from '../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../Contexts/LoanRequest/LoanRequestActions';

const CardCredError = () => {
    CardCredError.displayName = 'CardCredError';
    const location = useLocation();
    const [counter, setCounter] = useState(10);
    const [queryParamExtracted, setQueryParamExtracted] = useState(false);
    const personId = JSON.parse(getItemService(LoanRequestConstantsLS.PersonToGetLoan))?.personId;

    useEffect(() => {
        // eslint-disable-next-line no-undef
        const { REACT_APP_TRACKING_ID } = process.env;
        ReactGA.initialize(REACT_APP_TRACKING_ID);
    }, []);

    useEffect(() => {
        deleteScript();
    }, []);

    useEffect(() => {
        deleteScript();
    }, [location.pathname]);

    const deleteScript = () => {
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
        const scriptGlobal = parent.top.document.body.getElementsByClassName('s1-script');
        if (scriptGlobal.length > 0) {
            scriptGlobal[0]?.remove();
        }
        const chatGlobal = parent.top.document.body.getElementsByClassName('s1-channel-active');
        if (chatGlobal.length > 0) {
            chatGlobal[0]?.remove();
        }
        const scriptSelf = parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf.length > 0) {
            scriptSelf[0]?.remove();
        }
        const chatSelf = parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf.length > 0) {
            chatSelf[0]?.remove();
        }
        const scriptSelf2 = parent.parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf2.length > 0) {
            scriptSelf2[0]?.remove();
        }
        const chatSelf2 = parent.parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf2.length > 0) {
            chatSelf2[0]?.remove();
        }
    };

    const fetchData = async () => {
        try {
            if (!queryParamExtracted) {
                const queryString = window.location.search;
                const queryParams = new URLSearchParams(queryString);
                const extractedCodErrValue = queryParams.get('cod_err');
                setQueryParamExtracted(true);
                await saveErrorService(personId, extractedCodErrValue);
                return;
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();

        if (counter === 0) {
            const btn = window.parent.document.getElementById('backButton');
            if (btn) {
                btn.click();
            }
            return;
        }

        const interval = setInterval(() => {
            setCounter((counter) => counter - 1);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [counter, queryParamExtracted]);

    return (
        <Box display={'flex'} width="100%" justifyContent={'center'} alignItems={'center'}>
            {/* <GoogleAnalytics /> */}
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                width="315px"
                height={'315px'}
                sx={{ mt: 2 }}>
                <DangerousIcon sx={{ fontSize: 100, color: '#B42318' }} />
                <Typography
                    textAlign={'center'}
                    variant={'body1'}
                    fontWeight={'600'}
                    sx={{ mb: 2 }}>
                    ¡No se pudo registrar tu tarjeta, por favor inténtalo nuevamente!
                </Typography>
                <Typography
                    textAlign={'center'}
                    variant={'body1'}
                    fontWeight={'600'}
                    sx={{ mb: 2 }}>
                    {`Te redirigimos a la selección de métodos de
                    pago en`}
                </Typography>
                <Typography
                    textAlign={'center'}
                    variant={'h4'}
                    fontWeight={'600'}
                    color={'#000000ab'}>
                    {counter}
                </Typography>
                <BounceLoader size={60} color="#0069d7" />
            </Box>
        </Box>
    );
};

export { CardCredError };
