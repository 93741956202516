import { styled, Avatar as MuiAvatar, ListItemText as MuiListItemText } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Avatar = styled(MuiAvatar)`
    background: ${ColorConstants.green[50]};
    color: ${ColorConstants.green[900]};
`;

export const ListItemText = styled(MuiListItemText)`
    .MuiListItemText-primary {
        color: ${ColorConstants.blue[900]};
        font-weight: 600;
        font-size: 22px;
        line-height: 28px;
        letter-spacing: -0.44px;
    }
    .MuiListItemText-secondary {
        color: ${ColorConstants.blue[900]};
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }
`;
