export const BNPLConstants = {
    PERSON_DATA: 'personData',
    LOAN_DATA: 'LoanData',
    LOAN_COMMERCE: 'CANAL DIGITAL',
    WORKFLOW: 'workflow',
    CREDENTIALS: 'credentials',
    ORDER_HASH: 'orderHash',
    IS_CROSS_DEVICE: 'ICD',
    REGISTER_HASH: 'registerHash'
};

export const WORKFLOW_CONSTANTS = {
    BNPL: 'BNPL',
    CS: 'CS'
};

export const APP_ID = {
    LN: 1,
    BNPL: 2,
    CS: 3
};

export const ORDER_STATUS = {
    SUCCESS: 1,
    CANCEL: 2
};

export const PURCHASE_STATUS = {
    CONTINUING_FROM_MOBILE: 'continuing_from_mobile',
    WAITING_FOR_CONFIRMATION: 'waiting_for_confirmation',
    CANCELLED: 'cancelled',
    IDENTITY_NOT_VERIFIED: 'identity_not_verified'
};

export const INSTALLMENT_TYPE = {
    VENCIDO: 'VENCIDO',
    ADELANTADO: 'ADELANTADO'
};
