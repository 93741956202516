import { trackPromise } from "react-promise-tracker";

export const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        const imageCompressed = await trackPromise(compressPhoto(reader.result, 800));
        resolve(imageCompressed);
      };
      reader.onerror = reject;
    });
  };

export const compressPhoto = (imageB64, maxSize) => {
    const image = new Image();
    return new Promise((res) => {
        image.onload = () => {
            imageB64 = resizeImage(image, maxSize);
            res(imageB64);
        };
        image.src = imageB64;
    });
};

export const resizeImage = (image, maxSize) => {
    const canvas = document.createElement('canvas');
    let width = image.width;
    let height = image.height;
    if (width > height) {
        if (width > maxSize) {
            height *= maxSize / width;
            width = maxSize;
        }
    } else {
        if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
        }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const resizedImage = canvas.toDataURL('image/png');
    return resizedImage;
};