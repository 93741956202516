import { Link } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';


const BackHomeButton = ({ action }) => {
    return (
        <Box
            textAlign={'center'}
            mt="24px"
            sx={{ display: { xs: 'block', md: 'none' } }}
            width={'100%'}>
            <Link
                href={'/'}
                onClick={() => {
                    action();
                }}>
                Volver al inicio
            </Link>
        </Box>
    );
};

export default BackHomeButton;
