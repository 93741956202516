import { MobileStepper, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Stepper = styled(MobileStepper)`
    .MuiMobileStepper-dots {
        gap: 8px;
    }
    .MuiMobileStepper-dot {
        width: 30px;
        height: 6px;
        border-radius: 3px;
        background: ${ColorConstants.blue[100]};
        margin: 0;
    }
    .MuiMobileStepper-dotActive {
        background: ${ColorConstants.blue[900]};
    }
`;
