import { Button, Grid} from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ColorConstants } from '../../../Constants/ColorConstants';

const RowDetail = styled(Grid)(() => ({
    display: 'flex',
    // flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '30px',
    paddingRight: '30px',
    borderRadius: '32px',
    background: ColorConstants.MIDDLE_LIGHT_BLUE
}));

const Content = styled(Grid)(() => ({
    fontFamily: 'Archivo',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex!important',
    flexDirection: 'column!important',
    alignItems: 'start',
    alignContent: 'center'
}));

const Value = styled(Grid)(() => ({
    fontWeight: 600,
    color: ColorConstants.BLUE
}));

const Label = styled(Grid)(() => ({
    display: 'flex',
    fontWeight: 400,
    color: ColorConstants.BLUE
}));

export const AccountDetail = (props) => {
    const { body, value = '', label = '', icon, action } = props;

    return (
        <RowDetail onClick={action ? () => action() : null} my="8px">
            {body ? (
                body
            ) : (
                <>
                    <Content>
                        <Label>{label}</Label>
                        <Value>{value}</Value>
                    </Content>

                    <Button sx={{ p: 0, justifyContent: 'end' }} disableRipple>
                        {icon ? icon : null}
                    </Button>
                </>
            )}
        </RowDetail>
    );
};
