import React from 'react';
import { Grid } from '@mui/material';
import { HowItWorksSection } from './HowItWorksSection';
import { DoubtsSection } from './DoubtsSection';
import { LoanSection } from './LoanSection';
import { ContactPage } from '../Contact/ContactPage';
import { HomeState } from '../../Contexts/Home/HomeState';
import { MobileCheck } from '../../Helpers/MobileCheck';
import { useEffect } from 'react';
import { cleanBNPLOrderData } from '../../Helpers/BNPLUtils';

export const PublicHome = () => {
    useEffect(() => {
        cleanBNPLOrderData();
    }, []);

    return (
        <Grid container className="bg-aqua">
            <HomeState>

                <Grid container>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={11} sm={8} sx={{ ml: MobileCheck() ? 2 : 0 }}>
                        <LoanSection />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: 'white' }}>
                    <Grid item sm={2}></Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ ml: MobileCheck() ? 4 : 0, mr: MobileCheck() ? 3 : 0 }}>
                        <HowItWorksSection />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
                <Grid
                    container
                    sx={{ backgroundColor: 'white', borderRadius: '0px 0px 40px 40px' }}
                    mb={2}>
                    <Grid item xs={1} sm={2}></Grid>
                    <Grid item xs={10} sm={9}>
                        <DoubtsSection />
                    </Grid>
                    <Grid item xs={1} sm={2}></Grid>
                </Grid>
                <Grid container sx={{ backgroundColor: '#F5FAFF' }}>
                    <Grid item sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                        <ContactPage />
                    </Grid>
                    <Grid item sm={2}></Grid>
                </Grid>
            </HomeState>
        </Grid>
    );
};
