import { Box } from '@mui/material';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import FormChangePassword from '../FormChangePassword';

export const ChangePassword = () => {
    const titleForm = 'Configurar mi contraseña';
    const descriptionForm =
        'Para proteger tu cuenta usa una nueva contraseña que no hayas usado antes';
    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton route={'/'} />
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormChangePassword />
        </Box>
    );
};
