import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLOrderContext } from '../BNPLOrder/BNPLOrderContext';
import { getItemService } from '../../Services/LocalStorageService';
import { BNPLConstants, PURCHASE_STATUS } from '../../Constants/BNPLConstants';
import { getHashQR } from '../../Services/BNPLService';
import STRINGS from '../../i18n/local/es.json';

const { REACT_APP_POOLING_PERIOD } = process.env;

const BNPLCrossDeviceContext = createContext(null);

const BNPLCrossDeviceProvider = ({ children }) => {
    const navigate = useNavigate();
    const { orderData, updateOrderData, setFeedbackType } = useContext(BNPLOrderContext);
    const [startPooling, setStartPooling] = useState(false);
    const [activeStep, setActiveStep] = useState(-1);
    const personData = JSON.parse(getItemService(BNPLConstants.PERSON_DATA));
    const [purchaseStatus, setPurchaseStatus] = useState(PURCHASE_STATUS.CONTINUING_FROM_MOBILE);
    const [qrUrl, setQrUrl] = useState();

    const steps = [
        STRINGS.scan_qr_code.stepper.STEP_1,
        STRINGS.scan_qr_code.stepper.STEP_2,
        STRINGS.scan_qr_code.stepper.STEP_3,
        STRINGS.scan_qr_code.stepper.STEP_4,
        STRINGS.scan_qr_code.stepper.STEP_5
    ];

    useEffect(() => {
        if (orderData.status) {
            const step = orderData.steps.stepId - 1;
            setActiveStep(step);
            if (orderData.status === 'Cancel') {
                if (step === 1) {
                    setPurchaseStatus(PURCHASE_STATUS.IDENTITY_NOT_VERIFIED);
                } else {
                    setPurchaseStatus(PURCHASE_STATUS.CANCELLED);
                }
            } else if (step === steps.length - 1) {
                // completed purchase
                setFeedbackType('SUCCESSFUL_PAYMENT')
                setTimeout(() => {
                    navigate(RoutesConstants.BNPL_FEEDBACK);
                }, 1000);
            }
            setStartPooling(true)
        }
    }, [orderData]);

    useEffect(() => {
        if (startPooling) {
            const intervalId = setInterval(() => {
                updateOrderData();
            }, REACT_APP_POOLING_PERIOD * 1000);
            updateOrderData();
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [startPooling]);

    useEffect(() => {
        if (orderData.id && personData.personId && !qrUrl) {
            getHashQR(orderData.id, personData.personId)
                .then((response) => {
                    const url = window.location.origin;
                    const path = RoutesConstants.CROSS_DEVICE;
                    const param = response.data.hashQR;
                    setQrUrl(url + path + '?hashQR=' + param + '&ecommerceAccountId=' + personData.ecommerceAccountId);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [orderData, personData]);

    const state = {
        steps,
        activeStep,
        purchaseStatus,
        qrUrl
    };

    const actions = {};

    return (
        <BNPLCrossDeviceContext.Provider value={{ state, actions }}>
            {children}
        </BNPLCrossDeviceContext.Provider>
    );
};

export { BNPLCrossDeviceContext, BNPLCrossDeviceProvider };