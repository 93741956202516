import React from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { PaymentMethodState } from '../../../Contexts/PaymentMethod/PaymentMethodState';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { Description } from '../../../Components/BNPL/Description/Description';
import STRINGS from '../../../i18n/local/es.json'
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { firstCapitalLetter } from '../../../Helpers/Masks';
import { LoanRequestConstantsLS  } from '../../../Contexts/LoanRequest/LoanRequestActions';

export const PaymentMethodGeneralPage = () => {
    const pathname = window.location.pathname;
    const personData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.PersonToGetLoan));
    const userName = personData?.personName?.split(',')[1] || '';
    const firstName = userName.split(' ')[0];
    const firstCapitalizedName = firstCapitalLetter(firstName);

    return (
        <PaymentMethodState>
            <Grid container>
                <BNPLLayout
                    ContainerPanel={ pathname === RoutesConstants.LOAN_PAY_METHOD && 
                        <Description title={STRINGS.offer.FORM_TITLE + firstCapitalizedName
                        } 
                            description={STRINGS.offer.FORM_DESCRIPTION} returnButton={false} />}
                    Form={<Outlet />}
                    
                />
            </Grid>
        </PaymentMethodState>
    );
};