import { Box, Typography, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const TitleContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
`;

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        fontSize: '30px',
        fontWeight: '600',
        lineHeight: '34px',
        letterSpacing: '-0.02em'
    },
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    letterSpacing: '-0.02em',
    color: ColorConstants.error[700]
}));

export const Description = styled(Typography)`
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${ColorConstants.error[700]};
`;

export const Circle = styled(Box)`
    width: 40px;
    min-width: 40px;
    height: 40px;
    background: ${ColorConstants.error[200]};
    outline: 6px solid ${ColorConstants.error[100]};
    border-radius: 28px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${ColorConstants.error[600]};
`;
