import { useEffect, useRef, useState, useContext } from 'react';
import {
    Box,
    FormControl,
    Grid,
    OutlinedInput,
    Stack,
    Typography,
    styled,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ErrorLabel from '../../Components/Form/ErrorLabel';
import STRINGS from '../../i18n/local/es.json';
import { ColorConstants } from '../../Constants/ColorConstants';
import { AcceptTermsAndPolicy } from '../../Components/AcceptTermsAndPolicy';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { useEmailValidateTokenHandler } from './ValidateEmailTokenHandler';
import { BNPLConstants } from '../../Constants/BNPLConstants';
import { allValuesComplete, isEmpty } from '../../Helpers/Utils';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../Services/LocalStorageService';
import { RegisterConstantsLS } from '../../Contexts/Register/RegisterActions';
import { AppConstantsLS } from '../../Contexts/Global/AppActions';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import AppContext from '../../Contexts/Global/AppContext';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const EmailTokenForm = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();
    const { OnLogin, emailData } = useContext(AppContext);
    const { verifyEmailToken, resendEmailToken } = useEmailValidateTokenHandler();
    const { initOrderRequest } = useContext(BNPLOrderContext);

    const drmerchant = localStorage.getItem("drmerchant");
    const registerHash = getItemService(BNPLConstants.REGISTER_HASH);
    const ecommerceAccountID = getItemService(RegisterConstantsLS.EcommerceAccountID);

    const initialStateValues = { 1: '', 2: '', 3: '', 4: '' };
    const TokenPosition = [1, 2, 3, 4];

    const [values, setValues] = useState(initialStateValues);
    const [valuesRef] = useState({ 1: useRef(), 2: useRef(), 3: useRef(), 4: useRef() });
    const [onFocus, setOnFocus] = useState(TokenPosition.First);
    const [errorToken, setErrorToken] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const [countdown, setCountdown] = useState(process.env.REACT_APP_NEW_MESSAGE_TIMEOUT);
    const [lapseTime, setLapseTime] = useState(true);

    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            setLapseTime(false);
        }

        return () => clearInterval(intervalId);
    }, [countdown]);

    const handleTokenComplete = () => {
        const token = values[1] + values[2] + values[3] + values[4];
        setErrorToken(false);
        verifyEmailToken(token, ecommerceAccountID, registerHash)
            .then((response) => {
                if (response === true) {
                    setItemService(AppConstantsLS.EcommerceAccountId, ecommerceAccountID);
                    removeItemService(RegisterConstantsLS.EcommerceAccountID);
                    removeItemService('email');
                    removeItemService(BNPLConstants.REGISTER_HASH);
                    initOrderRequest();
                    // LOGIN
                    if(emailData.identifier && emailData.password)
                        OnLogin(emailData.identifier, emailData.password)
                } else {
                    setErrorToken(true);
                }
            })
            .catch(() => setErrorToken(true));
    };

    useEffect(() => {
        setTimeout(() => {
            setErrorToken(false);
            setTokenExpired(true);
        }, 1000 * 60 * 10); //10 minutes

        if(!emailData){
            navigate(RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE)
        }
    }, []);

    let timeout;
    useEffect(() => {
        if (!isEmpty(values[4]) && allValuesComplete(values)) {
            timeout = setTimeout(() => {
                setCountdown(0);
                handleTokenComplete();
            }, 1000);
        }
    }, [values]);

    const handleChange = (prop) => (event) => {
        clearInterval(timeout);
        const value = event.target.value.replace(/\s/g, '');

        // Delete and move focus to the previous field
        if (value.length === 0) {
            setValues((prevValues) => ({ ...prevValues, [prop]: value }));
            const prevPosition = TokenPosition[TokenPosition.indexOf(prop) - 1];
            if (prevPosition) {
                setOnFocus(prevPosition);
                valuesRef[prevPosition].current.focus();
            }
        }

        // Write and move focus to the next field
        if (value.length === 1) {
            setValues((prevValues) => ({ ...prevValues, [prop]: value }));
            const nextPosition = TokenPosition[TokenPosition.indexOf(prop) + 1];
            if (nextPosition) {
                setOnFocus(nextPosition);
                valuesRef[nextPosition].current.focus();
            }
        }

        // Case: Second value in the same field
        if (value.length === 2) {
            const splitValues = String(value).split('');
            setValues((prevValues) => ({
                ...prevValues,
                [prop]: splitValues[0],
                [prop + 1]: splitValues[1]
            }));
            const nextPosition = TokenPosition[TokenPosition.indexOf(prop) + 1];
            if (nextPosition) {
                setOnFocus(nextPosition);
                valuesRef[nextPosition].current.focus();
            }
        }

        // Paste a complete token
        if (value.length === 4) {
            const splitValues = String(value).split('');
            const updatedValues = { ...values };
            TokenPosition.forEach((position, index) => {
                updatedValues[position] = splitValues[index];
            });
            setValues(updatedValues);
        }
    };

    const cleanToken = () => {
        setValues(initialStateValues);
        setErrorToken(false);
        setOnFocus(1);
        valuesRef[1].current.focus();
    };

    const onSubmit = async () => {
        cleanToken();
        try {
            await resendEmailToken(ecommerceAccountID, registerHash);
            setCountdown(process.env.REACT_APP_NEW_MESSAGE_TIMEOUT);
            setLapseTime(true);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Stack direction="row" gap={1} justifyContent="center">
                {TokenPosition.map((position) => (
                    <Box key={position}>
                        <FormControl fullWidth focused={onFocus == position}>
                            <OutlinedInput
                                id={`${position}`}
                                value={values[position]}
                                className={`tokenInput ${errorToken ? '' : 'tokenSuccess'}`}
                                onChange={handleChange(position)}
                                inputRef={valuesRef[position]}
                                onFocus={() => setOnFocus(position)}
                                onBlur={() => setOnFocus(0)}
                                error={errorToken}
                                inputProps={{
                                    inputMode: 'numeric'
                                }}
                            />
                        </FormControl>
                    </Box>
                ))}
            </Stack>
            <Grid container justifyContent="center">
                {(errorToken || tokenExpired) && (
                    <Grid item xs={11} lg={9}>
                        <ErrorLabel
                            fontSizeText="14px"
                            description={
                                errorToken
                                    ? STRINGS.verify_email_code.INVALID_CODE
                                    : STRINGS.verify_email_code.EXPIRED_CODE
                            }
                        />
                    </Grid>
                )}
            </Grid>
            <Stack mt={isMobile ? 20 : 7}>
                {lapseTime && (
                    <Box textAlign="center">
                        <StyledText>
                            {STRINGS.verify_email_code.LAPSE_TIME.replace(
                                0,
                                countdown > 9 ? `0:${countdown}` : `0:0${countdown}`
                            )}
                        </StyledText>
                        {isMobile && (
                            <StyledText>{STRINGS.verify_email_code.CHECK_SPAM}</StyledText>
                        )}
                    </Box>
                )}
            </Stack>
            <>
                {!isMobile && <AcceptTermsAndPolicy />}
                <GrayButton
                    text={STRINGS.verify_email_code.NEW_CODE_BTN}
                    action={() => onSubmit()}
                    disabled={lapseTime}
                />
                <BackHomeDialogButton text={drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE} />
            </>
        </>
    );
};

const StyledText = styled(Typography)({
    fontSize: '14px',
    fontFamily: 'Archivo',
    color: ColorConstants.BLUE
});
