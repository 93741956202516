import { Stack } from '@mui/material';
import { Option, TitleOption, DescriptionOption, AvatarIcon } from './PaymentMethodOption.styled';

export const PaymentMethodOption = ({ value, ...props }) => {
    return (
        <Option {...props}>
            <Stack direction="row" spacing={2}>
                <AvatarIcon>{value.icon}</AvatarIcon>
                <Stack>
                    <TitleOption>{value.title}</TitleOption>
                    <DescriptionOption>{value.description}</DescriptionOption>
                </Stack>
            </Stack>
        </Option>
    );
};
