import { Box, Grid } from '@mui/material';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DescriptionForm, RadioGroup } from '../../Components/BNPL';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLRegisterContext } from '../../Contexts/BNPLRegister/BNPLRegisterContext';
import STRINGS from '../../i18n/local/es.json';
import { getDataPersonService } from '../../Services/ChangePasswordService';

export const ConfirmPage = () => {
    const { personData, peopleList, handleChangeMultipleValues, searchPersonByDniAndGender, setAccountData } =
        useContext(BNPLRegisterContext);
    const navigate = useNavigate();
    const [value, setValue] = useState(null);

    const optionsToShow = useMemo(() => {
        return [...peopleList].map((option, index) => {
            return {
                value: index,
                label: option.personName
            };
        });
    }, [peopleList]);

    const handleContinue = () => {
        localStorage.setItem('personData', JSON.stringify(personData));
        getDataPersonService(personData.personId)
            .then((res) => {
                setAccountData(res);
                if (res.googleAccountId || res.facebookAccountId || res.emailAccountId) {
                    if (process.env.REACT_APP_SKIP_EXISTING_ACCOUNT === 'true') {
                        navigate(RoutesConstants.IDENTIFICATION_CONTACT_PAGE);
                    } else {
                        navigate(RoutesConstants.EXISTING_ACCOUNT);
                    }
                } else {
                    navigate(RoutesConstants.IDENTIFICATION_CONTACT_PAGE);
                }
            })
            .catch((err) => {
                console.error('Error fetching data from server:', err);
            });
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('personData'));
        if (peopleList.length === 0) {
            if (data && data.dni && data.gender) {
                handleChangeMultipleValues({
                    ...data,
                    name: '',
                    areaCode: '',
                    phoneNumber: '',
                    provinceId: 0,
                    personId: ''
                });
                localStorage.setItem(
                    'personData',
                    JSON.stringify({
                        ...data,
                        name: '',
                        areaCode: '',
                        phoneNumber: '',
                        provinceId: 0,
                        personId: ''
                    })
                );
                searchPersonByDniAndGender(data.dni, data.gender);
            } else {
                navigate(RoutesConstants.IDENTIFICATION_DNI_PAGE);
            }
        }
    }, []);

    const handleRadioButtonOnClick = (event) => {
        setValue(event.target.value);
        const person = peopleList[event.target.value];
        handleChangeMultipleValues({
            name: person.personName,
            areaCode: person.phoneCode ? person.phoneCode : '',
            phoneNumber: person.phoneNumber ? person.phoneNumber : '',
            provinceId: person.provinceId > 0 ? person.provinceId : 0,
            personId: person.personId,
            cuit: person.cuit ? person.cuit : ''
        });
    };

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton />
                </Grid>
                <DescriptionForm
                    title={STRINGS.identification.confirmPage.TITLE}
                    description={STRINGS.identification.confirmPage.SUBTITLE}
                    pb={3}
                />
                <RadioGroup
                    options={optionsToShow}
                    selectedValue={value}
                    onChange={handleRadioButtonOnClick}
                />
            </Box>

            <Box>
                <GrayButton
                    text={STRINGS.identification.confirmPage.BTN}
                    id="confirmar_identidad"
                    disabled={!value}
                    action={handleContinue}
                />
                <Box textAlign="center" mt="24px" width="100%">
                    <Link to={RoutesConstants.IDENTIFICATION_DNI_PAGE}>
                        {STRINGS.identification.confirmPage.BACK_BTN}
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};
