import { Radio } from '@mui/material';
import { CheckIcon } from '../../Icons';
import StyledRadioButton, { RadioIcon } from './RadioButton.styled';

export const RadioButton = ({ value, isSelected }) => {
    return (
        <StyledRadioButton
            className={isSelected ? 'selected' : ''}
            labelPlacement="start"
            value={value.value}
            control={
                <Radio
                    icon={<RadioIcon />}
                    checkedIcon={
                        <RadioIcon className="checked">
                            <CheckIcon />
                        </RadioIcon>
                    }
                />
            }
            label={value.label}
        />
    );
};
