import { Box } from '@mui/material';
import { ReturnButton } from '../../../Components/LoginBtns/ReturnButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { DNIForm } from '../../../Components/DNIForm';
import RegisterContext from '../../../Contexts/Register/RegisterContext';
import AppContext from '../../../Contexts/Global/AppContext';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { getItemService } from '../../../Services/LocalStorageService';
import jwtDecode from 'jwt-decode';

export const CheckDNI = () => {
    const navigate = useNavigate();
    const titleForm = '¡Ya casi terminamos!';
    const descriptionForm =
        'Este es tu primer ingreso y para brindarte una mejor experiencia necesitamos tu DNI';

    const {
        savePerson,
        tokenValidate,
        alreadyExistsAccount,
        setAlreadyExistsAccount,
        autoRegisterPerson
    } = useContext(RegisterContext);
    const { tryRegisterExternal } = useContext(AppContext);

    useEffect(() => {
        const user = getItemService('TokenRegister')
            ? jwtDecode(getItemService('TokenRegister'))
            : null;
     if (user && user?.cuit) {
            const UserForRegister = {
                cuit: Number(user.Cuit),
                dni: Number(user.Dni),
                name: user.Name,
                phone: user.Phone,
                ecommerceAccountId: Number(user.Ecommerce),
                provinceId: Number(user.ProvinceId),
                sexTypeId: Number(user.SexTypeId),
                personId: Number(user.PersonId)
            };
            autoRegisterPerson(UserForRegister);
        }
    }, []);

    useEffect(() => {
        if (tryRegisterExternal) {
            return;
        }
        const isValid = tokenValidate();
        const registerMethod = getItemService('REGISTER_METHOD') || null;
        if (registerMethod) return;
        !isValid && navigate(RoutesConstants.REGISTER_EXPIRED_LINK_PAGE);
    }, []);

    return (
        <Box style={{ textAlign: 'center' }}>
            <ReturnButton route={RoutesConstants.REGISTER_CREATE_ACCOUNT_PAGE} />
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <DNIForm
                action={savePerson}
                alreadyExistsAccount={alreadyExistsAccount}
                setAlreadyExistsAccount={setAlreadyExistsAccount}
            />
        </Box>
    );
};
