import {
    Select as MuiSelect,
    InputLabel as MuiInputLabel,
    MenuItem as MuiMenuItem,
    styled
} from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const Select = styled(MuiSelect)(({ className }) => ({
    '.MuiSelect-select': {
        color: `${ColorConstants.gray[700]}`,
        padding: '19px 50px 19px 16px',
        fontWeight: 500,
        zIndex: 1
    },
    fieldset: {
        background: 'white',
        border: `1.5px solid ${ColorConstants.gray[200]}`,
        borderRadius: '16px'
    },
    '.MuiSelect-icon': {
        zIndex: 1
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1.5px solid',
        borderColor: `${ColorConstants.gray[200]}`
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: `${ColorConstants.blue[100]}`
    },
    ...(className === 'error' && {
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: `${ColorConstants.error[600]}`
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `${ColorConstants.error[300]}`
        }
    })
}));

export const InputLabel = styled(MuiInputLabel)`
    font-weight: 500;
    padding-top: 2px;
    color: ${ColorConstants.gray[700]};
`;

export const MenuItem = styled(MuiMenuItem)`
    height: 52px;
    &.MuiMenuItem-root:hover,
    &.Mui-focusVisible,
    &.Mui-selected {
        background-color: ${ColorConstants.blue[50]};
    }
`;
