import { postData, getData } from './BaseService';

const url = 'contact';

export const postContactForm = async (body) => {
    await postData(url, body);
};

export const getProvince = async () => {
    const url = 'province';
    return await getData(url);
};
