import { Box, Grid, Stack } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AcceptTermsAndPolicy } from '../../Components/AcceptTermsAndPolicy';
import { Autocomplete, DescriptionForm, Input } from '../../Components/BNPL';
import { LocationIcon, PhoneIcon } from '../../Components/Icons';
import GrayButton from '../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLRegisterContext } from '../../Contexts/BNPLRegister/BNPLRegisterContext';
import { getProvince } from '../../Services/ContactService';
import STRINGS from '../../i18n/local/es.json';
import AppContext from '../../Contexts/Global/AppContext';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

export const ContactDetailsPage = () => {
    const {
        personData,
        formValidation,
        handleOnChangeFieldEvent,
        validateUserAndSendMessage,
        handleOnChangeField,
        handleChangeMultipleValues,
        getUserInfo
    } = useContext(BNPLRegisterContext);
    const { authentication, user } = useContext(AppContext);
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const [provinces, setProvinces] = useState([]);
    const navigate = useNavigate();
    const drmerchant = localStorage.getItem("drmerchant");


    const loadFormOptions = useCallback(() => {
        getProvince()
            .then((res) => {
                setProvinces(
                    res.map((province) => ({
                        value: province.provinceId,
                        label: province.sDescription
                    }))
                );
                if (personData.provinceId) {
                    const province = res.filter((province) => {
                        return province.provinceId === personData.provinceId;
                    });
                    if (province.length > 0) {
                        const personProvince = province[0];
                        handleOnChangeField('province')({
                            value: personProvince.provinceId,
                            label: personProvince.sDescription
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error); // Aca se lanza un error de la app por default, pero se podría mostrar algo.
            });
    }, [setProvinces]);

    useEffect(() => {
        if (authentication && user) {
            getUserInfo();
        }
    }, []);

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('personData'));
	// TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("DATOS_CONTACTO", storageOrderID, localStorageHash)
            }
            // FINISH
        if ((data && data.dni && data.gender && data.name) || authentication) {
            handleChangeMultipleValues(JSON.parse(localStorage.getItem('personData')));
            loadFormOptions();
        } else {
            navigate(RoutesConstants.IDENTIFICATION_DNI_PAGE);
        }
    }, []);

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton route={RoutesConstants.IDENTIFICATION_CONFIRM_PAGE} />
                </Grid>
                <DescriptionForm
                    title={`${personData.name && personData.name.split(',')[1]}${
                        authentication
                            ? STRINGS.identification.contactDetailsPage.WITH_ACCOUNT_TITLE
                            : STRINGS.identification.contactDetailsPage.TITLE
                    }`}
                    description={STRINGS.identification.contactDetailsPage.SUBTITLE}
                    pb={3}
                />

                <Stack direction="row" mb={2} spacing={1}>
                    <Input
                        value={personData.areaCode}
                        label={STRINGS.identification.contactDetailsPage.area_code.LABEL}
                        onChange={handleOnChangeFieldEvent('areaCode')}
                        iconStart={<PhoneIcon />}
                        prefix={STRINGS.identification.contactDetailsPage.area_code.PREFIX}
                        helperText={STRINGS.identification.contactDetailsPage.area_code.HELPER_TEXT}
                        error={!!formValidation.areaCodeValid}
                        errorText={formValidation.areaCodeValid}
                    />

                    <Input
                        value={personData.phoneNumber}
                        label={STRINGS.identification.contactDetailsPage.phone_number.LABEL}
                        onChange={handleOnChangeFieldEvent('phoneNumber')}
                        prefix={STRINGS.identification.contactDetailsPage.phone_number.PREFIX}
                        helperText={
                            STRINGS.identification.contactDetailsPage.phone_number.HELPER_TEXT
                        }
                        error={!!formValidation.phoneNumberValid}
                        errorText={formValidation.phoneNumberValid}
                    />
                </Stack>

                {!authentication && !personData.provinceId && (
                    <Autocomplete
                        value={personData.province}
                        label={STRINGS.identification.contactDetailsPage.province.LABEL}
                        onChange={handleOnChangeFieldEvent('province')}
                        noOptionsText={
                            STRINGS.identification.contactDetailsPage.province.ERROR_TEXT
                        }
                        iconStart={<LocationIcon color="#656566" />}
                        options={provinces}
                    />
                )}
            </Box>
            <Box>
                <AcceptTermsAndPolicy />
                <GrayButton
                    type="submit"
                    text={STRINGS.buttons.CONTINUE}
                    action={() => {
                        validateUserAndSendMessage();
                    }}
                    disabled={
                        !!formValidation.phoneNumberValid ||
                        !!formValidation.areaCodeValid ||
                        !!formValidation.provinceValid
                    }
                />
                <Box textAlign="center" mt="24px" width="100%">
                    <BackHomeDialogButton
                        text={(drmerchant == 'S' && workflow === WORKFLOW_CONSTANTS.BNPL) ? STRINGS.buttons.CANCEL_PURCHASE : (workflow === WORKFLOW_CONSTANTS.BNPL && STRINGS.buttons.BACK_TO_STORE)}
                    />
                </Box>
            </Box>
        </Box>
    );
};
