import { FormControl } from '@mui/material';
import { useState } from 'react';
import { InputLabel, MenuItem, Select } from './SelectMenu.styled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
export const SelectMenu = ({
    label = '',
    items = [],
    onChange,
    disabled = false,
    error = false
}) => {
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        onChange(event);
    };

    return (
        <FormControl fullWidth disabled={disabled} error={error}>
            <InputLabel>{label}</InputLabel>
            <Select
                className={error ? 'error' : ''}
                value={selectedValue}
                label={label}
                onChange={handleChange}
                IconComponent={KeyboardArrowDownIcon}>
                {items.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
