import React, { useEffect, useContext } from 'react';
import { trackPromise} from 'react-promise-tracker';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { feedback_gradients } from '../../Financing/FinancingPage.styled';
import { Box } from '@mui/material';
import { DescriptionForm } from '../../../Components/BNPL';
import { Img } from '../../../Components/ImageContainer';
import ThumbsDownImg from '../../../Assets/feedback-unsuccessful-payment.svg';
import STRINGS from '../../../i18n/local/es.json';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { BNPLConstants, ORDER_STATUS } from '../../../Constants/BNPLConstants';
import { updateOrderStatus } from '../../../Services/BNPLService';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';
import AppContext from '../../../Contexts/Global/AppContext';

const CancelPurchaseForm = () => {
    return (
        <Box textAlign="center">
            <DescriptionForm
                title={""}
                description={""}
            />
            <Box my={12} display="flex" justifyContent="center">
                <Img src={ThumbsDownImg} />
            </Box>
        </Box>
    );
};

export const CancelPurchasePage = () => {
    const { removeSession } = useContext(AppContext);
    const { orderData } = useContext(BNPLOrderContext)
    const navigate = useNavigate();

    const resolveAfterSeconds = (url) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                window.location.replace(url);
                resolve(true);
            }, 4000);
        });
    };
    
    useEffect(() => {
        console.log(orderData)
        if (orderData.id) {
            updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
                .then(() => {
                    const isCrossDevice = localStorage.getItem(BNPLConstants.IS_CROSS_DEVICE);
                    if (isCrossDevice === 'true') {
                        navigate(RoutesConstants.PURCHASE_CANCELED);
                    } else {
			            removeSession();
                        trackPromise(
                            resolveAfterSeconds(orderData.return_Url_Error)
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                    navigate(RoutesConstants.PURCHASE_CANCELED);
                });
        }
    }, [orderData]);

    const layoutProps = {
        mobile: {
            background: 'transparent',
            showDescription: false,
            gradients: feedback_gradients
        }
    };
    return <BNPLLayout Form={<CancelPurchaseForm />} layoutProps={layoutProps} />;
};
