import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormControl, Grid, OutlinedInput, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@mui/material';
import GrayButton from '../../../../Components/LoginBtns/GrayButton';
import { Box } from '@mui/system';
import { ColorConstants } from '../../../../Constants/ColorConstants';
import { allValuesComplete, isEmpty } from '../../../../Helpers/Utils';
import ErrorLabel from '../../../../Components/Form/ErrorLabel';
import { useNavigate } from 'react-router-dom';
import { ValidateBNPLTokenHandlerContext } from './ValidateBNPLTokenHandler';
import { BNPLRegisterContext } from '../../../../Contexts/BNPLRegister/BNPLRegisterContext';
import { RoutesConstants } from '../../../../Constants/RoutesConstants';
import AppContext from '../../../../Contexts/Global/AppContext';
import STRINGS from '../../../../i18n/local/es.json';
import { BNPLConstants, ORDER_STATUS, WORKFLOW_CONSTANTS } from '../../../../Constants/BNPLConstants';
import { ALLOWED_REDIRECT_PARAMS } from '../../../../Helpers/GetURLByParam';
import { updateOrderStatus } from '../../../../Services/BNPLService';
import { BNPLOrderContext } from '../../../../Contexts/BNPLOrder/BNPLOrderContext';
import { BackHomeDialogButton } from '../../../../Components/BNPL/Buttons/BackHomeDialogButton';
import { CheckIcon } from '../../../../Components/Icons';

const BNPLTokenForm = () => {
    const drmerchant = localStorage.getItem("drmerchant");
    const { loanData, personData } = useContext(BNPLRegisterContext);
    const { verifyToken, resendToken, saveContactData } = useContext(
        ValidateBNPLTokenHandlerContext
    );
    const { authentication } = useContext(AppContext);
    const { orderData } = useContext(BNPLOrderContext);
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const initialStateValues = { 1: '', 2: '', 3: '', 4: '' };
    const TokenPosition = [1, 2, 3, 4];

    const navigate = useNavigate();
    const [values, setValues] = useState(initialStateValues);
    const [valuesRef] = useState({ 1: useRef(), 2: useRef(), 3: useRef(), 4: useRef() });
    const [onFocus, setOnFocus] = useState(TokenPosition.First);
    const [lapseTime, setLapseTime] = useState(true);
    const [countdown, setCountdown] = useState(process.env.REACT_APP_NEW_MESSAGE_TIMEOUT);
    const [verifySMSCodeSuccess, setVerifySMSCodeSuccess] = useState(false);
    const [errorToken, setErrorToken] = useState(false);

    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        } else {
            setLapseTime(false);
        }

        return () => clearInterval(intervalId);
    }, [countdown]);

    let timeout;
    const handleChange = (prop) => (event) => {
        clearInterval(timeout);
        const value = event.target.value.replace(/\s/g, '');

        //Borra y retrocede un campo
        if (value.length === 0) {
            setValues({ ...values, [prop]: value });
        }

        //Escribe y avanza al próximo campo
        if (value.length === 1) {
            setValues({ ...values, [prop]: value });
            if (prop + 1 != 5) {
                setOnFocus(prop + 1);
                valuesRef[prop + 1].current.click();
            }
        }

        //Caso especial: Segundo valor en el mismo campo
        if (value.length === 2) {
            const splitValues = String(value).split('');
            setValues({ ...values, [prop + 1]: splitValues[1] });
            if (prop + 1 != 5) {
                setOnFocus(Number([prop]) + 1);
                valuesRef[prop + 1].current.click();
            }
        }

        //Al pegar un token completo
        if (value.length == 4) {
            const splitValues = String(value).split('');
            setValues({
                ...values,
                [prop]: splitValues[0],
                [prop + 1]: splitValues[1],
                [prop + 2]: splitValues[2],
                [prop + 3]: splitValues[3]
            });
        }
    };

    const getNextPage = () => {
        const routes = {
            BNPL: authentication
                ? RoutesConstants.FINANCING_PAGE
                : `${RoutesConstants.REGISTER_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.FINANCING}`,
            CS: authentication
                ? RoutesConstants.AVAILABLE_BALANCE
                : `${RoutesConstants.REGISTER_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.BALANCE}`
        };
        return routes[localStorage.getItem(BNPLConstants.WORKFLOW)] || RoutesConstants.PUBLIC_HOME;
    };

    const handleCodeComplete = (code) => {
        setErrorToken(false);
        verifyToken(loanData.pendingRequestId, code)
            .then((response) => {
                if (response.status != 200) {
                    setErrorToken(true);
                    return;
                }

                saveContactData({
                    personId: personData.personId,
                    phoneNumberComplete: personData.fullPhoneNumber
                })
                    .then(() => {
                        setVerifySMSCodeSuccess(true);
                        if (loanData.offer[0].maximumAmount < orderData.price) {
                            updateOrderStatus(orderData.id, ORDER_STATUS.CANCEL)
                                .then(() => {
                                    setTimeout(() => {
                                        navigate(RoutesConstants.INSUFFICIENT_FUNDS_PAGE);
                                    }, 2000);
                                })
                                .catch(() => {
                                    setTimeout(() => {
                                        navigate(RoutesConstants.INSUFFICIENT_FUNDS_PAGE);
                                    }, 2000);
                                });
                        } else {
                            setTimeout(() => {
                                navigate(getNextPage());
                            }, 2000);
                        }
                    })
                    .catch(() => {
                        // Se debería mostrar un error distinto al codigo invalido.
                        setErrorToken(true);
                    });
            })
            .catch(() => {
                setErrorToken(true);
            });
    };

    useEffect(() => {
        if (!isEmpty(values[4]) && allValuesComplete(values)) {
            timeout = setTimeout(() => {
                setCountdown(0);
                handleCodeComplete(values[1] + values[2] + values[3] + values[4]);
            }, 1000);
        }
    }, [values]);

    const cleanToken = () => {
        setValues(initialStateValues);
        setErrorToken(false);
        setOnFocus(1);
        valuesRef[1].current.click();
    };

    const onSubmit = async () => {
        cleanToken();
        try {
            await resendToken(loanData.pendingRequestId, personData.fullPhoneNumber);
            setCountdown(process.env.REACT_APP_NEW_MESSAGE_TIMEOUT);
            setLapseTime(true);
        } catch (error) {
            console.log(error);
        }
    };

    const timerMessage = `Si aún no lo recibiste en 0:${
        countdown > 9 ? countdown : '0' + countdown
    } podrás pedir otro.`;

    const errorTokenMessage =
        'El código ingresado no es válido, revisá que coincida con el enviado o pedí uno nuevo';

    return (
        <Grid
            display={'flex'}
            flexWrap={'wrap'}
            alignContent={'space-between'}
            width="100%">
            <Grid container justifyContent={'center'}>
                <Box display="flex">
                    {TokenPosition.map((position) => (
                        <Box mx={0.5} my={1} key={position}>
                            <FormControl fullWidth focused={onFocus == position}>
                                <OutlinedInput
                                    id={`${position}`}
                                    value={values[position]}
                                    className={`tokenInput ${
                                        verifySMSCodeSuccess ? 'tokenSuccess' : ''
                                    }`}
                                    onChange={handleChange(position)}
                                    color="info"
                                    ref={valuesRef[position]}
                                    error={errorToken}
                                    inputProps={{
                                        inputMode: 'numeric'
                                    }}
                                />
                            </FormControl>
                        </Box>
                    ))}
                </Box>
                <Box width={'100%'} display="flex" justifyContent="center" flexWrap="wrap" mt="24px" height="140px">
                    {errorToken ? (
                        <Grid item xs={10} display="flex" justifyContent="center">
                            <ErrorLabel fontSizeText={'14px'} description={errorTokenMessage} />
                        </Grid>
                    ) : null}
                    {lapseTime ? (
                        <Typography fontFamily={'Archivo'} color={ColorConstants.BLUE}>
                            {timerMessage}
                        </Typography>
                    ) : null}
                    <List sx={{color: ColorConstants.blue[500]}}>
                        <ListItem key={1}>
                            <ListItemAvatar>
                                <Avatar sx={{ color: "inherit", background: ColorConstants.green[100], border: `5px solid ${ColorConstants.green[50]}` }}>
                                    <CheckIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primaryTypographyProps={{ style: { fontWeight: 700 }}} primary={STRINGS.identification.validateToken.CONTENT_TITLE}
                                secondaryTypographyProps={{ style: { color: "inherit" }}} secondary={STRINGS.identification.validateToken.CONTENT_SUBTITLE}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Grid>
            <Box width={'100%'}>
                <GrayButton
                    text={'Quiero un código nuevo'}
                    action={() => onSubmit()}
                    disabled={lapseTime}
                />
                <BackHomeDialogButton text={(drmerchant == 'S' && workflow === WORKFLOW_CONSTANTS.BNPL) ? STRINGS.buttons.CANCEL_PURCHASE : (workflow === WORKFLOW_CONSTANTS.BNPL && STRINGS.buttons.BACK_TO_STORE)} />
            </Box>
        </Grid>
    );
};

export default BNPLTokenForm;
