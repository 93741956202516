import { TabContext, TabList } from '@mui/lab';
import { Box } from '@mui/material';
import { useState } from 'react';
import { AccordionStack } from '../Accordion/Accordion';
import { Tab, TabContainer, TabPanel, TabPanelContainer } from './Tabs.styled';

export const Tabs = ({ items }) => {
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%'}}>
            <TabContext value={value}>
                <TabContainer>
                    <TabList onChange={handleChange}>
                        {items.map((tab, i) => (
                            <Tab key={`tab_${i + 1}`} label={tab.label} value={(i+1).toString()} />
                        ))}
                    </TabList>
                </TabContainer>
                <TabPanelContainer>
                {items.map((tab, i) => (
                    <TabPanel key={`tab_${i + 1}`} label={tab.label} value={(i+1).toString()}>
                        <AccordionStack {...tab.panel}/>
                    </TabPanel>
                ))}
                </TabPanelContainer>
            </TabContext>
        </Box>
    );
};
