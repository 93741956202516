import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { ButtonCustom } from '../Buttons/ButtonCustom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { Grid, Typography, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { StepsConstants } from '../../Constants/StepsConstants';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const InformationModal = ({ icon, image, buttonPosition, buttonText, dialogHook, type }) => {
    const navigate = useNavigate();

    const { openCamera, images } = useContext(BiometricDataContext);
    const [step, setStep] = useState();

    useEffect(() => {
        setStep(
            images[StepsConstants.THIRD_STEP].confirmImage
                ? StepsConstants.FOURTH_STEP
                : StepsConstants.THIRD_STEP
        );
    }, []);

    const handleContinue = () => {
        dialogHook.CloseDialog();
        navigate(`${RoutesConstants.FACE_CAPTURE_PAGE}/${type}`);
    };

    const handleClose = () => {
        dialogHook.CloseDialog();
    };

    return (
        <div>
            <Dialog
                open={dialogHook.showDialog}
                keepMounted
                onClose={() => handleClose()}
                aria-describedby="alert"
                PaperProps={{
                    style: { borderRadius: 32 }
                }}>
                <DialogContent>
                    {icon && (
                        <Grid
                            sx={{ textAlign: 'right', color: '#0069D7' }}
                            onClick={() => handleClose()}>
                            <CloseIcon />
                        </Grid>
                    )}
                    <Grid container xs={12} sx={{ mt: 5 }} className="mb-30">
                        <Grid item xs={2} sx={{ ml: 0, mr: 0 }}>
                            <Grid sx={{ pt: 0.1 }}>
                                <div className="icon-style">{icon}</div>
                            </Grid>
                        </Grid>
                        <Grid item xs={8} sx={{ textAlign: 'left', color: '#0069D7', mt: 0.5 }}>
                            <Typography className="font-blue font-weight font-size-20">
                                {dialogHook.title}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'left', color: '#0069D7', mb: 2 }}>
                        <Typography>{dialogHook.content}</Typography>
                    </Grid>
                    {image && (
                        <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <img src={image} style={{ width: '100%' }} />
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: buttonPosition, mb: 1, ml: 2, mr: 2 }}>
                    <Box width="100%">
                        <ButtonCustom
                            color={ColorConstants.WHITE}
                            text={buttonText}
                            action={() => handleContinue()}
                        />
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};
