import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const ContactContainer = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        background: 'white',
        padding: '10px 16%'
    },
    background: 'transparent'
}));

export const ContactCard = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        background: 'transparent',
        margin: 0
    },
    background: 'white',
    padding: '24px',
    borderRadius: '32px',
    margin: '0 -16px'
}));

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        fontSize: '40px',
        lineHeight: '48px',
        textAlign: 'center'
    },
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '700',
    marginBottom: '16px'
}));

export const Description = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        textAlign: 'center'
    },
    fontFamily: 'Archivo',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    marginBottom: '16px'
}));

export const ContactBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        maxWidth: '538px',
        margin: '0 auto',
        padding: '90px 0'
    },
    padding: '40px 0',
    color: ColorConstants.blue[900]
}));

export const ContactButtons = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
