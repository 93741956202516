import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Description } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import { BNPLRegisterProvider } from '../../Contexts/BNPLRegister/BNPLRegisterContext';
import AppContext from '../../Contexts/Global/AppContext';
import STRINGS from '../../i18n/local/es.json';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const IdentificationPage = () => {
    const { authentication } = useContext(AppContext);
    const { pathname } = window.location;
    return (
        <BNPLRegisterProvider>
            <BNPLLayout
                ContainerPanel={
                    <Description
                        title={
                            pathname === RoutesConstants.BNPL_TOKEN_PAGE
                                ? STRINGS.identification.TITLE
                                : authentication
                                ? STRINGS.identification.TITLE_AUTH
                                : STRINGS.identification.TITLE
                        }
                        description={
                            pathname === RoutesConstants.BNPL_TOKEN_PAGE
                                ? STRINGS.identification.SUBTITLE
                                : authentication
                                ? STRINGS.identification.SUBTITLE_AUTH
                                : STRINGS.identification.SUBTITLE
                        }
                        returnButton={false}
                    />
                }
                Form={<Outlet />}
            />
        </BNPLRegisterProvider>
    );
};
