import { Grid } from '@mui/material';
import { PartnersContent } from './components/PartnersContent';
import { PartnersForm } from './components/PartnersForm';
import { PartnerStoresLayout } from './PartnerStoresLayout';
import { PartnerStoresProvider } from '../../Contexts/PartnerStores/PartnerStoresContext'

export const PartnerStoresPage = () => {

    return (
        <PartnerStoresProvider>
            <PartnerStoresLayout>
                <Grid container direction={{ xs: 'column', sm: 'row' }}>
                    <Grid item xs={12} sm={5}>
                        <PartnersForm/>
                    </Grid>
                    <Grid item xs={12} sm={7} pl={[0,6]} pt={[6,0]}>
                        <PartnersContent/>
                    </Grid>
                </Grid>
            </PartnerStoresLayout>
        </PartnerStoresProvider>
    );
};
