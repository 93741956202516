import { Box, Grid, Link } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import BackHomeButton from '../../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';
import ErrorMessage from '../../../../Components/Form/ErrorMessage';
import GrayButton from '../../../../Components/LoginBtns/GrayButton';
import { ReturnButton } from '../../../../Components/LoginBtns/ReturnButton';
import { RoutesConstants } from '../../../../Constants/RoutesConstants';
import { LoanRequestConstantsLS } from '../../../../Contexts/LoanRequest/LoanRequestActions';
import { useCardMethod } from '../../../../Hooks/useCardMethod';
import { useTimer } from '../../../../Hooks/useTimer';
import {
    getItemService,
    removeItemService,
    setItemService
} from '../../../../Services/LocalStorageService';
import PaymentMethodContext from '../../../../Contexts/PaymentMethod/PaymentMethodContext';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../../../Constants/BNPLConstants';
import STRINGS from '../../../../i18n/local/es.json';
import { useDialog } from '../../../../Hooks/useDialog';
import { BackHomeDialog } from '../../../../Components/BNPL/Dialog/AlertDialog';
import { BackHomeDialogButton } from '../../../../Components/BNPL/Buttons/BackHomeDialogButton';

const CardLoadIframe = () => {
    const { urlToken, paymentMethods, setPayMethod, getPaymentMethods, setPaymentMethod } =
        useContext(PaymentMethodContext);
    const [noNewCards, setNoNewCards] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const { timerAction } = useTimer(8);
    const { getVerifyCards, getCards, cards } = useCardMethod();
    const cardsQuantity = getItemService(LoanRequestConstantsLS.CardsQuantity);
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const drmerchant = localStorage.getItem("drmerchant");
    const navigate = useNavigate();
    const dialog = useDialog();

    useEffect(() => {
        removeItemService('cardVerifyError');
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
    }, []);

    useEffect(() => {
        if (cards.length > 0) {
            setRedirect();
        }
    }, [cards]);

    useEffect(() => {
        if (workflow === WORKFLOW_CONSTANTS.CS && paymentMethods) {
            const method = paymentMethods.find((method) => method.id === 2);
            setPayMethod(method);
        }
    }, [paymentMethods]);

    const getNextPage = () => {
        const routes = {
            BNPL: RoutesConstants.CONFIRMATION_PAGE,
            CS: RoutesConstants.DISCOVERY_FEEDBACK
        };
        return routes[workflow] || RoutesConstants.LOAN_DETAILS_PAGE;
    };

    const getPrevPage = () => {
        return workflow === WORKFLOW_CONSTANTS.CS
            ? RoutesConstants.STEPS_REMAINING
            : RoutesConstants.LOAN_PAY_METHOD_BNPL;
    };

    const reloadCardLoadPage = () => {
        window.location.reload();
    };

    const setRedirect = async () => {
        try {
            await setPaymentMethod(true);
            const number = parseInt(cardsQuantity);
            if (cards.length > number) {
                navigate(getNextPage());
                setNoNewCards(false);
            } else {
                setNoNewCards(true);
            }
        } catch (error) {
            console.log(error);
            setNoNewCards(true);
        }
    };

    const disableContinueButton = () => {
        setDisableButton(true);
        timerAction(() => {
            setDisableButton(false);
            setNoNewCards(false);
        });
    };

    const handleButton = async () => {
        const verifyCards = await getVerifyCards();
        if (!verifyCards.successfully) {
            setItemService('cardVerifyError', JSON.stringify(verifyCards));
            navigate(getPrevPage());
            return;
        }
        getCards();
        disableContinueButton();
    };

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton route={getPrevPage()} />
                </Grid>
                <DescriptionForm title="Completa los datos de tu tarjeta de débito" pb={3} />
            </Box>
            {/*  <Iframe
                url={urlToken.url_debito}
                id="iframe_id"
                width="100%"
                height="550px"
                className="iframe"
                display="block"
                position="relative"
                sandbox={[
                    'allow-forms',
                    'allow-pointer-lock',
                    'allow-popups',
                    'allow-same-origin',
                    'allow-scripts',
                    'allow-top-navigation'
                ]}
            /> */}
            {urlToken && (
                <iframe
                    src={urlToken.url_debito}
                    id="iframe_id"
                    width={'100%'}
                    height={'1100px'}
                    className="iframe"
                    sandbox="allow-forms allow-modals allow-orientation-lock allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
                />
            )}
            {noNewCards ? <ErrorMessage title={'Primero debe cargar una nueva tarjeta '} /> : null}
            <Box display={'flex'} width="100%" justifyContent={'center'}>
                <Box width="315px">
                    <GrayButton
                        id="backButton"
                        text="Volver a métodos de pago"
                        height="46px"
                        action={() => reloadCardLoadPage()}
                        sx={{ display: 'none' }}
                    />
                    <GrayButton
                        id="continueButton"
                        text="Continuar al último paso"
                        height="46px"
                        action={() => handleButton()}
                        sx={{ display: 'none' }}
                    />
                </Box>
            </Box>
            {workflow === WORKFLOW_CONSTANTS.BNPL ? (
                <Box textAlign="center" mt="24px" width="100%">
                    <Link
                        component="button"
                        variant="button"
                        onClick={() => {
                            dialog.OpenDialog();
                        }}>
                        {drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE}
                    </Link>
                    <BackHomeDialog
                        open={dialog.showDialog}
                        onClose={dialog.CloseDialog}
                        onContinue={() => navigate(RoutesConstants.CANCEL_PURCHASE)}
                    />
                </Box>
            ) : (
                <BackHomeButton />
            )}
        </Box>
    );
};

export default CardLoadIframe;
