import StyledTextField from './Input.styled';
import { InputAdornment, Stack } from '@mui/material';
import { useMemo, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const Input = ({
    InputLabelProps,
    iconStart,
    iconEnd,
    prefix,
    type,
    onChange,
    helperText,
    errorText,
    error,
    ...props
}) => {
    const [focus, setFocus] = useState(false);

    const [value, setValue] = useState(props.value);
    const [isTouched, setIsTouched] = useState(false);
    const [showError, setShowError] = useState(false);
    const shrink = useMemo(() => {
        return props.value.length !== 0 || focus;
    }, [props.value, focus]);

    // type === 'password'
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const passwordIcon = (
        <InputAdornment position="end" onClick={handleClickShowPassword} className="passwordBtn">
            {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </InputAdornment>
    );

    const handleOnClickAway = () => {
        setFocus(value.length !== 0);
        if (isTouched && error) {
            setShowError(true);
        }
    };

    const handleOnClick = () => {
        setIsTouched(true);
    };

    const onTextFieldChange = (e) => {
        setValue(e.target.value);
        onChange(e);
        if (e.target.value.length) {
            setFocus(true);
        }
    };

    const helperTextValue = () => {
        if (error && showError) {
            return errorText;
        }
        if (shrink) {
            return helperText;
        }
        return '';
    };

    return (
        <ClickAwayListener onClickAway={handleOnClickAway}>
            <StyledTextField
                {...props}
                error={error && showError}
                helperText={helperTextValue()}
                className={iconStart ? 'iconStart' : ''}
                type={showPassword ? 'text' : type}
                onChange={onTextFieldChange}
                onClick={handleOnClick}
                variant="outlined"
                autoComplete="off"
                InputLabelProps={{
                    ...InputLabelProps,
                    shrink
                }}
                onFocus={() => {
                    setFocus(true);
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {iconStart}
                            {shrink && prefix ? <Stack ml={1}>{prefix}</Stack> : null}
                        </InputAdornment>
                    ),
                    endAdornment:
                        type === 'password' ? (
                            passwordIcon
                        ) : iconEnd ? (
                            <InputAdornment position="end">{iconEnd}</InputAdornment>
                        ) : null
                }}
            />
        </ClickAwayListener>
    );
};
