/* eslint-disable no-undef */
/* eslint-disable no-console */
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import Toast from '../Components/Toast';
import { AppConstantsLS } from '../Contexts/Global/AppActions';
import { getItemService } from './LocalStorageService';
import { except_locations } from '../Configs/exceptionPaths';
import { isBNPLRoute } from '../Helpers/IsBNPLRoute';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../Constants/BNPLConstants';
import { validateToastRoute } from '../Helpers/validateToastRoute';

const route = process.env.REACT_APP_URL_BACKEND;

const selectCredentials = () => {
    if (
        isBNPLRoute(window.location.pathname) &&
        getItemService(BNPLConstants.WORKFLOW) === WORKFLOW_CONSTANTS.BNPL
    ) {
        return localStorage.getItem(BNPLConstants.CREDENTIALS);
    } else {
        return process.env.REACT_APP_CREDENTIAL;
    }
};

axios.interceptors.request.use(
    (config) => {
        const token = getItemService(AppConstantsLS.Token);
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['ApiKey'] = process.env.REACT_APP_APIKEY_BACKEND;
        if (process.env.REACT_APP_SUBSCRIPTION_KEY)
            config.headers['Ocp-Apim-Subscription-Key'] = process.env.REACT_APP_SUBSCRIPTION_KEY;
        return config;
    },
    (error) => {
        //esto se puede mejorar
        Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        let config = error.config;
        config.retry -= 1;
        if (config.retry > 0) {
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    //console.log('retry the request', config.url);
                    resolve();
                }, config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(config));
        }
        /*  window.addEventListener('offline', console.log(window.navigator.onLine)); */
        if (window.navigator.onLine && !validateToastRoute(window.location.pathname)) {
            //adecuar a lo que se necesita este es un ejemplo
            if (error?.response) {
                if (
                    error.response.status === 400 &&
                    error.response.data.errors[0].typeError === 'CustomError'
                ) {
                    if (
                        error.response.data.errors[0].message !==
                        'El mail/Contraseña no se encuentra registrado.'
                    ) {
                        if (
                            !except_locations.some((item) =>
                                item.includes(window.location.pathname)
                            )
                        ) {
                            Toast.warning(error.response.data.errors[0].message);
                        }
                    }
                    // return error.response.data;
                } else if (error.response.status === 401) {
                    localStorage.clear();
                    Toast.error(
                        'No se encuentra autorizado para realizar la acción, por favor vuelva a loguearse'
                    );
                } else {
                    Toast.error(
                        'Ha ocurrido un error en la aplicación, por favor comunicarse con soporte'
                    );
                    console.log(error);
                }
            } else {
                Toast.error(
                    'Ha ocurrido un error en la aplicación, por favor comunicarse con soporte'
                );
                console.log(error);
            }
            // return Promise.reject(error);
            throw error;
        } else {
            return 'NoConnection';
        }
    }
);

export const getData = async (url, qs = '', credentials) => {
    try {
        axios.defaults.headers.get['Credentials'] = credentials ? credentials : selectCredentials();
        let res = await trackPromise(axios.get(route + url + qs, { retry: 3, retryDelay: 10000 }));
        return res.data;
    } catch (error) {
        return error;
    }
};

export const putData = async (url, body, credentials) => {
    axios.defaults.headers.put['Credentials'] = credentials ? credentials : selectCredentials();
    let res = await trackPromise(axios.put(`${route}${url}`, body));
    return res;
};

export const postData = async (url, body, credentials) => {
    try {
        axios.defaults.headers.post['Credentials'] = credentials ? credentials : selectCredentials();
        let res = await trackPromise(axios.post(`${route}${url}`, body));
        return res;
    } catch (error) {
        return error;
    }
};

export const deleteData = async (url, body, credentials) => {
    axios.defaults.headers.delete['Credentials'] = credentials ? credentials : selectCredentials();
    let res = await trackPromise(axios.delete(`${route}${url}`, body));
    //adecuar esto dependiendo la api
    return res;
};

export const patchData = async (url, body, credentials) => {
    axios.defaults.headers.patch['Credentials'] = credentials ? credentials : selectCredentials();
    let res = await trackPromise(axios.patch(`${route}${url}`, body));
    return res;
};

export const downloadData = async (url, body, credentials) => {
    
    axios.defaults.headers.post['Credentials'] = credentials ? credentials : selectCredentials();
    let res = await trackPromise(axios.post(`${route}${url}`, body, { responseType: 'blob' }));
    return res;
};
