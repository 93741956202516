import React, { useMemo } from 'react';
import { Grid, Paper, useTheme, useMediaQuery, styled } from '@mui/material';
import { TitleCustom } from '../../Components/TitleCustom';
import { setItemService } from '../../Services/LocalStorageService';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

export const IconContainer = styled(Grid)(() => {
    return {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };
});

export const CompletedLoanCard = ({ size = '24px', credit, index, totalCredits }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();

    const getDetail = async () => {
        setItemService('creditDetailId', credit.requestId);
        setItemService('indexCredit', index + 1 + totalCredits);
        navigate(RoutesConstants.LOAN_ACTIVE_PAGE);
    };

    const rateAmount = useMemo(() => {
        const result = credit?.rateAmount?.toLocaleString('es-AR', {
            style: 'currency',
            currency: 'ARS'
        });

        return result;
    }, [credit]);

    return (
        <Grid>
            <Paper
                elevation={1}
                sx={{
                    borderRadius: '32px',
                    width: '100%',
                    padding: desktop ? '24px' : '18px',
                    mb: 5,
                    cursor: 'pointer'
                }}
                onClick={() => getDetail()}>
                <Grid container spacing={2}>
                    <IconContainer item xs={2}>
                        <img src={'iconCompletedLoan.svg'} className="completed-loan" />
                    </IconContainer>
                    <Grid item xs={10}>
                        <TitleCustom
                            size={size}
                            sizeSubtitle={!desktop && '12px !important'}
                            title={`Préstamo ${index + 1 + totalCredits}: ${credit.commerce}`}
                            subtitle={`Importe total $${credit.creditAmount} / ${credit.quantityRate} cuotas 
                            de ${rateAmount}`}
                            titleLineHeightDesktop="24px"
                            titleLineHeightMobile="24px"
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
