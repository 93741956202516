import { useForm } from '../../../Hooks/useForm';
import { FeaturedService, Input } from '../../../Components/BNPL';
import { useHomeHandler } from '../PublicHomeHandler';
import { DNIIcon, MailIcon, PersonIcon } from '../../../Components/Icons';
import { dniValidate, emailValidate } from '../../../Helpers/Validations';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import STRINGS from '../../../i18n/local/es.json';
import { ContactCard, ContactContainer } from './Contact.styled';
import { useContext, useEffect, useState } from 'react';
import HomeContext from '../../../Contexts/Home/HomeContext';
import { clearMask, maskDNI } from '../../../Helpers/Masks';

export const ContactForm = () => {
    const { saveContactForm } = useContext(HomeContext);
    const { contactProps } = useHomeHandler();

    const formValidations = {
        name: [
            [(value) => !!value, STRINGS.home.contactForm.CONTACT_FORM_NAME_VALIDATION_REQUIRED]
        ],
        DNI: [
            [
                (value) => dniValidate(clearMask(value)),
                STRINGS.home.contactForm.CONTACT_FORM_ID_VALIDATION_REQUIRED
            ]
        ],
        email: [
            [
                (value) => emailValidate(value),
                STRINGS.home.contactForm.CONTACT_FORM_EMAIL_VALIDATION
            ]
        ],
        message: [
            [(value) => !!value, STRINGS.home.contactForm.CONTACT_FORM_COMMENT_VALIDATION_REQUIRED]
        ]
    };

    const {
        values: formData,
        formValidation,
        handleOnChangeFieldEvent,
        onResetForm,
        handleChangeMultipleValues
    } = useForm(
        {
            name: '',
            DNI: '',
            email: '',
            message: ''
        },
        formValidations
    );

    useEffect(() => {
        const contactInfoAsJson = globalThis.localStorage.getItem('ContactInfo');
        const contactInfo = JSON.parse(contactInfoAsJson);

        if(contactInfo?.userName) {
            handleChangeMultipleValues({
                name: contactInfo?.userName,
                DNI: contactInfo?.dni.toString(),
                email: contactInfo?.email,
                message: ''
            });
        }
    }, []);

    const sendMessage = () => {
        saveContactForm(formData);
        onResetForm();
    };

    return (
        <ContactContainer>
            <FeaturedService {...contactProps}>
                <ContactCard mb={2} spacing={3}>
                    <Input
                        value={formData.name}
                        label={STRINGS.home.contactForm.CONTACT_FORM_NAME_FIELD_PLACEHOLDER}
                        onChange={handleOnChangeFieldEvent('name')}
                        iconStart={<PersonIcon />}
                        error={!!formValidation.nameValid}
                        errorText={formValidation.nameValid}
                    />

                    <Input
                        value={maskDNI(formData.DNI) || formData.DNI}
                        label={STRINGS.home.contactForm.CONTACT_FORM_ID_FIELD_PLACEHOLDER}
                        onChange={handleOnChangeFieldEvent('DNI')}
                        iconStart={<DNIIcon />}
                        error={!!formValidation.DNIValid}
                        errorText={formValidation.DNIValid}
                    />

                    <Input
                        value={formData.email}
                        label={STRINGS.home.contactForm.CONTACT_FORM_EMAIL_FIELD_PLACEHOLDER}
                        onChange={handleOnChangeFieldEvent('email')}
                        iconStart={<MailIcon />}
                        error={!!formValidation.emailValid}
                        errorText={formValidation.emailValid}
                    />

                    <Input
                        value={formData.message}
                        label={STRINGS.home.contactForm.CONTACT_FORM_COMMENT_FIELD_PLACEHOLDER}
                        onChange={handleOnChangeFieldEvent('message')}
                        error={!!formValidation.messageValid}
                        errorText={formValidation.messageValid}
                        multiline
                        rows={4}
                    />

                    <GrayButton
                        type="submit"
                        text={STRINGS.home.contactForm.CONTACT_FORM_CTA}
                        action={() => {
                            sendMessage();
                        }}
                        disabled={
                            !!formValidation.nameValid ||
                            !!formValidation.dniValid ||
                            !!formValidation.emailValid ||
                            !!formValidation.commentValid
                        }
                    />
                </ContactCard>
            </FeaturedService>
        </ContactContainer>
    );
};
