import {
    Dialog,
    DialogTitle as MuiDialogTitle,
    DialogContent as MuiDialogContent,
    DialogActions as MuiDialogActions,
    styled,
    Typography,
    Box
} from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const DialogContainer = styled(Dialog)(({ theme }) => ({
    '.MuiDialog-paper': {
        borderRadius: '32px',
        padding: '24px',
        color: ColorConstants.blue[900],
        '@media (min-height: 450px)': {
            overflow: 'hidden'
        },
        overflow: 'scroll',
    },
    [theme.breakpoints.up('sm')]: {
        '.MuiModal-backdrop': {
            backdropFilter: 'blur(4px)'
        },
        '.MuiDialog-paper': {
            padding: '40px 64px 46px 64px',
            width: '455px',
            '@media (min-width: 600px)': {
                paddingTop: 'min(40px, calc(100vh - 370px)) !important',
                paddingBottom: 'min(46px, calc(100vh - 370px)) !important'
            }
        }
    },
    '&.error img': {
        width: '280px'
    }
}));

export const DialogContent = styled(MuiDialogContent)`
    padding: 0;
`;

export const DialogIcon = styled(Box)`
    border-radius: 200px;
    color: ${ColorConstants.blue[500]};
    background-color: ${ColorConstants.green[100]};
    border: 5px solid ${ColorConstants.green[50]};
    width: 40px;
    height: 40px;
    svg {
        width: 20px;
        height: 20px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

export const DialogTitle = styled(MuiDialogTitle)`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    padding: 0;
    padding-bottom: 8px;
`;

export const DialogDescription = styled(Typography)`
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
`;

export const ImageContainer = styled(Box)`
    text-align: center;
    margin-top: max(min(24px, calc(100vh - 350px)), 0px);
    img {
        @media (min-height: 450px) {
            width: 200px;
        }
        width: 100px;
        max-height: calc(100vh - 260px);
    }
`;

export const DialogActions = styled(MuiDialogActions)`
    div {
        width: 100%;
    }
`;
