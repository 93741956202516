import { useState, useEffect, useContext } from 'react';
import { Box, Stack, styled } from '@mui/material';
import { BNPLBiometricContext } from '../../Contexts/BNPLBiometric/BNPLBiometricContext';
import { cleanSteps } from '../../Services/BiometricDataService';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { StepComponent } from './StepComponent';
import { Stepper } from '../../Components/BNPL';
import { useDialog } from '../../Hooks/useDialog';
import { ValidationErrorForm } from '../Feedback/ValidationError/ValidationError';
import { ErrorDialog } from '../../Components/BNPL/Dialog/ErrorDialog';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

const StepperContainer = styled(Stack)`
    align-items: center;
    height: 24px;
    margin-bottom: 16px;
`;

const ButtonContainer = styled(Box)`
    .MuiBox-root {
        padding: 0 !important;
    }
`;

export const BiometricBNPLPage = () => {
    const { state, actions } = useContext(BNPLBiometricContext);
    const { orderData } = useContext(BNPLOrderContext);
    const { activeStep, inputRef, isRejected } = state;
    const { saveImage } = actions;
    const [activeComponent, setactiveComponent] = useState();
    const dialog = useDialog();
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);

    useEffect(() => {
        // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
		if ( storageOrderID != null && storageOrderID != undefined ) 
		{
                	logEventBNPLFunnel("INICIO_BIOMETRIA", storageOrderID, localStorageHash);
		} else {
			if (orderData != null && orderData != undefined && orderData.id != null && orderData.id != undefined )
			{
				localStorage.setItem("bnplOrderId", orderData.id);
				logEventBNPLFunnel("INICIO_BIOMETRIA", orderData.id, localStorageHash);
			}
		}
            }
            // FINISH
        setactiveComponent(StepComponent(activeStep));
    }, [activeStep]);

    useEffect(() => {
        if (isRejected) {
            dialog.OpenDialog();
        }
    }, [isRejected]);

    return (
        <Stack width="100%">
            <StepperContainer direction="row">
                <ButtonContainer>
                    <ReturnButton
                        route={ workflow ? 
                            (workflow === WORKFLOW_CONSTANTS.BNPL ? RoutesConstants.FINANCING_PAGE : RoutesConstants.STEPS_REMAINING) :
                            RoutesConstants.PUBLIC_HOME_BNPL
                        }
                        additionalAction={() => {
                            cleanSteps();
                        }}
                    />
                </ButtonContainer>
                <Box width="100%" ml="-64px" display="flex" justifyContent="center">
                    <Stepper
                        variant="dots"
                        steps={4}
                        position="static"
                        activeStep={activeStep}
                        sx={{ maxWidth: 500, flexGrow: 1, justifyContent: 'center' }}
                    />
                </Box>
                <Box ref={inputRef} display="none">
                    <input
                        id="camera"
                        type="file"
                        capture="environment"
                        accept="capture=camera"
                        onChangeCapture={(e) => saveImage(e)}
                    />
                </Box>
            </StepperContainer>
            {activeComponent}
            {dialog.showDialog && (
                <ErrorDialog open={dialog.showDialog} url={orderData.return_Url_Error}>
                    <ValidationErrorForm />
                </ErrorDialog>
            )}
        </Stack>
    );
};
