import { RegisterConstantsLS } from "../../Contexts/Register/RegisterActions";
import { getItemService } from "../../Services/LocalStorageService";

const TextDescriptionSendEmail = ({ resendEmail }) => {

    const email = getItemService(RegisterConstantsLS.UserEmail);
    const sendEmailText = 'Para continuar con tu registro, te enviamos un link';
    const resendEmailText = 'Para restablecer tu contraseña, te enviaremos un link';
    return (
        <>
            {resendEmail ? sendEmailText : resendEmailText} a <b>{email}</b>. Tené en cuenta que
            vence en 10 minutos.
        </>
    );
};

export default TextDescriptionSendEmail;