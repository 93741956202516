import Actions from './AppActions';

export const initialState = { exampleToken: false };

export const AppReducer = (state, action) => {
    switch (action.type) {
        case Actions.SET_EXAMPLE:
            return { ...state, exampleToken: action.payload };

        default:
            return state;
    }
};
