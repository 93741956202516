/* eslint-disable no-undef */
import { Grid, styled } from '@mui/material';
import React from 'react';
import { DescriptionForm } from '../../../../Components/DescriptionsLayout';

export const Img = styled('img')(() => ({
    height: '80%'
}));

const SuccessfulCardLoad = () => {
    return (
        <Grid
            container
            alignContent="center"
            justifyContent="center"
            p="24px"
            flexWrap="wrap"
            sx={{ backgroundColor: 'white' }}>
            <DescriptionForm
                title="Aprobado!"
                description="Hemos registrado exitosamente su tarjeta. Gracias."
            />
            <Grid item xs={12} justifyContent="center" display="flex">
                <Img src="ok.png" />
            </Grid>
        </Grid>
    );
};

export default SuccessfulCardLoad;
