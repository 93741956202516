import { Box, Stack, styled, Typography } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const Title = styled(Typography)`
    color: ${ColorConstants.blue[500]};
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
`;

export const Icon = styled(Box)`
    width: 40px;
    height: 40px;
    background: ${ColorConstants.green[100]};
    border: 6px solid ${ColorConstants.green[50]};
    color: ${ColorConstants.blue[500]};
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TitleContainer = styled(Stack)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
`;
