import { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SentimentSatisfiedOutlinedIcon from '@mui/icons-material/SentimentSatisfiedOutlined';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { Avatar } from '@mui/material';
import { cleanSteps } from '../../../Services/BiometricDataService';
import { DNIModal } from './DNIModal';
import { StepsConstants } from '../../../Constants/StepsConstants';
import { getItemService } from '../../../Services/LocalStorageService';
import BiometricDataContext from '../../../Contexts/BiometricData/BiometricDataContext';
import PaymentIcon from '@mui/icons-material/Payment';
import ErrorMessage from '../../../Components/Form/ErrorMessage';

import { InformationModal } from '../../../Components/Modal/InformationModal';
import smileFace from '../../../Assets/smileface.png';
import rightWink from '../../../Assets/right_wink.svg';
import leftWink from '../../../Assets/left_wink.svg';
import openMouth from '../../../Assets/open_mouth.svg';
import { FINAL_ATTEMPTS } from '../../../Constants/BiometricDataConstants';
import { BiometricDataConstants } from '../../../Constants/BiometricDataConstants';

const gestureTypeMap = {
    [BiometricDataConstants.SMILE_FACE]: {
        gestureTypeText: 'sonriendo',
        gestureImage: smileFace
    },
    [BiometricDataConstants.WINK_FACE]: {
        gestureTypeText: 'guino-ojo',
        gestureImage: leftWink
    },
    [BiometricDataConstants.OPEN_MOUTH_FACE]: {
        gestureTypeText: 'boca-abierta',
        gestureImage: openMouth
    }
};

export const ImageCapture = ({
    title,
    image,
    description,
    action,
    faceValidation,
    attempt,
    repeatCapture,
    buttonDisabled,
    dialog,
    disabled = false,
    errorMessage,
    step
}) => {
    const { images, activeStep, isOldImage, gestureType } = useContext(BiometricDataContext);

    const [position, setPosition] = useState();
    const [imagePreview, setImagePreview] = useState(new Image());

    useEffect(() => {
        let newImage = images[activeStep]?.photo;
        if (newImage === null || newImage === undefined) {
            newImage = images[activeStep - 1]?.photo;
        }
        let i = new Image();
        i.src = newImage;
        setImagePreview(i);
    }, [images]);

    useEffect(() => {
        if (imagePreview.src != '') {
            let positionImage = imagePreview.width > imagePreview.height;
            setPosition(positionImage);
        }
    }, [imagePreview]);

    const handleReset = () => {
        cleanSteps();
    };

    const getGestureTypeAndImage = () => {
        const { gestureTypeText, gestureImage } = gestureTypeMap[gestureType] || gestureTypeMap[BiometricDataConstants.SMILE_FACE];
        return { gestureTypeText, gestureImage };
    };

    const { gestureTypeText, gestureImage } = getGestureTypeAndImage();
    return (
        <>
            {!images[StepsConstants.SECOND_STEP].photo && (
                <DNIModal
                    action={repeatCapture}
                    dialogHook={dialog}
                    buttonPosition="center"
                    icon={<PaymentIcon />}
                    buttonText="Continuar"
                />
            )}
            {!images[StepsConstants.FOURTH_STEP].photo && step === 3 && (
                <InformationModal
                    dialogHook={dialog}
                    buttonPosition="center"
                    image={gestureImage}
                    icon={<SentimentSatisfiedOutlinedIcon />}
                    type={gestureTypeText}
                    buttonText="Entendido"
                />
            )}
            <DescriptionForm
                title={title}
                description={description}
                lineHeightDescription={'24px'}
                id="description-form"
            />

            <Box position={'relative'}>
                {!faceValidation ? (
                    <>
                        <Box
                            sx={{
                                zIndex: 2,
                                height: '200px',

                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '10%',
                                marginRight: '10%'
                            }}>
                            {image && (
                                <img
                                    /* className={mainCamera ? 'image-dni-main-camera' : 'image-dni'} */
                                    src={image}
                                    alt="imagen DNI"
                                    style={{
                                        transform: !position ? 'rotate(-90deg)' : 'rotate(0deg)',
                                        /*  maxWidth: `${window.screen.width * 0.52}px`,
                                        width: `${window.screen.width * 0.52}px`, */
                                        borderRadius: '20px',
                                        //height: `${window.screen.width * 0.85}px`,
                                        maxHeight: '275px',
                                        maxWidth: '98%'
                                    }}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Avatar
                            sx={{ width: 200, height: 200, mb: 3 }}
                            src={image}
                            alt="imagen rostro"
                        />
                    </Box>
                )}
            </Box>
            {/* <Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="body2"
                        align="center"
                        whiteSpace={'nowrap'}
                        sx={{ opacity: '0.75' }}>
                        {`Intento ${Number(attempt) + 1}/3`}
                    </Typography>
                </Grid>
            </Grid> */}
            {errorMessage?.title && errorMessage?.description && (
                <Box sx={{ mt: '10px', mb: '-45px' }}>
                    <ErrorMessage
                        title={errorMessage?.title}
                        description={errorMessage?.description}
                    />
                </Box>
            )}
            <Grid container spacing={2} sx={{ textAlign: 'center', justifyContent: 'center' }}>
                <Grid container spacing={2} mt={5} sx={{ zIndex: 3 }}>
                    <Grid item xs={6}>
                        <Box width="100%" sx={{ ml: 1.5 }}>
                            <ButtonCustom
                                border
                                disabled={getItemService('attempt') ? getItemService('attempt') == FINAL_ATTEMPTS : false}
                                bg="transparent"
                                color={ColorConstants.BLUE}
                                text={!images[activeStep].photo ? 'Tomar foto' : 'Sacar de nuevo'}
                                padding="8px 16px"
                                /*  disabled={attempt >= 3 || buttonDisabled} */
                                action={() => repeatCapture()}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box width="100%" sx={{ ml: 1 }}>
                            <ButtonCustom
                                text="Confirmar"
                                action={() => action()}
                                /*  disabled={errorMessage?.title || disabled || !image} */
                                disabled={errorMessage?.title}
                            />
                        </Box>
                    </Grid>
                </Grid>

                <BackHomeButton action={() => handleReset()} />
            </Grid>
        </>
    );
};
