import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ListItemAvatar, ListItemText, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Modal, Table } from '../../Components/BNPL';
import { AcceptTermsAndPolicy } from '../../Components/AcceptTermsAndPolicy';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { LinkButton, ListItem, PaymentPlanContainer, StyledAvatar, StyledList } from './FinancingPage.styled';
import { ColorConstants } from '../../Constants/ColorConstants';
import { CaldendarIcon } from '../../Components/Icons';
import { moneyMask } from '../../Helpers/Masks';
import STRINGS from '../../i18n/local/es.json';
import SellerCard from '../../Components/BNPL/SellerCard/SellerCard';
import InstallmentsCard from '../../Components/BNPL/InstallmentsCard/InstallmentsCard';

import { PaymentMethodItem } from './components/PaymentMethodItem';
import { OfferAvailablePageSkeleton } from './components/OfferAvailablePageSkeleton';
import { verifyBiometryRequirements } from '../../Services/OfferService';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { BNPLFinancingContext } from '../../Contexts/BNPLFinancing/BNPLFinancingContext';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { BNPLSiisaContext } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';
import { INSTALLMENT_TYPE } from '../../Constants/BNPLConstants';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { logEventBNPLFunnel, logEventBNPLInfo } from '../../Services/BNPLService';

export const OfferAvailablePage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const { state, actions, loanData } = useContext(BNPLFinancingContext);
    const { orderData } = useContext(BNPLOrderContext);
    const { getHasValidatedIdentity } = useContext(BNPLSiisaContext);
    const {
        allowedPaymentMethods,
        errorAllowedPaymentMethods,
        installments,
        selectedInstallment,
        total,
        date,
        errorOfferedInstallments
    } = state;

    const { handleInstallmentSelection, setSelectedCredit } = actions;
    const [showPlan, setShowPlan] = useState(false);

    // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("OFERTA", storageOrderID, localStorageHash)
            }
            // FINISH

    const OPERATION_DETAILS = {
        merchant_name: orderData.merchant_Name,
        src: '/tienda_online.svg',
        order: orderData.merchant_Order_Id,
        price: orderData.price
    };
    
    const skipBiometry = () => {
        const personData = JSON.parse(getItemService('personData'));
        setItemService(
            LoanRequestConstantsLS.PersonToGetLoan,
            JSON.stringify({
                personId: personData.personId,
                dni: personData.dni,
                sexTypeId: personData.gender,
                phoneCode: personData.areaCode,
                phoneNumber: personData.phoneNumber,
                cuit: personData.cuit
            })
        );

        if(orderData.steps.stepId === 4){
            navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL);
        } else {
            navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL);
        }
    }

    const handleContinue = async () => {
	var traceS = "A";
        try {
            await setSelectedCredit(OPERATION_DETAILS.price);
            traceS = "B"; 
            const hasValidatedIdentity = (await getHasValidatedIdentity()).data.isVerified;
	    traceS = "C";
            if (hasValidatedIdentity) {
		traceS = "D";
                skipBiometry();
            }
            else {
		traceS = "E";
                const biometryStatus = await verifyBiometryRequirements(loanData.pendingRequestId);
		traceS = "F";
                if (desktop) {
		    traceS = "G";
                    navigate(RoutesConstants.SCAN_QR_CODE);
                } else if (biometryStatus.hasBiometricData) {
		    traceS = "H";
                    skipBiometry();
                } else {
		    traceS = "I";
                    navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
                }
            }
            
        } catch (error) {
            console.log(error);
	    // TODO FUNNEL BNPL: First step
            // INIT
	    const localStorageHashInfo = localStorage.getItem("orderHash");
            if ( localStorageHashInfo )
            {
                const storageOrderIDError = localStorage.getItem("bnplOrderId");
                logEventBNPLInfo("ERROR_SELECT_CREDIT", storageOrderIDError, error.substring(0,200) + " - " + traceS);
            }
            // FINISH
        }
    };

    const handleViewPlan = () => {
        setShowPlan(!showPlan);
    };

    const handleOnCloseModal = () => {
        setShowPlan(false);
    };

    if (errorAllowedPaymentMethods || errorOfferedInstallments) {
        return <OfferAvailablePageSkeleton />;
    }

    const createDeadlineSchedule = () => {
        const deadlineSchedule = [];
        const date = new Date(selectedInstallment.expirationDate);

        if (selectedInstallment?.type === INSTALLMENT_TYPE.ADELANTADO) {
            deadlineSchedule.push({
                    due_date: "Hoy!",
                    installment: `${1}/${selectedInstallment.quantityRate}`,
                    amountRate: selectedInstallment.amountRate
            });
            //date.setMonth(date.getMonth() + 1);
            for (let i = 1; i < selectedInstallment.quantityRate; i++) {
                deadlineSchedule.push({
                    due_date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
                    installment: `${i + 1}/${selectedInstallment.quantityRate}`,
                    amountRate: selectedInstallment.amountRate
                });
                date.setMonth(date.getMonth() + 1);
            }
        } else {
            for (let i = 0; i < selectedInstallment.quantityRate; i++) {
                deadlineSchedule.push({
                    due_date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
                    installment: `${i + 1}/${selectedInstallment.quantityRate}`,
                    amountRate: selectedInstallment.amountRate
                });
                date.setMonth(date.getMonth() + 1);
            }
        }
        
        return deadlineSchedule;
    };
    return (
        <>
            <Stack width="100%" spacing={5} mb={2}>
                <Stack>
                    {OPERATION_DETAILS.price && (
                        <>
                            <SellerCard
                                avatar={{
                                    src: OPERATION_DETAILS.src
                                }}
                                shopName={OPERATION_DETAILS.merchant_name}
                                orderId={OPERATION_DETAILS.order}
                                value={moneyMask(OPERATION_DETAILS.price.toString(), true, 0)}
                            />
                            <InstallmentsCard
                                orderValue={OPERATION_DETAILS.price}
			        totalMon={total}
                                total={moneyMask(total, true, 2)}
                                numberOfInstallments={selectedInstallment?.quantityRate}
                                installmentAmount={moneyMask(
                                    selectedInstallment?.amountRate.toString(),
                                    true,
                                    2
                                )}
                                installments={installments}
                                selectedInstallment={selectedInstallment}
                                onChangeInstallments={handleInstallmentSelection}
                            />
                        </>
                    )}
                    <Box textAlign="center" my={2} p={2} width="100%">
                        <LinkButton onClick={handleViewPlan}>
                            {STRINGS.financing.CTA_VIEW_PLAN}
                        </LinkButton>
                    </Box>
                    <StyledList>
                        <ListItem>
                            <ListItemAvatar>
                                <StyledAvatar>
                                    <CaldendarIcon />
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${STRINGS.financing.INSTALLMENT_DATE} ${date}`}
                            />
                        </ListItem>
                        <PaymentMethodItem paymentMethod={selectedInstallment?.type === INSTALLMENT_TYPE.ADELANTADO ? selectedInstallment?.type : allowedPaymentMethods?.paymentId} />
                    </StyledList>
                    <AcceptTermsAndPolicy
                        sx={{ fontWeight: 'normal', paddingTop: 3, textAlign: 'center' }}
                        sxLink={{ '&&': { color: ColorConstants.blue[500] } }}
                    />
                </Stack>

                <Stack>
                    <ButtonCustom text={STRINGS.financing.CTA_CONTINUE} action={handleContinue} />
                    <BackHomeDialogButton />
                </Stack>
            </Stack>
            <Modal title="Plan de pagos" open={showPlan} onClose={handleOnCloseModal}>
                <PaymentPlanContainer>
                <Table
                    options={['Vencimiento', 'Cuota', 'Monto']}
                    values={createDeadlineSchedule()}
                />
                </PaymentPlanContainer>
            </Modal>
        </>
    );
};
