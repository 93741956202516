export const maskDNI = (number) => {
    if (number == '') return '';
    let n = clearMask(String(number));
    if (n.length > 1 && n.length < 4) {
        return `${n.slice(0, 1)}${'.'}${n.slice(1)}`;
    }
    else if (n.length > 7) {
        return `${n.slice(0, 2)}.${n.slice(2, 5)}.${n.slice(5)}`;
    }
    else if (n.length > 4) {
        return `${n.slice(0, 1)}.${n.slice(1, 4)}.${n.slice(4)}`;
    }
};

const addZero = (number) => {
    if (number.length < 2) {
        return `0${number}`;
    } else {
        return `${number}`;
    }
};

export const maskDate = (date) => {
    const newDate = new Date(date).toLocaleDateString();
    const splitDate = newDate.split('/');
    const resultDate = splitDate.map((number) => addZero(number));
    return `${resultDate[0]}/${resultDate[1]}/${resultDate[2].slice(2, 4)}`;
};

export const clearMask = (number) => {
    let n = number;

    return n.replace(/\./g, '');
};

export const firstCapitalLetter = (text = '') => {
    const textLowerCase = text.toLowerCase();

    const textCapitalized = String(textLowerCase).replace(
        /(^\w{1})|(,\w{1})|(\s+\w{1})/g,
        (letra) => letra.toUpperCase()
    );
    return textCapitalized;
};

export const firstName = (text) => {
    const textLowerCase = firstCapitalLetter(text);
    const firstName = textLowerCase.split(/\s|,| /g)[1];

    return firstName;
};

export const moneyMask = (value, showSign = true, minimumFractionDigits = 2) => {
    let divisor = 1,
        sign = '$';
    if (value.includes('.')) {
        const digits = value.split('.')[1].length;
        if (digits === 1) {
            divisor = 10;
        } else {
            divisor = 100;
        }
    }
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '');
    const options = { minimumFractionDigits };
    const result = new Intl.NumberFormat('pt-AR', options).format((parseFloat(value) / divisor));
    return showSign ? sign + result : result;
};

export const dateMask = (date) => {
    const dateToConvert = new Date(date).toLocaleDateString();
    const dateSplit = dateToConvert.split('/');

    return `${dateSplit[0]}/${dateSplit[1]}/${dateSplit[2].slice(2)}`;
};
