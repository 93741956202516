import { useContext } from 'react';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Description, DescriptionForm } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import STRINGS from '../../i18n/local/es.json';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { StepperComponent } from './components/Stepper';
import { Link, useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { BNPLSiisaContext } from '../../Contexts/BNPLSiisa/BNPLSiisaContext';

const steps = [
    STRINGS.remaining_steps.stepper.STEP_1,
    STRINGS.remaining_steps.stepper.STEP_2,
    STRINGS.remaining_steps.stepper.STEP_3,
    STRINGS.remaining_steps.stepper.STEP_4
];

const StepsForm = () => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const { getHasValidatedIdentity } = useContext(BNPLSiisaContext);

    const handleContinue = async () => {
        const hasValidatedIdentity = (await getHasValidatedIdentity()).data.isVerified;
        
        if (hasValidatedIdentity) {
            navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL)
        }
        else {
            if (desktop) {
            navigate(RoutesConstants.SCAN_QR_CODE_CS);
        } else {
            navigate(RoutesConstants.BIOMETRIC_BNPL_PAGE);
        }
        }
    };

    return (
        <Stack>
            <ReturnButton />
            <DescriptionForm
                title={
                    <Typography fontSize={24} fontWeight={700} maxWidth={380}>
                        {STRINGS.remaining_steps.FORM_TITLE}
                    </Typography>
                }
                description={STRINGS.remaining_steps.FORM_DESCRIPTION}
                fontSizeDescription={desktop ? '18px' : '16px'}
            />
            <Box mt={4} mb={4.5}>
                <StepperComponent steps={steps} />
            </Box>
            <Link to={RoutesConstants.PARTNERS_STORE_PAGE}>
                {STRINGS.remaining_steps.FORM_CTA_LINK}
            </Link>
            <Stack mt={['81px', '24px']}>
                <ButtonCustom
                    text={STRINGS.remaining_steps.stepper.STEP_3}
                    action={handleContinue}
                />
                <Box mt={['24px', '32px']} textAlign="center">
                    <Link to="/">{STRINGS.buttons.BACK_TO_HOME}</Link>
                </Box>
            </Stack>
        </Stack>
    );
};

export const StepsRemainingPage = () => {
    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={STRINGS.identification.TITLE}
                    description={STRINGS.identification.SUBTITLE}
                    returnButton={false}
                />
            }
            Form={<StepsForm />}
        />
    );
};
