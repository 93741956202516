import { createContext, useRef } from 'react';
import { Box } from '@mui/material';
import { LoanRequestConstantsLS } from '../LoanRequest/LoanRequestActions';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { getCurrentDate } from '../../Helpers/Utils';
import { useTimer } from '../../Hooks/useTimer';
import { trackPromise } from 'react-promise-tracker';
import { saveValidatedIdentity, getValidatedIdentity } from '../../Services/BiometricDataService';
import { confirmLoanService } from '../../Services/LoanRequestService';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { getFingerprint, setOption } from '@thumbmarkjs/thumbmarkjs';

const BNPLSiisaContext = createContext(null);

const BNPLSiisaProvider = ({ children }) => {
    const { timerAction } = useTimer(2);
    let text;
    const boxRef = useRef();
    const fpPromise = FingerprintJS.load();

    setOption('exclude', ['webgl', 'canvas', 'fonts', 'permissions', 'math', 'plugins', 'audio', 'locales']);

    const getSiisaCode = async () => {
        return await trackPromise(
            new Promise((resolve, reject) => {
                if (boxRef && timerAction) {
                    timerAction(() => {
                        // eslint-disable-next-line no-undef
                        fillSiisaData('jsdata');

                        timerAction(() => {
                            const siisaCode = boxRef.current?.firstChild?.value;
                            if (siisaCode) {
                                setItemService(LoanRequestConstantsLS.SiisaCode, siisaCode);
                                resolve(siisaCode);
                            } else {
                                reject();
                            }
                        });
                    });
                } else {
                    console.log('No boxRef/timerAction defined and no Siisa saved');
                    reject();
                }
            })
        );
    };

    const saveHasValidatedIdentity = async (appId, hasRequestId) => {
        setOption('exclude', ['webgl', 'canvas', 'fonts', 'permissions', 'math', 'plugins', 'audio', 'locales']);
        getFingerprint().then((fp) => { 
                const loanData = JSON.parse(getItemService(LoanRequestConstantsLS.LoanData));
                const personData = JSON.parse(getItemService('personData'));
                var ecommerceAccountId = JSON.parse(getItemService('ecommerceAccountId'));

                if ( ecommerceAccountId === null || ecommerceAccountId === undefined )
                {
                    ecommerceAccountId = Number(personData.ecommerceAccountId);
                }

                let PersonToGetLoan = JSON.parse(getItemService('PersonToGetLoan'));
                let data = {
                    appId: appId,
                    pendingRequestId: loanData ? loanData.pendingRequestId : '',
                    requestId: hasRequestId
                        ? getItemService(LoanRequestConstantsLS.CreditDetailId)
                        : 0,
                    ecommerceAccountId: ecommerceAccountId ? ecommerceAccountId : 0,
                    fingerPrint: fp,
                    phoneNumber: personData
                        ? `${personData.areaCode}${personData.phoneNumber}`
                        : `${PersonToGetLoan.phoneCode}${PersonToGetLoan.phoneNumber}`,
                    dateCreate: getCurrentDate()
                };

                return saveValidatedIdentity(data);
        }).catch((err) => {
                console.log(err);
        });
        /*
        fpPromise
            .then((fp) => fp.get())
            .then(async (result) => {
                const loanData = JSON.parse(getItemService(LoanRequestConstantsLS.LoanData));
                const personData = JSON.parse(getItemService('personData'));
                var ecommerceAccountId = JSON.parse(getItemService('ecommerceAccountId'));

        		if ( ecommerceAccountId === null || ecommerceAccountId === undefined )
        		{
        			ecommerceAccountId = Number(personData.ecommerceAccountId);
        		}

                let PersonToGetLoan = JSON.parse(getItemService('PersonToGetLoan'));
                let data = {
                    appId: appId,
                    pendingRequestId: loanData ? loanData.pendingRequestId : '',
                    requestId: hasRequestId
                        ? getItemService(LoanRequestConstantsLS.CreditDetailId)
                        : 0,
                    ecommerceAccountId: ecommerceAccountId ? ecommerceAccountId : 0,
                    fingerPrint: result.visitorId,
                    phoneNumber: personData
                        ? `${personData.areaCode}${personData.phoneNumber}`
                        : `${PersonToGetLoan.phoneCode}${PersonToGetLoan.phoneNumber}`,
                    dateCreate: getCurrentDate()
                };

                return await saveValidatedIdentity(data);
            })
            .catch((err) => {
                console.log(err);
            });
            */
    };

    const getHasValidatedIdentity = async () => {
        try {
            setOption('exclude', ['webgl', 'canvas', 'fonts', 'permissions', 'math', 'plugins', 'audio', 'locales']);
        } catch (errorSet) {
            return false;
        }
        return getFingerprint().then((fp) => {
                try {
                    let personData = JSON.parse(getItemService('personData'));
                    let ecommerceAccountId = JSON.parse(getItemService('ecommerceAccountId'));
                    let PersonToGetLoan = JSON.parse(getItemService('PersonToGetLoan'));
                    let data = {
                        ecommerceAccountId: personData
                            ? Number(personData.ecommerceAccountId)
                            : ecommerceAccountId
                            ? ecommerceAccountId
                            : 0,
                        phoneNumber: personData
                            ? `${personData.areaCode}${personData.phoneNumber}`
                            : `${PersonToGetLoan.phoneCode}${PersonToGetLoan.phoneNumber}`,
                        fingerPrint: fp
                    };

                    if (data.ecommerceAccountId) {
                        localStorage.setItem(
                                    'ecommerceAccountId',
                                    data.ecommerceAccountId
                        );
                        return getValidatedIdentity(data);
                    }
                } catch(errorData) {
                    return false;
                }
        }).catch((err) => {
                console.log(err);
                return false;
        });

        /*
        return fpPromise
            .then((fp) => fp.get())
            .then(async (result) => {
                let personData = JSON.parse(getItemService('personData'));
                let ecommerceAccountId = JSON.parse(getItemService('ecommerceAccountId'));
                let PersonToGetLoan = JSON.parse(getItemService('PersonToGetLoan'));
                let data = {
                    ecommerceAccountId: personData
                        ? Number(personData.ecommerceAccountId)
                        : ecommerceAccountId
                        ? ecommerceAccountId
                        : 0,
                    phoneNumber: personData
                        ? `${personData.areaCode}${personData.phoneNumber}`
                        : `${PersonToGetLoan.phoneCode}${PersonToGetLoan.phoneNumber}`,
                    fingerPrint: result.visitorId
                };

                if (data.ecommerceAccountId) {
                    localStorage.setItem(
                                'ecommerceAccountId',
                                data.ecommerceAccountId
                    );
                    return await getValidatedIdentity(data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
            */
    };

    return (
        <BNPLSiisaContext.Provider
            value={{
                getSiisaCode,
                saveHasValidatedIdentity,
                getHasValidatedIdentity
            }}>
            {children}
            <Box ref={boxRef}>
                <textarea id="jsdata" style={{ display: 'none' }} value={text}></textarea>
            </Box>
        </BNPLSiisaContext.Provider>
    );
};

export { BNPLSiisaContext, BNPLSiisaProvider };
