export const RoutesConstants = {
    ANY_ROUTE: '*',
    PUBLIC_HOME: '/',
    PRIVATE_HOME: '/inicio-privado',
    ERROR: '/error',
    //PAGINAS DEL FOOTER
    CONTACT_PAGE: '/contacto',
    FRECUENT_QUESTIONS_PAGE: '/preguntas-frecuentes',
    PRIVACY_PAGE: '/politica-privacidad',
    TERMS_PAGE: '/terminos-condiciones',
    //PRESTAMOS
    LOAN_REQUEST_PAGE: '/solicitud-prestamo',
    LOAN_CONTACT_INFORMATION_PAGE: '/informacion-contacto-prestamo',
    LOAN_TOKEN_PAGE: '/validacion-token-prestamo',
    LOAN_REDIRECT_PAGE: '/redireccion-prestamo',
    LOAN_NO_OFFERS: '/sin-oferta',
    LOAN_TYPE_OCUPPATION: '/tipo-ocupacion',
    LOAN_CALENDAR: '/dia-cobro',
    LOAN_SUMMARY_PAGE: '/resumen-prestamo',
    LOAN_DETAILS_PAGE: '/detalles-prestamo',
    LOAN_OFFER_DESKTOP_SELECTION: '/seleccion-oferta-desktop',
    LOAN_OFFER_SELECTION: '/seleccion-oferta',
    LOAN_CBU: '/validacion-cbu',
    LOAN_PAY_METHOD: '/metodo-pago',
    LOAN_ACTIVE_PAGE: '/prestamo-activo',
    LOAN_LAST_SATISFACTORY_SCREEN: '/prestamo-aprobado',
    LOAN_SATISFACTORY_CARD_LOAD: '/carga-satisfactoria',
    LOAN_LAST_DIFFERENTIAL_SCREEN: '/prestamo-pendiente',
    LOAN_CARD_LOAD: '/nueva-tarjeta',
    LOAN_CHANGE_DEVICE: '/cambio-movil/:token',
    //LOGIN
    LOGIN_PAGE: '/iniciar-sesion',
    //REGISTRO
    REGISTER_PAGE: '/registro',
    REGISTER_CREATE_ACCOUNT_PAGE: '/crear-cuenta',
    REGISTER_SUCCESSFUL_PAGE: '/registro-exito',
    REGISTER_CHECK_DNI_PAGE: '/registro-validacion-dni',
    REGISTER_CONTACT_INFORMATION_PAGE: '/informacion-contacto',
    REGISTER_SEND_EMAIL_PAGE: '/registro-envio-correo',
    REGISTER_EXPIRED_LINK_PAGE: '/registro-link-expirado',
    //NUEVA CONTRASEÑA
    NEW_PASSWORD_CHANGE_PAGE: '/proceso-cambio-password',
    NEW_PASSWORD_CHANGE_REQUEST_PAGE: '/solicitud-cambio-password',
    NEW_PASSWORD_SUCCESS_EMAIL_PAGE: '/correo-cambio-password',
    NEW_PASSWORD_EXPIRED_LINK_PAGE: '/link-expirado',
    NEW_PASSWORD_SUCCESSFUL_PAGE: '/cambio-password-exito',
    //PERFIL
    PROFILE_CHANGE_PASSWORD: '/cambio-password-perfil',
    UPDATE_USER_DATA: '/actualizacion-datos',
    NEW_PASSWORD_SUCCESSFUL_PRIVATE_PAGE: '/cambio-password-perfil-exito',
    //DATOS BIOMETRICOS
    BIOMETRIC_DATA_PAGE: '/datos-biometricos',
    DNI_FRONT_PAGE: '/datos-biometricos/DNI-front',
    FACE_CAPTURE_PAGE: '/datos-biometricos/Face-capture',
    //BNPL
    REDIRECT_TO_DIRECTO_PAGE: '/compra-cuotas',
    IDENTIFICATION_DNI_PAGE: '/ingresa-dni',
    IDENTIFICATION_GENDER_PAGE: '/ingresa-genero',
    IDENTIFICATION_CONFIRM_PAGE: '/confirma-identidad',
    IDENTIFICATION_CONTACT_PAGE: '/datos-contacto',
    BNPL_TOKEN_PAGE: '/validacion-token-bnpl',
    FINANCING_OFFER_PAGE: '/oferta-bnpl',
    SCAN_QR_CODE: '/scaneo-qr',
    BIOMETRIC_BNPL_PAGE: '/datos-biometricos-bnpl',
    FINANCING_PAGE: '/financiacion-bnpl',
    SELECT_PAYMENT_METHOD: '/seleccion-metodo-pago',
    CONFIRMATION_PAGE: '/confirmacion-oferta',
    CROSS_DEVICE: '/cambio-dispositivo',
    VERIFY_EMAIL_CODE: '/verificacion-codigo-email',
    VERIFY_PASSWORD_CODE: '/verificacion-cambio-password',
    LOAN_PAY_METHOD_BNPL: '/metodo-pago-bnpl',
    LOAN_CARD_LOAD_BNPL: '/nueva-tarjeta-bnpl',
    SCAN_QR_CODE_CS: '/scaneo-qr-cs',
    CROSS_DEVICE_CS: '/cambio-dispositivo-cs',
    //BNPL-ERROR
    BNPL_FEEDBACK: '/feedback',
    INSUFFICIENT_FUNDS_PAGE: '/fondos-insuficientes',
    MINIMUM_PURCHASE_PRICE_PAGE: '/precio-minimo-insuficiente',
    BNPL_NO_OFFERS: '/sin-oferta-bnpl',
    EXISTING_ACCOUNT: '/cuenta-existente',
    PURCHASE_CANCELED: '/compra-cancelada',
    CANCEL_PURCHASE: '/cancelar-compra',
    HASH_EXPIRED: '/hash-error',
    VALIDATION_ERROR: '/error-validacion',
    //BNPL-MVP2
    PUBLIC_HOME_BNPL: '/inicio-bnpl',
    PRIVATE_HOME_BNPL: '/inicio-privado-bnpl',
    PARTNERS_STORE_PAGE: '/locales-adheridos',
    FRECUENT_QUESTIONS_PAGE_BNPL: '/preguntas-frecuentes-bnpl',
    AVAILABLE_BALANCE: '/saldo-disponible',
    STEPS_REMAINING: '/pasos-faltantes',
    REGISTER_FEEDBACK: '/feedback-registro',
    CHECK_AVAILABLE_BALANCE: '/consulta-saldo-disponible',
    DISCOVERY_FEEDBACK: '/feedback-discovery',
    CAMERA_PAGE: '/camara',
    VERIFY_PASSWORD_SUCCESSFUL_PAGE: '/verificacion-cambio-password-exito',
    //IFRAME
    CARD_CRED_IFRAME: '/cardCredIframe',
    CARD_CRED_IFRAME_ERROR: '/cardCredIframeError',
    //EXTERNAL URL
    FINANCIAL_USER_INFO: 'https://www.directo.com.ar/informacion-al-usuario-financiero'
};
