import { ListItem, ListItemAvatar, List, useTheme, useMediaQuery } from '@mui/material';
import { Avatar, ListItemText } from './OperationDetails.styled';
import ImageIcon from '@mui/icons-material/Image';
import { DescriptionForm } from '../Description/Description';

export const OperationDetails = ({ title, subtitle, details = [] }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <>
            <DescriptionForm title={title} description={desktop && subtitle} />
            {details.map((detail) => (
                <List key={detail.title}>
                    <ListItem sx={{ padding: '0' }}>
                        <ListItemAvatar>
                            <Avatar src={detail.src}>{detail.icon || <ImageIcon />}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={detail.title} secondary={detail.description} />
                    </ListItem>
                </List>
            ))}
        </>
    );
};
