import { Route } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import ConfirmationPage from '../../Pages/ConfirmationPage/ConfirmationPage';
import { FinancingPage } from '../../Pages/Financing/FinancingPage';
import { OfferAvailablePage } from '../../Pages/Financing/OfferAvailablePage';
import { OfferPage } from '../../Pages/Financing/OfferPage';

export const FinancingRoutes = () => {
    return (
        <Route element={<FinancingPage />}>
            <Route exact path={RoutesConstants.FINANCING_PAGE} element={<OfferAvailablePage />} />
            <Route exact path={RoutesConstants.FINANCING_OFFER_PAGE} element={<OfferPage />} />
            <Route exact path={RoutesConstants.CONFIRMATION_PAGE} element={<ConfirmationPage />} />
        </Route>
    );
};
