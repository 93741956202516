export function CreditCardIcon({ color = 'currentColor', width = '20', height = '16' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.833008 6.33594H19.1663M2.49967 1.33594H17.4997C18.4201 1.33594 19.1663 2.08213 19.1663 3.0026V13.0026C19.1663 13.9231 18.4201 14.6693 17.4997 14.6693H2.49967C1.5792 14.6693 0.833008 13.9231 0.833008 13.0026V3.0026C0.833008 2.08213 1.5792 1.33594 2.49967 1.33594Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
