import { useNavigate } from 'react-router-dom';
import { ColorConstants } from '../../Constants/ColorConstants';
import { CaldendarIcon } from '../../Components/Icons';
import { moneyMask } from '../../Helpers/Masks';
import { ListItemAvatar, ListItemText, Stack } from '@mui/material';

import { ListItem, StyledAvatar, StyledList } from './FinancingPage.styled';
import { AcceptTermsAndPolicy } from '../../Components/AcceptTermsAndPolicy';
import { ButtonCustom } from '../../Components/Buttons/ButtonCustom';
import { RoutesConstants } from '../../Constants/RoutesConstants';

import STRINGS from '../../i18n/local/es.json';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import { LoanRequestConstantsLS } from '../../Contexts/LoanRequest/LoanRequestActions';
import { useContext, useEffect } from 'react';
import { BNPLFinancingContext } from '../../Contexts/BNPLFinancing/BNPLFinancingContext';
import SellerCard from '../../Components/BNPL/SellerCard/SellerCard';
import InstallmentsCard from '../../Components/BNPL/InstallmentsCard/InstallmentsCard';
import { PaymentMethodItem } from './components/PaymentMethodItem';
import { verifyBiometryRequirements } from '../../Services/OfferService';
import { BNPLOrderContext } from '../../Contexts/BNPLOrder/BNPLOrderContext';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { INSTALLMENT_TYPE } from '../../Constants/BNPLConstants';
import { logEventBNPLFunnel } from '../../Services/BNPLService';

export const OfferPage = () => {
    const navigate = useNavigate();
    const { state, actions, loanData } = useContext(BNPLFinancingContext);
    const { orderData } = useContext(BNPLOrderContext);
    const { allowedPaymentMethods, installments, selectedInstallment, total, date } = state;
    const { handleInstallmentSelection, getCreditDetails } = actions;

    const personData = JSON.parse(localStorage.getItem('personData'));
    const USERNAME = personData?.name?.split(',')[1] || '';

    const OPERATION_DETAILS = {
        merchant_name: orderData.merchant_Name,
        src: '/tienda_online.svg',
        order: orderData.merchant_Order_Id,
        price: orderData.price
    };

    const verifyBiometry = async () => {
        const response = await verifyBiometryRequirements(loanData.pendingRequestId);
        if (!response.hasBiometricData) {
            navigate(RoutesConstants.FINANCING_PAGE);
        } else {
            getCreditDetails();
        }
    };

    useEffect(() => {
        // TODO FUNNEL BNPL: First step
            // INIT
            const localStorageHash = localStorage.getItem("orderHash");
            if ( localStorageHash )
            {
                const storageOrderID = localStorage.getItem("bnplOrderId")
                logEventBNPLFunnel("OFERTA_CONFIRMACION", storageOrderID, localStorageHash)
            }
            // FINISH
        verifyBiometry();
    }, []);

    const handleContinue = () => {
        const personData = JSON.parse(getItemService('personData'));
        setItemService(
            LoanRequestConstantsLS.PersonToGetLoan,
            JSON.stringify({
                personId: personData.personId,
                dni: personData.dni,
                sexTypeId: personData.gender,
                phoneCode: personData.areaCode,
                phoneNumber: personData.phoneNumber,
                cuit: personData.cuit
            })
        );
        navigate(RoutesConstants.LOAN_PAY_METHOD_BNPL);
    };

    return (
        <>
            <Stack width="100%" spacing={5} mb={2}>
                <Stack>
                    {OPERATION_DETAILS.price && (
                        <SellerCard
                            avatar={{
                                src: OPERATION_DETAILS.src
                            }}
                            shopName={OPERATION_DETAILS.merchant_name}
                            orderId={OPERATION_DETAILS.order}
                            value={moneyMask(OPERATION_DETAILS.price.toString(), true, 2)}
                        />
                    )}
                    <InstallmentsCard 
	    		orderValue={OPERATION_DETAILS.price ? OPERATION_DETAILS.price : 0}	
                        total={moneyMask(total, true, 2)}
	    		totalMon={total}
                        numberOfInstallments={selectedInstallment?.quantityRate}
                        installmentAmount={moneyMask(
                            selectedInstallment?.amountRate.toString(),
                            true,
                            2
                        )}
                        installments={installments}
                        selectedInstallment={selectedInstallment}
                        onChangeInstallments={handleInstallmentSelection}
                        hideButton
                    />
                    <StyledList sx={{ paddingTop: 3 }}>
                        <ListItem>
                            <ListItemAvatar>
                                <StyledAvatar className="green">
                                    <CaldendarIcon />
                                </StyledAvatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${STRINGS.financing.INSTALLMENT_DATE} ${date}`}
                            />
                        </ListItem>
                        <PaymentMethodItem
                            paymentMethod={selectedInstallment?.type === INSTALLMENT_TYPE.ADELANTADO ? selectedInstallment?.type : allowedPaymentMethods?.paymentId}
                            variant="green"
                        />
                    </StyledList>
                    <AcceptTermsAndPolicy
                        sx={{ fontWeight: 'normal', paddingTop: 3 }}
                        sxLink={{ '&&': { color: ColorConstants.blue[500] } }}
                    />
                </Stack>
                <Stack>
                    <ButtonCustom text={STRINGS.offer.CTA_CONTINUE} action={handleContinue} />
                    <BackHomeDialogButton/>
                </Stack>
            </Stack>
        </>
    );
};
