import { Grid, useTheme, useMediaQuery } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import { CardActiveLoan } from '../ActiveLoan/CardActiveLoan';
import LoanActiveContext from '../../Contexts/LoanActive/LoanActiveContext';
import { TitleCustom } from '../../Components/TitleCustom';
import STRINGS from '../../i18n/local/es.json';
import { WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

export const ActiveLoansSection = ({ variant, list }) => {
    const { creditList } = useContext(LoanActiveContext);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));

    const [activeCreditList, setActiveCreditList] = useState([]);

    useEffect(() => {
        setActiveCreditList(list ? list : creditList?.credits);
    }, [creditList, list]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}></Grid>
            {activeCreditList && activeCreditList.length > 0 && (
                <Grid item xs={10} sm={3} md={3} mt={desktop ? 5 : 2}>
                    <TitleCustom
                        size={desktop ? '40px' : '24px'}
                        title={`Tenés ${activeCreditList.length} ${
                            variant === WORKFLOW_CONSTANTS.BNPL ? '' : ''
                        }${activeCreditList.length > 1 ? 'operaciones' : 'operación'} en curso`}
                        subtitle={STRINGS.loans_section.SUBTITLE}
                    />
                </Grid>
            )}
            <Grid item xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}></Grid>
            <Grid item xs={1} sm={2} sx={{ display: { xs: 'block' } }}></Grid>
            <Grid item xs={10} sm={7} md={7}>
                {activeCreditList &&
                    activeCreditList.map((c, index) => {
                        //c.status = 'Rechazada';
                        return (
                            <CardActiveLoan
                                key={`${c.id}${index}`}
                                size={desktop ? '24px' : '16px'}
                                sizeQuoteExpression={'12px'}
                                sizeQuotes={'14px'}
                                credit={c}
                                index={index}
                                variant={variant}></CardActiveLoan>
                        );
                    })}
            </Grid>
            {activeCreditList && activeCreditList.length > 0 && (
                <Grid
                    item
                    xs={12}
                    mb={desktop ? 10 : 1}
                    mt={desktop ? '' : 1}
                    ml={desktop ? '' : 3}>
                    <img src={desktop ? 'linePrivateHome.svg' : 'linePrivateHomeMobile.svg'} />
                </Grid>
            )}
        </Grid>
    );
};
