import {
    CameraIcon,
    CreditCardIcon,
    EyeIcon,
    FaceNeutralIcon,
    FaceSmileIcon,
    FlashIcon,
    RefreshIcon,
    SunIcon
} from '../../../Components/Icons';
import neutralFace from '../../../Assets/neutral-face.svg';
import smileFace from '../../../Assets/smiling-face.svg';
import rightWink from '../../../Assets/right_wink.svg';
import leftWink from '../../../Assets/left_wink.svg';
import openMouth from '../../../Assets/open_mouth.svg';

import { StepsConstants } from '../../../Constants/StepsConstants';
import STRINGS from '../../../i18n/local/es.json';

export const INSTRUCTIONS = {
    dni: [
        {
            icon: <FlashIcon />,
            description: STRINGS.biometric.dni.INSTRUCTIONS.NO_FLASH
        },
        {
            icon: <CameraIcon />,
            description: STRINGS.biometric.dni.INSTRUCTIONS.FRAME_DNI
        },
        {
            icon: <RefreshIcon />,
            description: STRINGS.biometric.dni.INSTRUCTIONS.ROTATE_PHONE
        }
    ],
    selfie: [
        {
            icon: <SunIcon />,
            description: STRINGS.biometric.selfie.INSTRUCTIONS.CLEAR_BACKGROUND
        },
        {
            icon: <EyeIcon />,
            description: STRINGS.biometric.selfie.INSTRUCTIONS.EYE_LEVEL_PHONE
        },
        {
            icon: <FaceNeutralIcon />,
            description: STRINGS.biometric.selfie.INSTRUCTIONS.NO_FACE_OBJECTS
        }
    ]
};

export const MODALS = {
    SECONDSTEP: {
        title: STRINGS.biometric.dni.DIALOG_BACK.TITLE,
        content: {
            description: STRINGS.biometric.dni.DIALOG_BACK.DESCRIPTION,
            buttonText: STRINGS.biometric.dni.DIALOG_BACK.CTA_CONTINUE,
            icon: <CreditCardIcon />,
            step: StepsConstants.SECOND_STEP
        }
    },
    THIRDSTEP: {
        title: STRINGS.biometric.selfie.DIALOG_NEUTRAL.TITLE,
        content: {
            description: STRINGS.biometric.selfie.DIALOG_NEUTRAL.DESCRIPTION,
            buttonText: STRINGS.biometric.selfie.DIALOG_NEUTRAL.CTA_CONTINUE,
            image: neutralFace,
            icon: <FaceNeutralIcon />,
            step: StepsConstants.THIRD_STEP
        }
    },
    FOURTHSTEP: {
        SMILE: {
            title: STRINGS.biometric.selfie.DIALOG_SMILE.TITLE,
            content: {
                description: STRINGS.biometric.selfie.DIALOG_SMILE.DESCRIPTION,
                buttonText: STRINGS.biometric.selfie.DIALOG_SMILE.CTA_CONTINUE,
                image: smileFace,
                icon: <FaceSmileIcon />,
                step: StepsConstants.FOURTH_STEP
            }
        },
        WINK: {
            title: STRINGS.biometric.selfie.DIALOG_WINK.TITLE,
            content: {
                description: STRINGS.biometric.selfie.DIALOG_WINK.DESCRIPTION,
                buttonText: STRINGS.biometric.selfie.DIALOG_WINK.CTA_CONTINUE,
                image: leftWink,
                icon: <FaceSmileIcon />,
                step: StepsConstants.FOURTH_STEP
            }
        },
        OPEN_MOUTH: {
            title: STRINGS.biometric.selfie.DIALOG_OPEN_MOUTH.TITLE,
            content: {
                description: STRINGS.biometric.selfie.DIALOG_OPEN_MOUTH.DESCRIPTION,
                buttonText: STRINGS.biometric.selfie.DIALOG_OPEN_MOUTH.CTA_CONTINUE,
                image: openMouth,
                icon: <FaceSmileIcon />,
                step: StepsConstants.FOURTH_STEP
            }
        }
    }
};
