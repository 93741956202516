import { Box, Grid } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DescriptionForm, Loader, RadioGroup } from '../../Components/BNPL';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import GrayButton from '../../Components/LoginBtns/GrayButton';

import ErrorMessage from '../../Components/Form/ErrorMessage';
import { BNPLRegisterContext } from '../../Contexts/BNPLRegister/BNPLRegisterContext';
import { GENDER_OPTIONS } from '../../Constants/GenderConstants';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import STRINGS from '../../i18n/local/es.json';
import { BackHomeDialogButton } from '../../Components/BNPL/Buttons/BackHomeDialogButton';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';

export const GenderPage = () => {
    const {
        error,
        personData,
        handleOnChangeFieldEvent,
        handleChangeMultipleValues,
        loading,
        searchPersonByDniAndGender
    } = useContext(BNPLRegisterContext);
    const navigate = useNavigate();
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const drmerchant = localStorage.getItem("drmerchant");

    const handleContinue = () => {
        localStorage.setItem('personData', JSON.stringify(personData));
        searchPersonByDniAndGender(personData.dni, personData.gender);
    };

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem('personData'));
        if (data && data.dni) {
            handleChangeMultipleValues(JSON.parse(localStorage.getItem('personData')));
        } else {
            navigate(RoutesConstants.IDENTIFICATION_DNI_PAGE);
        }
    }, []);

    return (
        <Box display="flex" width="100%" flexDirection="column" justifyContent="space-between">
            <Box>
                <Grid>
                    <ReturnButton />
                </Grid>
                <DescriptionForm
                    title={STRINGS.identification.genderPage.TITLE}
                    description={STRINGS.identification.genderPage.SUBTITLE}
                    pb={3}
                />
                <RadioGroup
                    options={GENDER_OPTIONS}
                    selectedValue={personData.gender}
                    onChange={handleOnChangeFieldEvent('gender')}
                />
            </Box>

            <Box>
                {error && (
                    <ErrorMessage
                        title={STRINGS.identification.genderPage.ERROR_TITLE}
                        description={STRINGS.identification.genderPage.ERROR_DESCRIPTION}
                    />
                )}
                <GrayButton
                    text={loading ? <Loader /> : STRINGS.buttons.CONTINUE}
                    disabled={!personData.gender}
                    action={handleContinue}
                />
                <BackHomeDialogButton
                    text={(drmerchant == 'S' && workflow === WORKFLOW_CONSTANTS.BNPL) ? STRINGS.buttons.CANCEL_PURCHASE : (workflow === WORKFLOW_CONSTANTS.BNPL && STRINGS.buttons.BACK_TO_STORE)}
                />
            </Box>
        </Box>
    );
};
