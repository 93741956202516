import React from 'react';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { feedback_gradients } from '../../Financing/FinancingPage.styled';
import { Box } from '@mui/material';
import { Img } from '../../../Components/ImageContainer';
import ThumbsDownImg from '../../../Assets/feedback-unsuccessful-payment.svg';
import STRINGS from '../../../i18n/local/es.json';
import { Circle, Description, Title, TitleContainer } from './ValidationError.styled';
import { ErrorIcon } from '../../../Components/Icons';
import { DescriptionForm } from '../../../Components/BNPL';

const ErrorTitle = ({ value }) => {
    return (
        <TitleContainer>
            <Circle>
                <ErrorIcon />
            </Circle>
            <Title>{value}</Title>
        </TitleContainer>
    );
};

export const ValidationErrorForm = () => {
    return (
        <Box textAlign="center">
            <DescriptionForm
                title={<ErrorTitle value={STRINGS.feedbackValidationError.TITLE} />}
                description={
                    <Description>{STRINGS.feedbackValidationError.DESCRIPTION}</Description>
                }
            />
            <Box my={12} display="flex" justifyContent="center">
                <Img src={ThumbsDownImg} />
            </Box>
        </Box>
    );
};

export const ValidationErrorPage = () => {
    const layoutProps = {
        mobile: {
            background: 'transparent',
            showDescription: false,
            gradients: feedback_gradients
        }
    };
    return <BNPLLayout Form={<ValidationErrorForm />} layoutProps={layoutProps} />;
};
