export function FaceSmileIcon({ color = 'currentColor', width = '16', height = '16' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.33325 9.33301C5.33325 9.33301 6.33325 10.6663 7.99992 10.6663C9.66659 10.6663 10.6666 9.33301 10.6666 9.33301M5.99992 5.99967H6.00659M9.99992 5.99967H10.0066M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
