import { Table as MuiTable, TableRow } from '@mui/material';
import { TableCell, TableHead, TableBody } from './Table.styled';

export const Table = ({ options = [], values = [] }) => {
    return (
        <MuiTable>
            <TableHead>
                <TableRow>
                    {options.map((opt, i) => (
                        <TableCell key={i} className={i === options.length - 1 ? 'right' : ''}>
                            {opt}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {values.map((row) => {
                    return (
                        <TableRow key={row.installment}>
                            {Object.values(row).map((value, i) => (
                                <TableCell key={i} className={i === options.length - 1 ? 'right' : ''}>{value}</TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};
