import React from 'react';
import { Grid } from '@mui/material';
import HomeLayout from '../../Components/HomeLayouts';
import { Outlet } from 'react-router-dom';
import { BiometricDataState } from '../../Contexts/BiometricData/BiometricDataState';

export const BiometricDataContainer = () => {
    return (
        <BiometricDataState>
            <Grid container>
                <HomeLayout
                    Form={<Outlet />}
                />
            </Grid>
        </BiometricDataState>
    );
};