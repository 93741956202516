import { FormHelperText } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ErrorLabel = ({ description, fontSizeIcon = 'small', fontSizeText = '12px', sx }) => {
    return (
        <>
            <FormHelperText
                sx={{ display: 'flex', fontSize: fontSizeText, ...sx }}
                id="outlined-adornment-amount">
                <ErrorOutlineIcon color="error" fontSize={fontSizeIcon} sx={{ mr: '2%' }} />
                {description}
            </FormHelperText>
        </>
    );
};

export default ErrorLabel;
