import { Stack } from '@mui/material';
import { Description, DescriptionForm } from '../../Components/BNPL';
import { BNPLLayout } from '../../Components/Layout/BNPLLayout';
import STRINGS from '../../i18n/local/es.json';
import { PasswordTokenForm } from './PasswordTokenForm';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';
import { Link } from 'react-router-dom';
import { getItemService } from '../../Services/LocalStorageService';
import { RegisterConstantsLS } from '../../Contexts/Register/RegisterActions';
import { RoutesConstants } from '../../Constants/RoutesConstants';

const FormDescription = () => {
    const email = getItemService(RegisterConstantsLS.UserEmail);
    return (
        <Stack mb={['36px', '46px']}>
            <ReturnButton />
            <Stack gap="16px">
                <DescriptionForm
                    title={STRINGS.verify_email_code.FORM_TITLE}
                    description={STRINGS.verify_email_code.FORM_DESCRIPTION_NEW_PASSWORD.replace('email', email)}
                />
                <Link to={RoutesConstants.NEW_PASSWORD_CHANGE_REQUEST_PAGE}>{STRINGS.verify_email_code.CHANGE_EMAIL}</Link>
            </Stack>
        </Stack>
    );
};

export const VerifyPasswordCodePage = () => {
    return (
        <BNPLLayout
            ContainerPanel={
                <Description
                    title={STRINGS.identification.TITLE_AUTH}
                    description={STRINGS.identification.SUBTITLE_AUTH}
                    returnButton={false}
                />
            }
            Form={
                <Stack>
                    <FormDescription />
                    <PasswordTokenForm />
                </Stack>
            }
            layoutProps={{
                desktop: {
                    showFullWidthDesciption: true
                }
            }}
        />
    );
};
