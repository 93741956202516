import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import { BiometricDataConstants } from '../../Constants/BiometricDataConstants';
import { StepsConstants } from '../../Constants/StepsConstants';
import BiometricDataContext from '../../Contexts/BiometricData/BiometricDataContext';
import { DNISteps } from './Components/DNISteps';
import { ImageCapture } from './Components/ImageCapture';
import { useDialog } from '../../Hooks/useDialog';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import Toast from '../../Components/Toast';

export const FirstStep = () => {
    const { confirmPhoto, images, error, attempt, openCamera, setAttempt } =
        useContext(BiometricDataContext);

    const [errorMessage, setErrorMessage] = useState(null);

    const dialog = useDialog();

    const handleOpenModal = () => {
        dialog.setTitle('Foto DNI');
        dialog.setContent('Por favor, ahora captura una foto del frente de tu DNI.');
        dialog.OpenDialog();
    };

    const handleSetDataAndExecute = async () => {
        setErrorMessage(null);

        let data = {
            frontDNI: images[StepsConstants.FIRST_STEP].photo,
            entreConsultasImage: [],
            step: StepsConstants.FIRST_STEP,
            biometricDataType: BiometricDataConstants.FRONT_DNI,
            extension: 'png'
        };
        //Intento de manejo de error
        try {
            if (!data.frontDNI) {
                Toast.error('Ocurrió un error al capturar la foto, por favor volvé a tomarla.');
                setErrorMessage({
                    title: 'Error al tomar la foto',
                    description: 'Ocurrió un error al capturar la foto, por favor volvé a tomarla.'
                });
                return;
            }
            const res = await confirmPhoto(data, RoutesConstants.BIOMETRIC_DATA_PAGE);

            if (res?.ErrorType && Number(attempt) < 2) {
                setErrorMessage({
                    title: res.message.split('.')[0],
                    description: res.message.split('.')[1]
                });
                return;
            }

            const errors = res?.response?.data?.errors[0]?.message;

            if (
                errors?.includes('El DNI Ingresado no Coincide') ||
                errors?.toLowerCase()?.includes('el dni ingresado no coincide')
            ) {
                setAttempt((prev) => prev - 1);
                setErrorMessage({
                    title: 'No pudimos validar tu identidad',
                    description: errors.split(':')[1]
                });
                return;
            }

            if (res.response?.data?.errors && Number(attempt) < 2) {
                if (errors && errors.includes('sentimos')) {
                    setErrorMessage({
                        title: 'No pudimos validar tu identidad',
                        description: errors
                    });
                    return;
                }

                if (errors && errors.split(':').length > 1) {
                    setErrorMessage({
                        title: 'No pudimos validar tu identidad',
                        description: errors.split(':')[1]
                    });
                    return;
                }

                if (errors.includes('El DNI Ingresado no Coincide')) {
                    setErrorMessage({
                        title: 'No pudimos validar tu identidad',
                        description: errors.split(':')[1]
                    });
                    return;
                }

                if (errors && Number(attempt) < 2) {
                    if (errors.includes('validar tu identidad')) {
                        setErrorMessage({
                            title: errors.split('.')[0],
                            description: errors.split('.')[1]
                        });
                    } else {
                        setErrorMessage({
                            title: 'No pudimos validar tu identidad',
                            description: errors
                        });
                        return;
                    }
                }
            }
            if (res.response?.data?.errors && Number(attempt) < 2) {
                const errors = res.response.data.errors[0].message.split('.');
                setErrorMessage({ title: errors[0], description: errors[1] });
                return;
            }
            //handleOpenModal();
        } catch (error) {
            console.log('Error..', error);
        }
    };

    return (
        <Grid>
            {/*  {!images[StepsConstants.FIRST_STEP].photo && (
                <DNIModal
                    action={() => openCamera(StepsConstants.FIRST_STEP)}
                    dialogHook={dialog}
                    buttonPosition="center"
                    icon={<PaymentIcon />}
                    buttonText="Continuar"
                />
            )} */}
            {!images[StepsConstants.FIRST_STEP].photo ? (
                <DNISteps handleOpenModal={handleOpenModal} dialog={dialog} />
            ) : (
                <ImageCapture
                    title={`Confirmemos tu foto del frente`}
                    description="Si ya lograste una foto nítida, continúa para avanzar con la foto del dorso."
                    image={images[StepsConstants.FIRST_STEP].photo}
                    action={() => handleSetDataAndExecute()}
                    repeatCapture={() => {
                        openCamera(StepsConstants.FIRST_STEP, true);
                        setErrorMessage(null);
                    }}
                    /* repeatCapture={() => {
                        navigate(`${RoutesConstants.DNI_FRONT_PAGE}/frente`);
                    }} */
                    error={error}
                    attempt={attempt}
                    dialog={dialog}
                    disabled={errorMessage != null && Number(attempt) < 3}
                    errorMessage={errorMessage}
                />
            )}
        </Grid>
    );
};
