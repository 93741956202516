export function DNIIcon({ color = 'currentColor', width = '24', height = '18' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 24 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4 14H20M4 11H20M12 5H15M12 8H20M3 1H21C22.1046 1 23 1.89543 23 3V15C23 16.1046 22.1046 17 21 17H3C1.89543 17 1 16.1046 1 15V3C1 1.89543 1.89543 1 3 1ZM8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4C7.10457 4 8 4.89543 8 6Z"
                stroke={color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
