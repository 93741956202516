import { TableCell as MuiTableCell, TableHead as MuiTableHead, TableBody as MuiTableBody } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const TableCell = styled(MuiTableCell)`
    font-family: 'Archivo';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: inherit;
    border-color: ${ColorConstants.blue[100]};
    &.right {
        width: 50%;
        text-align: right;
    }
`;

export const TableHead = styled(MuiTableHead)`
    th{
        padding: 16px 0;
    }
`

export const TableBody = styled(MuiTableBody)`
 td{
        padding: 16px 0;
    }
`