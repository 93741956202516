import React from 'react';
import STRINGS from '../../../i18n/local/es.json';
import { ListItemAvatar, ListItemText } from '@mui/material';
import { ListItem, StyledAvatar } from '../FinancingPage.styled';
import { CreditCardIcon } from '../../../Components/Icons';
import { INSTALLMENT_TYPE } from '../../../Constants/BNPLConstants';

export const PaymentMethodItem = (props) => {
    let textToRender;
    switch (props.paymentMethod) {
        case 1:
            textToRender = <ListItemText primary={STRINGS.financing.INSTALLMENT_METHOD_4} />
        break;
        case 2:
            textToRender = <ListItemText primary={STRINGS.financing.INSTALLMENT_METHOD_2} />
        break;
        case 3:
            textToRender = <ListItemText primary={STRINGS.financing.INSTALLMENT_METHOD_3} />
        break;
        case INSTALLMENT_TYPE.ADELANTADO:
            textToRender = <ListItemText primary={STRINGS.financing.INSTALLMENT_METHOD_2} />
        break;
        default:
            textToRender = <ListItemText primary={STRINGS.financing.INSTALLMENT_METHOD_4} />
        break;
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <StyledAvatar className={props.variant}>
                    <CreditCardIcon />
                </StyledAvatar>
            </ListItemAvatar>
            {textToRender}
        </ListItem>
    );
}
