import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Description, DescriptionForm } from '../../../Components/BNPL';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { Img } from '../../../Components/ImageContainer';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import AllSet from '../../../Assets/Images/all-set.svg';
import STRINGS from '../../../i18n/local/es.json';
import { feedback_gradients } from '../../Financing/FinancingPage.styled';
import { ALLOWED_REDIRECT_PARAMS } from '../../../Helpers/GetURLByParam';
import { BackHomeDialogButton } from '../../../Components/BNPL/Buttons/BackHomeDialogButton';
import { BNPLRegisterContext } from '../../../Contexts/BNPLRegister/BNPLRegisterContext';

export const ExistingAccount = () => {
    const drmerchant = localStorage.getItem("drmerchant");
    const { accountData } = useContext(BNPLRegisterContext);
    const navigate = useNavigate();

    const email = accountData.email.split('@').map((e) => ( e.substring(0,2) + "***" )).join('@');

    return (
        <Stack direction="column" justifyContent="space-between">
            <Box>
                <DescriptionForm title={STRINGS.existing_account.FORM_TITLE} description={STRINGS.existing_account.FORM_SUBTITLE} />
                <Typography
                    color='primary'
                    lineHeight='24px'
                    fontFamily='Archivo'
                    letterSpacing='-0.02em'
                    fontSize='16px'
                    fontWeight='regular'
                    marginTop='8px'
                    whiteSpace='pre-line'>
                    {
                        (accountData.emailAccountId ? `Email ${email}\n` : '') +
                        /*(accountData.facebookAccountId ? `Cuenta Facebook\n` : '') +*/
                        (accountData.googleAccountId ? `Cuenta Google\n` : '')
                    }
                </Typography>
                <Box textAlign="center" mt={5}>
                    <Img src={AllSet} />
                </Box>
            </Box>
            <Box textAlign="center" mt={[20, 0]}>
                <ButtonCustom
                    text={STRINGS.existing_account.SIGN_IN_BTN}
                    margin="0 0 24px"
                    action={() => navigate(`${RoutesConstants.LOGIN_PAGE}?redirectTo=${ALLOWED_REDIRECT_PARAMS.DATOS_CONTACTO}`)}
                />
                <BackHomeDialogButton text={drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE} />
            </Box>
        </Stack>
    );
};
