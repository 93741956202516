import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery, Stack, Typography } from '@mui/material';
import { moneyMask } from '../../../Helpers/Masks';
import { financing_gradients } from '../../Financing/FinancingPage.styled';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { getItemService } from '../../../Services/LocalStorageService';
import { BNPLConstants } from '../../../Constants/BNPLConstants';
import { BNPLLayout } from '../../../Components/Layout/BNPLLayout';
import { AcceptTermsAndPolicy } from '../../../Components/AcceptTermsAndPolicy';
import { ButtonContainer, Description, Title } from './InsufficientFunds.styled';
import STRINGS from '../../../i18n/local/es.json';
import { capitalize } from '../../../Helpers/Utils';
import { DollarIcon, HashIcon } from '../../../Components/Icons';
import { BnplCard, OperationDetails } from '../../../Components/BNPL';
import { useRedirectTimer } from '../../../Hooks/useRedirectTimer';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';
import { BackHomeDialogButton } from '../../../Components/BNPL/Buttons/BackHomeDialogButton';

const getDescription = (variant) => {
    const loanData = JSON.parse(getItemService(BNPLConstants.LOAN_DATA));
    let description = '';

    if (variant === 'minimumPrice') {
        const parts = STRINGS.feedbackInsufficientFunds.DESCRIPTION_MINIMUM.split('MontoAprobacion');
        description = (
            <div>
                {parts[0]}
                <strong>${loanData.offer[0].maximumAmount}</strong>
                {parts[1].replace('PrecioMinimo', process.env.REACT_APP_MINIMUM_AMOUNT_PURCHASE)}
            </div>
        );
    } else {
        description = STRINGS.feedbackInsufficientFunds.DESCRIPTION.replace('$', `$${loanData.offer[0].maximumAmount}`);
    }
    return description;
}

const InsufficientFundsTitle = ({ personData, variant }) => {
    return (
        <Box mt={5}>
            <Title>
                {STRINGS.feedbackInsufficientFunds.TITLE +
                    capitalize(personData.name.split(',')[1])}
            </Title>
            <Description mt={[1, 2]}>
                {getDescription(variant)}
            </Description>
        </Box>
    );
};

export const InsufficientFundsForm = ({ variant, personData }) => {
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const { orderData } = useContext(BNPLOrderContext);
    const drmerchant = localStorage.getItem("drmerchant");

    const OPERATION_DETAILS = [
        {
            title: 'Número de orden',
            description: orderData.merchant_Order_Id,
            icon: <HashIcon />
        },
        {
            title: 'Comercio:',
            description: orderData.merchant_Name,
            src: '/tienda_online.svg'
        },
        {
            title: 'Valor de la compra:',
            description: moneyMask(String(orderData.price)),
            icon: <DollarIcon />
        }
    ];

    let remainingSeconds;
    if (variant === 'withTimer') {
        ({ remainingSeconds } = useRedirectTimer(
            process.env.REACT_APP_CONTINUE_PURCHASE_TIMEOUT,
            orderData.return_Url_Error,
            true,
	    orderData
        ));
    }

    useEffect(() => {
        if (!personData || !personData?.name) {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    }, []);

    const handleOnClick = () => {
        navigate(RoutesConstants.PUBLIC_HOME);
    };

    return (
        <Stack width="100%" mt={[0, 11]}>
            <Box>
                <BnplCard p={[3, 5]}>
                    <OperationDetails
                        title={STRINGS.redirectToDirecto.CONTENT_TITLE}
                        details={OPERATION_DETAILS}
                    />
                </BnplCard>
            </Box>
            {variant === 'withTimer' ? (
                <Typography
                    mt={[11, 7]}
                    mb={[11, 0]}
                    align="center"
                    color={ColorConstants.blue[900]}
                    fontFamily="Archivo">
                    {STRINGS.feedbackInsufficientFunds.REDIRECT.replace(
                        0,
                        `0:0${remainingSeconds}`
                    )}
                </Typography>
            ) : (
                <ButtonContainer>
                    {desktop && (
                        <AcceptTermsAndPolicy
                            sx={{ fontWeight: 'normal', paddingTop: 3 }}
                            sxLink={{ '&&': { color: ColorConstants.blue[500] } }}
                        />
                    )}
                    <ButtonCustom
                        text={STRINGS.feedbackInsufficientFunds.NAVIGATE_BTN}
                        fontFamily="Archivo"
                        fontWeight={500}
                        action={handleOnClick}
                        margin={desktop ? '16px 0' : '80px 0 0 0'}
                    />
                    <Box textAlign="center" mt={3}>
                        <BackHomeDialogButton text={drmerchant == 'S' ? STRINGS.buttons.CANCEL_PURCHASE : STRINGS.buttons.BACK_TO_STORE} />
                    </Box>
                </ButtonContainer>
            )}
        </Stack>
    );
};

export const InsufficientFundsPage = ({ variant, minimumPrice }) => {
    const personData = JSON.parse(getItemService(BNPLConstants.PERSON_DATA));
    return (
        <BNPLLayout
            ContainerPanel={<InsufficientFundsTitle personData={personData} variant={minimumPrice && 'minimumPrice'} />}
            Form={<InsufficientFundsForm variant={variant} personData={personData} />}
            layoutProps={{
                desktop: {
                    background: 'transparent',
                    showDescription: true
                },
                mobile: {
                    background: 'transparent',
                    showDescription: true,
                    gradients: financing_gradients
                }
            }}
        />
    );
};
