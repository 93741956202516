import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import ChangePasswordContext from './ChangePasswordContext';
import { getItemService, setItemService } from '../../Services/LocalStorageService';
import {
    changePasswordRequestEmail,
    tokenTimeValidate,
    changePasswordService,
    resendEmailService
} from '../../Services/ChangePasswordService';
import { ChangePasswordReducer, initialState } from './ChangePasswordReducer';
import Actions, { ChangePasswordConstantsLS } from './Actions';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import jwt from 'jwt-decode';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../Constants/BNPLConstants';
import { changePasswordAccount } from '../../Services/BNPLService';
import { RegisterConstantsLS } from '../Register/RegisterActions';
import { AppConstantsLS } from '../Global/AppActions';

export const ChangePasswordState = ({ children }) => {
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const [state, dispatch] = useReducer(ChangePasswordReducer, initialState);

    const navigate = useNavigate();

    const changePassword = async (newPassword) => {
        let ecommerceAccountId;
        if (workflow === WORKFLOW_CONSTANTS.BNPL) {
            ecommerceAccountId = getItemService(AppConstantsLS.EcommerceAccountId);
        } else {
            const token = getItemService(ChangePasswordConstantsLS.TokenChangePassword);
            ecommerceAccountId = jwt(token).Ecommerce;
        }
        await changePasswordService(ecommerceAccountId, newPassword);
        return true;
    };

    const resendEmailRequest = async () => {
        const response = await resendEmailService(state.userEmail);

        return response;
    };

    const changePasswordRequest = async (email) => {
        if (workflow === WORKFLOW_CONSTANTS.BNPL) {
            try {
                const response = await changePasswordAccount(email);
                dispatch({ type: Actions.SET_USER_EMAIL, payload: email });
                setItemService(RegisterConstantsLS.UserEmail, email);
                setItemService(
                    RegisterConstantsLS.EcommerceAccountID,
                    response.data.ecommerceAccountId
                );
                setItemService(BNPLConstants.REGISTER_HASH, response.data.hash);
                navigate(RoutesConstants.VERIFY_PASSWORD_CODE);
            } catch (error) {
                console.log(error);
            }
        } else {
            const response = await changePasswordRequestEmail(email);
            dispatch({ type: Actions.SET_USER_EMAIL, payload: email });
            navigate(RoutesConstants.NEW_PASSWORD_SUCCESS_EMAIL_PAGE);
            return response;
        }
    };

    const tokenValidate = () => {
        const token = getItemService(ChangePasswordConstantsLS.TokenChangePassword);
        if (token == null) {
            return false;
        }
        return tokenTimeValidate(token);
    };

    return (
        <ChangePasswordContext.Provider
            value={{
                userEmail: state.userEmail,
                changePassword: changePassword,
                changePasswordRequest: changePasswordRequest,
                tokenValidate: tokenValidate,
                resendEmailRequest: resendEmailRequest
            }}>
            {children}
        </ChangePasswordContext.Provider>
    );
};

ChangePasswordState.propTypes = {
    children: PropTypes.element
};
