import { useState, useEffect, useContext } from "react";
import { Box, Stack } from "@mui/material";
import { BNPLLayout } from "../../../Components/Layout/BNPLLayout"
import { Description, DescriptionForm } from "../../../Components/BNPL";
import { BackHomeDialogButton } from "../../../Components/BNPL/Buttons/BackHomeDialogButton";
import { ReturnButton } from "../../../Components/LoginBtns/ReturnButton";
import { getItemService } from "../../../Services/LocalStorageService";
import { RoutesConstants } from "../../../Constants/RoutesConstants";
import { BNPLConstants, APP_ID } from "../../../Constants/BNPLConstants";
import { LoanRequestConstantsLS } from "../../../Contexts/LoanRequest/LoanRequestActions";
import STRINGS from "../../../i18n/local/es.json"
import QRCode from "react-qr-code";
import { BNPLSiisaContext } from "../../../Contexts/BNPLSiisa/BNPLSiisaContext";

const LeftPanel = () => {
	return (
		<>
			<Description
				title={STRINGS.scan_qr_code.continuing_from_mobile.TITLE}
				description={STRINGS.scan_qr_code.continuing_from_mobile.SUBTITLE}
				returnButton={false}
			/>
		</>
	);
};

const RightPanel = () => {
	const [qrUrl, setQrUrl] = useState();
	const personData = JSON.parse(getItemService(BNPLConstants.PERSON_DATA));
	const loanData = JSON.parse(localStorage.getItem(LoanRequestConstantsLS.LoanData));

	useEffect(() => {
		if (loanData && personData) {
			const url = window.location.origin;
			const path = RoutesConstants.CROSS_DEVICE_CS;
			setQrUrl(url + path + '?personId=' + personData.personId + '&pendingRequestId=' + loanData.pendingRequestId + '&ecommerceAccountId=' + personData.ecommerceAccountId);
		}
	}, [loanData, personData]);

	return (
		<Stack justifyContent="space-between">
			<ReturnButton />
			<DescriptionForm
				title={STRINGS.scan_qr_code.continuing_from_mobile.CONTENT_TITLE}
				description={STRINGS.scan_qr_code.continuing_from_mobile.CONTENT_SUBTITLE}
			/>
			<Box my={6} textAlign="center">
				{qrUrl && <Box display="flex" justifyContent="center">
					<QRCode value={qrUrl} />	
				</Box>}
			</Box>
			<BackHomeDialogButton />
		</Stack>
	);
};

export const ScanQrCodeCS = () => {
	const { saveHasValidatedIdentity } = useContext(BNPLSiisaContext);

	useEffect(() => {
		saveHasValidatedIdentity(APP_ID.CS, false);
	}, []);

	return (
		<>
			<BNPLLayout ContainerPanel={<LeftPanel />} Form={<RightPanel />} />
		</>
	);
};