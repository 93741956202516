import { Navigate, useParams } from 'react-router-dom';
import { RegisterConstantsLS } from '../../Contexts/Register/RegisterActions';
import { setItemService } from '../../Services/LocalStorageService';
import { RoutesConstants } from '../../Constants/RoutesConstants'

const RegisterValidateToken = () => {
    const { token } = useParams();

    setItemService(RegisterConstantsLS.TokenRegister, token);

    return <Navigate to={RoutesConstants.REGISTER_CHECK_DNI_PAGE} /> 
};

export default RegisterValidateToken;