import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';

export const gradients = {
    desktop: [
        {
            type: 'element',
            children: <img src="arrows.svg" />,
            blur: '0px',
            right: 0,
            top: '705px'
        }
    ],
    mobile: [
        {
            type: 'circle',
            width: '333px',
            height: '333px',
            bgcolor: ColorConstants.blue[100],
            blur: '100px',
            left: '-180px',
            top: '795px'
        },
        {
            type: 'circle',
            width: '355px',
            height: '355px',
            bgcolor: ColorConstants.green[500],
            blur: '100px',
            right: '-330px',
            top: '650px'
        }
    ]
};

export const HeroButtons = styled(Stack)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        maxWidth: '342px'
    },
    '& p': {
        display: 'flex',
        justifyContent: 'center'
    }
}));

export const Container = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        padding: '0 16%'
    },
    padding: '0 5%'
}));

export const Divider = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        height: '200px',
        maxWidth: '890px',
        margin: '40px auto'
    },
    background: `url('endArrowPublicHome.svg') no-repeat center`,
    backgroundSize: 'contain',
    width: '100%',
    height: '40px',
    margin: '40px 0'
}));