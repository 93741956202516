import {
    Stack,
    ListItem as MuiListItem,
    ListItemAvatar,
    Avatar,
    Typography,
    styled,
    ListItemText,
    Box
} from '@mui/material';
import { BnplCard } from '../BnplCard/BnplCard';
import STRINGS from '../../../i18n/local/es.json';
import ImageIcon from '@mui/icons-material/Image';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { DollarIcon } from '../../Icons';

export const ListItem = styled(MuiListItem)`
    padding: 0;

    .MuiListItemText-primary,
    .MuiListItemText-secondary {
        color: inherit;
        font-size: 14px;
    }

    .MuiListItemText-primary {
        font-weight: 700;
    }
`;

const SellerCard = ({ avatar, shopName, value, orderId, variant }) => {
    return (
        <BnplCard mt={2}>
            <Stack p={3}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar src={avatar.src}>{avatar.icon || <ImageIcon />}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={shopName}
                        secondary={`${STRINGS.financing.ORDER} ${orderId}`}
                    />
                </ListItem>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {variant === 'total' ? (
                        <Box display="flex" alignItems="center">
                            <Avatar sx={{ bgcolor: ColorConstants.green[50] }}>
                                <DollarIcon color={ColorConstants.green[900]} />
                            </Avatar>
                            <Typography ml={2}>
                                {STRINGS.feedbackInsufficientFunds.TOTAL}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography>{STRINGS.financing.VALUE}</Typography>
                    )}
                    <Typography>{value}</Typography>
                </Stack>
            </Stack>
        </BnplCard>
    );
};

export default SellerCard;
