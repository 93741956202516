import styled, { keyframes, css } from 'styled-components';

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    /* min-width: 242px;
    min-height: 30px; */
    margin-bottom: 9px;
    min-width: 260px;
    width: 100%;
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
    max-height: ${({ maxHeight }) => maxHeight && `${maxHeight}px`};
    border-radius: 20px;
    width: 100%;
    min-width: 260px;
    height: 415px;
    overflow: hidden;
`;

export const Canvas = styled.canvas`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const Video = styled.video`
    position: absolute;
    ali &::-webkit-media-controls-play-button {
        display: none !important;
        -webkit-appearance: none;
    }
`;

export const Overlay = styled.div`
    position: absolute;

    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
    box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
    border: 1px solid #ffffff;
    border-radius: 10px;
`;

export const Flash = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    opacity: 0;

    ${({ flash }) => {
        if (flash) {
            return css`
                animation: ${flashAnimation} 750ms ease-out;
            `;
        }
    }}
`;

export const Button = styled.button`
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    top: 35%;
    right: -20%;
    background: white;
    position: absolute;
`;
