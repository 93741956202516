import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { accept_page_chatBox } from '../../Configs/exceptionPaths';

const AsyncScriptWrapper = ({ children }) => {
    const location = useLocation();
    const [show, setShow] = useState(false);

    useEffect(() => {
        deleteScript();
    }, []);

    useEffect(() => {
        if (
            accept_page_chatBox.includes(location.pathname)
        ) {
            setShow(true);
        } else if (show) {
            setShow(false);
            deleteScript();
        }
    }, [location.pathname]);

    const deleteScript = () => {
        const script = document.body.getElementsByClassName('s1-script');
        if (script.length > 0) {
            script[0]?.remove();
        }
        const chat = document.body.getElementsByClassName('s1-channel-active');
        if (chat.length > 0) {
            chat[0]?.remove();
        }
        const scriptGlobal = parent.top.document.body.getElementsByClassName('s1-script');
        if (scriptGlobal.length > 0) {
            scriptGlobal[0]?.remove();
        }
        const chatGlobal = parent.top.document.body.getElementsByClassName('s1-channel-active');
        if (chatGlobal.length > 0) {
            chatGlobal[0]?.remove();
        }
        const scriptSelf = parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf.length > 0) {
            scriptSelf[0]?.remove();
        }
        const chatSelf = parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf.length > 0) {
            chatSelf[0]?.remove();
        }
        const scriptSelf2 = parent.parent.document.body.getElementsByClassName('s1-script');
        if (scriptSelf2.length > 0) {
            scriptSelf2[0]?.remove();
        }
        const chatSelf2 = parent.parent.document.body.getElementsByClassName('s1-channel-active');
        if (chatSelf2.length > 0) {
            chatSelf2[0]?.remove();
        }
    };

    return (
        <>
            {!show ? (
                <>
                    <Helmet>
                        <script>
                            {`
                                $('#AsisteClickWhatsAppWidget').hide();
                            `}    
                        </script>
                    </Helmet>
                    {children}
                </>
            ) : (
                <>
                    <Helmet>
                        <script>{`
                            $('#AsisteClickWhatsAppWidget').show();
                        `}    
                        </script>
                    </Helmet>
                    {children}
                </>
            )}
        </>
    );
};

AsyncScriptWrapper.displayName = 'AsyncScriptWrapper';

export { AsyncScriptWrapper };
