import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { getOffersService } from '../../../Services/OfferService';
import { useContext } from 'react';
import { BNPLOrderContext } from '../../../Contexts/BNPLOrder/BNPLOrderContext';
import { INSTALLMENT_TYPE } from '../../../Constants/BNPLConstants';
import { creditDetailsService } from '../../../Services/LoanRequestService';

export const useOfferedInstallments = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { orderData } = useContext(BNPLOrderContext);
    const [loanAmount, setLoanAmount] = useState(orderData.price);
    const [pendingRequestId, setPendingRequestId] = useState('');
    const [installments, setInstallments] = useState([]);
    const [total, setTotal] = useState('0');
    const [date, setDate] = useState('');
    const [selectedInstallment, setSelectedInstallment] = useState({
        quantityRate: '0',
        amountRate: '0',
        expirationDate: '',
        id: ''
    });

    useEffect(() => {
      if(orderData.price){
        setLoanAmount(orderData.price)
      }
    }, [orderData])

    useEffect(() => {
        const LOAN_DATA_LOCAL_STORAGE_KEY = 'LoanData'; // @todo: move this to an env file.
        const dataAsJson = globalThis.localStorage.getItem(LOAN_DATA_LOCAL_STORAGE_KEY);
        const data = JSON.parse(dataAsJson);
        if (data && data.pendingRequestId) {
            setPendingRequestId(data.pendingRequestId);
        } else {
            navigate(RoutesConstants.PUBLIC_HOME);
        }
    }, []);

    useEffect(() => {
        const serviceCall = async (paramPendingRequestId, paramLoanAmount) => {
            try {
                const fetchedInstallments = await getOffersService(
                    paramPendingRequestId,
                    paramLoanAmount
                );
                const details = await creditDetailsService(paramPendingRequestId);
                let installmentIndex = 0;
		        let maxQuotes = 0;
                if (location.pathname == RoutesConstants.CONFIRMATION_PAGE || location.pathname.includes("confirmacion-oferta") 
                    || location.pathname == RoutesConstants.FINANCING_OFFER_PAGE || location.pathname.includes("oferta-bnpl") 
                    ) {
                    // confirmacion-oferta / oferta-bnpl
                    fetchedInstallments.data.deadlines.forEach((e, index) => {
                        if (e.quantityRate == details.quantityRate) {
                            installmentIndex = index;
                        }
                    })
                } else {
                    // financiacion-bnpl
                    fetchedInstallments.data.deadlines.forEach((e, index) => {
                        if (e.quantityRate > maxQuotes) {
                            maxQuotes = e.quantityRate;
                            installmentIndex = index;
                        }
                    })
                }
                
                handleInstallmentSelection(fetchedInstallments.data.deadlines[installmentIndex]);
                setInstallments(fetchedInstallments.data.deadlines);
                const date = new Date(fetchedInstallments.data.deadlines[installmentIndex].expirationDate);
                const day = date.getDate();
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                let formattedDate = 'el ' + day + '/' + month + '/' + year;
                if (date.toDateString() === new Date().toDateString() || fetchedInstallments.data.deadlines[installmentIndex].type === INSTALLMENT_TYPE.ADELANTADO) {
                    formattedDate = 'hoy';
                }
                setDate(formattedDate);
            } catch (error) {
                console.log(error)
                setError(true);
                navigate(RoutesConstants.MINIMUM_PURCHASE_PRICE_PAGE)
            }
        };
        loanAmount && pendingRequestId && serviceCall(pendingRequestId, loanAmount);
    }, [loanAmount, pendingRequestId]);

    const handleInstallmentSelection = useCallback(
        (selectedInstallment) => {
            setSelectedInstallment(selectedInstallment);
            setTotal(
                (
                    selectedInstallment.quantityRate.toString() * selectedInstallment.amountRate
                ).toFixed(2).toString()
            );
        },
        [setSelectedInstallment, setTotal]
    );

    return {
        installments,
        selectedInstallment,
        handleInstallmentSelection,
        currentTotal: total,
        currentDate: date,
        error
    };
};
