import { Box } from '@mui/system';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import { Img } from '../../../Components/ImageContainer';
import GrayButton from '../../../Components/LoginBtns/GrayButton';
import { RoutesConstants } from '../../../Constants/RoutesConstants';
import { getItemService, setItemService } from '../../../Services/LocalStorageService';
import { BNPLConstants, WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';
import { useNavigate } from 'react-router-dom';

export const Successful = ({ register, myProfile }) => {
    const workflow = localStorage.getItem(BNPLConstants.WORKFLOW);
    const navigate = useNavigate();
    const titleRegister = '¡Todo Listo!';
    const descriptionRegister =
        'Bienvenido a tu cuenta DIRECTO. Todo lo que necesitás sobre tus préstamos en un solo lugar.';
    const titleChangePassword = 'Contraseña actualizada';
    const descriptionChangePassword = 'Ya tenés nueva contraseña para poder ingresar a tu cuenta';

    const titleForm = register ? titleRegister : titleChangePassword;
    const descriptionForm = register ? descriptionRegister : descriptionChangePassword;

    const ingresar = () => {
        const FrequentQuestions = getItemService('FrequentQuestions')
            ? JSON.parse(getItemService('FrequentQuestions'))
            : null;
        localStorage.clear();
        sessionStorage.clear();
        FrequentQuestions && setItemService('FrequentQuestions', JSON.stringify(FrequentQuestions));
        window.location.href = RoutesConstants.LOGIN_PAGE;
    };

    const handleOnContinue = () => {
        navigate(RoutesConstants.IDENTIFICATION_CONTACT_PAGE);
    };

    return (
        <Box style={{ textAlign: 'center' }}>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <Img sx={{ mb: '100px', mt: '24px' }} src=".\check.svg" />
            {workflow === WORKFLOW_CONSTANTS.BNPL ? (
                <GrayButton text={'Continuar'} action={handleOnContinue} />
            ) : !myProfile ? (
                <GrayButton text={'Ingresar'} action={ingresar} />
            ) : null}

            <BackHomeButton />
        </Box>
    );
};
