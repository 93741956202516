import { Button, styled, useTheme, useMediaQuery } from '@mui/material';
import React from 'react';
import DownloadIcon from '../../../Assets/Icons/DownloadIcon';
import useFileDownload from '../../../Hooks/useFileDownload';
import { WORKFLOW_CONSTANTS } from '../../../Constants/BNPLConstants';

const OptionButton = styled(Button)(({smallDevice, mediumDevice}) => ({
    borderRadius: '30px',
    border: '1px solid',
    height: '40px',
    width: '100%',
    fontSize: mediumDevice ? smallDevice ? '14px' : '13px' : '16px',
    lineHeight: '24px'
}));

const DownloadableEvolutionButton = (props) => {
    const { downloadFile } = useFileDownload();
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down(340));
    const mediumDevice = useMediaQuery(theme.breakpoints.down(380));

    return (
        <OptionButton smallDevice={smallDevice} mediumDevice={mediumDevice} onClick={() => downloadFile(3, 'Evolución del préstamo')}>
            Descargar Evolución {props.variant === WORKFLOW_CONSTANTS.BNPL ? 'de la compra' : 'del préstamo'} <DownloadIcon sx={{ ml: 1, mt: 0.7 }} />
        </OptionButton>
    );
};

export default DownloadableEvolutionButton;
