import { Grid } from '@mui/material';
import { TitleCustom } from '../../Components/TitleCustom';
import '../../Styles/GlobalStyle.css';
import { FrequentQuestionsState } from '../../Contexts/FrequentQuestions/FrequentQuestionsState';
import { FrequentQuestionsTabs } from './FrequentQuestionsTabs';
import { ReturnButton } from '../../Components/LoginBtns/ReturnButton';

import STRINGS from '../../i18n/local/es.json';
import { useEffect } from 'react';
import { RoutesConstants } from '../../Constants/RoutesConstants';
import { useNavigate } from 'react-router-dom';
const { REACT_APP_ENABLE_BNPL_FAQ } = process.env;

export const FrequentQuestionsPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (REACT_APP_ENABLE_BNPL_FAQ !== 'true') {
            navigate(RoutesConstants.PUBLIC_HOME_BNPL);
        }
    }, []);

    return (
        <FrequentQuestionsState>
            <Grid container spacing={3} sx={{ mb: 10 }}>
                <Grid item sm={2}></Grid>
                <Grid item xs={11} sm={10} sx={{ mt: 1, mb: -3 }}>
                    <ReturnButton text={STRINGS.FAQ.CTA_BACK} medium route={() => history.back()} />
                </Grid>
                <Grid item sm={2}></Grid>
                <Grid item xs={11} sm={6} md={4}>
                    <TitleCustom title={STRINGS.FAQ.TITLE} subtitle={STRINGS.FAQ.SUBTITLE} />
                </Grid>
                <Grid item xs={12} sm={4} md={4} sx={{ mt: 1 }}>
                    <FrequentQuestionsTabs />
                </Grid>
            </Grid>
        </FrequentQuestionsState>
    );
};
