export const AppConstantsLS = {
    Token: "Token",
    TokenRegister: "TokenRegister",
    Person: "Person",
    AccessMethod: "AccessMethod",
    EcommerceAccountId: "ecommerceAccountId"
}

export const ACCESS_METHOD = {
    MAIL: "MAIL",
    GOOGLE: "GOOGLE",
    FACEBOOK: "FACEBOOK"
}
const Actions = {

    SET_EXAMPLE: "SET_EXAMPLE",
    
}


export default Actions;