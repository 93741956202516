import React from 'react';
import { Box, Typography } from '@mui/material';
import { ReturnButtonRounded } from '../../LoginBtns/ReturnButtonRounded';

export const DescriptionForm = ({
    title,
    fontSizeTitle = '24px',
    description,
    lineHeightDescription = '24px',
    fontSizeDescription = '16px',
    fontFamilyDescription = 'Archivo',
    whiteSpaceDescription = 'normal',
    ...props
}) => {
    return (
        <Box sx={{ textAlign: 'left' }} {...props}>
            <Typography
                variant="h2"
                fontSize={fontSizeTitle}
                color="primary"
                fontWeight={700}
                letterSpacing="-0.02em"
                sx={{ mb: '8px' }}>
                {title}
            </Typography>
            <Typography
                color="primary"
                lineHeight={lineHeightDescription}
                fontFamily={fontFamilyDescription}
                letterSpacing={fontFamilyDescription ? '-0.02em' : ''}
                fontSize={fontSizeDescription}
                fontWeight="regular"
                whiteSpace={whiteSpaceDescription}>
                {description}
            </Typography>
        </Box>
    );
};

export const Description = ({ title, description = '', returnButton = true }) => (
    <Box mt={[3, 3, 5.25]}>
        {returnButton && (
            <Box mb="54px">
                <ReturnButtonRounded />
            </Box>
        )}
        <Typography
            variant="h1"
            sx={{
                fontSize: { xs: 24, sm: 32, md: 48 },
                fontWeight: 'bold',
                letterSpacing: { xs: '-0.02em', sm: '-0.96px' }
            }}
            // color={'#636569'}
        >
            {title}
        </Typography>
        <Typography
            component="h2"
            variant="subtitle1"
            color={'#636569'}
            sx={{
                fontSize: { xs: 16, sm: 18, md: 20 },
                mt: { xs: 1, md: 2 },
                fontWeight: 'regular'
            }}>
            {description}
        </Typography>
    </Box>
);
