import { useState, useContext, useEffect } from 'react';
import { Box, Tabs as MUITabs } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { StyledTab } from '../PrivateHomeBNPLPage.styled';
import STRINGS from '../../../i18n/local/es.json';
import { ActiveLoansSection } from '../../PrivateHome/ActiveLoansSection';
import { CompletedLoansSection } from '../../../Pages/PrivateHome/CompletedLoansSection';
import { LoanActiveState } from '../../../Contexts/LoanActive/LoanActiveState';
import LoanActiveContext from '../../../Contexts/LoanActive/LoanActiveContext';
import { PrivateHomeBenefitsSection } from './PrivateHomeBenefitsSection';
import { BNPLConstants } from '../../../Constants/BNPLConstants';

const TAB_VALUE_LOANS = '0';
const TAB_VALUE_PURCHARSES = '1';

export const PrivateHomeLoanSection = () => {
    const { creditList } = useContext(LoanActiveContext);
    const [tabToDisplay, setTabToDisplay] = useState(TAB_VALUE_LOANS);
    const [filteredCreditList, setFilteredCreditList] = useState({ loans: [], purchases: [] });
    const handleTabChange = (_, newValue) => setTabToDisplay(newValue);

    useEffect(() => {
        if (creditList) {
            const _filteredCreditList = {
                loans: creditList.credits.filter(
                    (credit) => credit.commerce === BNPLConstants.LOAN_COMMERCE
                ),
                purchases: creditList.credits.filter(
                    (credit) => credit.commerce !== BNPLConstants.LOAN_COMMERCE
                )
            };
            if (_filteredCreditList.purchases.length > 0) {
                setTabToDisplay(TAB_VALUE_PURCHARSES);
            } else {
                if (_filteredCreditList.loans.length > 0) {
                    setTabToDisplay(TAB_VALUE_LOANS);
                }
            }
            setFilteredCreditList(_filteredCreditList);
        }
    }, [creditList]);

    if (!creditList || creditList?.length <= 0) {
        return <PrivateHomeBenefitsSection />;
    }

    return (
        <LoanActiveState>
            <Box mt={[5, 10]}>
                <MUITabs value={tabToDisplay} onChange={handleTabChange} variant="fullWidth">
                    <StyledTab
                        disabled={filteredCreditList.loans.length === 0}
                        label={STRINGS.bnpl_private_home.tabs.LOANS_TAB.replace(
                            '$',
                            filteredCreditList.loans.length
                        )}
                        value={TAB_VALUE_LOANS}
                    />
                    <StyledTab
                        disabled={filteredCreditList.purchases.length === 0}
                        label={STRINGS.bnpl_private_home.tabs.PURCHASES_TAB.replace(
                            '$',
                            filteredCreditList.purchases.length
                        )}
                        value={TAB_VALUE_PURCHARSES}
                    />
                </MUITabs>
                <TabContext value={tabToDisplay}>
                    <TabPanel value={TAB_VALUE_LOANS}>
                        <ActiveLoansSection list={filteredCreditList.loans} />
                    </TabPanel>
                    <TabPanel value={TAB_VALUE_PURCHARSES}>
                        <ActiveLoansSection variant="BNPL" list={filteredCreditList.purchases} />
                    </TabPanel>
                </TabContext>
                <CompletedLoansSection variant="BNPL" />
            </Box>
        </LoanActiveState>
    );
};
