import { Box, Stack } from '@mui/material';
import { DescriptionForm, Loader } from '../../../Components/BNPL';
import BackHomeButton from '../../../Components/Buttons/BackHomeButton';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { ButtonsContainer, DniPhoto, SelfiePhoto } from './ConfirmPhotoLayout.styled';

import STRINGS from '../../../i18n/local/es.json';
import defaultImage from '../../../Assets/complete-logo.svg';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import { BackHomeDialogButton } from '../../../Components/BNPL/Buttons/BackHomeDialogButton';
import { useEffect, useState } from 'react';

export const ConfirmPhotoLayout = ({
    title = '',
    description = '',
    src,
    onRepeatCapture,
    onConfirm,
    variant = 'dni',
    error,
    loading = false
}) => {
    const [position, setPosition] = useState();
    const [imagePreview, setImagePreview] = useState(new Image());

    useEffect(() => {
        if (src && variant === 'dni') {
            let i = new Image();
            i.src = src;
            setImagePreview(i);
        }
    }, [src]);

    useEffect(() => {
        if (imagePreview.src != '') {
            let positionImage = imagePreview.width > imagePreview.height;
            setPosition(positionImage);
        }
    }, [imagePreview]);

    const ImageComponent = (variant, src = defaultImage) => {
        switch (variant) {
            case 'dni':
                return (
                    <DniPhoto
                        src={src}
                        alt="dni"
                        style={{ transform: !position ? 'rotate(-90deg)' : 'rotate(0deg)' }}
                    />
                );
            case 'selfie':
                return <SelfiePhoto src={src} alt="selfie" />;
        }
    };

    return (
        <>
            <Stack>
                <DescriptionForm title={title} description={description} pb={3} />
                <Box width="100%" textAlign="center">
                    {ImageComponent(variant, src)}
                </Box>
            </Stack>
            <Stack mt="auto">
                {error && (
                    <Box>
                        <ErrorMessage title={error?.title} description={error?.description} />
                    </Box>
                )}
                <ButtonsContainer direction="row">
                    <ButtonCustom
                        border
                        bg="transparent"
                        color={ColorConstants.blue[900]}
                        text={STRINGS.biometric.CTA_REPEAT_CAPTURE}
                        action={onRepeatCapture}
                    />
                    <ButtonCustom
                        text={loading ? <Loader /> : STRINGS.biometric.CTA_CONFIRM}
                        action={onConfirm}
                        disabled={error}
                    />
                </ButtonsContainer>
                <BackHomeDialogButton />
            </Stack>
        </>
    );
};
