export const isEmpty = (value) => {
    if (value != undefined && value != null) {
        const splitValues = String(value).split('');
        return splitValues.length < 1;
    }

    return true;
};

export const allValuesComplete = (values) => {
    const valuesObject = Object.values(values);
    const emptyValues = valuesObject.filter((v) => isEmpty(v));
    const valuesComplete = isEmpty(emptyValues);

    return valuesComplete;
};

export const formatURL = (url) => {
    const domain = url.slice(7);
    const newURL = `https://${domain}`;

    return newURL;
};

export function capitalize(string) {
    if (string.split(' ').length == 1) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const splitString = string.split(' ');
    const capitalizedString = splitString.map((s) => {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
    });

    return capitalizedString.join(' ');
}

export function getCurrentDate() {
    return new Date().toISOString().split('T')[0];
}