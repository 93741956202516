import * as React from 'react';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import { Typography, Grid } from '@mui/material';
import { ColorConstants } from '../../Constants/ColorConstants';
import loading from './../../Assets/Images/loading.gif';

export const ButtonCustom = ({
    bg,
    color,
    border,
    size = '16px',
    hover,
    icon,
    text,
    width,
    height = '48px',
    padding = '12px 32px',
    margin,
    action = {},
    disabled,
    iconPosition = 'end',
    fontSize = '16px',
    isLoading = false,
    fontFamily = 'Archivo',
    fontWeight = 400,
    lineHeight = 1.5,
    id=undefined
}) => {
    const ColorButton = styled.button`
        background: ${bg ? bg : '#0069D7'};
        color: ${color ? color : ColorConstants.WHITE};
        border: ${border ? '1.5px solid' : 'none'};
        padding: ${padding};
        margin: ${margin ? margin : '10px 0px'};
        border-radius: 48px;
        font-size: ${size};
        display: flex;
        align-content: center;
        width: ${width ? width : '100%'};
        justify-content: center;
        align-items: center;
        height: ${height};
        line-height: ${lineHeight};
        :hover {
            color: white;
            background: ${hover ? hover : '#636569'};
            cursor: pointer;
        }
        :disabled {
            background: #e0e0e0;
            color: #80808094;
            border: 0px;
            cursor: context-menu;
        }
    `;
    return (
        <Stack spacing={2} direction="row" useFlexGap justifyContent={'center'}>
            <ColorButton variant="contained" onClick={() => action()} disabled={disabled} id={id}>
                {!isLoading ? (
                    icon ? (
                        <Grid container>
                            <Grid
                                xs={2}
                                sx={{
                                    '& .css-i4bv87-MuiSvgIcon-root': {
                                        width: '20px',
                                        height: '20px,'
                                    },
                                    mt: '0px',
                                    display: {
                                        xs: `${iconPosition === 'start' ? 'block' : 'none'}`
                                    }
                                }}>
                                {iconPosition === 'start' ? (
                                    icon ? (
                                        <Typography>{icon}</Typography>
                                    ) : null
                                ) : null}
                            </Grid>
                                {iconPosition === 'inline-start' ? (
                                    <Grid item xs={12} mt={iconPosition === 'end' && 0.8} display={'flex'} justifyContent={'center'} gap={'8px'} >
                                        {icon ? (
                                            <Typography>{icon}</Typography>
                                        ) : null}
                                        <Typography fontSize={fontSize} lineHeight={lineHeight}>{text}</Typography>
                                    </Grid>
                                ) : 
                                <Grid item xs={icon ? 10 : 12} mt={iconPosition === 'end' && 0.8} >
                                    <Typography fontSize={fontSize} lineHeight={lineHeight}>{text}</Typography>
                                </Grid>
                            }
                            
                            <Grid
                                xs={2}
                                sx={{
                                    '& .css-i4bv87-MuiSvgIcon-root': {
                                        width: '20px',
                                        height: '20px,'
                                    },
                                    mt: '5px',
                                    display: { xs: `${iconPosition === 'end' ? 'block' : 'none'}` }
                                }}>
                                {iconPosition === 'end' ? (
                                    icon ? (
                                        <Typography>{icon}</Typography>
                                    ) : null
                                ) : null}
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography
                            fontWeight={fontWeight}
                            fontFamily={fontFamily}
                            fontSize={fontSize}
                            lineHeight={lineHeight}>
                            {text}
                        </Typography>
                    )
                ) : (
                    <>
                        <img src={loading} style={{ width: '12%' }} />
                    </>
                )}
            </ColorButton>
        </Stack>
    );
};
