import { Box } from '@mui/system';
import { useContext } from 'react';
import { DescriptionForm } from '../../../Components/DescriptionsLayout';
import ChangePasswordContext from '../../../Contexts/ChangePassword/ChangePasswordContext';
import { ValidateEmailAndExecuteRegister } from '../../../Helpers/ValidateEmailAndExecute';
import FormExpiredLink from '../../Register/CreateAccount/FormExpiredLink';

export const ExpiredLinkChangePassword = () => {
    const { changePasswordRequest } = useContext(ChangePasswordContext);

    const changePasswordRequestValid = (email) => {
        ValidateEmailAndExecuteRegister(email, () => changePasswordRequest(email));
    };

    const titleForm = 'Tu link está vencido';
    const descriptionForm =
        'Ya pasaron más de los 10 minutos límites para usarlo, pero no te preocupes podemos enviarte uno nuevo a tu mail.';
    return (
        <Box style={{ textAlign: 'center' }}>
            <DescriptionForm title={titleForm} description={descriptionForm} />
            <FormExpiredLink actionSubmit={(email) => changePasswordRequestValid(email)} />
        </Box>
    );
};
