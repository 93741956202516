import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { ColorConstants } from '../../../Constants/ColorConstants';

const RowDetail = styled(Grid)(({ decreasePadding }) => ({
    display: 'flex',
    alignItems: 'start',
    alignContent: 'center',
    padding: decreasePadding ? '24px 8px' : '24px 24px',
    borderRadius: '32px',
    background: 'white'
}));

const Amount = styled(Grid)(({ inverted }) => ({
    fontFamily: 'Public Sans',
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: '34px',
    letterSpacing: '-0.02em',
    color: inverted ? ColorConstants.BLUE : ColorConstants.LIGHT_BLUE
}));

const Details = styled(Grid)(() => ({
    display: 'flex',
    textAlign: 'end',
    fontFamily: 'Archivo',
    fontWeight: 400,
    height: '30px',
    fontSize: '14px',
    lineHeight: '16px',
    color: ColorConstants.BLUE
}));

export const ItemDetail = ({ amount, description, inverted }) => {
    const [ decreasePadding, setDecreasePadding] = useState(false);
    const theme = useTheme();
    const smallDevice = useMediaQuery(theme.breakpoints.down(340));
    
    useEffect(() => {
        if(amount ? amount.props!=undefined : false){
            if(amount.props.children[1].length>5){
                setDecreasePadding(true);
            }else{
                setDecreasePadding(false);
            }
        }
    },[amount])
    return (
        <RowDetail inverted={inverted} decreasePadding={decreasePadding}>
            <Amount item xs={7.5} alignItems={'center'} display="flex" inverted={inverted} smallDevice={smallDevice}>
                {amount}
            </Amount>
            <Details item xs={4.5} alignItems={'center'} display="flex">
                {description}
            </Details>
        </RowDetail>
    );
};
