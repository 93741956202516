export function CaldendarIcon({ color = 'currentColor', width = '18', height = '20' }) {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 18 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.3333 1.66406V4.9974M5.66667 1.66406V4.9974M1.5 8.33073H16.5M3.16667 3.33073H14.8333C15.7538 3.33073 16.5 4.07692 16.5 4.9974V16.6641C16.5 17.5845 15.7538 18.3307 14.8333 18.3307H3.16667C2.24619 18.3307 1.5 17.5845 1.5 16.6641V4.9974C1.5 4.07692 2.24619 3.33073 3.16667 3.33073Z"
                stroke={color}
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
