import { useState } from 'react';
import parse from 'html-react-parser';
import { Icon, Title, TitleContainer } from '../FrequentQuestionsPage.styled';
import AccordionCustom from '../../../Components/AccordionCustom';
import { ButtonCustom } from '../../../Components/Buttons/ButtonCustom';
import { ColorConstants } from '../../../Constants/ColorConstants';
import { QuestionIcon } from '../../../Components/Icons';

import STRINGS from '../../../i18n/local/es.json';

export const FaqCategory = ({ title, faqs }) => {
    const [numQuestions, setNumQuestions] = useState(5);

    const handleClick = () => {
        setNumQuestions(numQuestions + 5);
    };

    const shownQuestions = faqs.slice(0, numQuestions);

    return (
        <>
            <TitleContainer>
                <Icon>
                    <QuestionIcon />
                </Icon>
                <Title>{title}</Title>
            </TitleContainer>
            {shownQuestions.map((faq) => (
                <AccordionCustom
                    key={faq.question}
                    title={parse(faq.question)}
                    information={parse(faq.answere)}
                />
            ))}
            {shownQuestions.length !== faqs.length && (
                <ButtonCustom
                    text={STRINGS.FAQ.FAQ_CATEGORY_CTA}
                    bg="transparent"
                    color={ColorConstants.blue[900]}
                    border
                    action={handleClick}
                    margin="0 0 32px 0"
                />
            )}
        </>
    );
};
