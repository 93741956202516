import { Box, Typography, styled } from '@mui/material';
import { ColorConstants } from '../../../Constants/ColorConstants';

export const ButtonContainer = styled(Box)`
    max-width: 413px;
    margin: 0 auto;

    h5 {
        text-align: center;
    }
`;

export const Title = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        fontSize: '24px',
        lineHeight: '30px'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '48px',
        lineHeight: '52px'
    },
    color: ColorConstants.blue[900],
    fontWeight: 700,
    fontFamily: 'Public Sans'
}));

export const Description = styled(Typography)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        fontSize: '22px',
        lineHeight: '28px'
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '20px',
        lineHeight: '30px'
    },
    color: ColorConstants.blue[900],
    fontWeight: 400,
    fontFamily: 'Archivo'
}));

export const gradients = [
    {
        type: 'circle',
        width: '279px',
        height: '293px',
        bgcolor: ColorConstants.blue[200],
        blur: '100px',
        left: '0',
        top: '-335px'
    },
    {
        type: 'circle',
        width: '316px',
        height: '333px',
        bgcolor: ColorConstants.blue[100],
        blur: '100px',
        left: '-60px',
        top: '389px'
    },
    {
        type: 'circle',
        width: '355px',
        height: '335px',
        bgcolor: '#7DEE80',
        blur: '100px',
        right: '-300px',
        top: '200px'
    }
];
